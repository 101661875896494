import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CmsComponentData} from "@spartacus/storefront";
import {CmsNavigationComponent} from '@spartacus/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ValioNavigationNode} from './valio-navigation-node.model';
import {ValioNavigationService} from './valio-navigation.service';
import {ValioCart} from "../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../services/cart/valio-cart.service";

@Component({
  selector: 'valio-cx-navigation',
  templateUrl: './valio-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioNavigationComponent implements OnInit{
  valioCart$: Observable<ValioCart>;
  node$: Observable<ValioNavigationNode> = this.service.createNavigation(
    this.componentData.data$
  );

  styleClass$: Observable<string> = this.componentData.data$.pipe(
    map(d => d.styleClass)
  );

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: ValioNavigationService,
    protected cartService: ValioCartService
  ) {
  }

  ngOnInit(): void {
    this.valioCart$ = this.cartService.getActive();
  }
}
