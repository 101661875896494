import {Papa, ParseResult} from "ngx-papaparse";
import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class CsvParserService {

  constructor(protected papa: Papa) {

  }

  parseAsync(str: string,
             delimiters: string[] = [' ', ',', '\t', ';'],
             header: boolean = false,
             skipEmptyLines: boolean = true): Observable<ParseResult> {
    return of(this.parse(str, delimiters, header, skipEmptyLines));
  }

  parse(str: string,
        delimiters: string[] = [',', ' ', '\t', ';'],
        header: boolean = false,
        skipEmptyLines: boolean = true): ParseResult {
    return this.papa.parse(str, {
      header: header,
      delimitersToGuess: delimiters,
      skipEmptyLines: skipEmptyLines
    });
  }
}
