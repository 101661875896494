import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule,} from '@spartacus/core';
import {ValioOrderHistoryComponent} from './valio-order-history.component';
import {ValioCartDetailsModule} from "../cart/cart-detail/valio-cart-details.module";
import {ValioDeliveryNoteModule} from "../deliverynotes/valio-deliverynote.module";
import {ValioDatepickerModule} from "../deliverynotes/datepicker/valio-datepicker.module";
import {ReactiveFormsModule} from "@angular/forms";
import {ValioInputModule} from "../../input/valio-input.module";

@NgModule({
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                AccountOrderHistoryComponent: {
                    component: ValioOrderHistoryComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        RouterModule,
        UrlModule,
        I18nModule,
        ValioCartDetailsModule,
        ValioDeliveryNoteModule,
        ValioDatepickerModule,
        ReactiveFormsModule,
        ValioInputModule,
    ],
    declarations: [ValioOrderHistoryComponent],
    exports: [ValioOrderHistoryComponent]
})

export class ValioOrderHistoryModule {
}
