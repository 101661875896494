import {Injectable} from "@angular/core";
import {ProductListComponentService, ViewConfig} from "@spartacus/storefront";
import {
  ActivatedRouterStateSnapshot,
  CurrencyService,
  LanguageService,
  ProductSearchService,
  RoutingService,
  SearchConfig
} from "@spartacus/core";
import {combineLatest, Observable} from "rxjs";
import {distinctUntilChanged, filter, map, pluck, shareReplay, tap} from "rxjs/operators";
import {ValioProductSearchPage} from "../../../../models";
import {ValioSearchConfig} from "../../../../services/product/valio-searchbox.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ValioPartnerSiteService} from "../../../../services/site/valio-partner-site.service";
import {openCloseSpinner} from "../../../../services/util/valio-modals-utils";

export const DEFAULT_PAGE_SIZE = 21;
export const IGNORE_SEARCH_URL_PARAM = "ignore=true";

export interface SearchCriteria {
  currentPage?: number;
  pageSize?: number;
  sortCode?: string;
  query?: string;
}

interface ProductListRouteParams {
  brandCode?: string;
  categoryCode?: string;
  query?: string;
}

@Injectable({providedIn: 'root'})
export class ValioProductListComponentService extends ProductListComponentService {

  selectedPartner: string;
  private latestSearchUrl: string;

  constructor(
    protected productSearchService: ProductSearchService,
    protected routing: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected valioPartnerSiteService: ValioPartnerSiteService,
    protected viewConfig?: ViewConfig
  ) {
    super(productSearchService, routing, activatedRoute, currencyService, languageService, router, viewConfig ? viewConfig : {
      view: {
        defaultPageSize: DEFAULT_PAGE_SIZE
      }
    } as ViewConfig);
    this.valioPartnerSiteService.getActive().subscribe(
      p => this.selectedPartner = p
    )
  }

  setQuery(query: string): void {
    this.setQueryParameters({query, currentPage: undefined});
  }

  private setQueryParameters(queryParams: SearchCriteria): void {
    this.router.navigate(['/search'], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  getPageItems(pageNumber: number): void {
    this.routing
      .getRouterState()
      .subscribe(route => {
        const routeCriteria = this.getCriteriaFromRoute2(
          route.state.params,
          route.state.queryParams
        );
        const criteria = {
          ...routeCriteria,
          currentPage: pageNumber,
        };
        if (route.state.queryParams.pageNumber != pageNumber) {
          this.routing.go('/search', {
            queryParams: {
              'query': this.activatedRoute.snapshot.queryParams.query,
              currentPage: pageNumber
            }
          });
        }
      })
      .unsubscribe();
  }


  private getCriteriaFromRoute2(
    routeParams: ProductListRouteParams,
    queryParams: SearchCriteria
  ): SearchCriteria {
    return {
      query: queryParams.query || this.getQueryFromRouteParams2(routeParams),
      pageSize: queryParams.pageSize || this.config.view.defaultPageSize,
      currentPage: queryParams.currentPage,
      sortCode: queryParams.sortCode,
    };
  }

  private search2(criteria: SearchCriteria): void {
    const query = criteria.query;
    const searchConfig = this.getSearchConfig2(criteria);
    openCloseSpinner(true);
// TODO:Spartacus - The 'search' method's signature changed to: 'search(  query: string | undefined,  searchConfig: SearchConfig): void'
    this.productSearchService.search(query, searchConfig);
  }

  private getSearchConfig2(criteria: SearchCriteria): SearchConfig {
    const result: ValioSearchConfig = {
      currentPage: criteria.currentPage,
      pageSize: criteria.pageSize,
      sort: criteria.sortCode,
      partner: this.selectedPartner
    };
    // drop empty keys
    Object.keys(result).forEach(key => !result[key] && delete result[key]);
    return result;
  }


  private getQueryFromRouteParams2({
                                     brandCode, categoryCode, query,
                                   }: ProductListRouteParams) {
    if (query) {
      return query;
    }
    if (categoryCode) {
      return this.RELEVANCE_ALLCATEGORIES + categoryCode;
    }
    if (brandCode) {
      return this.RELEVANCE_ALLCATEGORIES + brandCode;
    }
  }

  private searchResults2$: Observable<ValioProductSearchPage> = this.productSearchService
    .getResults()
    .pipe(
      filter(searchResult => Object.keys(searchResult).length > 0),
      map(ret => {
        return ret as ValioProductSearchPage;
      }));

  private searchByRouting2$: Observable<ActivatedRouterStateSnapshot> = combineLatest([
    this.routing.getRouterState().pipe(
      tap(state => {
        if (state.nextState === undefined) {
          this.latestSearchUrl = undefined;// clear old search when navigating back to the page from e.g. cart
        }
      }),
      distinctUntilChanged((x, y) => {
        // router emits new value also when the anticipated `nextState` changes
        // but we want to perform search only when current url changes
        return x.state.url === y.state.url;
      })
    )
  ]).pipe(
    pluck(0, 'state'),
    tap((state: ActivatedRouterStateSnapshot) => {
      if (!state.url.endsWith(IGNORE_SEARCH_URL_PARAM) && state.url != this.latestSearchUrl) {// skip empty search and duplicates
        const criteria = this.getCriteriaFromRoute2(
          state.params,
          state.queryParams
        );
        this.latestSearchUrl = state.url;
        this.search2(criteria);
      }
    })
  );

  readonly model$: Observable<ValioProductSearchPage> = combineLatest([
    this.searchResults2$,
    this.searchByRouting2$,
  ]).pipe(
    pluck(0),
    shareReplay({bufferSize: 1, refCount: true}),
    map(ret => {
      return ret;
    })
  );
}
