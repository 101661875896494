import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'valio-cx-browser-check',
  templateUrl: './valio-browser-check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioBrowserCheckComponent {
  closed: boolean = false;

  constructor() {
  }

  show(): boolean {
    const userAgent = window.navigator.userAgent;
    console.log(userAgent);
    return /msie\s|trident\//i.test(userAgent);
  }

  close() {
    this.closed = true;
  }
}
