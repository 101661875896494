import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CHECKOUT_FEATURE } from './checkout-state';
import { effects } from './effects';
import { reducerProvider, reducerToken } from './reducers';
import {CheckoutPaymentTypeAdapter, CheckoutPaymentTypeConnector} from "@spartacus/checkout/b2b/core";
import {ValioCheckoutPaymentTypeConnector} from "../../../../services/checkout/valio-checkout-payment-type.connector";
import {OccCheckoutPaymentTypeAdapter} from "@spartacus/checkout/b2b/occ";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CHECKOUT_FEATURE, reducerToken),
    EffectsModule.forFeature(effects),
  ],
  providers: [reducerProvider,
    {
      provide: CheckoutPaymentTypeConnector,
      useClass: ValioCheckoutPaymentTypeConnector,
    },
    {
      provide: CheckoutPaymentTypeAdapter,
      useClass: OccCheckoutPaymentTypeAdapter,
    }
  ],
})
export class CheckoutStoreModule {}
