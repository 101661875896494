import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { NgSelectModule } from "@ng-select/ng-select";
import { CartNotEmptyGuard } from "@spartacus/checkout/base/components";
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ValioPipeModule } from "../../../../services/pipes/valio-pipe.module";
import { ValioCalendarModule } from "../../../calendar/valio-calendar.module";
import { ValioInputModule } from '../../../input/valio-input.module';
import { ValioCartDetailsModule } from "../../cart/cart-detail/valio-cart-details.module";
import { ValioCartHeaderModule } from "../../cart/cart-header/valio-cart-header.module";
import { ValioCheckoutModule } from "../valio-checkout.module";
import { ValioReviewSubmitRowsComponent } from "./rows/valio-review-submit-rows.component";
import { ValioGenericAddressDetailsComponent } from "./shipping-address/details/valio-generic-address-details.component";
import { ValioShippingAddressDetailsComponent } from "./shipping-address/details/valio-shipping-address-details.component";
import { ValioShippingAddressComponent } from "./shipping-address/valio-shipping-address.component";
import { ValioReviewSubmitTitleComponent } from "./title/valio-review-submit-title.component";
import { ValioReviewSubmitComponent } from "./valio-review-submit.component";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    RouterModule,
    FeaturesConfigModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewOrder: {
          component: ValioReviewSubmitComponent,
        },
        CheckoutShippingAddress: {
          component: ValioShippingAddressComponent,
          guards: [CartNotEmptyGuard]
        },
      },
    }),
    ValioCheckoutModule,
    ValioCartHeaderModule,
    ValioCartDetailsModule,
    ReactiveFormsModule,
    ValioInputModule,
    ValioPipeModule,
    ValioCalendarModule,
    NgSelectModule,
    FormsModule,
  ],
  declarations: [ValioReviewSubmitComponent, ValioReviewSubmitTitleComponent, ValioReviewSubmitRowsComponent, ValioShippingAddressComponent, ValioShippingAddressDetailsComponent, ValioGenericAddressDetailsComponent],
  exports: [ValioReviewSubmitComponent, ValioReviewSubmitTitleComponent, ValioReviewSubmitRowsComponent, ValioShippingAddressComponent, ValioShippingAddressDetailsComponent, ValioGenericAddressDetailsComponent]
})
export class ValioReviewSubmitModule {
}
