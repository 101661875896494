import {ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {ValioProduct, ValioFeatureValue} from "../../../../models";
import {CurrentProductService} from '@spartacus/storefront';
import {Observable} from 'rxjs';
import {Classification, Feature, FeatureValue} from "@spartacus/core";

@Component({
  selector: 'valio-cx-product-nutrition',
  templateUrl: './valio-product-nutrition.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioProductNutritionComponent implements OnInit {
  product$: Observable<ValioProduct>;

  constructor(protected currentProductService: CurrentProductService, private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showHideNutritionTable(true, 'nutrition-left');
      this.showHideNutritionTable(true, 'nutrition-right');
      this.showHideNutritionTable(true, 'product-description');
    }
  }

  ngOnInit() {
    this.product$ = this.currentProductService.getProduct();
  }

  getClass(classifications: Classification[], className: string): Classification {
    return classifications ? classifications
      .filter(c => c.code === className)
      .find(() => true) : null;
  }

  getFeatures(classifications: Classification[], className: string): Feature[] {
    return classifications && this.getClass(classifications, className) ? this.getClass(classifications, className).features : null;
  }

  getFeature(classifications: Classification[], className: string, featureName: string): Feature {
    return classifications && this.getFeatures(classifications, className) ? this.getFeatures(classifications, className)
      .filter(f =>  f.code.endsWith('.' + featureName))
      .find(() => true) : null;
  }

  showHideNutritionTable(forceHide: boolean, nutritionTable: string) {
    if (document.getElementById(nutritionTable)) {
      if (forceHide) {
        document.getElementById(nutritionTable).querySelector('.nutrition-header').classList.remove('collapsed');
        document.getElementById(nutritionTable).querySelector('.nutrition-info').classList.add('d-none');
      } else {
        document.getElementById(nutritionTable).querySelector('.nutrition-header').classList.toggle('collapsed');
        document.getElementById(nutritionTable).querySelector('.nutrition-info').classList.toggle('d-none');
      }
    }
  }

  getImage(value) {
    return (value as ValioFeatureValue).image;
  }
}
