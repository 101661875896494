<div *ngFor="let message of item.erpMessages" class="message {{message.severity}}">

  <ng-container *ngIf="message.date">
    {{message.message }}
    <button class="action link" (click)="removeItem()">
      {{'cart.cartItems.messages.cancel' |cxTranslate}}
    </button>
    <button class="action link" (click)="updateRequestedDate(message.date)">
      {{'cart.cartItems.messages.updateRequestedDate' |cxTranslate}}
    </button>
  </ng-container>

  <ng-container *ngIf="message.merge == true">
    {{'cart.cartItems.messages.doMerge' |cxTranslate}}
    <button class="action link" (click)="removeItem()">
      {{'cart.cartItems.messages.cancel' |cxTranslate}}
    </button>
    <button class="action link" (click)="mergeEntry(false)">
      {{'cart.cartItems.messages.doNotMergeEntry' |cxTranslate}}
    </button>
    <button class="action link" (click)="mergeEntry(true)">
      {{'cart.cartItems.messages.mergeEntry' |cxTranslate}}
    </button>
  </ng-container>

  <ng-container *ngIf="message.confirm == true && message.message && message.severity == 'INFO'">
    {{message.message + ''|cxTranslate }}
    <button class="action link" (click)="confirm(false)">
      {{'cart.cartItems.messages.cancel' |cxTranslate}}
    </button>
    <button class="action link" (click)="confirm(true)">
      {{'cart.cartItems.messages.confirm' |cxTranslate}}
    </button>
  </ng-container>

  <ng-container *ngIf="message.productFoundWithSameCode == true ">
    {{message.message |cxTranslate }}
  </ng-container>

  <ng-container *ngIf="message.switchProduct" >
    {{message.message }}
    <button class="action link" (click)="replace(message.replacement)">{{'cart.cartItems.messages.replace'|cxTranslate}}</button>
  </ng-container>

  <ng-container *ngIf="!message.date && !message.merge && !message.confirm &&!message.productFoundWithSameCode">
    {{message.message }}
  </ng-container>
</div>
