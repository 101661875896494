<label class="searchbox" [class.dirty]="!!searchInput.value">
  <input
    [class.is-invalid]="isInvalid"
    autocomplete="off"
    #searchInput
    id="searchInputField"
    [placeholder]="'searchBox.placeholder' | cxTranslate"
    value="{{value}}"
    aria-label="search"
    (keyup)="searchEvent($event)"
    (keydown.escape)="clearSearchBox()"
    data-hj-whitelist
  />
  <ng-container *ngIf="frontPage">
    <span class="reset">
      <span
        aria-label="reset"
        (click)="clearSearchBox()"
        class="valio-icon icon-reset"
      ></span>
    </span>
    <span class="search">
      <span
        aria-label="search"
        class="valio-icon icon-search"
        (click)="avoidReopen($event)"
      ></span>
    </span>
    <span class="search-open">
      <span
        aria-label="search"
        class="valio-icon icon-search"
        (click)="launchSearchResult($event, searchInput.value)"
      ></span>
    </span>
  </ng-container>
</label>

<div id="result-wrapper" class="result-wrapper d-none d-lg-block" *ngIf="resultActive && searchInput.value.length > 0 && searchResults$ | async as result" (click)="close($event)">
  <div class="results">
    <div *ngIf="result.message" class="message" [innerHTML]="result.message"></div>

    <div class="suggestions" (mousedown)="disableClose()">
      <a
        *ngFor="let suggestion of result.suggestions"
        [routerLink]="null" ]="
        {
          cxRoute: 'search',
          params: { query: suggestion }
        } | cxUrl
      "
      >
      </a>
    </div>

    <div class="products" (mousedown)="disableClose()" *ngIf="result.products">
      <a *ngFor="let product of result.products; index as idx" (mouseover)="showTooltip(product, idx)" class="{{getAnchorClassName()}}" [ngClass]="{'active':isActive(idx)}" [attr.data-index]="idx" [attr.data-product]="product|json">
        <span class="product d-block" (click)="selectProduct(product)">
          <span class="image d-inline-block margin-right-base"><cx-media [container]="product.images?.PRIMARY" format="cartIcon"></cx-media></span>
          <span class="d-inline-block padding-top-sm">
            <span class="name d-block" [innerHtml]="product.nameHtml"></span>
            <span class="code d-block">{{ 'cartItems.id' | cxTranslate }} {{ product.code|productCode }}</span>
          </span>
        </span>
      </a>
    </div>
  </div>
  <div class="sticky-results text-right" *ngIf="result?.pages && !result.message">
    <button class="btn btn-link btn-icon-link" (click)="launchSearchResult($event, searchInput.value)">{{'searchBox.showAll'|cxTranslate: {total: result.pages.totalResults} }} <span class="valio-icon icon-chevron icon-chevron-right"></span></button>
  </div>
</div>

<valio-product-cx-tooltip
  [showEvents]="tooltipSubject.asObservable()"
  [showPrice]="true"
  (tooltipClosed)="tooltipClosed()"
>
</valio-product-cx-tooltip>
