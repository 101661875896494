import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule, I18nModule, UrlModule,} from '@spartacus/core';
import {PageSlotModule} from '@spartacus/storefront';
import {ValioLoginComponent} from './valio-login.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        PageSlotModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                LoginComponent: {
                    component: ValioLoginComponent,
                },
            },
        }),
        I18nModule
    ],
    declarations: [ValioLoginComponent],
    exports: [ValioLoginComponent]
})
export class ValioLoginModule {
}
