import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {ValioCalendarDateComponent} from "./date/valio-calendar-date.component";
import {ValioCommonModule} from "../../shared/valio-common.module";
import {ValioCalendarComponent} from "./valio-calendar.component";
import {ValioCartHeaderModule} from "../cms-components/cart/cart-header/valio-cart-header.module";
import {RouterModule} from "@angular/router";
import {MediaModule} from "@spartacus/storefront";
import {ValioCartSharedModule} from "../cms-components/cart/cart-shared/valio-cart-shared.module";
import {ValioPipeModule} from "../../services/pipes/valio-pipe.module";
import {ValioCalendarDataService} from "./valio-calendar-data.service";
import {ValioNavigationGuard} from "../cms-components/navigation/valio-navigation.guard";
import {ValioInputModule} from "../input/valio-input.module";

@NgModule({
    providers: [
        {
            provide: ValioCalendarDataService,
            useClass: ValioCalendarDataService,
        }
    ],
    imports: [
        I18nModule,
        ValioCommonModule,
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioCalendarComponent: {
                    component: ValioCalendarComponent,
                    guards: [ValioNavigationGuard],
                },
            },
        }),
        ValioCartHeaderModule,
        RouterModule,
        MediaModule,
        ValioCartSharedModule,
        ValioPipeModule,
        ValioInputModule
    ],
    exports: [ValioCalendarDateComponent, ValioCalendarComponent],
    declarations: [ValioCalendarDateComponent, ValioCalendarComponent]
})
export class ValioCalendarModule {
}
