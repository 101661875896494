<ng-container *ngIf="!(isLoading$ | async); else spinner">
  <div class="cx-register">
    <div class="content-center">

      <ng-container *ngIf="showStep1">
        <div class="label">{{'valioRegister.header.register' | cxTranslate}}</div>
        <form [formGroup]="userRegistrationFormStep1">
          <div class="dots">
            <span class="dot black"></span>
            <span class="dot white"></span>
          </div>

          <h3>{{'valioRegister.header.customer' | cxTranslate}}</h3>

          <valio-cx-input [isNotValidFunction]="isInvalidStep1('firstName')"
                          [labelPlaceholder]="'updateProfileForm.firstName.placeholder'" [nameForm]="'firstName'"
                          [parentForm]="userRegistrationFormStep1" [idName]="'firstName'" class="valio-input"></valio-cx-input>

          <valio-cx-input [isNotValidFunction]="isInvalidStep1('lastName')"
                          [labelPlaceholder]="'updateProfileForm.lastName.placeholder'" [nameForm]="'lastName'"
                          [parentForm]="userRegistrationFormStep1" [idName]="'lastName'" class="valio-input"></valio-cx-input>

          <valio-cx-input [isNotValidFunction]="isInvalidStep1('phoneNumber')" [errorMessage]="'updateProfileForm.phone.errorMessage'"
                          [labelPlaceholder]="'updateProfileForm.phone.label'" [nameForm]="'phoneNumber'"
                                    [parentForm]="userRegistrationFormStep1" [idName]="'phoneNumber'" class="valio-input"></valio-cx-input>

          <valio-cx-input [isNotValidFunction]="isInvalidStep1('email') || (userRegistrationFormStep1.get('email').errors && (userRegistrationFormStep1.get('email').errors['email'] || userRegistrationFormStep1.get('email').errors['InvalidEmail']) && userRegistrationFormStep1.get('email').dirty)"
                          [labelPlaceholder]="'updateProfileForm.email.placeholder'" [nameForm]="'email'"
                          [parentForm]="userRegistrationFormStep1" [typeInput]="'email'" [idName]="'email'" class="valio-input"></valio-cx-input>

          <div class="label-content header">{{'valioRegister.userAndPassword.label' | cxTranslate}}</div>

          <valio-cx-input [isNotValidFunction]="isInvalidStep1('userId') || userRegistrationFormStep1.hasError('userIdTaken')"
                          [labelPlaceholder]="'updateProfileForm.userId.placeholder'" [nameForm]="'userId'" (afterChange)="validateForm()"
                          [parentForm]="userRegistrationFormStep1" [idName]="'userId'" class="valio-input" [errorMessage]="'valioRegister.userIdTaken'"></valio-cx-input>

          <valio-cx-input [isNotValidFunction]="isInvalidStep1('password') || (userRegistrationFormStep1.get('password').invalid && userRegistrationFormStep1.get('password').dirty)"
                          [typeInput]="'password'" [errorMessage]="'updatePasswordForm.passwordMinRequirements'" [idName]="'password'"
                          [labelPlaceholder]="'updatePasswordForm.createNewPassword.placeholder'" [nameForm]="'password'"
                          [parentForm]="userRegistrationFormStep1"
                          [extraInfoBottom]="'updatePasswordForm.passwordMinRequirements'" class="valio-input"></valio-cx-input>

          <valio-cx-input [isNotValidFunction]="userRegistrationFormStep1.hasError('NotEqual') && (userRegistrationFormStep1.get('passwordconf').dirty && userRegistrationFormStep1.get('passwordconf').touched)"
                          [typeInput]="'password'" [errorMessage]="'updatePasswordForm.bothPasswordMustMatch'" [idName]="'passwordconf'"
                          [labelPlaceholder]="'updatePasswordForm.confirmNewPassword.placeholder'" [nameForm]="'passwordconf'"
                          [parentForm]="userRegistrationFormStep1"
                          class="valio-input"></valio-cx-input>

          <div class="form-group">
            <div class="form-check">
              <label>
                <input
                  [class.is-invalid]="isInvalidStep1('termsandconditions')"
                  type="checkbox"
                  name="termsandconditions"
                  formControlName="termsandconditions"
                />
                <span
                  [class.is-invalid]="isInvalidStep1('termsandconditions')"
                  class="checkmark"></span>
                <span class="form-check-label">{{ 'register.confirmThatRead' | cxTranslate }}
                  <a role="link"
                     [routerLink]="{ cxRoute: 'termsOfService' } | cxUrl"
                     target="_blank"
                  >{{ 'register.termsOfService' | cxTranslate }}</a>
                  {{ 'register.and' | cxTranslate }}
                  <a role="link"
                     [routerLink]="{ cxRoute: 'privacyPolicy' } | cxUrl"
                     target="_blank"
                  >{{ 'register.privacyPolicy' | cxTranslate }}</a></span>
              </label>
            </div>
          </div>

          <div class="register-next">
            <button
              type="button"
              (click)="goToSecondStep()"
              class="btn btn-lg btn-primary"
            >{{ 'valioRegister.next' | cxTranslate }}
            </button>
            <br/>
            <br/>
            <a
              class="cx-login-link"
              [routerLink]="{ cxRoute: 'login' } | cxUrl"
            >{{ 'valioRegister.toLogin' | cxTranslate }}
              <span class="bold">{{ 'valioRegister.signIn' | cxTranslate }}</span>
            </a>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="showStep2">
        <div class="label">{{'valioRegister.header.register' | cxTranslate}}</div>
        <form [formGroup]="userRegistrationFormStep2">
          <div class="dots">
            <span class="dot white"></span>
            <span class="dot black"></span>
          </div>

          <h3>{{'valioRegister.header.company' | cxTranslate}}</h3>

          <valio-cx-input [isNotValidFunction]="isInvalidStep2('companyName')"
                          [labelPlaceholder]="'valioRegister.companyName.placeholder'" [nameForm]="'companyName'"
                          [parentForm]="userRegistrationFormStep2" class="valio-input"></valio-cx-input>

          <valio-cx-input [isNotValidFunction]="isInvalidStep2('companyNameDepartment')"
                          [labelPlaceholder]="'valioRegister.companyNameDepartment.placeholder'" [nameForm]="'companyNameDepartment'"
                          [parentForm]="userRegistrationFormStep2" class="valio-input"></valio-cx-input>

          <valio-cx-input [isNotValidFunction]="isInvalidStep2('vatId') || (userRegistrationFormStep2.get('vatId').errors && (userRegistrationFormStep2.get('vatId').errors['vatId'] || userRegistrationFormStep2.get('vatId').errors['InvalidVatId']) && userRegistrationFormStep2.get('vatId').dirty)"
                          [labelPlaceholder]="'valioRegister.vatId.placeholder'" [nameForm]="'vatId'"
                          [parentForm]="userRegistrationFormStep2" class="valio-input"></valio-cx-input>

          <div class="label-content header">{{'valioRegister.header.add' | cxTranslate}}</div>
          <div class="text-content">{{'valioRegister.header.info' | cxTranslate}}</div>

          <valio-cx-customer-number
            [submitted]="submittedStep2"
            [form]="userRegistrationFormStep2">
          </valio-cx-customer-number>

          <div class="register-next">
            <div class="register-back">
              <a (click)="goToFirstStep()">
                <span class="valio-icon icon-inline icon-chevron icon-chevron-left margin-right-sm"></span>
                <span>{{ 'common.return' | cxTranslate }}</span>
              </a>
            </div>
            <button
              type="button"
              (click)="submit()"
              class="btn btn-lg btn-primary"
            >
              {{ 'valioRegister.register' | cxTranslate }}
            </button>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="showThanks">
        <div class="last-step-content">
          <h3>{{'valioRegister.validRegister.label' | cxTranslate}}</h3>
          <p>{{'valioRegister.validRegister.existing' | cxTranslate}}</p>
          <div class="register-next success">
            <button
              type="button"
              (click)="goToHomePage()"
              class="btn btn-lg btn-primary"
            >{{ 'valioRegister.toHome' | cxTranslate }}
            </button>
          </div>

          <cx-page-slot position="CustomerServiceContact"></cx-page-slot>
        </div>
      </ng-container>

    </div>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
