import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output,} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'valio-cx-generic-address-details',
  templateUrl: './valio-generic-address-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioGenericAddressDetailsComponent {

  @Input() submitted: boolean;
  @Input() soldTo: boolean;
  @Input() titleKey: string;
  @Input() addressForm: UntypedFormGroup;
  @Output() afterChange = new EventEmitter<void>();

  constructor() {
  }

  isInvalidStep(fieldName: string): boolean {
    return this.submitted && this.addressForm.get(fieldName) && this.addressForm.get(fieldName).invalid;
  }

  onChange() {
    this.afterChange.emit();
  }
}
