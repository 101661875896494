import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {BREAKPOINT, CarouselComponent, LayoutConfig} from '@spartacus/storefront';
import {Observable, Subscription} from 'rxjs';
import {ValioCmsBannerComponent, ValioProduct} from '../../../../models';
import {ValioCarouselService} from "./valio-carousel.service";
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";

@Component({
  selector: 'valio-cx-carousel',
  templateUrl: './valio-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioCarouselComponent extends CarouselComponent implements OnInit,OnDestroy {
  @Input() products: ValioProduct[];
  @Input() banner$: Observable<ValioCmsBannerComponent>;
  @Input() size: number;
// TODO:Spartacus - The type of property 'title: string' changed to: 'title: string | undefined | null' 
  @Input() title: string;
  @Input() showScroll: string;
  productWidthsSet: boolean = true;
  previousArrowDisabled: boolean = false;
  nextArrowDisabled: boolean = false;
  carouselScrollTimeout: any;
  isMobile: boolean;
  private resize = new EventEmitter();
  subscriptions: Subscription = new Subscription();

  constructor(protected el: ElementRef,
              protected config: LayoutConfig,
              protected service: ValioCarouselService,
              protected googleAnalyticsService: ValioGoogleAnalyticsService,
              private cdr: ChangeDetectorRef) {
    super(el, service);
  }

  ngOnInit() {
    this.isMobile = this.getIsMobile();
    if (this.products) {
      this.products = this.products.filter(product => product.code).map((product, index) => {
        return {
          ...product,
// TODO:Spartacus - The type of property 'title: string' changed to: 'title: string | undefined | null' 
          gaList: {position: index + 1, listName: this.title}
        }
      });
    }
    if (this.showScroll == "true") {
      this.subscriptions.add(
        this.resize.pipe().subscribe(() => {
          this.productWidthsSet = false;
          const $nativeElement = this.el.nativeElement;
          $nativeElement.setAttribute("style", "width: 100%");
          this.checkPosition(Math.ceil($nativeElement.querySelector(".carousel-scroll").scrollLeft));
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize')
  onResize() {
    if (this.showScroll == "true") {
      this.resize.next();
    }
  }

  next() {
    this.scrollTo(true);
  }

  previous() {
    this.scrollTo(false);
  }

  scrollTo(next: boolean) {
    const $nativeElement = this.el.nativeElement;
    const firstProductWidth = Math.ceil($nativeElement.querySelector("valio-cx-product-grid-item").offsetWidth);
    const productWidth = this.isMobile ? firstProductWidth + 8 : firstProductWidth; // for mobile add padding-left to product width
    const bannerElement = $nativeElement.querySelector("valio-cx-banner");
    const bannerWidth = this.isMobile || bannerElement == null ? 0 : Math.ceil(bannerElement.offsetWidth) + 8; // banner width plus padding-right to next product
    const carouselPosition = Math.ceil($nativeElement.querySelector(".carousel-scroll").scrollLeft);
    const fullItemsScrolled = Math.round(carouselPosition / productWidth);
    const zoomLevelAdjustment = window.devicePixelRatio != 1 ? 2 : 0; // Browser zoom is changing scroll position value and causing calculation bug so adding couple pixels helps us over the banner
    let scrollTo = 0;

    if (next) {
      if ((carouselPosition + zoomLevelAdjustment) >= bannerWidth) {
        scrollTo = (fullItemsScrolled * productWidth) + productWidth;
      } else {
        scrollTo = bannerWidth;
      }
    } else {
      if ((carouselPosition - zoomLevelAdjustment) <= bannerWidth) {
        scrollTo = 0;
      } else {
        scrollTo = (fullItemsScrolled * productWidth) - productWidth;
      }
    }

    $nativeElement.querySelector(".carousel-scroll").scrollLeft = scrollTo;
  }

  initProductCarouselWidths(isLast: boolean) {
    if (isLast) {
      this.productWidthsSet = false;
      this.checkPosition(0);
    }
  }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = this.config.breakpoints[BREAKPOINT.sm];
    return w < breakpoint;
  }

  checkPosition(carouselPosition) {
    const $nativeElement = this.el.nativeElement;
    const carouselWidth = $nativeElement.offsetWidth - 16; // valio-cx-carousel width minus paddings
    const carouselItemsWidth = $nativeElement.querySelector(".carousel-items").scrollWidth;
    const carouselEndPosition = carouselItemsWidth - carouselWidth;

    // set carousel arrows visible based scroll position
    if (carouselEndPosition == 0) {
      this.previousArrowDisabled = true;
      this.nextArrowDisabled = true;
    } else if (carouselPosition == 0) {
      this.previousArrowDisabled = true;
      this.nextArrowDisabled = false;
    } else if (carouselPosition >= carouselEndPosition) { // when carousel is scrolled to end
      this.previousArrowDisabled = false;
      this.nextArrowDisabled = true;
    } else {
      this.previousArrowDisabled = false;
      this.nextArrowDisabled = false;
    }
    this.cdr.detectChanges();

    this.carouselProductsReady($nativeElement).then(() => {
      let fullItemsScrolled = 0;
      let visibleItems = 0;

      if (this.showScroll == "true") {
        const isMobile = this.isMobile;
        const productMinWidth = 192;
        const carouselWidth = $nativeElement.offsetWidth;
        const carouselItems = Math.floor(carouselWidth / productMinWidth);
        let productWidth = Math.ceil(carouselWidth / carouselItems); // Calculate width for product cards
        if (isMobile && carouselItems < 2) { // set product width to min width for mobile
          productWidth = productMinWidth;
        }
        let bannerWidth = isMobile ? 0 : Math.ceil(productWidth * 2) - 8; // banner width minus padding-left

        // set widths for products and banner
        if (!this.productWidthsSet) {
          const banners = $nativeElement.querySelectorAll("valio-cx-banner");
          banners.forEach(element => {
            element.setAttribute("style", "width:" + bannerWidth + "px");
          });
          const products = $nativeElement.querySelectorAll("valio-cx-product-grid-item");
          products.forEach(function (element, idx, array) {
            if ((isMobile && idx === 0) || idx === array.length - 1) {
              element.setAttribute("style", "width:" + (productWidth - 8) + "px"); // product width minus padding-right when last item or product width minus padding-left when mobile and first item
            } else {
              element.setAttribute("style", "width:" + productWidth + "px");
            }
          });
          $nativeElement.setAttribute("style", "width:" + (productWidth * carouselItems) + "px"); // Adjust carousel width to match new calculated product cards width
          this.productWidthsSet = true;
        }

        // check visible products for google analytics
        const carouselItemsWidth = $nativeElement.querySelector(".carousel-items").scrollWidth;
        const carouselEndPosition = carouselItemsWidth - carouselWidth;
        visibleItems = Math.round(carouselWidth / productWidth);
        if (!isMobile) {
          bannerWidth += 8; // banner width plus padding-right to next product
        }
        if (carouselPosition <= bannerWidth) {
          visibleItems = Math.round((carouselWidth - (bannerWidth - carouselPosition)) / productWidth);
        } else if (carouselPosition >= carouselEndPosition) { // when carousel is scrolled to end
          fullItemsScrolled = Math.round((carouselEndPosition - bannerWidth) / productWidth);
        } else {
          fullItemsScrolled = Math.round((carouselPosition - bannerWidth) / productWidth);
        }
      } else {
        visibleItems = this.products.length;
      }

      const start = fullItemsScrolled;
      const end = fullItemsScrolled + visibleItems;
      if (end > 0) { // on mobile carousel banner might take full device width
// TODO:Spartacus - The type of property 'title: string' changed to: 'title: string | undefined | null' 
        this.googleAnalyticsService.createCarouselImpressionEvent(this.products, this.title, start, end);
      }
    });
  }

  carouselProductsReady = function ($nativeElement) {
    return new Promise((resolve) => {
      const waitForProducts = function () {
        if ($nativeElement.querySelector("valio-cx-product-grid-item")) { // check if carousel products exist
          resolve($nativeElement);
        } else {
          window.requestAnimationFrame(waitForProducts);
        }
      };
      waitForProducts();
    })
  };

  scrolling() {
    clearTimeout(this.carouselScrollTimeout);
    this.carouselScrollTimeout = setTimeout(() => { // check carousel position and call google analytics after scrolling is stopped
      const $nativeElement = this.el.nativeElement;
      this.checkPosition(Math.ceil($nativeElement.querySelector(".carousel-scroll").scrollLeft));
    }, 500);
  }
}
