import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Actions} from "@ngrx/effects";
import {CartActions} from "@spartacus/cart/base/core";
import {DeliveryMode} from "@spartacus/cart/base/root";
import {
  Country,
  OCC_USER_ID_ANONYMOUS,
  RoutingService, UserIdService
} from '@spartacus/core';
import {CustomFormValidators} from '@spartacus/storefront';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {Observable, Subscription} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import {ValioUser} from '../../../../../../models/misc.model';
import {RouteCalendar} from "../../../../../../models/valio-calendar.model";
import {ValioAddress} from "../../../../../../services/address/address-model";
import {ValioCart, ValioPointOfService} from '../../../../../../services/cart/valio-cart.objects';
import {ValioCartService} from '../../../../../../services/cart/valio-cart.service';
import {ValioRouteCalendarService} from "../../../../../../services/routecalendar/valio-routecalendar.service";
import {AnonymousPostalCodeValidator} from "../../../../../../services/user/anonymousPostalCode.validator";
import {ValioUserService} from '../../../../../../services/user/valio-user.service';
import {openCloseSpinner} from "../../../../../../services/util/valio-modals-utils";
import VatIdValidator from "../../../../../../shared/utils/validators/VatIdValidator";
import {ValioRegisterExistingComponent} from "../../../../user/register/valio-register-existing.component";

@Component({
  selector: 'valio-cx-shipping-address-details',
  templateUrl: './valio-shipping-address-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioShippingAddressDetailsComponent implements OnInit, OnDestroy {

  @Input() cart: ValioCart;
  @Input() editable = true as boolean;
  @Input() headerDate: Date;
  user$: Observable<ValioUser>;
  contactForm: UntypedFormGroup;
  loggedUser: boolean;
  routeCalendar: RouteCalendar;

  deliveryslotNotSelected: boolean;
  pickupPointNotSelected: boolean;

  soldToAddressForm: UntypedFormGroup;
  shipToAddressForm: UntypedFormGroup;
  useShipTo = false;
  submitted = false;
  requestedDate: Date;

  selectedPickupValue: string;
  selectedPickupPoint: ValioPointOfService;
  subscription: Subscription = new Subscription();
  userId: string;
  isGuestCart: boolean = false;

  constructor(
    protected fb: UntypedFormBuilder,
    protected routingService: RoutingService,
    protected cartService: ValioCartService,
    protected userIdService: UserIdService,
    protected cdr: ChangeDetectorRef,
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade,
    protected anonymousPostalCodeValidator: AnonymousPostalCodeValidator,
    protected actions$: Actions,
    protected routeCalendarService: ValioRouteCalendarService) {
  }

  ngOnInit(): void {
    this.routeCalendarService.getRouteCalendar().subscribe(r => {
      if (r.routeDates?.length > 0) {
        this.routeCalendar = r;
        this.cdr.detectChanges();
      }
    });

    this.subscription.add(
      this.cartService.isGuestCart()
        .pipe(withLatestFrom(this.userService.isSignedIn()))
        .subscribe(([isGuestCart, isSigned]) => {
          this.loggedUser = isSigned && !isGuestCart;
          this.isGuestCart = isGuestCart;
        })
    )
    this.initContactForm(this.cart.deliveryAddress.phone, this.cart.deliveryAddress.email ? this.cart.deliveryAddress.email : '', this.cart.deliveryAddress.vatId);
    this.user$ = this.userAccountFacade.get().pipe(map(user => user as ValioUser))
    this.useShipTo = this.cart.paymentAddress?.postalCode != null && this.cart.paymentAddress?.postalCode != '';

    this.soldToAddressForm = this.initAddressForm(this.useShipTo ? this.cart.paymentAddress : this.cart.deliveryAddress, [Validators.required, CustomFormValidators.emailValidator], [Validators.required, VatIdValidator.valid]);
    this.shipToAddressForm = this.initAddressForm(this.useShipTo ? this.cart.deliveryAddress : {}, null, null);

    this.subscription.add(
      this.cartService.getRequestedDate().subscribe(d => this.requestedDate = d)
    );
    this.selectedPickupValue = this.cart.allowedPickupPoints?.find(pp => pp.selected)?.name;
    this.selectedPickupPoint = this.cart.allowedPickupPoints?.find(pp => pp.selected);
    this.subscription.add(this.userIdService.getUserId().subscribe((userId) => {
      this.userId = userId
    }))
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getDeliveryDay(): string {
    return 'cart.header.weekday.' + (1 + this.headerDate.getDay());
  }

  goNext(cart: ValioCart): void {
    this.submitted = true;
    if (this.cart.deliverySlot == null && !this.cart.allowedPickupPoints) {
      this.deliveryslotNotSelected = true;
    } else {
      this.deliveryslotNotSelected = false;
      if (!this.loggedUser) {
        this.pickupPointNotSelected = this.cart.allowedPickupPoints && !this.selectedPickupValue;
        if (this.allowGoNext()) {
          if (cart.user.uid === OCC_USER_ID_ANONYMOUS) {
            openCloseSpinner(true);
            // Join email, first name and last name here, do splitting in backend
            const email: string = this.soldToAddressForm.controls.email.value + '|' + this.soldToAddressForm.controls.firstName.value + '|' + this.soldToAddressForm.controls.lastName.value;
            this.cartService.addValioEmail(cart, email, this.userId);

            let emailAdded = false;
            let cartUpdated = false;
            const sub = this.actions$.subscribe(action => {

              if (action.type === CartActions.ADD_EMAIL_TO_CART_SUCCESS) {
                emailAdded = true
              }
              if (action.type === CartActions.LOAD_CART_SUCCESS) {
                cartUpdated = true
              }
              if(emailAdded && cartUpdated) {
                emailAdded=false
                cartUpdated= false
                this.anonymousCheckout();
              }
              // TODO:Spartacus - Action removed. Look for replacement in 'AuthService' and 'OAuthLibWrapperService'.
              if (action.type === CartActions.ADD_EMAIL_TO_CART_FAIL  /*|| action.type === AuthActions.LOAD_USER_TOKEN_FAIL*/) {
                console.log("failed to register " + action.type)
                openCloseSpinner(false);
              }
            });
          } else {
            this.anonymousCheckout();
          }
        }
      } else {
        if (this.contactForm.valid) {
          this.routingService.go('/checkout/review-order-invoice');
        }
      }
    }
    this.cdr.detectChanges();
  }

  private anonymousCheckout() {
    this.routingService.go('/checkout/review-order-credit');
  }

  back(): void {
    this.routingService.go('cart');
  }

  updateDeliverySlot(val: string): void {
    this.cartService.updateHeader('deliverySlot', val);
  }

  updatePhone(): void {
    if (this.contactForm.controls.phone.valid) {
      this.cartService.updateHeader('phone', this.contactForm.controls.phone.value);
    }
  }

  updateEmail(): void {
    if (this.contactForm.controls.email.valid) {
      this.cartService.updateHeader('email', this.contactForm.controls.email.value);
    }
  }

  updateVatId(): void {
    if (this.contactForm.controls.vatId.valid) {
      this.cartService.updateHeader('vatId', this.contactForm.controls.vatId.value);
    }
  }

  updateAddresses(): void {
    if (this.useShipTo) {
      this.cartService.changeSoldtoAddress(this.createAddressPayload(this.soldToAddressForm));
    } else {
      this.cartService.changeShiptoAddress(this.createAddressPayload(this.soldToAddressForm));
    }
    // This needs to be done like this. Otherwise, won't update soldTo/paymentAddress to cart when using shipTo. Most likely has something to do with requireLoadedCart method.
    const sub = this.actions$.subscribe(action => {
        if (action.type === CartActions.LOAD_CART_SUCCESS) {
          if (this.useShipTo) {
            this.cartService.changeShiptoAddress(this.createAddressPayload(this.shipToAddressForm));
          } else {
            this.cartService.changeSoldtoAddress({});
          }
          sub.unsubscribe()
        }
      }
    );
  }
  createAddressPayload(form: UntypedFormGroup) {
    return {
      ...form.getRawValue(),
      country: {isocode: 'FI'} as Country
    } as ValioAddress;
  }

  private initContactForm(phone: string, email: string, vatId: string) {
    this.contactForm = this.fb.group({
      phone: [phone, ValioRegisterExistingComponent.phoneNumberValidator],
      email: [email, CustomFormValidators.emailValidator],
      vatId: [vatId, [Validators.required, VatIdValidator.valid]]
    });
  }

  private initAddressForm(address: ValioAddress, emailValidators, vatIdValidators): UntypedFormGroup {
    return this.fb.group(
      {
        vatId: [address.vatId ? address.vatId : '', vatIdValidators],
        firstName: [address.firstName ? address.firstName : '', Validators.required],
        lastName: [address.lastName ? address.lastName : '', Validators.required],
        phone: [address.phone ? address.phone : '+358', ValioRegisterExistingComponent.phoneNumberValidator],
        email: [address.email ? address.email : '', emailValidators],
        companyName: [address.companyName ? address.companyName : '', Validators.required],
        department: [address.department ? address.department : ''],
        line1: [address.line1 ? address.line1 : '', Validators.required],
        postalCode: [address.postalCode ? address.postalCode : '', {
          validators: Validators.required,
          asyncValidators: this.anonymousPostalCodeValidator.validate.bind(this.anonymousPostalCodeValidator)
        }],
        town: [address.town ? address.town : '', Validators.required]
      }
    );
  }

  selectDate(date: string) {
    this.cartService.changeDate(date);
  }

  updateDeliveryMode(mode: DeliveryMode) {
    this.cartService.changeDeliveryMode(mode);
  }

  updatePickupLocation(point: ValioPointOfService) {
    this.cartService.changePickupLocation(point.name);
    this.pickupPointNotSelected = false;
  }

  setUseShipTo(checked: boolean) {
    this.useShipTo = checked;
    this.updateAddresses()
    this.cdr.detectChanges();
  }

  allowGoNext() {
    return this.soldToAddressForm?.valid && (!this.useShipTo || this.shipToAddressForm?.valid)
      && !(this.cart.deliverySlot == null && !this.cart.allowedPickupPoints)
      && !(this.cart.allowedPickupPoints && !this.selectedPickupValue);
  }
}
