import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {ValioUser} from "../../../../models/misc.model";
import {map} from "rxjs/operators";
import { UserAccountFacade } from '@spartacus/user/account/root';


@Component({
  selector: 'valio-cx-contracts-profile',
  templateUrl: './valio-contracts.component.html',
})
export class ValioContractsComponent implements OnInit {
  user$: Observable<ValioUser>;

  constructor(protected userAccountFacade: UserAccountFacade) {

  }

  ngOnInit(): void {
    this.user$ =this.userAccountFacade.get().pipe(map(user => (user as ValioUser)));
  }

  togglePartner(id) {
    document.getElementById(id).classList.toggle('collapsed');
  }

  isCollapsed(id: string): boolean {
    if (document.getElementById(id)) {
      return (!document.getElementById(id).classList.contains('collapsed'));
    } else {
      return false;
    }
  }
}
