<div class="valio-modal modal-dialog modal-dialog-centered">
  <div class="modal-content medium-modal">
    <ng-container *ngIf="cartModifications$ |async as cartModifications">
      <h3 class="margin-bottom-md">{{ 'cart.importFromFileSummary.title' | cxTranslate : {amount: success} }}</h3>
      <div class="import-from-file">

        <div class="error-description margin-bottom-md" *ngIf="errors>0">{{ 'cart.importFromFileSummary.description' | cxTranslate :
          {amount: errors} }}</div>
        <ng-container *ngFor="let cartModification of cartModifications">
          <div class="message ERROR" *ngIf="!(cartModification.statusCode === 'success')">
            {{cartModification.statusMessage}}
          </div>
        </ng-container>

        <div class="footer-panel text-center">
          <button type="button" class="btn btn-primary" aria-label="ok" (click)="dismissModal()">
            {{ 'cart.importFromFileSummary.okButton' | cxTranslate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
