import {AbstractControl, ValidationErrors} from "@angular/forms";

export default class Atleast1LetterValidator {
  static PATTERN: string = "\\w*[a-zA-Z]\\w*";

  static valid(control: AbstractControl): ValidationErrors | null {
    const val = control.value as string;
    return val.match(Atleast1LetterValidator.PATTERN) ? null : {invalid: true};
  }
}
