import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import {ValioNewContractComponent} from "./valio-new-contract.component";
declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        NEW_CONTACT= 'NEW_CONTACT',
    }
}
export const valioNewContactLayoutConfig: LayoutConfig = {
    launch: {
        NEW_CONTACT: {
            inlineRoot: true,
            component: ValioNewContractComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};
