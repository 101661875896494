<ng-container *ngIf="products?.length > 0">
  <h3 class="product-container-header" *ngIf="title">{{ title }}
    <ng-container *ngIf="size>0"> ({{size}})</ng-container>
  </h3>
  <div class="carousel-panel">
    <ng-container *ngIf="showScroll === 'true'">
      <button
        class="previous"
        (click)="previous()"
        [disabled]="previousArrowDisabled"
      >
        <span class="valio-icon icon-inline icon-chevron icon-chevron-white icon-chevron-left"></span>
      </button>
    </ng-container>

    <div class="carousel-scroll" (scroll)="scrolling()" [ngClass]="{'show-scroll' : showScroll == 'true'}">
      <div class="carousel-items">
        <ng-container *ngIf="banner$|async as banner">
          <ng-container *ngIf="banner.uid">
            <valio-cx-banner [banner]="banner"></valio-cx-banner>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let product of products; last as isLast">
          <valio-cx-product-grid-item
            [product]="product"
            [listName]="title"
            (initProductCarouselWidths)="initProductCarouselWidths(isLast)"
            class="col-"
          ></valio-cx-product-grid-item>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="showScroll === 'true'">
      <button
        class="next"
        (click)="next()"
        tabindex="0"
        [disabled]="nextArrowDisabled"
      >
        <span class="valio-icon icon-inline icon-chevron icon-chevron-white icon-chevron-right"></span>
      </button>
    </ng-container>
  </div>
</ng-container>
