import {Injectable, OnDestroy} from '@angular/core';
import {OCC_USER_ID_ANONYMOUS, RoutingService, UserIdService} from "@spartacus/core";
import {select, Store} from "@ngrx/store";

import {Observable, of, Subscription} from "rxjs";
import {ValioOrderTemplatesConnector} from "./valio-order-templates.connector";
import {filter, map, switchMap, tap} from "rxjs/operators";
import {ValioBaseOrder, ValioCart} from "../cart/valio-cart.objects";
import {
  getBaseOrderContent,
  getOrderTemplateListContent,
  StateWithOrderTemplate,
  ValioBaseOrderLoad,
  ValioBaseOrderSave,
  ValioBaseOrderSimulate,
  ValioOrderTemplateCreate,
  ValioOrderTemplateList,
  ValioOrderTemplateRemove,
  ValioOrderTemplateSave
} from "./valio-order-template.action";
import {ValioCartService} from "../cart/valio-cart.service";
import {ValioDatePipe} from "../pipes/valio-date.pipe";


@Injectable({
  providedIn: 'root',
})
export class ValioOrderTemplatesService implements OnDestroy {
  private activeOrderTemplate$: Observable<ValioCart>;
  userId: string;
  cartId: string;
  subscriptions: Subscription = new Subscription();

  private templatesLoaded = false;

  constructor(protected store: Store<StateWithOrderTemplate>,
              protected cartService: ValioCartService,
              protected cxDate: ValioDatePipe,
              protected orderTemplatesConnector: ValioOrderTemplatesConnector,
              private routingService: RoutingService,
              protected userIdService: UserIdService) {
    this.activeOrderTemplate$ = this.routingService.getRouterState()
      .pipe(
        map(routingData => {
          return routingData.state.params.orderTemplateCode;
        }))
      .pipe(
        switchMap(orderTemplate => this.getOrderTemplate(orderTemplate))
      );
    this.subscriptions.add(this.userIdService.getUserId().subscribe((userId) => this.userId = userId));
    this.subscriptions.add(this.cartService.takeActiveCartId().subscribe((cartId : string) => this.cartId = cartId));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getActiveOrderTemplate(): Observable<ValioCart> {
    return this.activeOrderTemplate$;
  }

  updateTemplate(orderTemplate: ValioCart) {
    this.store.dispatch(
      new ValioOrderTemplateSave(
        this.userId,
        orderTemplate.code,
        orderTemplate
      )
    );
  }

  addTemplateToCart(orderTemplate: ValioCart) {
    orderTemplate.entries.forEach(e => e.external = true);// add all entries to cart
    this.store.dispatch(
      new ValioOrderTemplateSave(
        this.userId,
        this.cartId,
        orderTemplate,
        true
      )
    );
  }

  getOrderTemplate(orderTemplate: string): Observable<ValioCart> {
    if (orderTemplate) {
      return this.orderTemplatesConnector.getOrderTemplate(this.userId, orderTemplate);
    }
    return of(null);
  }

  getOrderTemplates(): Observable<ValioCart[]> {
    return this.store.pipe(
      select(getOrderTemplateListContent),
      tap(details => {
        if (!this.templatesLoaded) {
          this.templatesLoaded = true;
          this.loadOrderTemplates();
        }
      })
    );
  }

  private loadOrderTemplates() {
    this.store.dispatch(
      new ValioOrderTemplateList(
        this.userId
      )
    );
  }

  createOrderTemplate(name: string, copyId?: string, copyDate?: string) {
    this.store.dispatch(
      new ValioOrderTemplateCreate(this.userId, name, copyId, copyDate)
    );
  }

  removeOrderTemplate(id: string) {
    this.store.dispatch(
      new ValioOrderTemplateRemove(this.userId, id)
    );
  }

  getBaseOrder(): Observable<ValioBaseOrder> {
    return this.userIdService.getUserId().pipe(
      filter(usr => usr !== OCC_USER_ID_ANONYMOUS),
      switchMap(usr =>
        this.store.pipe(
          select(getBaseOrderContent),
          tap(details => {
            if (details == null) {
              this.loadBaseOrder();
            }
          })
        )
      )
    );
  }

  updateBaseOrder(baseOrder: ValioBaseOrder): void {
    this.store.dispatch(
      new ValioBaseOrderSave(
        this.userId,
        baseOrder
      )
    );
  }

  checkBaseOrder(baseOrder: ValioBaseOrder): void {
    this.store.dispatch(
      new ValioBaseOrderSimulate(
        this.userId,
        baseOrder
      )
    );
  }

  private loadBaseOrder(): void {
    this.store.dispatch(
      new ValioBaseOrderLoad(
        this.userId
      )
    );
  }


}

