import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, concatMap, filter, flatMap, map, pluck, switchMap, tap} from 'rxjs/operators';

import {
  ConverterService,
  OccConfig,
  OccEndpointsService,
  PRODUCT_SEARCH_PAGE_NORMALIZER,
  PRODUCT_SUGGESTION_NORMALIZER,
  ProductSearchAdapter,
  Suggestion
} from "@spartacus/core";
import {ValioCartService} from "../cart/valio-cart.service";
import {ValioOccEndpoints} from "../../valio-occ-endpoints";
import {ValioSearchConfig} from "./valio-searchbox.service";
import {CategoryHierarchy, ValioProductSearchPage} from "../../models";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {DEFAULT_PAGE_SIZE} from "../../features/cms-components/product/container/valio-product-list-component.service";
import {ValioDatePipe} from "../pipes/valio-date.pipe";
import {ValioRestrictedAssortmentService} from "../site/valio-restricted-assortment.service";
import {DateUtils} from "../../features/misc/util/date-utils";
import {ValioUser} from "../../models/misc.model";
import {UserAccountFacade} from '@spartacus/user/account/root';

const DEFAULT_SEARCH_CONFIG: ValioSearchConfig = {
  pageSize: DEFAULT_PAGE_SIZE
};

@Injectable()
export class ValioOccProductSearchAdapter implements ProductSearchAdapter {
  endpoints: ValioOccEndpoints;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected cartService: ValioCartService,
    protected cxDate: ValioDatePipe,
    protected config: OccConfig,
    private restrictedAssortmentSiteService: ValioRestrictedAssortmentService,
    protected userAccountFacade: UserAccountFacade
  ) {
    this.endpoints = this.config.backend.occ.endpoints;
  }


  search(
    query: string,
    searchConfig: ValioSearchConfig
  ): Observable<ValioProductSearchPage> {
    if (!query) {
      query = '';
    }
    if (searchConfig && typeof (searchConfig.currentPage) == 'undefined' && searchConfig.showPrices != false) {// only for first search
      openCloseSpinner(true);
    }
    return this.doSearch(query, searchConfig);
  }

  private doSearch(query: string,
                   searchConfig: ValioSearchConfig): Observable<ValioProductSearchPage> {
    return this.getSearchEndpoint(searchConfig?.showPrices == false ? 'suggestiveSearch' : 'productSearch', query, searchConfig).pipe(
      switchMap(url => this.http.get(url).pipe(
        this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER)) as Observable<ValioProductSearchPage>)).pipe(
      tap(data => {
        openCloseSpinner(false);
      }),
      catchError((error) => {
          openCloseSpinner(false);
          console.error(error);
          return of({} as ValioProductSearchPage);
        }
      )
    );
  }

  loadLastPurchasedProducts(): Observable<ValioProductSearchPage> {
    return this.getSearchEndpoint('lastPurchased', '', DEFAULT_SEARCH_CONFIG, DateUtils.getNow()).pipe(
      concatMap(url => this.http.get(url)
        .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER)))) as Observable<ValioProductSearchPage>;
  }

  loadTopSellingProducts(): Observable<ValioProductSearchPage> {
    return this.getSearchEndpoint('topSellers', '', DEFAULT_SEARCH_CONFIG, DateUtils.getNow()).pipe(
      concatMap(url => this.http.get(url)
        .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER)))) as Observable<ValioProductSearchPage>;
  }

  loadBoughtTogetherProducts(productCode: string): Observable<ValioProductSearchPage> {
    const query: string = productCode ? productCode : '';
    return this.getSearchEndpoint('boughtTogether', query, DEFAULT_SEARCH_CONFIG, DateUtils.getNow()).pipe(
      concatMap(url => this.http.get(url)
        .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER)))) as Observable<ValioProductSearchPage>;
  }

  getCategories(superCategory: string, partner: string, assortment: string, b2bunit: string, lang: string): Observable<CategoryHierarchy> {
    return this.http.get(this.occEndpoints.buildUrl('categories', {
      urlParams: {},
      queryParams: {query: superCategory, partner: partner, assortment: assortment, unit: b2bunit, lang: lang}
    }));
  }

  loadSuggestions(
    term: string,
    pageSize: number = 3
  ): Observable<Suggestion[]> {
    return this.getSuggestionEndpoint(term, pageSize.toString()).pipe(flatMap(url => this.http.get(url)
      .pipe(
        pluck('suggestions'),
        this.converter.pipeableMany(PRODUCT_SUGGESTION_NORMALIZER)
      )));
  }

  getSearchEndpoint(endPointId, query: string, searchConfig: ValioSearchConfig, requestedDate?: Date): Observable<string> {
    return this.restrictedAssortmentSiteService.getActiveAsUrlParameterValue().pipe(flatMap(assortment =>
        this.userAccountFacade.get()
          .pipe(map(user => user as ValioUser))
          .pipe(
            switchMap(user =>
              (requestedDate ? of(requestedDate) : this.cartService.getRequestedDate())
                .pipe(
                  filter(date => date != null),
                  map(requestedDate => {
                      return this.occEndpoints.buildUrl(
                        endPointId,
                        {
                          urlParams: {},
                          queryParams: {
                            query: query,
                            pageSize: searchConfig.pageSize,
                            currentPage: searchConfig.currentPage,
                            sort: searchConfig.sort,
                            unit: !user?.activeB2bUnit ? '' : user.activeB2bUnit.nonContractUnit ? user.uid : user.activeB2bUnit.uid,
                            requestedDate: this.cxDate.transform(requestedDate ? new Date(requestedDate) : DateUtils.getNow(), this.endpoints.dateFormat),
                            suggestiveMode: searchConfig.showPrices == false,
                            partner: searchConfig.partner,
                            baseOrderMode: searchConfig.baseOrderMode,
                            searchCategories: searchConfig.searchCategories,
                            assortment: assortment
                          }
                        }
                      );
                    }
                  )
                )
            )
          )
      )
    );

  }

  protected getSuggestionEndpoint(term: string, max: string): Observable<string> {
    return this.cartService.getRequestedDate()
      .pipe(
        filter(date => date != null),
        map(date => this.occEndpoints.buildUrl('productSuggestions', {
              urlParams: {},
              queryParams: {
                term, max,
                requestedDate: this.cxDate.transform(date, this.endpoints.dateFormat)
              }
            }
          )
        )
      );
  }

}
