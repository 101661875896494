import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";
import {CategoryHierarchy} from "../../models";
import { StateUtils} from "@spartacus/core";


export const CATEGORIES_FEATURE = 'valio_categories';

export const getCategoryState: MemoizedSelector<ValioStateWithCategories,
  ValioCategoriesState> = createFeatureSelector<ValioCategoriesState>(CATEGORIES_FEATURE);

export const getCategoriesState: MemoizedSelector<ValioStateWithCategories,
  StateUtils.EntityLoaderState<CategoryHierarchy>> = createSelector(
  getCategoryState,
  (state: ValioCategoriesState) => {
    return state.active;
  }
);

export const getCategoriesContent = (
  code: string,
  partner: string
): MemoizedSelector<ValioStateWithCategories, StateUtils.LoaderState<CategoryHierarchy>> => {
  return createSelector(
    getCategoriesState,
    details => {
      return StateUtils.entityLoaderStateSelector(details, partner + code);
    }
  );
};

export interface ValioStateWithCategories {
  [CATEGORIES_FEATURE]: CategoryHierarchy;
}

export interface ValioCategoriesState {
  active: StateUtils.EntityLoaderState<CategoryHierarchy>;
}

export const CATEGORIES_DATA = '[Categories] Data';
export const LOAD_CATEGORIES = '[Categories] Load category hierarchy';

export class ValioLoadCategories extends StateUtils.EntityLoadAction {
  readonly type = LOAD_CATEGORIES;

  constructor(public superCategory: string, public partner: string) {
    super(CATEGORIES_DATA, partner + superCategory);
  }
}

export const LOAD_CATEGORIES_SUCCESS = '[Categories] Load Success';

export class ValioLoadCategoriesSuccess extends StateUtils.EntitySuccessAction {
  readonly type = LOAD_CATEGORIES_SUCCESS;

  constructor(public superCategory: string, public partner: string, public categories: CategoryHierarchy) {
    super(CATEGORIES_DATA, partner + superCategory, categories);
  }
}


