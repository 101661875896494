<div class="container">
  <div class="valio-product-summary">
    <ng-container *ngIf="(product$ | async) as product">

      <ng-template
        [cxOutletContext]="{ product: product }"
        [cxOutlet]="outlets.PRICE"
      >
        <valio-cx-product-info [product]="product" [breadCrumbs$]="breadCrumbs$"></valio-cx-product-info>
      </ng-template>

      <ng-template
        [cxOutletContext]="{ product: product }"
        [cxOutlet]="outlets.PRICE"
      >
        <valio-cx-product-price [product]="product" isSummary="true"></valio-cx-product-price>
        <span *ngIf="product.campaignProduct">
            {{ 'valioProduct.productDetails.campaignStart' | cxTranslate : {
            from: convertDate(product.price?.validFrom) |valioDate:'shortDate',
            to: convertDate(product.price?.validTo) |valioDate:'shortDate'
        } }}
          </span>
      </ng-template>

      <valio-cx-add-to-cart [setProduct]="product" [setShowAddToCartAndQuantity]="true"></valio-cx-add-to-cart>

      <ng-container *ngIf="showDeliveryPeriod(product)">
        <valio-product-delivery-period
          [product]="product"
          [showLongText]="true"
        ></valio-product-delivery-period>
      </ng-container>

      <section *ngIf="product.description" class="product-info-section d-none d-lg-block">
        <div [innerHTML]="product.description" class="product-description"></div>
      </section>
    </ng-container>
  </div>
</div>
