<ng-container>
  <div *ngIf="isUpdating$ | async; else updateForm">
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </div>

  <ng-template #updateForm>
    <div class="container-account">
      <section class="text-center">
        <h3>{{'updateProfileForm.personalInformation' | cxTranslate}}</h3>
        <p class="user-name">{{'updateProfileForm.userName' | cxTranslate}}
          <span *ngIf="user$|async as user">{{ user.uid }}</span>
        </p>
      </section>
      <div class="row d-flex justify-content-center">
        <valio-cx-update-profile-form
          *ngIf="(user$ | async)?.uid"
          class="col-md-12"
          [user]="user$ | async"
          (submited)="onSubmit()"
        ></valio-cx-update-profile-form>
      </div>
    </div>
  </ng-template>
</ng-container>
