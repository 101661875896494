import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule, I18nModule, NotAuthGuard, UrlModule,} from '@spartacus/core';
import {ValioLoginFormComponent} from './valio-login-form.component';
import {ValioInputModule} from "../../../input/valio-input.module";
import {ValioNoAccountComponent} from "./valio-no-account.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        ValioInputModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ReturningCustomerLoginComponent: {
                    component: ValioLoginFormComponent,
                    guards: [NotAuthGuard],
                },
            },
        }),
        I18nModule,
    ],
    declarations: [ValioLoginFormComponent, ValioNoAccountComponent],
    exports: [ValioLoginFormComponent, ValioNoAccountComponent]
})
export class ValioLoginFormModule {}
