import {RestrictedAssortmentState} from './valio-site.constants';
import {
  VALIO_SET_RESTRICTED_ASSORTMENT_SUCCESSFUL,
  ValioSetRestrictedAssortmentSuccessful
} from './valio-restricted-assortment.action';


export const initialState: RestrictedAssortmentState = {
  selected: false
};

export function restrictedAssortmentReducer(state = initialState,
                                            action: ValioSetRestrictedAssortmentSuccessful): RestrictedAssortmentState {
  if (action.type === VALIO_SET_RESTRICTED_ASSORTMENT_SUCCESSFUL) {
    return {
      ...state,
      selected: action.value
    };
  }
  return state;
}


