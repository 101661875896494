import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {normalizeHttpError} from '@spartacus/core';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {CheckoutActions} from '../actions';
import {CheckoutPaymentConnector} from "@spartacus/checkout/base/core";

@Injectable()
export class CardTypesEffects {

    loadCardTypes$: Observable<CheckoutActions.LoadCardTypesSuccess | CheckoutActions.LoadCardTypesFail> = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.LOAD_CARD_TYPES),
            switchMap(() => {
                return this.checkoutPaymentConnector.getPaymentCardTypes().pipe(
                    map((cardTypes) => new CheckoutActions.LoadCardTypesSuccess(cardTypes)),
                    catchError((error) =>
                        of(new CheckoutActions.LoadCardTypesFail(normalizeHttpError(error)))
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private checkoutPaymentConnector: CheckoutPaymentConnector
    ) {
    }
}

