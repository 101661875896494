<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="cx-update-password-component "
>
  <div class="form-group">
    <valio-cx-input [labelPlaceholder]="'updatePasswordForm.oldPassword.placeholder'"
                    [errorMessage]="'updatePasswordForm.oldPasswordIsRequired'" [idName]="'oldPassword'"
                    [nameForm]="'oldPassword'" class="valio-input" [parentForm]="form"
                    [submitClicked]="isSubmitClicked" [typeInput]="'password'"></valio-cx-input>

  </div>
  <div class="form-group">
    <valio-cx-input [labelPlaceholder]="'updatePasswordForm.newPassword.placeholder'"
                    [errorMessage]="'updatePasswordForm.passwordMinRequirements'"
                    [nameForm]="'newPassword'" class="valio-input" [parentForm]="form"
                    [submitClicked]="isSubmitClicked" [typeInput]="'password'" [idName]="'newPassword'"
                    [extraInfoBottom]="'updatePasswordForm.passwordMinRequirements'"></valio-cx-input>
  </div>
  <div class="form-group">

    <valio-cx-input [labelPlaceholder]="'updatePasswordForm.confirmPassword.placeholder'"
                    [errorMessage]="'updatePasswordForm.bothPasswordMustMatch'"
                    [nameForm]="'newPasswordConfirm'" class="valio-input" [parentForm]="form"
                    [submitClicked]="isSubmitClicked" [typeInput]="'password'" [idName]="'newPasswordConfirm'"
                    [isNotValidFunction]="isPasswordConfirmNotValid()"></valio-cx-input>
  </div>
  <div class="form-group btn-centered">
    <button
      [disabled]="!this.form.dirty"
      class="btn btn-lg btn-block-sm-down btn-primary order-md-2" type="submit">
      {{ 'common.save' | cxTranslate }}
    </button>

    <button
      *ngIf="showCancel()"
      [routerLink]="'/'"
      (click)="allowNormalHeader()"
      class="btn btn-lg btn-block-sm-down btn-secondary order-md-1 mt-3 mt-md-0 mr-md-3" type="button">
      {{ 'common.cancel' | cxTranslate }}
    </button>
  </div>
</form>
