import {Action} from "@ngrx/store";

export const VALIO_SET_RESTRICTED_ASSORTMENT = '[RESTRICTED_ASSORTMENT] Set Restricted Assortment';

export class ValioSetRestrictedAssortment implements Action {
  readonly type = VALIO_SET_RESTRICTED_ASSORTMENT;

  constructor(public value: boolean) {
  }
}

export const VALIO_SET_RESTRICTED_ASSORTMENT_SUCCESSFUL = '[RESTRICTED_ASSORTMENT] Successfully Set Restricted Assortment';

export class ValioSetRestrictedAssortmentSuccessful implements Action {
  readonly type = VALIO_SET_RESTRICTED_ASSORTMENT_SUCCESSFUL;

  constructor(public value: boolean) {
  }
}
