<form [formGroup]="form" class="form-inline">
  <div class="form-row align-items-center">
    <valio-datepicker-input [labelPlaceholder]="labelPlaceholder"
                            [errorMessage]="'deliveryNotes.datePicker.error'"
                            [nameForm]="'date'"
                            [idName]="dateId"
                            [typeInput]="'text'"
                            [parentForm]="form"
                            [isNotValidFunction]="isDateInvalid()"
                            [customDayTemplate]="customDay"
                            (navigationArrowClickEvent)="dateNavigate($event)"
                            [selectedDate]="selectedDate"
                            (onChangeEvent)="searchDate()"
                            class="valio-input"
    >
    </valio-datepicker-input>
  </div>
</form>
<!-- Template for days -->
<ng-template #customDay let-date let-currentMonth="currentMonth" let-today="today" let-selected="selected"
             let-disabled="disabled" let-focused="focused">
  <valio-datepicker-day
    [disabled]="disabled"
    [focused]="focused"
    [today]="today"
    [selected]="selected"
    [hidden]="date.month !== currentMonth"
    [hasContent]="containsDeliveryNotes(date)"
    [date]="date"
  >
  </valio-datepicker-day>
</ng-template>
