import {ItemCounterModule} from "@spartacus/storefront";
import {NgModule} from "@angular/core";
import {I18nModule, UrlModule} from "@spartacus/core";

import {CommonModule} from "@angular/common";
import {ValioDateViewComponent} from "./date/valio-date-view.component";

import {ValioTotalsViewComponent} from "./total/valio-totals-view.component";
import {RouterModule} from "@angular/router";
import {ValioTodaysCartRowsHeaderComponent} from "./todays-cart-rows-header/valio-todays-cart-rows-header.component";
import {ValioCartHeaderComponent} from "./cart-header/valio-cart-header.component";
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";

@NgModule({
    imports: [
        ItemCounterModule,
        I18nModule,
        CommonModule,
        RouterModule,
        UrlModule,
        ValioPipeModule
    ],
    declarations: [ValioDateViewComponent, ValioCartHeaderComponent, ValioTotalsViewComponent, ValioTodaysCartRowsHeaderComponent],
    exports: [ValioDateViewComponent, ValioCartHeaderComponent, ValioTotalsViewComponent, ValioTodaysCartRowsHeaderComponent]
})
export class ValioCartHeaderModule {
}
