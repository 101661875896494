import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  ConverterService,
  InterceptorUtil,
  Occ,
  OccConfig,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
  User
} from "@spartacus/core";
import {
  ValioDeliveryNoteList,
  ValioDeliveryNoteProductList,
  ValioOrderHistoryList,
  ValioPartnerApplication,
  ValioReclamationCreate,
  ValioUser
} from "../../models/misc.model";
import {ValioOccEndpoints} from "../../valio-occ-endpoints";
import {ORDER_HISTORY_NORMALIZER} from "./valio-order-history-normalizer";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {map, tap} from "rxjs/operators";
import {ValioDatePipe} from "../pipes/valio-date.pipe";
import {OccUserAccountAdapter} from "@spartacus/user/account/occ";
import {USER_ACCOUNT_NORMALIZER} from "@spartacus/user/account/core";


@Injectable({
  providedIn: 'root',
})
export class ValioOccUserAdapter extends OccUserAccountAdapter {
  endpoints: ValioOccEndpoints;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected cxDate: ValioDatePipe,
    protected config: OccConfig,
  ) {
    super(http, occEndpoints, converter);
    this.endpoints = this.config.backend.occ.endpoints;
  }

  selectUnit(userId: string, unit: string): Observable<User> {
    const url = this.occEndpoints.buildUrl('selectUnit', {urlParams: {userId, unit}});
    return this.http
      .post<Occ.User>(url, null)
      .pipe(this.converter.pipeable(USER_ACCOUNT_NORMALIZER));
  }

  getHistory(userId: string, sort: string, from: Date, to?: Date, poNumber?: string, metaDataOnly?: boolean): Observable<ValioOrderHistoryList> {
    openCloseSpinner(true);
    const url = this.occEndpoints.buildUrl('orderHistory', {
      urlParams: {userId},
      queryParams: {
        from: (this.cxDate.transform(from, this.endpoints.dateFormat)),
        to: to != null ? (this.cxDate.transform(to, this.endpoints.dateFormat)) : null,
        sort: sort,
        poNumber: poNumber,
        metaDataOnly: metaDataOnly
      }
    });
    return this.http.get<ValioOrderHistoryList>(url).pipe(this.converter.pipeable(ORDER_HISTORY_NORMALIZER)).pipe(tap(() => openCloseSpinner(false)));
  }

  getDeliveryNoteList(userId: string, fromDate: Date, toDate: Date): Observable<ValioDeliveryNoteList> {
    openCloseSpinner(true);
    const url = this.occEndpoints.buildUrl('deliveryNotes', {
        urlParams: {userId},
        queryParams: {
          fromDate: (this.cxDate.transform(fromDate, this.endpoints.dateFormat)),
          toDate: (this.cxDate.transform(toDate, this.endpoints.dateFormat))
        }
      }
    );
    return this.http.get<ValioDeliveryNoteList>(url).pipe(tap(() => openCloseSpinner(false)));
  }

  getDeliveryNoteProducts(userId: string, date: Date, billingDocument: string, partner: string): Observable<ValioDeliveryNoteProductList> {
    const url = this.occEndpoints.buildUrl('deliveryNoteProducts', {
      urlParams: {userId},
      queryParams: {
        date: (this.cxDate.transform(date, this.endpoints.dateFormat)),
        partner: partner,
        billingDocument: billingDocument
      }
    });
    return this.http.get<ValioDeliveryNoteProductList>(url);
  }

  setRestrictedAssortment(userId: string, newValue: boolean): Observable<boolean> {
    const url = this.occEndpoints.buildUrl("restrictedAssortment", {
      urlParams: {userId},
      queryParams: {value: newValue}
    });
    return this.http.post<any>(url, null);
  }

  isRestrictedAssortment(userId: string): Observable<boolean> {
    const url = this.occEndpoints.buildUrl("restrictedAssortment", {urlParams: {userId}});
    return this.http.get<boolean>(url);
  }

  sendReclamation(userId: string, reclamation: ValioReclamationCreate): Observable<string> {
    const url = this.occEndpoints.buildUrl("createReclamation", {urlParams: {userId}});
    return this.http.post(url, reclamation, {responseType: 'text'});
  }

  sendNewContractRequest(userId: string, form: any): Observable<boolean> {
    const url = this.occEndpoints.buildUrl("sendNewContractRequest", {urlParams: {userId}});
    return this.http.post<boolean>(url, form);
  }


  checkIfUserExists(userId: string): Observable<boolean> {
    const url = this.occEndpoints.buildUrl('checkIfUserExists', {urlParams: {userId}});
    return this.http.get<boolean>(url).pipe(
      tap(() => openCloseSpinner(false))
    );
  }

  applyForPartner(userId: string, partner: string, data: ValioPartnerApplication): Observable<boolean> {
    const url = this.occEndpoints.buildUrl('applyForPartner', {urlParams: {userId, partner}});
    return this.http.post<boolean>(url, data);
  }


  requestForgotPasswordEmail(userEmailAddress: string): Observable<ValioUser> {
    const url = this.occEndpoints.buildUrl('userForgotPassword');
    const httpParams: HttpParams = new HttpParams().set(
      'userId',
      userEmailAddress
    );
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    return this.http.post<ValioUser>(url, httpParams, {headers});
  }
}
