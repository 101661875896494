import {Injectable} from "@angular/core";
import {Vendor} from "../../models/misc.model";
import {Observable} from "rxjs";
import {VENDOR_FEATURE, VendorState} from "./valio-vendor-state";
import {createFeatureSelector, createSelector, select, Store} from "@ngrx/store";
import {LoadVendors} from "./valio-vendor.action";
import {map, tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class ValioVendorService {
  constructor(protected store: Store<VendorState>) {
  }

  getActiveVendors(): Observable<Vendor[]> {
    return this.store.pipe(
      select(
        createSelector(
          createFeatureSelector<VendorState>(VENDOR_FEATURE),
          vendor => vendor != null ? vendor.vendors : null
        )
      )
    ).pipe(tap(vendors => {
      if (!vendors || Object.keys(vendors).length === 0) {
        this.loadActiveVendors();
      }
    }));
  }

  getVendor(code: string): Observable<Vendor> {
    return this.getActiveVendors().pipe(
      map(vendors => vendors
        .filter(vendor => vendor.code == code)
        .find(() => true)));
  }

  private loadActiveVendors() {
    this.store.dispatch(new LoadVendors());
  }

}
