<div class="order-row">
  <valio-cart-header
    [dateEntry]="dateEntry"
    [date]="convertDate(dateEntry.deliveryDate)"
    [totalPrice]="dateEntry.totalPrice"
    [totalPriceWithTax]="dateEntry.totalPriceWithTax"
    [totalItemsInvoice]="dateEntry.totalItemsInvoice"
    [totalItemsCredit]="dateEntry.totalItemsCredit"
    [totalItemsCreditUnPaid]="dateEntry.totalItemsCreditUnPaid"
    [checkoutEnabled]="dateEntry.validForOrdering"
    [totalWeightInKg]="dateEntry.totalWeightInKg"
    [status]="dateEntry.status"
    [showButton]="showNextButton"
    [showDateToggle]="showDateToggle"
    [showOtherDateToggle]="showOtherDateToggle"
    (dateClicked)="toggleDate()"
    [historyView]="historyView"
    [isReadOnly]="isReadOnly"
    [detailsCollapsed]="collapsed"
    [collapsed]="collapsed"
  >
  </valio-cart-header>

  <div class="cart-details-wrapper" [ngClass]="{'collapsed' : collapsed}">
    <div *ngFor="let partnerEntry of dateEntry.entries" class="cart-entry-block">
      <valio-cx-cart-details-rows
        [partnerEntry]="partnerEntry"
        [cartLoaded]="cartLoaded"
        [isReadOnly]="isReadOnly"
        [allowDelete]="allowDelete"
        [collapsed]="!partnerEntry.listOpen"
      ></valio-cx-cart-details-rows>
    </div>

    <div *ngFor="let partnerEntry of dateEntry.paidEntries" class="cart-entry-block">
      <valio-cx-cart-details-rows
        [partnerEntry]="partnerEntry"
        [cartLoaded]="cartLoaded"
        [collapsed]="!partnerEntry.listOpen"
        [isReadOnly]="isReadOnly"
        [allowDelete]="allowDelete"
        [title]="'cart.header.paidProducts'|cxTranslate"
      ></valio-cx-cart-details-rows>
    </div>

    <ng-container *ngIf="dateEntry.entries.length == 0 && dateEntry.paidEntries.length == 0 ">
      <cx-page-slot position="EmptyCartMiddleContent"></cx-page-slot>
    </ng-container>
    <ng-container *ngIf="!historyView">
      <ng-container *ngIf="orderTemplateList$|async as orderTemplateList">
        <div class="add-multiple-products" *ngIf="orderTemplateList.length > 0 || isCsvImportEnabled()">
          <h5 class="text-center margin-bottom-md">{{'cart.header.addMultipleProducts'|cxTranslate}}</h5>
          <div class="add-multiple-products-bg">
            <div class="template-selector" *ngIf="orderTemplateList.length > 0 && isContractCustomer()|async">
              <div class="margin-bottom-lg">{{'cart.header.addFromTemplateDescription'|cxTranslate}}</div>
              <ng-select (change)="orderTemplateButtonClick($event)"
                         [clearable]="true"
                         [closeOnSelect]="true"
                         [items]="orderTemplateList"
                         [searchable]="true"
                         bindLabel="name"
                         bindValue="code"
                         placeholder="{{ 'cart.header.addFromTemplate' | cxTranslate}}"
              ></ng-select>
              <button type="button"
                      class="btn btn-primary"
                      aria-label="cancel"
                      [disabled]="!(selectedOrderTemplate)"
                      (click)="selectTemplate( dateEntry.deliveryDate)">{{ 'cart.header.addToCart' | cxTranslate }}
              </button>
            </div>
            <div class="file-import" *ngIf="isCsvImportEnabled()">
              <div class="margin-bottom-lg">{{'cart.header.addFromFileDescription'|cxTranslate}}</div>
              <button type="button"
                      class="btn btn-primary"
                      aria-label="cancel"
                      (click)="openImportFromFile()">{{ 'cart.header.addFromFile' | cxTranslate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

</div>
