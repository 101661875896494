import {Action} from "@ngrx/store";
import {StateUtils} from "@spartacus/core";

export const PAYMENT_DATA = '[Cart] payment process';
export const START_PAYMENT = '[Cart] start payment';
export class ValioStartPayment extends StateUtils.LoaderLoadAction {
  readonly type = START_PAYMENT;

  constructor(
    public payload: {
      userId: string;
      cartId: string;
      date: string;
    }
  ) {
    super(PAYMENT_DATA);
  }
}

export const PAYMENT_STARTED = '[Cart] payment started';

export class ValioPaymentStarted extends StateUtils.LoaderSuccessAction {
  readonly type = PAYMENT_STARTED;

  constructor(public link: string) {
    super(PAYMENT_DATA);
  }
}

export const PAYMENT_FAILED = '[Cart] payment failed';

export class ValioPaymentFailed extends StateUtils.LoaderFailAction {
  readonly type = PAYMENT_FAILED;

  constructor(public payload: any) {
    super(PAYMENT_DATA, payload);
  }
}
export const PAYMENT_REDIRECT = '[Cart] payment redirect';
export class ValioRedirectStarted extends StateUtils.LoaderSuccessAction {
  readonly type = PAYMENT_REDIRECT;

  constructor(public payload: any) {
    super(PAYMENT_REDIRECT);
  }
}
export const INVOICE_DATA = '[Cart] invoice process';
export const START_INVOICE = '[Cart] start invoice';

export class ValioStartInvoice extends StateUtils.LoaderLoadAction {
  readonly type = START_INVOICE;

  constructor(
    public payload: {
      userId: string;
      cartId: string;
      date: string;
    }
  ) {
    super(INVOICE_DATA);
  }
}

export const INVOICE_SUCCESS = '[Cart] invoice success';

export class ValioInvoiceSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = INVOICE_SUCCESS;

  constructor(public data: string, public date: string, public userId: string, public cartId: string) {
    super(INVOICE_DATA)
  }
}

export const INVOICE_FAILED = '[Cart] invoice failed';

export class ValioInvoiceFailed extends StateUtils.LoaderFailAction {
  readonly type = INVOICE_FAILED;

  constructor(public payload: any) {
    super(INVOICE_DATA, payload);
  }
}


export const LOAD_ORDER = '[ORDER] load';

export class ValioLoadOrder implements Action {
  readonly type = LOAD_ORDER;

  constructor(public user: string, public cartId: string, public date: string, public orderCode?: string) {
  }
}
