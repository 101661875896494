<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="selectedPartner$|async as selectedPartner">
    <ng-container *ngIf="products$ | async as products">
      <ng-container *ngIf="products.length>0; else emptyText">
        <div class="cx-product-container" *ngIf="(data.carouselType != 'LASTPURCHASES' && data.carouselType != 'TOPSELLERS' && data.carouselType != 'BOUGHTTOGETHER') && showCarousel(selectedPartner, data.partner)"
             [ngClass]="{'full-width' : fullWidth$ | async,'with-calendar' : hasUICart$ | async}">
          <valio-cx-carousel
            [banner$]="bannerComponent$"
            [products]="products"
            [title]="title$ | async"
            [showScroll]="data.showScroll"
          >
          </valio-cx-carousel>
        </div>
      </ng-container>
      <ng-template #emptyText>
        <ng-container *ngIf="isAnonymous$ | async">
          <div *ngIf="(data.carouselType != 'LASTPURCHASES' && data.carouselType != 'TOPSELLERS' && data.carouselType != 'BOUGHTTOGETHER') && showCarousel(selectedPartner, data.partner)"
                        [ngClass]="{'full-width' : fullWidth$ | async,'with-calendar' : hasUICart$ | async}">
            <div class="empty-text margin-top-lg" *ngIf="data.emptyText?.length > 0" [innerHTML]="data.emptyText">{{data.emptyText}}</div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <div class="cx-product-container" *ngIf="data.carouselType === 'LASTPURCHASES' && showCarousel(selectedPartner, data.partner)"
         [ngClass]="{'full-width' : fullWidth$ | async,'with-calendar' : hasUICart$ | async}">
      <valio-last-purchases
        [title]="title$ | async"
        [showScroll]="data.showScroll"
      ></valio-last-purchases>
    </div>
    <div class="cx-product-container" *ngIf="data.carouselType === 'TOPSELLERS' && showCarousel(selectedPartner, data.partner)"
         [ngClass]="{'full-width' : fullWidth$ | async,'with-calendar' : hasUICart$ | async}">
      <valio-top-sellers
        [title]="title$ | async"
        [showScroll]="data.showScroll"
      ></valio-top-sellers>
    </div>
    <div class="cx-product-container" *ngIf="data.carouselType === 'BOUGHTTOGETHER' && showCarousel(selectedPartner, data.partner)"
         [ngClass]="{'full-width' : fullWidth$ | async,'with-calendar' : hasUICart$ | async}">
      <valio-bought-together
        [title]="title$ | async"
        [showScroll]="data.showScroll"
      ></valio-bought-together>
    </div>
  </ng-container>
</ng-container>

