import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { OccCartAdapter } from "@spartacus/cart/base/occ";
import {CART_NORMALIZER} from "@spartacus/cart/base/root";
import { ConverterService, Occ, OccConfig, OccEndpointsService } from "@spartacus/core";
import { Observable } from "rxjs";
import { ValioOccEndpoints } from "../../valio-occ-endpoints";
import { ValioDatePipe } from "../pipes/valio-date.pipe";
import { ValioCart } from "./valio-cart.objects";


@Injectable()
export class ValioOccCartAdapter extends OccCartAdapter {
  endpoints: ValioOccEndpoints;

  constructor(protected cxDate: ValioDatePipe,
    protected config: OccConfig,
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService) {
    super(http, occEndpointsService, converterService);
    this.endpoints = this.config.backend.occ.endpoints;
  }

  public changeCartHeader(userId: string, cartId: string, field: string, value: any, body?: any): Observable<ValioCart> {
    return this.http
      .patch<Occ.Cart>(
        this.occEndpointsService.buildUrl(field, {
          urlParams: { userId, cartId },
          queryParams: { [field]: encodeURIComponent(value as string) }
        }), body)
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<ValioCart>;
  }

  removeCartDate(userId: string, cartId: string, date: Date): Observable<ValioCart> {
    const url = this.occEndpointsService.buildUrl('cartDate', {
      urlParams: { userId, cartId },
      queryParams: { date: this.cxDate.transform(date, this.endpoints.dateFormat) }
    });
    return this.http.delete<Occ.Cart>(url).pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<ValioCart>;
  }


  public load(userId: string, cartId: string, simulate?: boolean): Observable<ValioCart| undefined> {
    return this.http
      .get<Occ.Cart>(this.occEndpointsService.buildUrl('cart', { urlParams: { userId, cartId }, queryParams: { simulate } }))
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<ValioCart>;
  }
}
