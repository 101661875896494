<div class="cart-details-wrapper">
  <div class="row">
    <div *ngFor="let partnerEntry of group.entries" class="cart-entry-block col-12">
      <valio-cx-cart-details-rows
        [isReadOnly]="false"
        [partnerEntry]="partnerEntry"
        [cartLoaded]="true"
        [collapsed]="!showItems"
        [switchToCardPayment]="switchToCardPayment"
      ></valio-cx-cart-details-rows>
      <ng-container *ngIf="group.paidEntries[partnerEntry.partner.code]!=null">
        <valio-cx-cart-details-rows
          [isReadOnly]="true"
          [title]="'cart.header.paidProducts'|cxTranslate"
          [collapsed]="true"
          [partnerEntry]="group.paidEntries[partnerEntry.partner.code]"
          [cartLoaded]="true"
        ></valio-cx-cart-details-rows>
      </ng-container>
    </div>
  </div>
  <valio-cart-total-bar-rows
    *ngIf="showItems"
    [cart]="cart"
    [showButton]="showButton"
    [loggedUser]="loggedUser"
    [buttonTextKey]="buttonTextKey"
    [totalItems]="group.totalItems"
    [totalPrice]="group.totalPrice"
    [subtotal]="group.subtotal"
    [totalPriceWithTax]="group.totalPriceWithTax"
    [totalPriceWithoutTax]="group.totalPrice"
    (clickButton)="click()"
    [buttonEnabled]="group.validForOrdering"
    [freightCharge]="group.paymentMode.card?cart.freightCharge:null"
    [variableWeightPaymentCost]="group.paymentMode.card?cart.variableWeightPaymentCost:null"
    [group]="group"
  ></valio-cart-total-bar-rows>
</div>
