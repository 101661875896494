import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {getLastPurchasesContent, ValioLoadLastPurchases, ValioStateWithLastPurchases} from "./valio-last-purchases.action";
import {map, tap} from "rxjs/operators";
import {ProductSearchPage} from "@spartacus/core";

@Injectable({
  providedIn: 'root',
})
export class ValioLastPurchasesService {
  constructor(
    protected store: Store<ValioStateWithLastPurchases>
  ) {
  }

  getLastPurchases(): Observable<ProductSearchPage> {
    return this.store.pipe(
      select(getLastPurchasesContent),
      tap(details => {
        if (Object.keys(details).length == 1) {
          this.loadLastPurchases();
        }
      })
    );
  }

  private loadLastPurchases() {
    this.store.dispatch(
      new ValioLoadLastPurchases()
    );
  }

  isLoaded(): Observable<boolean> {
    return this.store.select(getLastPurchasesContent)
      .pipe(map(() => {
        return true;// Object.keys(details).length > 1;
      }));
  }
}
