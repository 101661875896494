import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard, CmsConfig, ConfigModule, I18nModule, provideDefaultConfig, UrlModule,} from '@spartacus/core';
import {MediaModule, SpinnerModule} from "@spartacus/storefront";
import {ValioOrderTemplateListComponent} from "./sidebar/valio-order-template-list.component";
import {RouterModule} from "@angular/router";
import {ValioCreateOrderTemplateComponent} from "./popup/valio-create-order-template.component";
import {ValioOrderTemplateGridComponent} from "./grid/valio-order-template-grid.component";
import {ValioCartSharedModule} from "../cart/cart-shared/valio-cart-shared.module";
import {ValioRemoveOrderTemplateComponent} from "./popup/valio-remove-order-template.component";
import {ValioOrderTemplateHeaderComponent} from "./header/valio-order-template-header.component";
import {ValioBaseorderContentComponent} from "./baseorder/valio-baseorder-content.component";
import {ValioBaseorderSearchComponent} from "./baseorder/valio-baseorder-search.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {ValioSuggestiveSearchBoxModule} from "../navigation/suggestive-search-box/valio-suggestive-search-box.module";
import {ValioItemCounterModule} from "../cart/cart-shared/cart-item/item-counter/valio-item-counter.module";
import {ValioInputModule} from '../../input/valio-input.module';
import {ValioBaseorderBlockModalComponent} from "./baseorder/valio-baseorder-block-modal.component";
import {ValioDatepickerModule} from "../deliverynotes/datepicker/valio-datepicker.module";
import {ValioMainNavigationModule} from '../navigation/valio-main-navigation/valio-main-navigation.module';
import {ValioPipeModule} from "../../../services/pipes/valio-pipe.module";
import {valioBaseOrderBlockModalLayoutConfig} from "./baseorder/valio-baseorder-block-modal-layout.config";
import {
  valioRemoveOrderTemplateLayoutConfig
} from "./popup/valio-remove-order-template-layout.config";
import {
  valioCreateOrderTemplateLayoutConfig
} from "./popup/valio-create-order-template-layout.config";

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        OrderTemplateListComponent: {
          component: ValioOrderTemplateListComponent,
          guards: [AuthGuard],
        },
        OrderTemplateGridComponent: {
          component: ValioOrderTemplateGridComponent,
          guards: [AuthGuard],
        },
        ModifyBaseOrderComponent: {
          component: ValioBaseorderContentComponent,
          guards: [AuthGuard],
        }
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    RouterModule,
    ValioCartSharedModule,
    MediaModule,
    UrlModule,
    NgSelectModule,
    ValioSuggestiveSearchBoxModule,
    ValioItemCounterModule,
    ValioInputModule,
    ValioDatepickerModule,
    ValioMainNavigationModule,
    ValioPipeModule,
  ],
  providers: [
    provideDefaultConfig(valioBaseOrderBlockModalLayoutConfig),
    provideDefaultConfig(valioRemoveOrderTemplateLayoutConfig),
    provideDefaultConfig(valioCreateOrderTemplateLayoutConfig)
  ],
  declarations: [ValioOrderTemplateListComponent, ValioCreateOrderTemplateComponent, ValioOrderTemplateGridComponent, ValioRemoveOrderTemplateComponent, ValioOrderTemplateHeaderComponent, ValioBaseorderContentComponent, ValioBaseorderSearchComponent, ValioBaseorderBlockModalComponent],
  exports: [ValioOrderTemplateListComponent, ValioCreateOrderTemplateComponent, ValioOrderTemplateGridComponent, ValioRemoveOrderTemplateComponent, ValioOrderTemplateHeaderComponent, ValioBaseorderContentComponent, ValioBaseorderSearchComponent, ValioBaseorderBlockModalComponent]
})
export class ValioOrderTemplateModule {

}
