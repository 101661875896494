<div *ngIf="user$|async as user" class="checkbox-div">
  <ng-container *ngIf="user.activeB2bUnit && ( user.activeB2bUnit.restrictedAssortment == true || user.activeB2bUnit.restrictedAssortment == false)">
    <div class="form-group">
      <div class="form-check" >
        <label>
          <input
            type="checkbox"
            class="form-check-input"
            [disabled]="false"
            [checked]="getCurrentValue() |async"
            (change)="onCheckedChange($event.target.checked)"
          />
          <span class="checkmark"></span>
          <span class="form-check-label">{{'toggleContractual.label'|cxTranslate}}</span>
        </label>
      </div>
    </div>
  </ng-container>
</div>
