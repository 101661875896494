import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {Price} from "@spartacus/core";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {ValioCartDateGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {DateUtils} from "../../../../misc/util/date-utils";


@Component({
  selector: 'valio-cart-header',
  templateUrl: './valio-cart-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioCartHeaderComponent {
  @Input() dateEntry: ValioCartDateGroupedEntryWrapper;
  @Input() date: Date;
  @Input() totalItemsInvoice: number;
  @Input() totalItemsCredit: number;
  @Input() totalItemsCreditUnPaid: number;
  @Input() totalPrice: Price;
  @Input() totalPriceWithTax: Price;
  @Input() showButton = true;
  @Input() checkoutEnabled: boolean;
  @Input() totalWeightInKg: string;
  @Input() status: string;
  @Input() collapsed: boolean;
  @Input() detailsCollapsed: boolean;
  @Input() showDateToggle = false;
  @Input() showOtherDateToggle = false;
  @Input() historyView: boolean = false;
  @Input() isReadOnly: boolean;

  @Output() dateClicked: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('dateToggleButton', {static: false})
  dateToggleButton: ElementRef;

  constructor(
    protected  cartService: ValioCartService) {
  }

  toCheckout() {
    this.cartService.checkout(this.date, this.totalItemsCreditUnPaid > 0);
  }

  convertDate(date: string): Date {
    return DateUtils.convertDate(date);
  }

  hasSalesEndPassed() {
    return this.dateEntry.salesEndTime && DateUtils.getNow().getTime() > this.convertDate(this.dateEntry.salesEndTime).getTime();
  }

  toggleDate(toggleButton: boolean) {
    this.dateClicked.emit();
    if (!toggleButton) {
      this.showOtherDateToggle = false;
    }
  }

  print($event: MouseEvent) {
    const parent = (<HTMLElement>$event.target).closest('.order-row');
    parent.classList.add('print');
    window.print();
    parent.classList.remove('print');
  }

  deleteCartDate(deliveryDate: string) {
    this.cartService.removeCartDate(deliveryDate);
  }
}
