import {Injectable} from '@angular/core';
import {Converter, Occ, OccConfig, Product} from "@spartacus/core";
import {ValioProduct} from "../../models";

@Injectable({providedIn: 'root'})
export class ValioProductUrlNormalizer implements Converter<Occ.Product, ValioProduct> {
  constructor(protected config: OccConfig) {
  }

  convert(source: Occ.Product, target?: ValioProduct): Product {
    if (target === undefined) {
      target = {...(source as any)};
    }
    if (source.name) {
      target.prettyUrl = this.normalize(source.name);
    }
    return target;
  }

  protected normalize(name: string): string {
    return name.replace(/<em(.+?)<\/em>/g, '').replace(/\//g, '');//delete highlighting and backslashes which break cloud apache
  }

}
