import {HttpClient} from "@angular/common/http";
import {OccEndpointsService} from "@spartacus/core";
import {Observable} from "rxjs";
import {Vendor} from "../../models/misc.model";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ValioVendorAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {
  }

  get(userId: string): Observable<Vendor[]> {
    const url = this.occEndpoints.buildUrl('vendor', {urlParams: {userId}});
    return this.http.get<Vendor[]>(url);
  }
}
