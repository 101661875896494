import {Injectable} from '@angular/core';
import {ValioOccValioOrderTemplatesAdapter} from "./valio-occ-valio-order-templates.adapter";
import {Observable} from "rxjs";
import {ValioBaseOrder, ValioCart} from "../cart/valio-cart.objects";


@Injectable({
  providedIn: 'root',
})
export class ValioOrderTemplatesConnector {
  constructor(protected adapter: ValioOccValioOrderTemplatesAdapter) {
  }

  getOrderTemplates(userId: string): Observable<ValioCart[]> {
    return this.adapter.getOrderTemplates(userId);
  }

  getOrderTemplate(userId: string, orderTemplate: string): Observable<ValioCart> {
    return this.adapter.getOrderTemplate(userId, orderTemplate);
  }

  createOrderTemplate(userId: string, name: string, copyId?: string, copyDate?: string): Observable<ValioCart[]> {
    return this.adapter.createOrderTemplate(userId, name, copyId, copyDate);
  }

  saveOrderTemplate(userId: string, cartId: string, orderTemplate: ValioCart,  updateCart?: boolean): Observable<ValioCart[]> {
    return this.adapter.saveOrderTemplate(userId, cartId, orderTemplate, updateCart);
  }

  removeOrderTemplate(userId: string, id: string): Observable<ValioCart[]> {
    return this.adapter.removeOrderTemplate(userId, id);
  }

  getBaseOrder(userId: string): Observable<ValioBaseOrder> {
    return this.adapter.getBaseOrder(userId);
  }

  updateBaseOrder(userId: string, baseOrder: ValioBaseOrder): Observable<ValioBaseOrder> {
    return this.adapter.updateBaseOrder(userId, baseOrder);
  }

  checkBaseOrder(userId: string, baseOrder: ValioBaseOrder): Observable<ValioBaseOrder> {
    return this.adapter.checkBaseOrder(userId, baseOrder);
  }

}
