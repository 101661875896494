<ng-container *ngIf="cart$ | async as cart">
  <div class="container">
    <div class="cart-header">
      <div class="page-header-back">
        <a (click)="goBack()">
          <span class="valio-icon icon-inline icon-chevron icon-chevron-left margin-right-sm"></span>
          <span class="d-none d-md-inline-block">{{ 'common.return' | cxTranslate }}</span>
        </a>
      </div>
      <div class="page-header-title">
        <h1>{{ 'checkout.header.page' | cxTranslate }}</h1>
      </div>
    </div>
    <valio-cx-shipping-address-details
      [cart]="cart"
      [headerDate]="requestedDate$|async"
    ></valio-cx-shipping-address-details>
  </div>
</ng-container>
