<div class="head-categories" [ngClass]="{'visible' : showHeadCat}">
  <ul>
    <li [routerLink]="'/'">
      {{ 'mobileNavigation.toHome' | cxTranslate }}
    </li>
    <li *ngIf="mainCategories$|async" (click)="showProducts($event)">
      {{'mobileNavigation.products'|cxTranslate}}
      <span class="valio-icon icon-inline icon-chevron icon-chevron-right"></span>
    </li>
    <li *ngIf="vendors$ |async" (click)="showVendors($event)">
      {{'mobileNavigation.partners'|cxTranslate}}
      <span class="valio-icon icon-inline icon-chevron icon-chevron-right"></span>
    </li>
  </ul>
</div>

<div class="products main-categories" [ngClass]="{'visible' : showProductsCat, 'active' : lastSelectedCategory != null}">
  <ul *ngIf="mainCategories$|async as mainCategories">
    <li class="selected-main-category" (click)="showHead($event)">
      <span class="valio-icon icon-inline icon-chevron icon-chevron-left"></span>
      <span>{{'mobileNavigation.products'|cxTranslate}}</span>
    </li>
    <li [ngClass]="{'active':lastSelectedCategory==mainCat.id}"
        [attr.data-categoryName]="mainCat.fiName"
        *ngFor="let mainCat of mainCategories.subcategories"
        (click)="toggleChildCategory(mainCat.id, mainCat.name,mainCat.fiName,$event)"
    >
      <span [innerHTML]="mainCat.name"></span>
      <span class="valio-icon icon-inline icon-chevron icon-chevron-right"></span>
    </li>
  </ul>
</div>
<div class="child-categories" *ngIf="childCategories$ && childCategories$|async as childCategories">
  <ul>
    <li [attr.data-categoryName]="lastSelectedCategoryFiName" class="selected-main-category" (click)="toggleChildCategory(lastSelectedCategory, lastSelectedCategoryName,lastSelectedCategoryFiName, $event)">
      <span class="valio-icon icon-inline icon-chevron icon-chevron-left"></span>
      <span (click)="openCategory(lastSelectedCategory)" [innerHTML]="lastSelectedCategoryName"></span>
    </li>
    <li [attr.data-categoryName]="childCat.fiName" *ngFor="let childCat of childCategories.subcategories">
      <a (click)="openCategory(childCat.id)" [innerHTML]="childCat.name"></a>
    </li>
    <li [attr.data-categoryName]="lastSelectedCategoryFiName">
      <a (click)="openCategory(lastSelectedCategory)">
        <b>{{'categories.showmore'|cxTranslate}}</b>
      </a>
    </li>
  </ul>
</div>

<div class="vendors main-categories" [ngClass]="{'visible' : showVendorsCat}">
  <ul *ngIf="vendors$ |async as vendors">
    <li class="selected-main-category" (click)="showHead($event)">
      <span class="valio-icon icon-inline icon-chevron icon-chevron-left"></span>
      <span>{{'mobileNavigation.partners'|cxTranslate}}</span>
    </li>
    <li *ngFor="let vendor of vendors" (click)="switchPartner(vendor)" [innerHTML]="vendor.name"></li>
    <li *ngIf="isAnonymous$ | async" [routerLink]="{ cxRoute: 'partnersPage' } | cxUrl">
      <b>{{'categories.showmore'|cxTranslate}}</b>
    </li>
    <li *ngIf="isSignedIn$ | async" [routerLink]="'/'">
      <b>{{'categories.showmore'|cxTranslate}}</b>
    </li>
  </ul>
</div>
