<ng-container>
  <div *ngIf="isUpdating$ | async; else updateForm">
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </div>

  <ng-template #updateForm>
    <div class="container-account">
      <section class="text-center">
        <h3>{{'updatePasswordForm.changePassword' | cxTranslate}}</h3>
      </section>
      <div class="row d-flex justify-content-center">
        <valio-cx-update-password-form
          class="col-md-12"
          (submitted)="onSubmit()"
        ></valio-cx-update-password-form>
      </div>
    </div>
  </ng-template>
</ng-container>
