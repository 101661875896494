import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {CmsBannerComponent} from "@spartacus/core";
import {ValioNavigationService} from "../../navigation/valio-navigation.service";


@Component({
  selector: 'valio-cx-background-image',
  templateUrl: './valio-background-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioBackgroundImageComponent {

  data$ = this.componentData.data$;

  constructor(
    protected componentData: CmsComponentData<CmsBannerComponent>,
    protected service: ValioNavigationService
  ) {
  }
}
