<ng-container *ngIf="product$ | async as product">
  <div class="container">
    <div class="nutrition-wrapper">
      <div class="row">
        <div *ngIf="product.description" class="col-lg-6 d-lg-none">
          <div class="nutrition product-description" id="product-description">
            <section class="nutrition-header" (click)="showHideNutritionTable(false, 'product-description')">
              <h2 class="h1">{{ 'valioProduct.productDetails.productDescription' | cxTranslate }}</h2>
              <div class="valio-icon icon-chevron icon-chevron-right-down"></div>
            </section>
            <div class="d-none d-lg-block d-print-block nutrition-info">
              <div [innerHTML]="product.description"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="nutrition nutrition-left" id="nutrition-left">
            <section class="nutrition-header" (click)="showHideNutritionTable(false, 'nutrition-left')">
              <h2 class="h1">{{ 'valioProduct.productDetails.nutrition.nutritionalInformation' | cxTranslate }}</h2>
              <div class="valio-icon icon-chevron icon-chevron-right-down"></div>
            </section>
            <div class="d-none d-lg-block d-print-block nutrition-info">

              <div *ngIf="getClass(product.classifications,  'nutritionalFactsClass') as cclass">
                <div class="row nutrition-title">
                  <div class="col-12"> {{cclass.name}} {{'valioProduct.productDetails.nutrition.dosage'|cxTranslate}}</div>
                </div>
                <div *ngFor="let feature of cclass.features" class="row">
                  <span [ngClass]="feature.subAttribute?'col-7 offset-1':'col-8'">{{feature.name}}</span>
                  <span class="col-4 text-right">{{feature.featureValues[0].value | number : '1.0-1':'fi'}} {{feature.featureValues[0].unit?.name}}</span>
                </div>
              </div>

              <div *ngIf="false && getClass(product.classifications,  'compositionClass') as cclass">
                <div class="row nutrition-title">
                  <div class="col-12"> {{cclass.name}} {{'valioProduct.productDetails.nutrition.dosage'|cxTranslate}}</div>
                </div>
                <div *ngFor="let feature of cclass.features" class="row">
                  <span [ngClass]="feature.subAttribute?'col-7 offset-1':'col-8'">{{feature.name}}</span>
                  <span class="col-4">{{feature.featureValues[0].value}} {{feature.featureValues[0].unit?.name}}</span>
                </div>
              </div>

              <div *ngIf="getClass(product.classifications,  'vitaminsClass') as cclass">
                <div class="row nutrition-title">
                  <div class="col-12"> {{cclass.name}} {{'valioProduct.productDetails.nutrition.dosage'|cxTranslate}}</div>
                </div>
                <div *ngFor="let feature of cclass.features" class="row">
                  <span class="col-8">
                    {{feature.name}}
                  </span>
                  <span class="col-4 text-right">
                    {{feature.featureValues[0].value}} {{feature.featureValues[0].unit?.name}}
                  </span>
                </div>
              </div>

              <div *ngIf="getClass(product.classifications,  'mineralsClass') as cclass">
                <div class="row nutrition-title">
                  <div class="col-12"> {{cclass.name}} {{'valioProduct.productDetails.nutrition.dosage'|cxTranslate}}</div>
                </div>
                <div *ngFor="let feature of cclass.features" class="row">
                  <div class="col-8">{{feature.name}}</div>
                  <div class="col-4 text-right">{{feature.featureValues[0].value}} {{feature.featureValues[0].unit?.name}}
                    <span class="margin-left-base">{{'valioProduct.productDetails.nutrition.dailyDosage'|cxTranslate:{percentage: feature.featureValues[0].percent} }}</span>
                  </div>
                </div>
              </div>

              <div class="margin-top-md">
                {{'valioProduct.productDetails.nutrition.dailyDosageInfo'|cxTranslate}}
              </div>

              <div class="nutrition-title">{{'valioProduct.productDetails.nutrition.allergenics'|cxTranslate}}</div>

              <div *ngIf="getFeature(product.classifications, 'nutritionalContentClass','allergenics') as feature">
                <div class="nutrition-title">{{'valioProduct.productDetails.nutrition.contains'|cxTranslate}}</div>
                <span *ngFor="let value of feature.featureValues; index as idx">
                    {{value.value}}{{idx == feature.featureValues.length - 1 ? '' : ','}}
                  </span>
              </div>

              <div *ngIf="getFeature(product.classifications, 'nutritionalContentClass','nonallergenics') as feature">
                <div class="nutrition-title">{{'valioProduct.productDetails.nutrition.notcontain'|cxTranslate}}</div>
                <span *ngFor="let value of feature.featureValues; index as idx">
                    {{value.value}}{{idx == feature.featureValues.length - 1 ? '' : ','}}
                  </span>
              </div>

              <div *ngIf="getFeature(product.classifications, 'nutritionalContentClass','maybeallergenics') as feature">
                <div class="nutrition-title">{{'valioProduct.productDetails.nutrition.maycontain'|cxTranslate}}</div>
                <span *ngFor="let value of feature.featureValues; index as idx">
                    {{value.value}}{{idx == feature.featureValues.length - 1 ? '' : ','}}
                  </span>
              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="nutrition nutrition-right" id="nutrition-right">
            <section class="nutrition-header" (click)="showHideNutritionTable(false, 'nutrition-right')">
              <h2 class="h1">{{ 'valioProduct.productDetails.nutrition.productInformation' | cxTranslate }}</h2>
              <div class="valio-icon icon-chevron icon-chevron-right-down"></div>
            </section>
            <div class="d-none d-lg-block d-print-block nutrition-info">
              <div>
                <div class="nutrition-title">{{ 'valioProduct.productDetails.nutrition.ingredients' | cxTranslate }}</div>
                {{product.keyIngredients}}
              </div>

              <div>
                <div *ngIf="getFeature(product.classifications, 'nutritionalContentClass','nutritionfeatures') as feature">
                  <div class="nutrition-title">{{feature.name}}</div>
                  <span *ngFor="let value of feature.featureValues; index as idx">
                      {{value.value}}{{idx == feature.featureValues.length - 1 ? '' : ', '}}
                    </span>
                </div>
              </div>
              <div>
                <div class="nutrition-title">{{ 'valioProduct.productDetails.preparationInstructions' | cxTranslate }}</div>
                {{product.preparationInstructions}}
              </div>
              <div>
                <div class="nutrition-title">{{ 'valioProduct.productDetails.nutrition.usageInstructions' | cxTranslate }}</div>
                {{product.usageInstructions}}
              </div>
              <div>
                <div class="nutrition-title">{{ 'valioProduct.productDetails.nutrition.storageInformation' | cxTranslate }}</div>
                <div class="margin-bottom-md">{{product.storageInstructions}}</div>
                <div class="nutrition-title">{{ 'valioProduct.productDetails.nutrition.originInformation' | cxTranslate }}</div>
                <div *ngIf="product.countryOfOrigin" class="row">
                  <div class="col-4">{{ 'valioProduct.productDetails.nutrition.countryOrigin' | cxTranslate }}</div>
                  <div class="col-8 text-right">{{product.countryOfOrigin.name}}</div>
                </div>

                <div *ngIf="product.countryOfProduction" class="row">
                  <div class="col-4">{{ 'valioProduct.productDetails.nutrition.countryProduction' | cxTranslate }}</div>
                  <div class="col-8 text-right">{{product.countryOfProduction.name}}</div>
                </div>

                <div *ngIf="product.ingredients.length" class="margin-top-md margin-bottom-md">
                  <div *ngFor="let ingredient of product.ingredients; index as idx">
                    {{ingredient.value}}
                    <div *ngFor="let origin of ingredient.originvalue; index as idx" class="row margin-left-base">
                      <div class="col-4">{{origin.activity}}</div>
                      <div class="col-8 text-right">{{origin.country.name}}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4 text-nowrap">{{ 'valioProduct.productDetails.nutrition.manufacturer' | cxTranslate }}</div>
                  <span *ngIf="product.producers.length == 1" class="col-8 text-right">{{product.producers[0].name}}</span>
                  <ul *ngIf="product.producers.length > 1" class="col-8 text-right producer-list">
                    <li *ngFor="let producer of product.producers; index as idx">
                      {{producer.name}}
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="product.productUnitConversion">
                <div class="nutrition-title">{{ 'valioProduct.productDetails.measurements.title' | cxTranslate }}</div>
                <div class="row">
                  <div class="col-4">{{ 'valioProduct.productDetails.measurements.length' | cxTranslate }}</div>
                  <div class="col-8 text-right">{{product.productUnitConversion.length}} {{product.productUnitConversion.unitOfDimensions.name}}</div>
                </div>
                <div class="row">
                  <div class="col-4">{{ 'valioProduct.productDetails.measurements.width' | cxTranslate }}</div>
                  <div class="col-8 text-right">{{product.productUnitConversion.width}} {{product.productUnitConversion.unitOfDimensions.name}}</div>
                </div>
                <div class="row">
                  <div class="col-4">{{ 'valioProduct.productDetails.measurements.heigth' | cxTranslate }}</div>
                  <div class="col-8 text-right">{{product.productUnitConversion.heigth}} {{product.productUnitConversion.unitOfDimensions.name}}</div>
                </div>
              </div>
              <div *ngIf="getFeature(product.classifications,  'commonClass','disposals') as feature">
                <div class="nutrition-title">{{feature.name}}</div>
                <div *ngFor="let value of feature.featureValues">
                  <div>{{value.value}}</div>
                </div>
              </div>
              <div class="packaging-images"
                   *ngIf="getFeature(product.classifications,  'commonClass','packagingmarks') as feature">
                <div class="packaging-image" *ngFor="let value of feature.featureValues">
                  <div class="image" *ngIf="getImage(value)">
                    <cx-media [container]="getImage(value)"></cx-media>
                  </div>
                </div>
              </div>

              <div *ngIf="product.disposalInformation">
                <div class="nutrition-title">{{ 'valioProduct.productDetails.nutrition.disposalInformation' | cxTranslate }}</div>
                {{product.disposalInformation}}
              </div>

              <div *ngIf="product.additionalDescription">
                <div class="nutrition-title">{{ 'valioProduct.productDetails.nutrition.additionalDescription' | cxTranslate }}</div>
                {{product.additionalDescription}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>
