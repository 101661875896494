import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {SiteContext, SiteContextConfig, StateWithSiteContext} from "@spartacus/core";
import {select, Store} from "@ngrx/store";
import {NavigationStart, Router, RoutesRecognized} from "@angular/router";
import {ALL_PARTNERS, getActivePartner} from "./valio-site.constants";
import {ValioSetSitePartner} from "./valio-partner-site.action";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ValioPartnerSiteService implements SiteContext<string> ,OnDestroy{
  subscriptions: Subscription = new Subscription();

  constructor(
    protected store: Store<StateWithSiteContext>,
    protected config: SiteContextConfig,
    protected router: Router
  ) {
    this.subscriptions.add(
    router.events.subscribe(event => {
      if (event instanceof NavigationStart || event instanceof RoutesRecognized) {
        const url = (event as (NavigationStart | RoutesRecognized)).url;
        if (url.indexOf('/search') < 0 && // reset partner on other pages than these
          url.indexOf('/category') < 0 &&
          url.indexOf('/product') < 0
        ) {
          this.setActive(ALL_PARTNERS);
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Represents the current baseSite uid.
   */
  getActive(): Observable<string> {
    return this.store.pipe(
      select(getActivePartner)
    );
  }

  isPartnerSelected(): Observable<boolean> {
    return this.getActive().pipe(map(p => p !== ALL_PARTNERS));
  }

  /**
   * We currently don't support switching baseSite at run time
   */
  getAll(): Observable<string[]> {
    return null;
  }

  reset(): void {
    this.setActive(ALL_PARTNERS)
  }

  setActive(partner: string): Subscription {
    this.setActivePartner(partner);
    return this.getActive().subscribe();
  }

  setActivePartner(partner: string, redirectUrl?: string): void {
    this.config.context['partner'] = [partner];
    this.store.dispatch(new ValioSetSitePartner(encodeURIComponent(partner)));
  }
}

