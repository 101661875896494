<div class="suggestive-row">
  <div class="row">
    <div class="col-12 col-md-6" [ngClass]="templateMode ? 'col-xl-2' : ' col-lg-2'" *ngIf="partners$|async as partners">
      <ng-select
        [searchable]="false"
        [clearable]="true"
        [closeOnSelect]="true"
        placeholder="{{ 'valioRegister.vendor.selectOne'|cxTranslate}}"
        bindLabel="name"
        bindValue="code"
        [items]="partners"
        (change)="selectPartner($event)"
      ></ng-select>
    </div>
    <div class="col-12 col-md-6" [ngClass]="templateMode ? 'col-xl-3' : ' col-lg-3'">
      <valio-suggestive-cx-searchbox
        [baseOrderMode]="baseOrderMode"
        [templateMode]="templateMode"
        [setSelectedPartner]="selectedPartner"
        [value]="(selectedProduct.code ? (selectedProduct.code|productCode)  : '') +( selectedProduct.name ? ( ', '+selectedProduct.name): '')"
        (onSelect)="selectProduct($event)"
        (addToCartEmitter)="addToCart()"
      ></valio-suggestive-cx-searchbox>
    </div>
    <div class="col-12 col-md-6 text-nowrap" [ngClass]="templateMode ? 'col-xl-3' : ' col-lg-3'">
      <valio-cx-item-counter
        [isValueChangeable]="true"
        [step]="1"
        [min]="1"
        [value]="selectedQty"
        [max]="1000"
        (update)="updateQuantity($event)"
        (enableAddButton)="enableAddButton($event)"
        [updateOnBlur]="true"
        [showButtons]="true"
        [unit]="selectedUnit"
        [valueSetter$]="valueSetter.asObservable()"
        [styleClass]="'cx-counter cx-counter-lg cx-counter-transparent cx-counter-suggestive margin-bottom-base'"
      >
      </valio-cx-item-counter>
      <div class="d-inline-block margin-left-base padding-top-sm" *ngIf="selectedUnit?.conversionToWeightUnit">
        {{'cart.cartItems.unit'|cxTranslate}}
        <div class="bold">{{selectedUnit?.conversionToInvoiceUnit}}</div>
      </div>
    </div>
    <div class="col-12 col-md-3" [ngClass]="templateMode ? 'col-xl-2' : ' col-lg-2'">
      <valio-cx-input [labelPlaceholder]="'cart.cartItems.poNumber'"
                      [nameForm]="'poNumber'" class="valio-input dark-input small-text"
                      [inputValue]="selectedPoNumber ? selectedPoNumber : ''"
                      (keyup.enter)="addToCart()"
                      (change)="selectPoNumber($event)"></valio-cx-input>
    </div>
    <div class="col-12 col-md-3" [ngClass]="templateMode ? 'col-xl-2' : ' col-lg-2'">
      <button
        (click)="addToCart()"
        type="submit"
        class="btn btn-lg btn-block btn-primary margin-bottom-base">
        {{'cart.header.addToCart'|cxTranslate}}
      </button>
    </div>
  </div>
</div>
