import {Component} from '@angular/core';
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";

@Component({
  selector: 'valio-cx-close-account-profile',
  templateUrl: './valio-close-account.component.html',
})
export class ValioCloseAccountComponent {

  constructor(protected launchDialogService: LaunchDialogService) {

  }


  openConfirm(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CLOSE_ACCOUNT_MODAL, null);
  }


}
