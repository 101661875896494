import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule, UrlModule,} from '@spartacus/core';

import {MediaModule, SearchBoxModule} from "@spartacus/storefront";

import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {ValioProductTooltipModule} from "../../product/product-tooltip/valio-product-tooltip.module";
import {FormsModule} from "@angular/forms";
import {ValioSearchBoxModule} from "../search-box/valio-search-box.module";
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";
import {ValioGlobalSearchBoxComponent} from "./valio-global-search-box.component";

@NgModule({
    imports: [
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioGlobalSearchBoxComponent: {
                    component: ValioGlobalSearchBoxComponent,
                },
            },
        }),
        MediaModule,
        CommonModule,
        UrlModule,
        I18nModule,
        ValioProductTooltipModule,
        FormsModule,
        ValioSearchBoxModule,
        ValioPipeModule
    ],
    declarations: [ValioGlobalSearchBoxComponent],
    exports: [ValioGlobalSearchBoxComponent]
})
export class ValioGlobalSearchBoxModule extends SearchBoxModule {
}
