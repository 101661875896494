import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartSharedModule } from "@spartacus/cart/base/components";
import { I18nModule, UrlModule } from '@spartacus/core';
import { ValioInputModule } from "../../../input/valio-input.module";
import { ValioCartSharedModule } from "../../cart/cart-shared/valio-cart-shared.module";
import { ValioVoucherComponent } from "./valio-voucher.component";

@NgModule({
  imports: [
    CartSharedModule,
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ValioCartSharedModule,
    ValioInputModule,
  ],
  declarations: [ValioVoucherComponent],
  exports: [ValioVoucherComponent]
})
export class ValioVoucherModule {
}
