import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from "@ng-select/ng-select";
import { CartSharedModule } from "@spartacus/cart/base/components";
import { I18nModule, UrlModule } from '@spartacus/core';
import { ItemCounterModule, MediaModule, PromotionsModule } from "@spartacus/storefront";
import { ValioPipeModule } from "../../../../services/pipes/valio-pipe.module";
import { ValioCommonModule } from "../../../../shared/valio-common.module";
import { ValioInputModule } from '../../../input/valio-input.module';
import { ValioProductIconsModule } from "../../product/product-icons/valio-product-icons.module";
import { ValioProductInfoModule } from "../../product/product-info/valio-product-info.module";
import { ValioCartItemListComponent } from "./cart-item-list/valio-cart-item-list.component";
import { ValioItemCounterModule } from "./cart-item/item-counter/valio-item-counter.module";
import { ValioCartItemMessageComponent } from "./cart-item/messages/valio-cart-item-message.component";
import { ValioCartItemComponent } from "./cart-item/valio-cart-item.component";
import { ValioMiniCartItemComponent } from "./minicart-item/valio-minicart-item.component";
import { ValioPriceSummaryComponent } from "./price-summary/valio-price-summary.component";



@NgModule({
  imports: [
    ValioCommonModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    UrlModule,
    NgbModule,
    PromotionsModule,
    I18nModule,
    MediaModule,
    ItemCounterModule,
    ValioItemCounterModule,
    NgSelectModule,
    FormsModule,
    ValioProductInfoModule,
    ValioProductIconsModule,
    ValioPipeModule,
    ValioInputModule
  ],
  declarations: [
    ValioCartItemComponent,
    ValioCartItemListComponent,
    ValioMiniCartItemComponent,
    ValioCartItemMessageComponent,
    ValioPriceSummaryComponent
  ],
  exports: [ValioCartItemComponent, ValioCartItemListComponent, ValioMiniCartItemComponent, ValioCartItemMessageComponent, ValioPriceSummaryComponent],
})
export class ValioCartSharedModule extends CartSharedModule {
}
