import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {OccConfig, OccEndpointsService} from "@spartacus/core";
import {Observable} from "rxjs";
import {Qualtrics} from "./valio-environment.objects";

@Injectable({
  providedIn: 'root',
})
export class ValioOccEnvironmentAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected config: OccConfig,
  ) {
  }

  getQualtricsId(): Observable<Qualtrics> {
     return this.http.get<Qualtrics>(this.occEndpoints.buildUrl('qualtricsId'));
  }
}
