export const environment = {
  production: true,
  backend : {
    occ : {
      baseUrl: 'https://api.valotilaus.fi'
    }
  },
  googleManager: {
    id: 'GTM-PLFLST8',
    url: 'https://www.googletagmanager.com/ns.html?id='
  },
  csvImport:true,
  marketingCloud: {
    boughtTogether: {
      enabled:false
    },
    topSellers: {
      enabled:false
    }
  }
};
