<div class="valio-modal modal-dialog modal-dialog-centered">
  <div class="modal-content medium-modal">
    <h3 class="margin-bottom-md">{{ 'cart.importFromFile.title' | cxTranslate }}</h3>
    <div class="margin-bottom-md">{{ 'cart.importFromFile.description' | cxTranslate }}</div>
    <div class="import-from-file">
      <form [formGroup]="form">
        <div class="text-area-scroll">
          <div class="text-area-wrapper">
            <div class="line-numbers">
              <div *ngFor="let counter of counterArray; let i = index">{{i + 1}}</div>
            </div>
            <div class="text-area">
              <textarea rows="{{counter}}" cols="50" formControlName="csvData" [ngModel]="rawData" #textarea (ngModelChange)="onEnter(textarea)"></textarea>
            </div>
          </div>
        </div>
        <ng-container *ngIf="form.invalid && (form.dirty || form.touched)">
          <div *ngIf="form.controls['csvData']?.errors" class="message ERROR">{{'cart.importFromFile.invalidDataError'|cxTranslate}} </div>
        </ng-container>
      </form>
      <div class="footer-panel text-center">
        <button type="button"
                class="btn btn-secondary"
                aria-label="cancel"
                (click)="dismissModal()">
          {{ 'cart.importFromFile.cancelButton' | cxTranslate }}
        </button>
        <button type="button"
                class="btn btn-primary margin-left-base"
                aria-label="import"
                (click)="importFromFile()"
                [disabled]="!form.valid">
          {{ 'cart.importFromFile.importButton' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
