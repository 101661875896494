import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';

const PASSWORD_PATTERN =  /^(?=.*?[a-zöäå])(?=.*?[A-ZÖÄÅ])(?=.*?[0-9]).{10,}$/;

export class ValioCustomFormValidators extends CustomFormValidators {
    /**
     * Overridden PASSWORD_PATTERN
     *
     * Checks control's value with predefined password regexps, max 1 can fail.
     *
     * NOTE: Use it as a control validator
     *
     * @static
     * @param {AbstractControl} control
     * @returns {(ValidationErrors | null)} Uses 'cxInvalidPassword' validator error
     * @memberof AimoCustomFormValidators
     */
    static passwordValidator(control: AbstractControl): ValidationErrors | null {
        const password = control.value as string;

        return password && (!password.length || password.match(PASSWORD_PATTERN)) ? null : { cxInvalidPassword: true };
    }
}
