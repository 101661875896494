<div id="my-account-menu" class="my-account-menu">
  <button class="btn btn-primary-inv btn-icon close-menu" (click)="hideMenuOnMobile()">
    <span class="valio-icon icon-reset icon-reset-black"></span>
  </button>
  <ul>
    <li [ngClass]="{'active':isActive('update-profile')|async}" [routerLink]="'/my-account/update-profile'" (click)="hideMenuOnMobile()">
      <span class="valio-icon icon-inline icon-personal-details margin-right-md"></span>
      {{'myaccount.profile'|cxTranslate}}
    </li>
    <li [ngClass]="{'active':isActive('update-password')|async}" [routerLink]="'/my-account/update-password'" (click)="hideMenuOnMobile()">
      <span class="valio-icon icon-inline icon-password margin-right-md"></span>
      {{'myaccount.updatepassword'|cxTranslate}}
    </li>
    <li [ngClass]="{'active':isActive('contracts')|async}" [routerLink]="'/my-account/contracts'" (click)="hideMenuOnMobile()">
      <span class="valio-icon icon-inline icon-contract margin-right-md"></span>
      {{'myaccount.contracts'|cxTranslate}}
    </li>
    <li [ngClass]="{'active':isActive('closeAccount')|async}" [routerLink]="'/my-account/closeAccount'" (click)="hideMenuOnMobile()">
      <span class="valio-icon icon-inline icon-close-account margin-right-md"></span>
      {{'myaccount.closeaccount'|cxTranslate}}
    </li>
  </ul>

  <div *ngIf="isActive('contracts')|async">
    <button class="btn btn-block btn-primary" type="submit" (click)="openNewContractPopup()">
      {{ 'myaccount.newContract.title' | cxTranslate }}
    </button>
  </div>

</div>
