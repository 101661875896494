<div class="modal-logout valio-modal modal medium-modal fade show" id="valio-browserCheck" *ngIf="!closed && show()">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <h1 class="modal-header">{{ 'navigation.browserCheck.title' | cxTranslate }}</h1>
      <p class="text-bold">{{ 'navigation.browserCheck.body1' | cxTranslate }}</p>
      <p class="text-bold">{{ 'navigation.browserCheck.body2' | cxTranslate }}</p>
      <div class="row buttons">
        <div class="col-4 d-flex justify-content-center ">
          <a href="https://www.google.com/intl/fi_fi/chrome/" target="_blank">
            <button type="button"
                    class="btn btn-block btn-primary"
            >
              {{ 'navigation.browserCheck.chrome' | cxTranslate }}
            </button>
          </a>
        </div>
        <div class="col-4 d-flex justify-content-center">
          <a href="https://www.mozilla.org/fi/firefox/new/" target="_blank">
            <button type="button"
                    class="btn btn-block btn-primary"
            >
              {{ 'navigation.browserCheck.firefox' | cxTranslate }}</button>
          </a>
        </div>
        <div class="col-4 d-flex justify-content-center">
          <button type="button" (click)="close()"
                  class="btn btn-block btn-secondary"
          >
            {{ 'navigation.browserCheck.close' | cxTranslate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
