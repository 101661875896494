import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {ValioCart, ValioCartDateGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {ValioOrderTemplatesService} from "../../../../../services/orderTemplates/valio-order-templates.service";
import {Observable} from "rxjs";
import {openCloseSpinner} from "../../../../../services/util/valio-modals-utils";
import {DateUtils} from "../../../../misc/util/date-utils";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {ValioImportFromFileComponent} from "../../file-import/valio-import-from-file.component";
import {environment} from "../../../../../../environments/environment";
import {ValioUserService} from "../../../../../services/user/valio-user.service";

@Component({
  selector: 'valio-cx-cart-details-title',
  templateUrl: './valio-cart-details-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioCartDetailsTitleComponent {
  @Input() dateEntry: ValioCartDateGroupedEntryWrapper;
  @Input() cartLoaded: any;
  @Input() showNextButton = true;
  @Input() isReadOnly: boolean;
  @Input() showDateToggle = false;
  @Input() showOtherDateToggle = false;
  @Input() collapsed = false;
  @Input() historyView: boolean = false;
  @Input() allowDelete = true;

  selectedOrderTemplate: ValioCart

  orderTemplateList$: Observable<ValioCart[]>;

  constructor(protected cdr: ChangeDetectorRef,
              protected userService: ValioUserService,
              protected cartService: ValioCartService,
              protected orderTemplatesService: ValioOrderTemplatesService,
              protected launchDialogService: LaunchDialogService) {
    this.orderTemplateList$ = this.orderTemplatesService.getOrderTemplates();
  }

  convertDate(date: string): Date {
    return DateUtils.convertDate(date);
  }

  toggleDate() {
    this.collapsed = !this.collapsed;
    this.cdr.detectChanges();
  }

  openImportFromFile() {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.IMPORT_FROM_FILE, null)
  }

  isCsvImportEnabled() {
    return environment.csvImport == true
  }

  orderTemplateButtonClick(orderTemplate: ValioCart) {
    this.selectedOrderTemplate = orderTemplate;
  }

  selectTemplate(date: string) {
    openCloseSpinner(true);
    let orderTemplate: ValioCart = this.selectedOrderTemplate
    orderTemplate = {
      ...orderTemplate,
      requestedDate: date,
      entries: [...orderTemplate.entries]
    }
    orderTemplate.entries = orderTemplate.entries.map(e => ({...e, requestedDate: date}));
    this.orderTemplatesService.addTemplateToCart(orderTemplate);
  }

  isContractCustomer(): Observable<boolean> {
    return this.userService.isContractCustomer();
  }
}
