import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { CartDetailsModule } from "@spartacus/cart/base/components";
import { CheckoutAuthGuard } from "@spartacus/checkout/base/components";
import {CmsConfig, ConfigModule, I18nModule, provideDefaultConfig} from '@spartacus/core';
import { AddressFormModule, PageSlotModule, PromotionsModule } from "@spartacus/storefront";
import { ValioCartOccModule } from "../../../../services/cart/valio-cart-module";
import { ValioPipeModule } from "../../../../services/pipes/valio-pipe.module";
import { ValioInputModule } from '../../../input/valio-input.module';
import { ValioSuggestiveSearchBoxModule } from "../../navigation/suggestive-search-box/valio-suggestive-search-box.module";
import { ValioProductListModule } from "../../product/valio-product-list.module";
import { ValioCartHeaderModule } from "../cart-header/valio-cart-header.module";
import { ValioItemCounterModule } from "../cart-shared/cart-item/item-counter/valio-item-counter.module";
import { ValioCartSharedModule } from "../cart-shared/valio-cart-shared.module";
import { ValioCartDetailsRowsComponent } from "./rows/valio-cart-details-rows.component";
import { ValioSuggestiveSearchComponent } from "./suggestive-search/valio-suggestive-search.component";
import { ValioSwitchPaymentComponent } from "./switchpayment/valio-switch-payment.component";
import { ValioCartDetailsTitleComponent } from "./title/valio-cart-details-title.component";
import { ValioCartTotalBarComponent } from "./totals/valio-cart-total-bar.component";
import { ValioCartDetailsComponent } from "./valio-cart-details.component";
import {valioSwitchPaymentLayout} from "./switchpayment/valio-switch-payment-layout.config";

@NgModule({
  imports: [
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CartComponent: {
          component: ValioCartDetailsComponent,
          guards: [CheckoutAuthGuard]
        },
      },
    }),
    I18nModule,
    PromotionsModule,
    CommonModule,
    ValioCartSharedModule,
    ValioCartOccModule,
    AddressFormModule,
    FormsModule,
    ValioCartHeaderModule,
    ValioProductListModule,
    ValioSuggestiveSearchBoxModule,
    NgSelectModule,
    ValioItemCounterModule,
    ValioInputModule,
    PageSlotModule,
    ValioPipeModule,
    RouterModule
  ],
  providers: [

    provideDefaultConfig(valioSwitchPaymentLayout),
  ],
  declarations: [ValioCartDetailsComponent, ValioCartDetailsRowsComponent, ValioCartDetailsTitleComponent, ValioSwitchPaymentComponent, ValioSuggestiveSearchComponent, ValioCartTotalBarComponent],
  exports: [ValioCartDetailsComponent, ValioCartDetailsRowsComponent, ValioCartDetailsTitleComponent, ValioSwitchPaymentComponent, ValioSuggestiveSearchComponent, ValioCartTotalBarComponent]
})
export class ValioCartDetailsModule extends CartDetailsModule {
}
