import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'valioCxHighlight'})
export class ValioHighlightPipe implements PipeTransform {
  transform(text: string, match?: string): string {
    if (!match) {
      return text;
    }
    match.split(' ').forEach(token => text = text.replace(
      token.trim(),
      `<span class="highlight">${token.trim()}</span>`
    ));
    return text;
  }
}
