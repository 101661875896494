<div class="valio-modal modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div id="valio-logout">
      <button
        (click)="dismissModal()"
        data-dismiss="modal"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span aria-hidden="true" class="valio-icon icon-reset icon-reset-black"></span>
      </button>
      <h1 class="modal-header">{{ 'navigation.warningLogout.title' | cxTranslate }}</h1>
      <p class="text-bold">{{ 'navigation.warningLogout.body' | cxTranslate }}</p>
      <p>{{ 'navigation.warningLogout.instructions' | cxTranslate }}</p>
      <div class="row buttons">
        <div class="col-12 col-sm-6 d-flex justify-content-center margin-bottom-base">
          <button (click)="logout()" *ngIf="logoutModal"
                  class="btn btn-block btn-secondary"
                  type="button">
            {{ 'navigation.warningLogout.buttonSignOut' | cxTranslate }}
          </button>

          <button (click)="switchUnit()" *ngIf="!logoutModal"
                  class="btn btn-block btn-secondary"
                  type="button">
            {{ 'navigation.warningLogout.switchUnit' | cxTranslate }}
          </button>
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-center">
          <button (click)="goToOrders()"
                  class="btn btn-block btn-primary"
                  type="button">
            {{ 'navigation.warningLogout.buttonGoToOrder' | cxTranslate }}</button>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <button (click)="dismissModal()"
                  class="btn btn-link"
                  type="button">
            {{ 'common.cancel' | cxTranslate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
