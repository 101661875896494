import {Injectable, InjectionToken} from "@angular/core";
import {Converter, ConverterService, PRODUCT_NORMALIZER} from "@spartacus/core";
import {ValioOrderHistoryList} from "../../models/misc.model";

export const ORDER_HISTORY_NORMALIZER = new InjectionToken<Converter<ValioOrderHistoryList, ValioOrderHistoryList>>(
  'OrderHistoryNormalizer'
);

@Injectable()
export class ValioOccOrderHistoryNormalizer implements Converter<ValioOrderHistoryList, ValioOrderHistoryList> {
  constructor(private converter: ConverterService) {
  }

  convert(source: ValioOrderHistoryList, target?: ValioOrderHistoryList): ValioOrderHistoryList {
    if (target === undefined) {
      target = {...(source as any)};
    }
    if (source && source.pastEntries) {
      target.pastEntries = source.pastEntries.map(e => ValioOccOrderHistoryNormalizer.convertGroupedEntries(e, this.converter));
    }
    ValioOccOrderHistoryNormalizer.handlePaymentEntries(target);
    return target;
  }

  public static convertGroupedEntries(entry, converter: ConverterService) {
    return {
      ...entry,
      entries: ValioOccOrderHistoryNormalizer.convertStatusEntries(entry.entries, converter),
      paidEntries: ValioOccOrderHistoryNormalizer.convertStatusEntries(entry.paidEntries, converter)
    };
  }

  static convertStatusEntries(entries: any, converter: ConverterService) {
    if (entries) {
      return entries.map(groupedEntry =>
        ({
          ...groupedEntry,
          entries: ValioOccOrderHistoryNormalizer.convertPartnerEntries(groupedEntry.entries, converter)
        })
      );
    }
    return null;
  }

  static convertPartnerEntries(entries: any, converter: ConverterService) {
    if (entries) {
      return entries.map(partnerEntry =>
        ({
          ...partnerEntry,
          product: converter.convert(partnerEntry.product, PRODUCT_NORMALIZER)
        })
      );
    }
    return null;
  }

  public static handlePaymentEntries(cart) {
    if (cart.paymentModeGroupedEntries) {
      let paymentModeGroupedEntries = cart.paymentModeGroupedEntries.reduce(function (map, obj) {
        ValioOccOrderHistoryNormalizer.handleInnerEntries(map, obj, obj.entries);
        return ValioOccOrderHistoryNormalizer.handleInnerEntries(map, obj, obj.paidEntries);
      }, []);
      cart.paymentModeGroupedEntries = paymentModeGroupedEntries;
    }
  }

  static handleInnerEntries(map, obj, entries) {
    let paidEntries = entries.reduce(function (paidEntryMap, paidEntry) {
      paidEntryMap[paidEntry.partner.code] = paidEntry;
      return paidEntryMap;
    }, []);
    map[obj.paymentMode.code] = {
      ...obj,
      paidEntries
    };
    return map;
  }
}

