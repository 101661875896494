<div class="searchbox-wrapper d-inline-block">
  <label id="searchbox" class="searchbox" [class.dirty]="!!searchInput.value">
    <span class="search-open">
      <span
        aria-label="search-open"
        class="valio-icon icon-search"
        (click)="launchSearchResult($event, searchInput.value)"
      ></span>
    </span>
    <input
      autocomplete="off"
      #searchInput
      id="searchInput"
      [placeholder]="'globalSearch.placeholder' | cxTranslate"
      value="{{value}}"
      aria-label="search"
      (keyup)="searchEvent($event)"
      (keyup.escape)="clearSearchBox()"
      data-hj-whitelist
    />
    <span class="search">
      <span
        aria-label="search"
        class="valio-icon icon-search"
      ></span>
    </span>
    <span class="reset">
      <span
        aria-label="reset"
        (click)="clearSearchBox()"
        class="valio-icon icon-reset"
      ></span>
    </span>
  </label>

  <div *ngIf="searchInput.value.length>0 && searchResults$ | async as result" class="results" (click)="close($event)">
    <div
      *ngIf="result.message"
      class="message INFO"
      [innerHTML]="result.message"
    ></div>
    <div class="products" (mousedown)="disableClose()" *ngIf="result.products">
      <div class="result-header">{{'globalSearch.products'|cxTranslate}}</div>
      <ng-container *ngTemplateOutlet="globalProductList; context: { products: result.products, isSuggestion: false }"></ng-container>
      <hr class="result-separator"/>
      <div class="result-header">{{'globalSearch.categories'|cxTranslate}}</div>
    </div>
  </div>
  <div id="suggestions" class="suggestions d-none">
    <div class="results" *ngIf="searchInput.value.length==0">
      <div class="products" (mousedown)="disableClose()">
        <div class="result-header">{{'globalSearch.latestQueries'|cxTranslate}}</div>
        <div *ngFor="let query of getOldQueries(); index as idx" class="{{getAnchorClassName()}}">
          <div class="product-row old-query-icon" [ngClass]="idx%2==1?'even':'odd'">
            <a class="link" (click)="searchInput.value = query;doSearch(query)">{{query}}</a>
          </div>
        </div>
        <hr class="result-separator"/>
        <div class="result-header">{{'globalSearch.latestOrders'|cxTranslate}}</div>
        <ng-container *ngIf="latestOrders$|async as latestOrders">
          <ng-container *ngTemplateOutlet="globalProductList; context: { products: latestOrders, isSuggestion: true }"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #globalProductList let-products="products" let-isSuggestion="isSuggestion">
  <div *ngFor="let product of products; index as idx" class="{{getAnchorClassName()}}" [attr.data-product]="product|json">
    <div class="product-row" [ngClass]="idx%2==1?'even':'odd'">
      <div class="row">
        <div class="col-2 image" [routerLink]="'p/'+ product.code ">
          <cx-media *ngIf="!isSuggestion"
                    [container]="product.images?.PRIMARY"
                    format="cartIcon"></cx-media>
          <div class="code" *ngIf="isSuggestion">
            <span [innerHTML]=" product.code | productCode | valioCxHighlight: searchInput.value"></span>
          </div>
        </div>
        <div class="item" [ngClass]="isSuggestion ? 'col-10': 'col-7'" [routerLink]="'p/'+product.code">
          <div class="code" *ngIf="!isSuggestion">
            <span [innerHTML]="product.partnerSupplier.name"></span> |
            <span [innerHTML]="product.code | productCode | valioCxHighlight: searchInput.value"></span></div>
          <div class="name">
            <span [innerHTML]="product.name  | valioCxHighlight: searchInput.value"></span> ({{product.unit.conversionToInvoiceUnit}})
          </div>
        </div>
        <div class="col-3 add-to-cart text-right" *ngIf="!isSuggestion">
          <a class="link" (click)="addToCart(product)">{{'globalSearch.add'|cxTranslate}}</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
