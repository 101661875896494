import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard, CmsConfig, ConfigModule, I18nModule, provideDefaultConfig,} from '@spartacus/core';
import {SpinnerModule} from "@spartacus/storefront";

import {ValioUpdateProfileFormComponent} from "./profile/valio-update-profile-form.component";
import {ValioUpdateProfileComponent} from "./profile/valio-update-profile.component";
import {ValioSideBarComponent} from "./sidebar/valio-sidebar.component";
import {RouterModule} from "@angular/router";
import {ValioUpdatePasswordComponent} from "./update-password/valio-update-password.component";
import {ValioUpdatePasswordFormComponent} from "./update-password/valio-update-password-form.component";
import {ValioContractsComponent} from "./contracts/valio-contracts.component";
import {ValioCloseAccountComponent} from "./close-account/valio-close-account.component";
import {ValioMyAccountHeaderComponent} from './my-account-header/valio-my-account-header.component';
import {ValioForgotPasswordModule} from "./forgot-password/valio-forgot-password.module";
import {ValioResetPasswordModule} from './reset-password/valio-reset-password.module';
import {ValioCloseAccountModalComponent} from "./close-account/close-account-modal/valio-close-account-modal.component";
import {ValioInputModule} from "../../input/valio-input.module";
import {ValioNewContractComponent} from "./new-contract/valio-new-contract.component";
import {ValioRegisterModule} from "../user/register/valio-register.module";
import {UpdatePasswordComponentService, UpdateProfileComponentService} from "@spartacus/user/profile/components";
import {ValioUpdateProfileComponentService} from "./profile/valio-update-profile.component.service";
import {valioNewContactLayoutConfig} from "./new-contract/valio-new-contract-layout.config";
import {
  valioCloseAccountModalLayoutConfig
} from "./close-account/close-account-modal/valio-close-account-modal-layout.config";

@NgModule({
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                UpdateProfileComponent: {
                    component: ValioUpdateProfileComponent,
                    guards: [AuthGuard],
                },
                SideBarComponent: {
                    component: ValioSideBarComponent,
                    guards: [AuthGuard],
                },
                MyAccountHeaderComponent: {
                    component: ValioMyAccountHeaderComponent,
                    guards: [AuthGuard],
                },
                ContractsComponent: {
                    component: ValioContractsComponent,
                    guards: [AuthGuard],
                },
                UpdatePasswordComponent: {
                    component: ValioUpdatePasswordComponent,
                    guards: [AuthGuard],
                    providers: [
                        {
                            provide: UpdatePasswordComponentService,
                            useClass: ValioUpdateProfileComponentService,
                        },
                    ],
                },
                CloseAccountComponent: {
                    component: ValioCloseAccountComponent,
                    guards: [AuthGuard],
                }
            },
        }),
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule,
        I18nModule,
        RouterModule,
        ValioForgotPasswordModule,
        ValioResetPasswordModule,
        ValioInputModule,
        ValioRegisterModule
    ],
    declarations: [ValioSideBarComponent, ValioMyAccountHeaderComponent, ValioUpdateProfileComponent, ValioUpdateProfileFormComponent, ValioUpdatePasswordComponent, ValioUpdatePasswordFormComponent, ValioContractsComponent, ValioCloseAccountComponent, ValioCloseAccountModalComponent, ValioNewContractComponent],
    exports: [ValioSideBarComponent, ValioMyAccountHeaderComponent, ValioUpdateProfileComponent, ValioUpdateProfileFormComponent, ValioUpdatePasswordComponent, ValioUpdatePasswordFormComponent, ValioContractsComponent, ValioCloseAccountComponent, ValioCloseAccountModalComponent, ValioNewContractComponent],
    providers: [
        {
            provide: UpdateProfileComponentService,
            useClass: ValioUpdateProfileComponentService,
        },
      provideDefaultConfig(valioNewContactLayoutConfig),
      provideDefaultConfig(valioCloseAccountModalLayoutConfig)

    ]
})
export class ValioMyAccountModule {
}
