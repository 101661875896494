import {CATEGORIES_DATA, ValioCategoriesState} from "./valio-categories.action";
import {InjectionToken, Provider} from "@angular/core";
import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store";
import {CategoryHierarchy} from "../../models";
import {AuthActions, SiteContextActions, StateUtils} from "@spartacus/core";
import {SELECT_UNIT} from "../user/valio-user-actions";

export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<ValioCategoriesState>> = new InjectionToken<ActionReducerMap<ValioCategoriesState>>('CategoriesReducers');

export const valioCategoriesReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getValioCategoriesReducers,
};

export function clearState(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    if ( // clear categories when currency/language changes
      action.type === SiteContextActions.CURRENCY_CHANGE ||
      action.type === SiteContextActions.LANGUAGE_CHANGE ||
      action.type === SELECT_UNIT ||
      action.type === AuthActions.LOGIN

    ) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export function getValioCategoriesReducers(): ActionReducerMap<ValioCategoriesState> {
  return {
    active: StateUtils.entityLoaderReducer<CategoryHierarchy>(CATEGORIES_DATA)
  }
}
