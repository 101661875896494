<div class="container">
  <div class="cart-header">
    <div class="page-header-back">
      <a [routerLink]="'/'">
        <span class="valio-icon icon-inline icon-chevron icon-chevron-left margin-right-sm"></span>
        <span class="d-none d-md-inline-block">{{ 'common.return' | cxTranslate }}</span>
      </a>
    </div>
    <div class="page-header-title">
      <h1>{{ 'cart.header.cart' | cxTranslate }}</h1>
    </div>
  </div>
  <div class="cart-rows-for-date">
    <h3>{{ 'cart.header.cartRows' | cxTranslate }}</h3>
  </div>
  <div class="d-none d-lg-block">
    <valio-suggestive-search
      (addToCartEmitter)="addToCart($event)">
    </valio-suggestive-search>
    <valio-cx-product-contractual-switch></valio-cx-product-contractual-switch>
  </div>

  <valio-cart-item-message *ngIf="addMessage"
                           [item]="addMessage"
                           (add)="addToCart($event)"
                           [compact]="false"
  ></valio-cart-item-message>

  <ng-container *ngIf="valioCart$ | async as cart">
    <div class="cart-message">
      <valio-cart-item-message
        [item]="cart"
        [compact]="false"
      ></valio-cart-item-message>
    </div>
    <ng-container *ngIf="cart.dateGroupedEntries as dateEntries">
      <ng-container *ngFor="let dateEntry of dateEntries; index as idx">
        <ng-container *ngIf="idx == 1">
          <div class="cart-rows-for-other-date">
            <h3>{{ 'cart.header.cartRowsOther' | cxTranslate }}</h3>
          </div>
        </ng-container>
        <valio-cx-cart-details-title
          [isReadOnly]="isReadOnly"
          [dateEntry]="dateEntry"
          [cartLoaded]="cartLoaded$|async"
          [showOtherDateToggle]="idx > 0"
          [collapsed]="idx > 0"
        ></valio-cx-cart-details-title>
      </ng-container>
    </ng-container>
  </ng-container>
  <cx-page-slot position="CartCarouselSlot"></cx-page-slot>
</div>
