import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule,} from '@spartacus/core';
import {ValioSiteContextSelectorComponent} from './valio-site-context-selector.component';
import {ValioSiteContextModule} from "../../../services/site/valio-site-context.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                CMSSiteContextComponent: {
                    component: ValioSiteContextSelectorComponent
                }
            },
        }),
        ValioSiteContextModule
    ],
    declarations: [ValioSiteContextSelectorComponent],
    exports: [ValioSiteContextSelectorComponent]
})
export class ValioSiteContextSelectorModule {
}
