import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ValioRegisterComponent} from './valio-register.component';
import {CmsConfig, ConfigModule, I18nModule, NotAuthGuard, UrlModule} from "@spartacus/core";
import {ReactiveFormsModule} from "@angular/forms";
import {PageSlotModule, SpinnerModule} from "@spartacus/storefront";
import {RouterModule} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {ValioRegisterExistingComponent} from "./valio-register-existing.component";
import {ValioRegisterNewComponent} from "./valio-register-new.component";
import {ValioVendorModule} from "../../../../services/vendor/valio-vendor.module";
import {ValioInputModule} from "../../../input/valio-input.module";
import {ValioCustomerNumberComponent} from "./customer-number/valio-customer-number.component";
import {ValioLoginModule} from "../login/valio-login.module";

@NgModule({
    declarations: [
        ValioRegisterComponent,
        ValioRegisterExistingComponent,
        ValioRegisterNewComponent,
        ValioCustomerNumberComponent
    ],
    imports: [
        CommonModule,
        ValioLoginModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                RegisterCustomerComponent: {
                    component: ValioRegisterComponent,
                    guards: [NotAuthGuard],
                },
            },
        }),
        I18nModule,
        SpinnerModule,
        NgSelectModule,
        PageSlotModule,
        ValioVendorModule,
        ValioInputModule,
    ],
    exports: [
        ValioCustomerNumberComponent
    ]
})
export class ValioRegisterModule {
}
