import {VendorState} from "./valio-vendor-state";
import {LOAD_VENDORS_SUCCESS, VendorsAction} from "./valio-vendor.action";
import {ActionReducer, MetaReducer} from "@ngrx/store";
import {SELECT_UNIT} from "../user/valio-user-actions";
import {AuthActions} from "@spartacus/core";

export const initialState: VendorState = {
  vendors: [],
};

export const metaReducers: MetaReducer<any>[] = [clearState];

export function clearState(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    if (action.type === SELECT_UNIT ||
      action.type === AuthActions.LOGOUT ||
      action.type === AuthActions.LOGIN) {
      state = initialState;
    }
    return reducer(state, action);
  };
}

export function reducer(
  state = initialState,
  action: VendorsAction
): VendorState {
  switch (action.type) {
    case LOAD_VENDORS_SUCCESS: {
      const vendors = action.payload;
      return {vendors};
    }
  }

  return state;
}
