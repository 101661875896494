import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {LaunchDialogService} from "@spartacus/storefront";
import {Observable, Subscription} from "rxjs";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {ValioCartModification, ValioCartEntry} from "../../../../services/cart/valio-cart.objects";

@Component({
  selector: 'valio-cx-import-from-file-summary',
  templateUrl: './valio-import-from-file-summary.component.html',
})
export class ValioImportFromFileSummaryComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  cartModifications$: Observable<ValioCartModification[]> = this.cartService.getCsvImportCartModifications()
  errors: number = 0;
  success: number = 0;

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected cdr: ChangeDetectorRef,
    protected cartService: ValioCartService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.cartService.getCsvImportCartModifications().subscribe(modifications => {
      this.success = modifications.filter(modification => modification.statusCode == 'success').length
      this.errors = modifications.filter(modification => modification.statusCode != 'success').length
    }));
  }

  hasErpErrors(entry: ValioCartEntry): boolean {
    return entry?.erpMessages?.find(msg => msg.error) != undefined
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dismissModal(): void {
    this.launchDialogService.closeDialog(null);
    this.cartService.resetCsvImportCartModifications();
  }
}
