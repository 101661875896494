<div class="cx-item"
     [attr.data-productid]="item?.product?.code"
     [attr.data-listName]="listName ? listName : null">
  <div class="row align-items-center">
    <div [ngClass]="{'deleted': item.deleted}" class="cart-item-message col-12">
      <!-- error messages -->
      <valio-cart-item-message
        *ngIf="item.erpMessages"
        (remove)="removeItem($event)"
        (update)="updateEntryData($event)"
        [compact]="compact"
        [item]="item"
      ></valio-cart-item-message>
    </div>
    <!-- Item Information -->
    <div [ngClass]="{'has-errors':!item.validForOrdering, 'deleted': item.deleted}" class="col-12 col-md-6 col-lg-4 margin-bottom-base print-item">
      <div class="row align-items-center">
        <div class="col-12 col-lg-9 product-image-description">
          <!-- Item Image -->
          <div class="cx-image-container">
            <a (click)="viewItem()"
               [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl">
              <cx-media
                [container]="item.product.images?.PRIMARY"
                format="cartIcon"></cx-media>
            </a>
          </div>
          <!-- Item Description -->
          <div class="product-description">
            <div *ngIf="item.product.name" class="cx-name">
              <a (click)="viewItem()"
                 [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
                 class="cx-link"
              >{{ item.product.name }}</a
              >
            </div>
            <div *ngIf="item.product.code" class="cx-code">{{ 'cartItems.id' | cxTranslate }} {{ item.product.code | productCode}} | {{item.unit?.conversionToInvoiceUnit}}</div>
            <valio-product-icons
              [entry]="item"
            ></valio-product-icons>
          </div>
        </div>
        <div *ngIf="!hidePrices" class="d-none d-lg-block col-lg-3 d-print-block product-prices">
          <p class="bold" [ngClass]="{'voucher':item.voucher}">{{item.basePrice?.formattedValue}} / {{item.product.invoicingUnit?.name}}</p>
          <p *ngIf="item.comparisonPrice">{{item.comparisonPrice?.formattedValue }} / {{item.product.baseUnit?.name}}</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-6 margin-bottom-base print-quantity" [ngClass]="{'has-errors':!item.validForOrdering, 'deleted': item.deleted}">
      <div class="row align-items-center">
        <div class="col-5 col-md-5 col-lg-5 product-prices">
          <div class="row align-items-center">
            <!-- Item Quantity -->
            <div class="cx-quantity col-12">
              <div class="cx-label d-block d-lg-none d-xl-none d-print-none" title="{{ 'cartItems.quantityTitle' | cxTranslate }}">
                {{ 'cartItems.quantity' | cxTranslate }}
              </div>
              <div *ngIf="getIsReadOnly()" class="cart-item-quantity cx-value">
                {{ item.quantity }}
                <span class="unit-name">{{item.unit?.name}}</span>
              </div>
              <div
                *ngIf="!getIsReadOnly() && parent"
                [formGroup]="parent"
                class="cx-value d-inline-block"
              >
                <valio-cx-item-counter
                  (update)="updateEntryData($event)"
                  [cartIsLoading]="cartIsLoading"
                  [id]="'itemCounterInput_'+item.entryNumber+'_'+item.product.code"
                  [isValueChangeable]="item.editable"
                  [max]="item.product.stock?.stockLevel || 1000"
                  [min]="0"
                  [showButtons]="true"
                  [step]="1"
                  [unit]="item.unit"
                  [value]="item.quantity"
                  [styleClass]="'cx-counter cx-counter-cart-item'"
                >
                </valio-cx-item-counter>
              </div>
              <div *ngIf="item.schoolMilkAllowed" class="d-none d-lg-inline-block d-print-inline-block school-milk">
                <input
                  (change)="updateSchoolMilkSupport($event.currentTarget.checked)"
                  [checked]="item.schoolMilkSupport"
                  [disabled]="getIsReadOnly() || !item.schoolMilkAllowed"
                  class="form-check-input"
                  type="checkbox"
                />
                <label>
                  {{'cart.cartItems.schoolMilkSupport'|cxTranslate}}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div [ngClass]="{'deleted': item.deleted}" class="col-7 col-md-7 col-lg-7 totals">
          <div class="row align-items-center">
            <div class="weight-totals" [ngClass]="!hidePrices && item.totalPrice ? 'col-6 col-lg-3' : 'col-12 col-lg-6' ">
              <span class="weight-unit">{{item.unit?.conversionToWeightUnit}}</span>
              <span class="weight-total">{{'cart.cartItems.total' | cxTranslate}}</span>
            </div>
            <!-- Total -->
            <div *ngIf="!hidePrices && item.totalPrice" class="cx-total col-6 col-lg-3">
              <div class="cx-price" [ngClass]="{'voucher':item.voucher}">{{ item.totalPrice?.formattedValue }}</div>
              <div class="cx-vat">{{'cart.header.alv.zero' | cxTranslate}}</div>
            </div>
            <!-- po number -->
            <div class="d-none d-lg-block d-print-block col-lg-6 po-number">
              <valio-cx-input (change)="updatePoNumber($event.target.value)" [idName]="'poNumber_'+item.externalItemNumber+'_'+item.externalOrderNumber+'_'+item.entryNumber"
                              [inputValue]="item.poNumber ? item.poNumber : ''" [labelPlaceholder]="'cart.cartItems.poNumber'"
                              [nameForm]="'poNumber'"
                              [ngClass]="getIsReadOnly() ? 'disabled' : ''"
                              class="valio-input dark-input small-input small-text po-number"></valio-cx-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-2 margin-bottom-base print-status" [ngClass]="{'has-errors':!item.validForOrdering}">
      <div class="row align-items-center">
        <div class="col-12 cart-item-status-actions">
          <div *ngIf="item.statusDisplay" class="cart-item-status-bg" [ngClass]="{'deleted': item.deleted}">
            <div class="cart-item-status">
              <span class="dot {{item.statusDisplay}}"></span>{{'cart.cartItems.status.' + item.statusDisplay|cxTranslate}}
            </div>
            <div *ngIf="item.validForOrdering && item.salesendDate && item.statusDisplay!='closed'" class="cart-item-closing-time" [ngClass]="{'closing-time-passed': closingTimePassed, 'closing-time-about-to-pass':closingTimeAboutToPass}">
              <span class="closing-item">{{'cart.cartItems.status.closingtime' | cxTranslate}}&nbsp;</span>
              <span class="closing-item">{{convertDate(item.salesendDate)| valioDate : 'd.M.'}} {{'cart.cartItems.status.closingtimeSeparator' | cxTranslate}} {{convertDate(item.salesendDate)| valioDate : 'H:mm'}}</span>
            </div>
          </div>

          <!-- Actions -->
          <div *ngIf="item.deletable && allowDelete" class="cart-item-actions text-right">
            <button
              (click)="removeItem($event)"
              [class.disabled]="cartIsLoading"
              [disabled]="cartIsLoading"
              [ngClass]="{'delete-icon btn-icon':!item.deleted,'recover-icon btn-link':item.deleted}"
              class="btn">

              <ng-container *ngIf="item.deleted; else icon">
                {{('cart.cartItems.recover') | cxTranslate }}
              </ng-container>

              <ng-template #icon>
                <span class="valio-icon icon-remove"></span>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
