import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsPartnerCardComponent} from '../../../../models';
import {Observable} from "rxjs";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {ValioUser} from "../../../../models/misc.model";
import {map} from "rxjs/operators";
import { UserAccountFacade } from '@spartacus/user/account/root';


@Component({
  selector: 'valio-cx-partner-card',
  templateUrl: './valio-partner-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioPartnerCardComponent {
  isAnonymous$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;

  data$ = this.componentData.data$;
  user$: Observable<ValioUser>;

  constructor(
    protected componentData: CmsComponentData<ValioCmsPartnerCardComponent>,
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade) {
  }

  ngOnInit() {
    this.isAnonymous$ = this.userService.isAnonymous();
    this.isSignedIn$ = this.userService.isSignedIn();
    this.user$ = this.userAccountFacade.get().pipe(map(user => (user as ValioUser)));
  }

  hasContract(user: ValioUser, partner: string): boolean {
    return user.activeB2bUnit && user.activeB2bUnit.partners.map(p => p.code).includes(partner);
  }

  getContractNumber(user: ValioUser, partner: string): string {
    return user.activeB2bUnit.partners.filter(p => p.code == partner).map(p => p.partnerCustomerCode)[0];
  }
}
