import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Breadcrumb, ValioProduct} from "../../../../models";
import {RoutingService} from '@spartacus/core';
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";
import {Observable} from "rxjs";
import {CurrentProductService} from "@spartacus/storefront";
import {Location} from "@angular/common";

@Component({
  selector: 'valio-cx-product-info',
  templateUrl: './valio-product-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ValioGoogleAnalyticsService]
})
export class ValioProductInfoComponent implements OnInit {
  @Input() product: ValioProduct;
  @Input() breadCrumbs$: Observable<Breadcrumb[]>;

  constructor(
    protected routingService: RoutingService,
    protected googleAnalyticsService: ValioGoogleAnalyticsService,
    protected location: Location) {
  }

  ngOnInit(): void {
    // Send product details to GA on product view
    this.googleAnalyticsService.createProductDetailViewEvent(this.product);
  }

  print() {
    window.print();
  }

  goBack(): void {
    this.location.back(); // <-- go back to previous location
  }
}
