import {ALL_PARTNERS, PartnersState, ValioSiteContextState} from "./valio-site.constants";
import {VALIO_SET_SITE_PARTNER_SUCCESSFUL, ValioSetSitePartnerSuccessful} from "./valio-partner-site.action";
import {ActionReducerMap} from "@ngrx/store";
import {InjectionToken, Provider} from "@angular/core";
//import {ɵgs as languageReducer, ɵgt as currencyReducer, ɵgu as siteReducer} from "@spartacus/core";
import {restrictedAssortmentReducer} from "./valio-restricted-assortment.reducer";
import {siteReducer} from "./base-site.reducer";
import {currencyReducer} from "./currencies.reducer";
import {languageReducer} from "./languages.reducer";

export function getReducers(): ActionReducerMap<ValioSiteContextState> {
  return {
    baseSite: siteReducer,
    currencies: currencyReducer,
    languages: languageReducer,
    partner: reducer,
    restrictedAssortment: restrictedAssortmentReducer
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<ValioSiteContextState>> = new InjectionToken<ActionReducerMap<ValioSiteContextState>>(
  'ValioSiteContextReducers'
);

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};


export const initialState: PartnersState = {
  entities: null,
  activePartner: ALL_PARTNERS
};

export function reducer(
  state = initialState,
  action: ValioSetSitePartnerSuccessful
): PartnersState {
  switch (action.type) {
    case VALIO_SET_SITE_PARTNER_SUCCESSFUL: {
      return {
        ...state,
        activePartner: action.code,
      };
    }
  }

  return state;
}
