import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {OutletModule, ProductSummaryModule} from '@spartacus/storefront';
import {ValioProductSummaryComponent} from './valio-product-summary.component';
import {ValioCommonModule} from "../../../../shared/valio-common.module";
import {ValioProductPriceModule} from "../product-price/valio-product-price.module";
import {ValioProductInfoModule} from "../product-info/valio-product-info.module";
import {ValioAddToCartModule} from "../../cart/add-to-cart/valio-add-to-cart.module";
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";
import {ValioProductDeliveryPeriodModule} from "../product-delivery-period/valio-product-delivery-period.module";


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ProductSummaryComponent: {
                    component: ValioProductSummaryComponent,
                },
            },
        }),
        ValioProductPriceModule,
        ValioProductInfoModule,
        ValioAddToCartModule,
        ValioPipeModule,
        ValioProductDeliveryPeriodModule,
    ],
    declarations: [ValioProductSummaryComponent],
    exports: [ValioProductSummaryComponent]
})
export class ValioProductSummaryModule extends ProductSummaryModule {
}
