import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {UrlModule} from '@spartacus/core';
import {ValioCarouselComponent} from './valio-carousel.component';
import {CarouselModule, CarouselService, MediaModule} from '@spartacus/storefront';
import {ValioBannerModule} from '../../../cms-components/content/banner/valio-banner.module';
import {ValioCarouselService} from "./valio-carousel.service";
import {ValioProductListModule} from "../../../cms-components/product/valio-product-list.module";

@NgModule({
  imports: [CommonModule, RouterModule, MediaModule, UrlModule, ValioBannerModule, ValioProductListModule],
  declarations: [ValioCarouselComponent],
  exports: [ValioCarouselComponent],
  providers: [
    {provide: CarouselService, useClass: ValioCarouselService}
  ]
})
export class ValioCarouselModule extends CarouselModule {
}
