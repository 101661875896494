import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import {ValioImportFromFileComponent} from "./valio-import-from-file.component";
declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    IMPORT_FROM_FILE = 'IMPORT_FROM_FILE',
  }
}
export const valioImportFromFileLayoutConfig: LayoutConfig = {
  launch: {
    IMPORT_FROM_FILE: {
      inlineRoot: true,
      component: ValioImportFromFileComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
