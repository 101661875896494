/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {CurrenciesState, Currency, SiteContextActions} from "@spartacus/core";

export const initialState: CurrenciesState = {
  entities: null,
  activeCurrency: null,
};

export function currencyReducer(
  state = initialState,
  action: SiteContextActions.CurrenciesAction
): CurrenciesState {
  switch (action.type) {
    case SiteContextActions.LOAD_CURRENCIES_SUCCESS: {
      const currencies: Currency[] = action.payload;
      const entities = currencies.reduce(
        (currEntities: { [isocode: string]: Currency }, currency: Currency) => {
          return {
            ...currEntities,
            [currency.isocode ?? '']: currency,
          };
        },
        {
          ...state.entities,
        }
      );

      return {
        ...state,
        entities,
      };
    }

    case SiteContextActions.SET_ACTIVE_CURRENCY: {
      const isocode: string = action.payload;

      return {
        ...state,
        activeCurrency: isocode,
      };
    }
  }

  return state;
}
