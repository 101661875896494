import { StateUtils} from "@spartacus/core";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";
import {ValioCartModification, ValioCartEntryUpdateData, ValioCartEntry} from "./valio-cart.objects";

export const CSV_IMPORT_FEATURE = 'csvImport';

const getCsvImportContentSelector = (state: ValioCsvImportState) => {
  return state.content;
};

export const getCsvImportsState: MemoizedSelector<ValioStateWithCsvImport,
  ValioCsvImportsState> = createFeatureSelector<ValioCsvImportsState>(CSV_IMPORT_FEATURE);

export const getActiveCsvImportState: MemoizedSelector<ValioStateWithCsvImport,
  StateUtils.LoaderState<ValioCsvImportState>> = createSelector(
  getCsvImportsState,
  (cartsState: ValioCsvImportsState) => cartsState.active
);

export const getCsvImportState: MemoizedSelector<ValioStateWithCsvImport,
  ValioCsvImportState> = createSelector(
  getActiveCsvImportState,
  state => StateUtils.loaderValueSelector(state)
);

export const getCsvImportContent: MemoizedSelector<ValioStateWithCsvImport,
  ValioCartModification[]> = createSelector(
  getCsvImportState,
  getCsvImportContentSelector
);

export interface ValioStateWithCsvImport {
  [CSV_IMPORT_FEATURE]: ValioCsvImportState;
}

export interface ValioCsvImportsState {
  active: StateUtils.LoaderState<ValioCsvImportState>;
}

export interface ValioCsvImportState {
  content: ValioCartModification[];
}

export const CSV_IMPORT_DATA = '[CsvImport] Data';
export const CSV_IMPORT = '[CsvImport] Csv import';

export class ValioCsvImport extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CSV_IMPORT;

  constructor(public userId: string, public cartId: string, public payload: ValioCartEntry[]) {
    super(CSV_IMPORT_DATA,cartId);
  }

}

export const CSV_IMPORT_SUCCESS = '[CsvImport] Cvs import Success';

export class ValioCsvImportSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CSV_IMPORT_SUCCESS;

  constructor(public payload: {
    userId: string,
    cartId: string,
    cartModification: ValioCartModification[]
  }) {
    super(CSV_IMPORT_DATA);
  }
}

export const CSV_IMPORT_FAIL = '[CsvImport] Csv import failed';

export class ValioCsvImportFail extends StateUtils.LoaderFailAction {
  readonly type = CSV_IMPORT_FAIL;

  constructor(public payload:any) {
    super(CSV_IMPORT_DATA);
  }
}


export const CSV_IMPORT_RESET = '[CsvImport] Cvs import reset';

export class ValioCsvImportReset extends StateUtils.LoaderSuccessAction {
  readonly type = CSV_IMPORT_RESET;

  constructor(public payload:any = {}) {
    super(CSV_IMPORT_DATA);
  }
}
