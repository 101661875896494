import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CartActions} from "@spartacus/cart/base/core";
import {AuthActions, EventService, ProductActions, RoutingService, User, WindowRef} from "@spartacus/core";
import {UserAccountChangedEvent} from "@spartacus/user/account/root";
import {Observable, of} from 'rxjs';
import {catchError, concatMap, filter, map, mergeMap} from 'rxjs/operators';
import {DateUtils} from "../../features/misc/util/date-utils";
import {ValioCartChangeHeader, ValioCartChangeHeaderReload, ValioCartNullAction, ValioLoadUserDetailsFail} from "../cart/valio-cart-entry.action";
import {ValioCartService} from "../cart/valio-cart.service";
import {ValioDatePipe} from "../pipes/valio-date.pipe";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {SELECT_UNIT, SelectUnit} from "./valio-user-actions";
import {ValioUserConnector} from "./valio-user.connector";
import {ValioUserService} from "./valio-user.service";
import {ValioUserAccountFacade} from "./valio-user-account.facade";

export const DEFAULT_LOCAL_STORAGE_KEY = 'spartacus-local-data';

@Injectable()
export class ValioUserDetailsEffects {
  constructor(
    private actions$: Actions,
    protected userConnector: ValioUserConnector,
    protected winRef: WindowRef,
    protected cxDate: ValioDatePipe,
    protected cartService: ValioCartService,
    protected userService: ValioUserService,
    protected userAccountFacade: ValioUserAccountFacade,
    protected eventService: EventService,
    protected routingService: RoutingService,
  ) {
  }

  selectUnit$: Observable<CartActions.ResetCartDetails
    | ValioCartChangeHeader | ProductActions.ClearProductSearchResult | ValioCartChangeHeaderReload | ValioLoadUserDetailsFail> = createEffect(() => this.actions$.pipe(
    ofType(SELECT_UNIT),
    map((action: SelectUnit) => action),
    mergeMap(action => {
      return this.userConnector.selectUnit(action.userId, action.unit).pipe(
        concatMap((user: User) => {
          const value = this.winRef.localStorage.getItem(DEFAULT_LOCAL_STORAGE_KEY);
          const sessionValue = value ? JSON.parse(value) : {user: null}
          sessionValue.user = user;
          this.winRef.localStorage.setItem(DEFAULT_LOCAL_STORAGE_KEY, JSON.stringify(sessionValue)); // update storage user

          const evt = new UserAccountChangedEvent();
          evt.user = user;
          this.eventService.dispatch(evt);

          return [
            //new UserActions.UpdateUserDetailsSuccess(user),
            new CartActions.ResetCartDetails(),
            new ValioCartChangeHeader({
              userId: action.userId,
              cartId: 'current',
              field: 'deliveryDate',
              value: this.cxDate.transform(DateUtils.getNow(), 'yyyy-MM-dd')
            }),
            new ValioCartChangeHeaderReload(true, {userId: action.userId, cartId: 'current'}),//VALCOM-1654 select the first available date
            // this will trigger the reset of product store. Needed when delivery date changes
          ];
        }),
        catchError(error => {
            console.error(makeErrorSerializable(error))
            return of(new ValioLoadUserDetailsFail(makeErrorSerializable(error)));
          }
        )
      );
    })
  ));

  login$: Observable<ValioCartNullAction | ValioCartChangeHeader> = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGIN),
    concatMap(() =>
      this.userService.isAnonymous().pipe(
        concatMap(anon => {
          if (!anon) {
            return this.userAccountFacade.get().pipe(
              filter(user => user !== undefined),
              map(user => {
                  if (user.requirePasswordChange) {
                    this.routingService.go('/my-account/force-update-password', {queryParams: {force: !user.allowPasswordChangeSkip}});
                  }
                  return new ValioCartChangeHeader({
                    userId: 'current',
                    cartId: 'current',
                    field: 'deliveryDate',
                    value: this.cxDate.transform(DateUtils.getNow(), 'yyyy-MM-dd')
                  });
                }
              )
            );
          }
          return of(new ValioCartNullAction());
        })
      )
    )//VALCOM-1654 select the first available date
  ));

}
