import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from "@spartacus/core";
import {OrderFacade} from "@spartacus/order/root";
import {OrderConfirmationTotalsComponent} from "@spartacus/order/components";

@Component({
  selector: 'valio-cx-order-confirmation-totals',
  templateUrl: './valio-order-confirmation-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioOrderConfirmationTotalsComponent extends OrderConfirmationTotalsComponent {
  constructor(protected orderFacade: OrderFacade,
    protected routingService: RoutingService) {
    super(orderFacade);
  }

  ngOnInit() {

  }
}
