import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule, UrlModule} from '@spartacus/core';
import {CarouselModule, MediaModule} from '@spartacus/storefront';
import {ValioProductCarouselComponent} from './valio-product-carousel.component';
import {ValioProductListModule} from '../../valio-product-list.module';
import {ValioCarouselModule} from '../../../../shared/components/carousel/valio-carousel.module';
import {ValioLastPurchasesModule} from "../last-purchases/valio-last-purchases.module";
import {ValioTopSellersModule} from "../top-sellers/valio-top-sellers.module";
import {ValioBoughtTogetherModule} from "../bought-together/valio-bought-together.module";

@NgModule({
    imports: [
        CommonModule,
        CarouselModule,
        MediaModule,
        RouterModule,
        UrlModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioProductCarouselComponent: {
                    component: ValioProductCarouselComponent,
                },
            },
        }),
        ValioProductListModule,
        ValioCarouselModule,
        ValioLastPurchasesModule,
        ValioTopSellersModule,
        ValioBoughtTogetherModule,
    ],
    declarations: [ValioProductCarouselComponent],
    exports: [ValioProductCarouselComponent]
})
export class ValioProductCarouselModule {
}
