import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CmsBreadcrumbsComponent, PageMeta, PageMetaService, RoutingService, TranslationService} from "@spartacus/core";
import {CmsComponentData} from "@spartacus/storefront";
import {combineLatest, Observable} from "rxjs";
import {filter, map} from "rxjs/operators";
import {Location} from '@angular/common';

@Component({
  selector: 'valio-cx-breadcrumb',
  templateUrl: './valio-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioBreadcrumbComponent {
  title$: Observable<string>;
  crumbs$: Observable<any[]>;

  constructor(
    public component: CmsComponentData<CmsBreadcrumbsComponent>,
    protected pageMetaService: PageMetaService,
    protected translation: TranslationService,
    protected routingService: RoutingService,
    protected location: Location) {
  }

  ngOnInit(): void {
    this.setTitle();
    this.setCrumbs();
  }

  private setTitle(): void {
// TODO:Spartacus - Method getMeta return type has been changed from 'Observable<PageMeta>' to 'Observable<PageMeta | null>'.
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
  }

  private setCrumbs(): void {
    this.crumbs$ = combineLatest([
// TODO:Spartacus - Method getMeta return type has been changed from 'Observable<PageMeta>' to 'Observable<PageMeta | null>'.
      this.pageMetaService.getMeta(),
      this.translation.translate('common.home'),
    ]).pipe(
      map(([meta, textHome]) =>
        meta && meta.breadcrumbs ? meta.breadcrumbs : [{label: textHome, link: '/'}]
      )
    );
  }

  goBack(): void {
    this.location.back(); // <-- go back to previous location
  }
}
