<ng-container *ngIf="product && product.freezeItem">
  <div class="product-icon valio-icon icon-md icon-freeze"></div>
</ng-container>
<ng-container *ngIf="product && product.variableWeight">
  <div class="product-icon valio-icon icon-md icon-variable-weight"></div>
</ng-container>
<ng-container *ngIf="false && (product && product.paymentMode?.card) || (entry && entry.paymentMode?.card)">
  <div class="product-icon valio-icon icon-md icon-credit"></div>
</ng-container>
<ng-container *ngIf="product && product.contractual">
  <div class="product-icon valio-icon icon-md icon-contractual-product"></div>
</ng-container>
