<ng-container *ngIf="data$ | async as data">
  <div class="partner-card" [ngClass]="{'is-anonymous' : isAnonymous$ | async,'is-signed-in' : isSignedIn$ | async}">
    <cx-media [container]="data.media"></cx-media>
    <div class="head-text">{{data.headText}}</div>
    <div class="card-text"><span [innerHTML]="data.text">{{data.text}}</span></div>
    <!-- Removing products link for now
    <div class="margin-bottom-base">
      <a [ngClass]="{'btn-primary' : isAnonymous$ | async,'btn-link search-text' : isSignedIn$ | async}" class="btn" [routerLink]="data.productUrl">{{'partnersCard.search'|cxTranslate}}</a>
    </div>
    -->
    <ng-container *ngIf="user$|async as user">
      <div class="margin-bottom-base" *ngIf="!hasContract(user,data.productUrl)">
        <a [ngClass]="{'d-none' : isAnonymous$ | async,'btn-primary' : isSignedIn$ | async}" class="btn btn-lg" [routerLink]="data.applyUrl">{{'partnersCard.apply'|cxTranslate}}</a>
      </div>
      <div *ngIf="hasContract(user,data.productUrl)" class="existing-contract">
        <div class="margin-bottom-base">
          <p class="text-bold">{{'partnersCard.contract'|cxTranslate}}</p>
        </div>
        <p>{{'partnersCard.customerId'|cxTranslate}} {{getContractNumber(user, data.productUrl)}}</p>
      </div>
    </ng-container>
  </div>
</ng-container>
