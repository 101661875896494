import {InjectionToken, Provider} from "@angular/core";
import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store";
import {SelectUnit, SELECT_UNIT} from "../user/valio-user-actions";
import {
    LOAD_LAST_PURCHASES_FAIL,
    LOAD_LAST_PURCHASES_SUCCESS,
    ValioLastPurchasessState,
    ValioLastPurchasesState,
    ValioLoadLastPurchasesFail,
    ValioLoadLastPurchasesSuccess
} from "./valio-last-purchases.action";
import {StateUtils} from "@spartacus/core";

export const initialState: ValioLastPurchasesState = {
    content: {products: []}
};

export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<ValioLastPurchasessState>> = new InjectionToken<ActionReducerMap<ValioLastPurchasessState>>('LastPurchasesReducers');

export const valioLastPurchasesReducerProvider: Provider = {
    provide: reducerToken,
    useFactory: getValioLastPurchasesReducers,
};

export function clearState(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return function (state, action) {
        return reducer(state, action);
    };
}

export function getValioLastPurchasesReducers(): ActionReducerMap<ValioLastPurchasessState> {
    return {
        active: StateUtils.loaderReducer<ValioLastPurchasesState>(LOAD_LAST_PURCHASES_SUCCESS, valioLastPurchasesReducer),
    };
}

export function valioLastPurchasesReducer(
    state = initialState,
    action: ValioLoadLastPurchasesSuccess | ValioLoadLastPurchasesFail | SelectUnit
): ValioLastPurchasesState {
    switch (action.type) {
        case LOAD_LAST_PURCHASES_SUCCESS: {
            return {
                content: action.products
            };
        }
        case LOAD_LAST_PURCHASES_FAIL:
        case SELECT_UNIT: {
            //case CheckoutActions.PLACE_ORDER_SUCCESS:
            return initialState;
        }
        default: {
            return state;
        }
    }
}
