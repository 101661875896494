export function openCloseSpinner(show: boolean, element?: HTMLElement) {
  const node = document.getElementById('valio-spinner');
  if (node && node.classList) {
    if (show) {
      node.classList.add('show');
    } else {
      node.classList.remove('show');
    }
  }
}
