<valio-main-navigation-component></valio-main-navigation-component>
<div class="valio-sidebar">
  <div class="order-templates-menu">
    <ul *ngIf="orderTemplates$|async as orderTemplates">
      <li class="order-templates-title">
        <span class="valio-icon icon-inline icon-order-template margin-left-base margin-right-md"></span>
        <a [ngClass]="{'active':isActive('order-templates')|async}" [routerLink]="'/my-account/order-templates'">{{'myaccount.orderTemplate.title'|cxTranslate}}</a>
      </li>
      <li class="delimiter">
        <div class="template-item" *ngFor="let template of orderTemplates">
          <a [ngClass]="{'active':isActive(template.code)|async}" (click)="switchTemplate(template.code)">{{template.name}}</a>
        </div>
      </li>
      <li class="order-templates-create">
        <button class="btn btn-block btn-primary margin-bottom-md" type="submit" (click)="openCreateTemplate()">
          {{ 'myaccount.orderTemplate.new' | cxTranslate }}
        </button>
      </li>
    </ul>
  </div>
  <div class="order-templates-menu" *ngIf="user$ |async as user">
    <ul *ngIf="user.activeB2bUnit" class="d-none d-lg-block">
      <li class="base-order-title">
        <span class="valio-icon icon-inline icon-base-order margin-left-base margin-right-md"></span>
        <a [ngClass]="{'active':isActive('modify-base-order')|async}" [routerLink]="'/my-account/modify-base-order'">{{'myaccount.baseorder.title'|cxTranslate}}</a>
      </li>
    </ul>
  </div>
</div>
