import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ValioCart, ValioCartPaymentModeGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {ValioGoogleAnalyticsService} from "../../../../../services/analytics/valio-google-analytics.service";
import {ValioUserService} from "../../../../../services/user/valio-user.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'valio-cx-review-submit-rows',
  templateUrl: './valio-review-submit-rows.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioReviewSubmitRowsComponent implements OnInit, OnDestroy {
  @Input() group: ValioCartPaymentModeGroupedEntryWrapper;
  @Input() cart: ValioCart;
  @Input() showButton: boolean;
  @Input() showItems: boolean;
  @Input() buttonTextKey: string;
  @Input() title: string;
  @Input() switchToCardPayment;
  @Output()
  clickButton = new EventEmitter<any>();
  loggedUser: boolean;
  subscription: Subscription = new Subscription();


  constructor(protected googleAnalyticsService: ValioGoogleAnalyticsService,
              protected userService: ValioUserService) {
  }

  click() {
    this.clickButton.emit(this.cart.requestedDate);
  }

  ngOnInit(): void {
    this.googleAnalyticsService.checkoutStepEvent(this.cart);
    this.subscription.add(this.userService.isSignedIn().subscribe(signed => this.loggedUser = signed));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
