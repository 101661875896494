import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidator, ValidationErrors} from "@angular/forms";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {CsvParserService} from "./CsvParserService";

@Injectable({providedIn: 'root'})
export class ValioCsvImportValidator implements AsyncValidator {

  constructor(protected csvParserService: CsvParserService) {

  }

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.csvParserService.parseAsync(ctrl.value).pipe(
      map(result => {
        let returnValue = {};
        const invalidCsv: boolean = result.errors && result.errors.length != 0;
        if (invalidCsv) {
          returnValue = {...returnValue, 'csvParseError': {value: invalidCsv}};
        }
        const invalidLine: boolean = result.data
          .filter(lineArray => lineArray.length >= 2)
          .filter(lineArray => {
            const strValue:string = lineArray[1]
            if(strValue.length == 0) {
              return false;
            }
            const value = Number(strValue);
            return !isNaN(value) && value > 0
          }).length != result.data.length;

        if (invalidLine) {
          returnValue = {...returnValue, 'valueParseError': {value:invalidLine}};
        }
        return returnValue || invalidLine ? returnValue : null;
      }),

      catchError(() => null)
    )
  }
}
