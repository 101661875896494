import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {GenericLinkModule, MediaModule, OutletModule, PageSlotModule} from '@spartacus/storefront';

import {ValioCommonModule} from '../../../../shared/valio-common.module';
import {ValioNewsItemComponent} from './valio-news-item.component';
import {RouterModule} from '@angular/router';
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioNewsItemComponent: {
                    component: ValioNewsItemComponent,
                },
            },
        }),
        GenericLinkModule,
        PageSlotModule,
        RouterModule,
        ValioPipeModule,
    ],
    declarations: [ValioNewsItemComponent],
    exports: [ValioNewsItemComponent]
})
export class ValioNewsItemModule {
}
