import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CheckoutAuthGuard } from "@spartacus/checkout/base/components";
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ValioCheckoutModule } from "../../valio-checkout.module";
import { ValioVoucherModule } from "../../voucher/valio-voucher.module";
import { ValioReviewSubmitModule } from "../valio-review-submit.module";
import { ValioInvoicePageGuard } from "./valio-invoice-page.guard";
import { ValioReviewSubmitInvoiceComponent } from "./valio-review-submit-invoice.component";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    RouterModule,
    FeaturesConfigModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewOrderInvoice: {
          component: ValioReviewSubmitInvoiceComponent,
          guards: [
            ValioInvoicePageGuard,
            CheckoutAuthGuard,
          ],
        },
      },
    }),
    ValioCheckoutModule,
    ValioReviewSubmitModule,
    ValioVoucherModule
  ],
  declarations: [ValioReviewSubmitInvoiceComponent],
  exports: [ValioReviewSubmitInvoiceComponent]
})
export class ValioReviewSubmitInvoiceModule {
}
