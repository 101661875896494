<div class="contracts">
  <div class="header">
    <h3>{{'myaccount.contracts'|cxTranslate}}</h3>
    <p>{{'myaccount.contractsText'|cxTranslate}}</p>
  </div>
  <div class="content" *ngIf="user$|async as user">
    <div class="company" *ngFor="let unit of user.b2bUnits">
      <div class="description" (click)="togglePartner('company_'+unit.uid)">
        <div class="valio-icon icon-inline icon-chevron icon-chevron-down margin-right-base" [ngClass]="!isCollapsed('company_'+unit.uid) ? 'd-none' : 'd-inline-block'"></div>
        <div class="valio-icon icon-inline icon-chevron icon-chevron-right margin-right-base" [ngClass]="isCollapsed('company_'+unit.uid) ? 'd-none' : 'd-inline-block'"></div>
        {{unit.uid}} {{unit.name}}</div>
      <div class="partners collapsed" id="company_{{unit.uid}}">
        <div class="partner row">
          <div class="col-12 col-lg-4 col-xl-6 customer">
            <div class="margin-bottom-sm">{{'myaccount.customerNumber'|cxTranslate}}:</div>
            <div class="value margin-bottom-base-md-down">{{unit.uid}}</div>
          </div>
          <div class="col-12 col-lg-8 col-xl-6">
            <div class="margin-bottom-sm">{{'myaccount.address'|cxTranslate}}:</div>
            <div class="value">{{unit.shippingAddress?.formattedAddress}}</div>
          </div>
        </div>
        <div class="partner row" *ngFor="let partner of unit.partners">
          <div class="col-12 col-lg-4 col-xl-6 value margin-bottom-base-md-down">
            {{partner.name}}
          </div>
          <div class="col-12 col-lg-8 col-xl-6">
            <div class="row">
              <div class="col-12 col-lg-9 col-xl-8">
                {{'myaccount.partnerNumber'|cxTranslate}}:
              </div>
              <div class="col-12 col-lg-3 col-xl-4 value customer-code">
                {{partner.partnerCustomerCode}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
