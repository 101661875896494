import {RouteCalendar} from "../../models/valio-calendar.model";
import {StateUtils} from "@spartacus/core";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";

export const ROUTE_CALENDAR_FEATURE = 'routeCalendar';

const getRouteCalendarContentSelector = (state: ValioRouteCalendarState) => {
  return state.content;
};

export const getRouteCalendarsState: MemoizedSelector<ValioStateWithRouteCalendar,
  ValioRouteCalendarsState> = createFeatureSelector<ValioRouteCalendarsState>(ROUTE_CALENDAR_FEATURE);

export const getActiveRouteCalendarState: MemoizedSelector<ValioStateWithRouteCalendar,
  StateUtils.LoaderState<ValioRouteCalendarState>> = createSelector(
  getRouteCalendarsState,
  (cartsState: ValioRouteCalendarsState) => cartsState.active
);

export const getRouteCalendarState: MemoizedSelector<ValioStateWithRouteCalendar,
  ValioRouteCalendarState> = createSelector(
  getActiveRouteCalendarState,
  state => StateUtils.loaderValueSelector(state)
);

export const getRouteCalendarContent: MemoizedSelector<ValioStateWithRouteCalendar,
  RouteCalendar> = createSelector(
  getRouteCalendarState,
  getRouteCalendarContentSelector
);

export interface ValioStateWithRouteCalendar {
  [ROUTE_CALENDAR_FEATURE]: ValioRouteCalendarState;
}

export interface ValioRouteCalendarsState {
  active: StateUtils.LoaderState<ValioRouteCalendarState>;
}

export interface ValioRouteCalendarState {
  content: RouteCalendar;
  loading: boolean;
}

export const ROUTE_CALENDAR_DATA = '[RouteCalendar] Data';
export const LOAD_ROUTE_CALENDAR = '[RouteCalendar] Load calendar';

export class ValioLoadRouteCalendar extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_ROUTE_CALENDAR;

  constructor(public userId: string, public cartId: string) {
    super(ROUTE_CALENDAR_DATA);
  }
}

export const LOAD_ROUTE_CALENDAR_SUCCESS = '[RouteCalendar] Load Success';

export class ValioLoadRouteCalendarSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_ROUTE_CALENDAR_SUCCESS;

  constructor(public routeCal: RouteCalendar) {
    super(ROUTE_CALENDAR_DATA);
  }
}

export const LOAD_ROUTE_CALENDAR_FAIL = '[RouteCalendar] Load Failed';

export class ValioLoadRouteCalendarFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_ROUTE_CALENDAR_FAIL;

  constructor() {
    super(ROUTE_CALENDAR_DATA);
  }
}
