<ng-container *ngIf="!selectedPartner.allPartners">
  <ng-container *ngIf="isContractCustomer()|async">
    <ng-container *ngIf="!selectedPartner.activeContract">
      <div class="partner-unactive">
        <div class="partner-no-contract-text d-inline-block text-right margin-right-base">
          {{'productList.partners.noContract'|cxTranslate: {partner: selectedPartner.name} }}
        </div>
        <button
          class="btn btn-lg btn-primary"
          type="button"
          [routerLink]="'/hae-sopimusasiakkaaksi/' + selectedPartner.code"
        >
          {{'productList.partners.apply'|cxTranslate}}
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
