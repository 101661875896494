<div *ngIf="tooltipProduct$ && tooltipProduct$ | async as tooltipProduct" id="product-tooltip-templ" class="product-tooltip-templ" [ngClass]="showPrice ? 'clickable' : ''" (mousedown)="toggleTooltip(null, showPrice)" (keypress)="toggleTooltip(null, showPrice)">
  <button *ngIf="showPrice" (click)="closeTooltip()" id="product-tooltip-close" class="btn btn-primary-inv btn-icon close-btn" type="button">
    <span class="valio-icon icon-reset icon-reset-black"></span>
  </button>
  <div class="row">
    <div class="col-4">
      <div class="cx-image-container">
        <cx-media
          [container]="tooltipProduct.images?.PRIMARY"
          format="thumbnail"></cx-media>
      </div>
    </div>
    <div class="col-8">
      <div class="details">
        <div>{{ tooltipProduct.name }}</div>
        <div class="bold">{{ tooltipProduct.code }}</div>
        <div class="padding-top-sm">{{ 'cart.cartItems.unit' | cxTranslate }} {{ tooltipProduct.unit.conversionToInvoiceUnit }}</div>
        <div *ngIf="tooltipProduct.ean">{{ 'valioProduct.productDetails.ean' | cxTranslate }} {{ tooltipProduct.ean }}</div>
        <div class="padding-top-sm">{{tooltipProduct.keyIngredients}}</div>
      </div>
      <div class="padding-top-sm" *ngIf="showPrice">
        <valio-cx-product-price [product]="tooltipProduct"></valio-cx-product-price>
        <valio-cx-add-to-cart [setProduct]="tooltipProduct"></valio-cx-add-to-cart>
      </div>
    </div>
  </div>
</div>
