import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {GenericLinkModule, MediaModule, OutletModule, PageSlotModule} from '@spartacus/storefront';

import {ValioCommonModule} from '../../../../shared/valio-common.module';
import {ValioPartnerCardComponent} from './valio-partner-card.component';
import {RouterModule} from '@angular/router';


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioPartnerCardComponent: {
                    component: ValioPartnerCardComponent,
                },
            },
        }),
        GenericLinkModule,
        PageSlotModule,
        RouterModule,
    ],
    declarations: [ValioPartnerCardComponent],
    exports: [ValioPartnerCardComponent]
})
export class ValioPartnerCardModule {
}
