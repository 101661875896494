<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <valio-cx-input [labelPlaceholder]="'updateProfileForm.firstName.label'" idName="firstName"
                    [errorMessage]="'updateProfileForm.firstNameIsRequired'"
                    [nameForm]="'firstName'" class="valio-input" [parentForm]="form"
                    [submitClicked]="submitClicked"></valio-cx-input>
  </div>
  <div class="form-group">
    <valio-cx-input [labelPlaceholder]="'updateProfileForm.lastName.label'" idName="lastName"
                    [errorMessage]="'updateProfileForm.lastNameIsRequired'"
                    [nameForm]="'lastName'" class="valio-input" [parentForm]="form"
                    [submitClicked]="submitClicked"></valio-cx-input>
  </div>
  <div class="form-group">
    <valio-cx-input [labelPlaceholder]="'updateProfileForm.email.label'" idName="email"
                    [errorMessage]="'updateProfileForm.emailRequired'"
                    [typeInput]="'email'" [nameForm]="'email'" class="valio-input" [parentForm]="form"
                    [submitClicked]="submitClicked"></valio-cx-input>
  </div>
  <div class="form-group">
    <valio-cx-input [labelPlaceholder]="'updateProfileForm.phone.label'" idName="phone"
                    [errorMessage]="'updateProfileForm.phoneRequired'"
                    [nameForm]="'phone'" class="valio-input" [parentForm]="form"
                    [submitClicked]="submitClicked"></valio-cx-input>
  </div>

  <div class="form-group">
    <div class="form-check">
      <label>
        <input
          type="checkbox"
          name="enableOrderConfirmationEmails"
          formControlName="enableOrderConfirmationEmails"
          class="form-check-input"
        />
        <span class="checkmark"></span>
        <span class="form-check-label"> {{'updateProfileForm.sendOrderConfirmationEmails'|cxTranslate}}</span>
      </label>
    </div>

  </div>

  <div class="form-group btn-centered">
    <button
      [disabled]="!this.form.dirty"
      class="btn btn-lg btn-block-sm-down btn-primary" type="submit">
      {{ 'common.save' | cxTranslate }}
    </button>
  </div>
</form>
