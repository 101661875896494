import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {GenericLinkModule, MediaModule, OutletModule} from '@spartacus/storefront';

import {ValioCommonModule} from '../../../../shared/valio-common.module';
import {ValioThreeBoxBannerComponent} from './valio-three-box-banner.component';


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioThreeBoxBannerComponent: {
                    component: ValioThreeBoxBannerComponent,
                },
            },
        }),
        GenericLinkModule,
    ],
    declarations: [ValioThreeBoxBannerComponent],
    exports: [ValioThreeBoxBannerComponent]
})
export class ValioThreeBoxBannerModule  {
}
