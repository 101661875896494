<div class="row" *ngIf="product.price || isSummary">
  <ng-container *ngIf="product.price">
    <div class="price" [ngClass]="isSummary ? 'col-8 col-md-12' : 'col-12'" aria-label="new item price" *ngIf="!product?.listPrice">
      <ng-container *ngIf="isSummary">
        {{ product?.price.formattedValue}}
        <span class="supsub">
        <sub>/{{product.invoicingUnit?.name}}</sub>
      </span>
      </ng-container>
      <ng-container *ngIf="!isSummary">
        {{ product?.price | euros }}
        <span class="supsub">
        <sup>{{ product?.price | cents }}</sup>
        <sub>/{{product.invoicingUnit?.name}}</sub>
      </span>
      </ng-container>
    </div>
    <div class="col-12" *ngIf="product?.listPrice">
      <div class="campaign-price">
        {{ product?.price | euros }}
        <span class="supsub">
        <sup>{{ product?.price | cents }}</sup>
        <sub>/{{product.invoicingUnit?.name}}</sub>
      </span>
      </div>
      <div class="list-price">
        {{ product?.listPrice?.formattedValue }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isSummary">
    <div class="d-md-none col-4">
    <span
      *ngIf="product.salesUnitPrice"><strong>{{product.salesUnitPrice?.formattedValue}}</strong>/{{product.unit?.name}}</span>
      <span
        *ngIf="product.comparisonPrice"><strong>{{product.comparisonPrice?.formattedValue}}</strong>/{{product.baseUnit?.name}}</span>
    </div>
  </ng-container>
</div>
