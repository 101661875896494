import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule, UrlModule,} from '@spartacus/core';

import {MediaModule, SearchBoxConfig, SearchBoxModule} from "@spartacus/storefront";
import {ValioSuggestiveSearchBoxComponent} from "./valio-suggestive-search-box.component";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {Vendor} from "../../../../models/misc.model";
import {ValioProductTooltipModule} from "../../product/product-tooltip/valio-product-tooltip.module";
import {FormsModule} from "@angular/forms";
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";


@NgModule({
    imports: [
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                SearchBoxComponent: {
                    component: ValioSuggestiveSearchBoxComponent
                }
            }
        }),
        RouterModule,
        MediaModule,
        CommonModule,
        UrlModule,
        I18nModule,
        ValioProductTooltipModule,
        FormsModule,
        ValioPipeModule
    ],
    declarations: [ValioSuggestiveSearchBoxComponent],
    exports: [ValioSuggestiveSearchBoxComponent]
})
export class ValioSuggestiveSearchBoxModule extends SearchBoxModule {
}

export interface ValioSuggestiveSearchBoxConfig extends SearchBoxConfig {
  showPrices?: boolean;
  selectedPartner?: Vendor;
  restrictedAssortment?: boolean;
  baseOrderMode?: boolean;
  searchCategories?: boolean;
  globalSearch?: boolean;
}
