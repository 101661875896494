import {ChangeDetectorRef, Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ValioNavigationService} from '../valio-navigation.service';

import {ValioNavigationNode} from '../valio-navigation-node.model';
import {ValioCmsMainNavigationComponent} from "../../../../models";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {RouteCalendar} from "../../../../models/valio-calendar.model";
import {ValioRouteCalendarService} from "../../../../services/routecalendar/valio-routecalendar.service";

@Component({
  selector: 'valio-main-navigation-component',
  templateUrl: './valio-main-navigation.component.html',
})
export class ValioMainNavigationComponent implements OnInit {
  requestedDate$: Observable<Date>;
  routeCalendar$: Observable<RouteCalendar>;
  isCalendarShown = false;

  constructor(
    protected componentData: CmsComponentData<ValioCmsMainNavigationComponent>,
    protected service: ValioNavigationService,
    protected routeCalendarService: ValioRouteCalendarService,
    protected cartService: ValioCartService,
    protected cdr: ChangeDetectorRef,
    protected eRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.requestedDate$ = this.cartService.getRequestedDate();
    this.routeCalendar$ = this.routeCalendarService.getRouteCalendar();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showHideCalendar(true);
    }
  }

  private componentData$: Observable<ValioCmsMainNavigationComponent> = this.componentData.data$.pipe(
    filter(Boolean)
  );

  productsNavigationNode$: Observable<ValioNavigationNode> = this.service.createNavigation(
    this.componentData$.pipe(
      map(data => data.navigationNode ? data.navigationNode.children
        .filter(node => node.uid === 'ProductsNavNode')[0] : null)
    )
  );
  vendorsNavigationNode$: Observable<ValioNavigationNode> = this.service.createNavigation(
    this.componentData$.pipe(
      map(data => data.navigationNode ? data.navigationNode.children
        .filter(node => node.uid === 'VendorsNavNode')[0] : null)
    )
  );

  node$: Observable<ValioNavigationNode> = this.service.createNavigation(
    this.componentData.data$
  );

  selectDate(date: string) {
    this.showHideCalendar(true);
    this.cartService.changeDate(date);
  }

  showHideCalendar(forceHide: boolean) {
    if (forceHide) {
      this.isCalendarShown = false;
    } else {
      this.isCalendarShown = !this.isCalendarShown;
    }
    this.cdr.detectChanges();
  }
}
