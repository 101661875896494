import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {GenericLinkModule, PageSlotModule} from '@spartacus/storefront';
import {ValioNavigationModule} from '../valio-navigation.module';
import {ValioMobileNavigationComponent} from './valio-mobile-navigation.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioMobileNavigationComponent: {
                    component: ValioMobileNavigationComponent,
                },
            },
        }),
        ValioNavigationModule,
        GenericLinkModule,
        PageSlotModule,
        I18nModule,
        UrlModule,
    ],
    declarations: [ValioMobileNavigationComponent],
    exports: [ValioMobileNavigationComponent]
})
export class ValioMobileNavigationModule {
}
