import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {OutletModule} from '@spartacus/storefront';

import {ValioCommonModule} from "../../../../shared/valio-common.module";
import {ValioProductPriceComponent} from "./valio-product-price.component";
import {ValioPipeModule} from '../../../../services/pipes/valio-pipe.module';


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioProductPriceComponent: {
                    component: ValioProductPriceComponent,
                },
            },
        }),
        ValioPipeModule,
    ],
    declarations: [ValioProductPriceComponent],
    exports: [ValioProductPriceComponent]
})
export class ValioProductPriceModule  {
}
