import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PageMetaModule, I18nModule} from '@spartacus/core';
import {ValioBrowserCheckComponent} from "./valio-browser-check.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PageMetaModule,
        I18nModule,
    ],
    declarations: [ValioBrowserCheckComponent],
    exports: [ValioBrowserCheckComponent]
})
export class ValioBrowserCheckModule {
}
