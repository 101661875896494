import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { CartDetailsModule } from "@spartacus/cart/base/components";
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { PromotionsModule } from "@spartacus/storefront";
import { ValioCarouselModule } from "../../../../shared/components/carousel/valio-carousel.module";
import { ValioProductListModule } from "../../valio-product-list.module";
import { ValioBoughtTogetherComponent } from "./valio-bought-together.component";
import {CartBaseModule} from "@spartacus/cart/base";


@NgModule({
  imports: [
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ValioBoughtTogetherComponent: {
          component: ValioBoughtTogetherComponent,
        },
      },
    }),
    I18nModule,
    PromotionsModule,
    CartBaseModule,
    CommonModule,
    ValioProductListModule,
    ValioCarouselModule,
  ],
  declarations: [ValioBoughtTogetherComponent],
  exports: [ValioBoughtTogetherComponent]
})
export class ValioBoughtTogetherModule extends CartDetailsModule {
}
