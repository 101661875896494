import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartSharedModule } from "@spartacus/cart/base/components";
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ValioCartHeaderModule } from "../cart/cart-header/valio-cart-header.module";
import { ValioCartSharedModule } from "../cart/cart-shared/valio-cart-shared.module";
import { ValioCheckoutHeaderComponent } from "./checkout-header/valio-checkout-header.component.";
import { ValioCheckoutDetailsComponent } from './valio-checkout-details.component';

@NgModule({
  imports: [
    CartSharedModule,
    CommonModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutComponent: {
          component: ValioCheckoutDetailsComponent,
        },
      },
    }),
    I18nModule,
    ValioCartSharedModule,
    ValioCartHeaderModule,
  ],
  declarations: [ValioCheckoutDetailsComponent, ValioCheckoutHeaderComponent],
  exports: [ValioCheckoutDetailsComponent, ValioCheckoutHeaderComponent]
})
export class ValioCheckoutModule {
}
