<cx-spinner *ngIf="isUpdating$ | async">
</cx-spinner>
<form *ngIf="token$ | async as token"
      (ngSubmit)="onSubmit(token)"
      [formGroup]="form">
  <div class="login-form">
    <div class="content-group">
      <h1>{{ 'resetPassword.uid.label' | cxTranslate }}</h1>
      <p>{{ 'resetPassword.uid.instructions' | cxTranslate }}</p>
    </div>
    <div class="content-group">
      <div class="form-group">
        <valio-cx-input [isNotValidFunction]="isPasswordInvalid('password')"
                        [labelPlaceholder]="'updatePasswordForm.newPassword.placeholder'" [errorMessage]="'updatePasswordForm.passwordMinRequirements'"
                        [typeInput]="'password'" [nameForm]="'password'" [idName]="'password'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>
      </div>
      <div class="form-group">
        <valio-cx-input [isNotValidFunction]="isPasswordConfirmInvalid('passwordConfirm')"
                        [labelPlaceholder]="'updatePasswordForm.confirmPassword.placeholder'" [errorMessage]="'updatePasswordForm.bothPasswordMustMatch'"
                        [typeInput]="'password'" [nameForm]="'passwordConfirm'" [idName]="'passwordConfirm'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>
      </div>
      <div class="form-check">
        <label>
          <input
            [class.is-invalid]="isInvalid('termsandconditions')"
            type="checkbox"
            name="termsandconditions"
            formControlName="termsandconditions"
          />
          <span
            [class.is-invalid]="isInvalid('termsandconditions')"
            class="checkmark"></span>
          <span class="form-check-label">{{ 'register.confirmThatRead' | cxTranslate }}
            <a role="link"
               [routerLink]="{ cxRoute: 'termsOfService' } | cxUrl"
               target="_blank"
            >{{ 'register.termsOfService' | cxTranslate }}</a>
            {{ 'register.and' | cxTranslate }}
            <a role="link"
               [routerLink]="{ cxRoute: 'privacyPolicy' } | cxUrl"
               target="_blank"
            >{{ 'register.privacyPolicy' | cxTranslate }}</a></span>
        </label>
      </div>
    </div>
    <div class="content-group">
      <div class="form-group">
        <button class="btn btn-lg btn-primary" type="submit">
          {{ 'updatePasswordForm.changePassword' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</form>
