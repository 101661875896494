import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from "@angular/router";
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from '@spartacus/core';
import {PageSlotModule, PwaModule} from "@spartacus/storefront";
import {ValioPipeModule} from "../../../services/pipes/valio-pipe.module";
import {ValioCartDetailsModule} from "../cart/cart-detail/valio-cart-details.module";
import {ValioCartHeaderModule} from "../cart/cart-header/valio-cart-header.module";
import {ValioCartSharedModule} from "../cart/cart-shared/valio-cart-shared.module";
import {ValioReviewSubmitModule} from "../checkout/review-submit/valio-review-submit.module";
import {
  ValioOrderConfirmationItemsComponent
} from './components/order-confirmation-items/valio-order-confirmation-items.component';
// tslint:disable-next-line
import {
  ValioOrderConfirmationThankYouMessageComponent
} from './components/order-confirmation-thank-you-message/valio-order-confirmation-thank-you-message.component';
import {
  ValioOrderConfirmationTotalsComponent
} from './components/order-confirmation-totals/valio-order-confirmation-totals.component';
import {ValioOrderHeaderComponent} from './components/order-header/valio-order-header.component';
import {ValioOrderConfirmationGuard} from "./components/valio-order-confirmation.guard";
import {CheckoutAuthGuard} from "@spartacus/checkout/base/components";
import {OrderConfirmationModule, OrderDetailsModule} from '@spartacus/order/components';
import {CartSharedModule} from "@spartacus/cart/base/components";
import {
  OrderConfirmationOverviewComponent
} from "./components/order-confirmation-overview/order-confirmation-overview.component";


const orderConfirmationComponents = [
  ValioOrderConfirmationItemsComponent,
  ValioOrderConfirmationThankYouMessageComponent,
  ValioOrderConfirmationTotalsComponent,
  ValioOrderHeaderComponent,
  OrderConfirmationOverviewComponent
];

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        ReactiveFormsModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                OrderConfirmationThankMessageComponent: {
                    component: ValioOrderConfirmationThankYouMessageComponent,
                    guards: [ValioOrderConfirmationGuard],
                },
                OrderConfirmationItemsComponent: {
                    component: ValioOrderConfirmationItemsComponent,
                    guards: [CheckoutAuthGuard, ValioOrderConfirmationGuard],
                },
                OrderConfirmationTotalsComponent: {
                    component: ValioOrderConfirmationTotalsComponent,
                    guards: [ValioOrderConfirmationGuard],
                },
                OrderConfirmationOverviewComponent: {
                    component: OrderConfirmationOverviewComponent,
                    guards: [ValioOrderConfirmationGuard],
                },
            },
        }),
        CartSharedModule,
        PwaModule,
        ValioCartSharedModule,
        ValioReviewSubmitModule,
        ValioCartDetailsModule,
        ValioCartHeaderModule,
        RouterModule,
        UrlModule,
        PageSlotModule,
        ValioPipeModule,
        OrderConfirmationModule,
        OrderDetailsModule
    ],
  declarations: [...orderConfirmationComponents],
  exports: [...orderConfirmationComponents],
  entryComponents: [...orderConfirmationComponents],
})
export class ValioOrderConfirmationModule extends OrderConfirmationModule {
}
