import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {createFeatureSelector, createSelector, select, Store} from "@ngrx/store";
import {QUALTRICS_FEATURE, ValioEnvironmentState} from "./valio-environment-state";
import {LoadQualtrics} from "./valio-environment.action";


@Injectable({
  providedIn: 'root',
})
export class ValioEnvironmentService {

  constructor(protected store: Store<ValioEnvironmentState>) {
  }

  qualtricsId(): Observable<string> {
    return this.store
      .pipe(
        select(
          createSelector(
            createFeatureSelector<ValioEnvironmentState>(QUALTRICS_FEATURE), state => {
              return state != null && state.qualtrics != null && state.qualtrics.code != null ? state.qualtrics.code : null
            }
          )
        )
      ).pipe(tap(q => {
        if (!q || Object.keys(q).length === 0) {
          this.loadQualtrics();
        }
      }));
  }

  private loadQualtrics() {
    this.store.dispatch(new LoadQualtrics());
  }
}

