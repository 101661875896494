import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValioProduct} from "../../../../models";

@Component({
  selector: 'valio-product-delivery-period',
  templateUrl: './valio-product-delivery-period.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioProductDeliveryPeriod {
  @Input() product: ValioProduct;
  @Input() showLongText: boolean;
}
