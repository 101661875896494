import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ValioCalendarDataService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();

  activateMiniCart(val: boolean) {
    this.messageSource.next(val)
  }
}
