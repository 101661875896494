import {Injectable, OnDestroy} from "@angular/core";
import {StateWithSiteContext} from "@spartacus/core";
import {Observable, Subscription} from "rxjs";
import {getActiveRestrictedAssortment} from "./valio-site.constants";
import {select, Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {ValioSetRestrictedAssortment} from "./valio-restricted-assortment.action";
import {ValioUserService} from "../user/valio-user.service";

@Injectable({providedIn: 'root'})
export class ValioRestrictedAssortmentService implements OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(
    protected store: Store<StateWithSiteContext>,
    protected userService: ValioUserService

  ) {
  }

  /**
   * Represents the current baseSite uid.
   */
  getActive(): Observable<boolean> {
    return this.store.pipe(select(getActiveRestrictedAssortment));
  }

  getActiveAsUrlParameterValue(): Observable<string> {
    return this.getActive().pipe(map(value => value ? 'limited':'full'));
  }

  reset(): void {
    this.setActive(false)
  }

  setActive(value: boolean): Subscription {
    const sub = this.userService.setRestrictedAssortment(value)
      .pipe(map(v1=> this.store.dispatch(new ValioSetRestrictedAssortment(v1))))
      .pipe(map(v2=>this.getActive())).subscribe();
    this.subscriptions.add(sub);
    return sub;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
