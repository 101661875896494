<div class="container">
  <div class="page-header">
    <div class="page-header-title">
      <h1>{{'orderhistory.title'|cxTranslate}}</h1>
    </div>
  </div>

  <div *ngIf="user$ | async as user" class="print-customer-info d-none d-print-block">
    <ng-container *ngIf="user.activeB2bUnit; then user.activeB2bUnit?.nonContractUnit ? activeNonContractB2BUnitHeading : activeB2BUnitHeading; else noB2BUnitHeading"></ng-container>

    <ng-template #activeNonContractB2BUnitHeading>
      {{user.firstName}} {{user.lastName}}
    </ng-template>
    <ng-template #activeB2BUnitHeading>
      <div>{{'valioRegister.customerNumber.label' | cxTranslate}} {{user.activeB2bUnit.uid}}</div>
      <div>{{user.activeB2bUnit.name}}</div>
      <div>{{user.activeB2bUnit.shippingAddress.formattedAddress}}</div>
    </ng-template>
    <ng-template #noB2BUnitHeading>
      {{'valioRegister.guest'|cxTranslate}}
    </ng-template>
  </div>

  <ng-container [formGroup]="form" *ngIf="fullOrderHistory$| async as fullOrderHistory">
    <div class="search-slot">
      <div class="row align-items-center">
        <div class="col-12 col-sm-6" [ngClass]="!isDayView ? 'col-lg-4 col-xl-3':''">
          <valio-deliverynote-datepicker-form (searchButtonEventEmitter)="searchDate($event,true)"
                                              (resetViewEventEmitter)="resetView()"
                                              [resetViewVisible]="!isDayView"
                                              [dateList]="fullOrderHistory"
                                              [labelPlaceholder]="'deliveryNotes.datePicker.placeHolderFrom'"
                                              [dateId]="'dateFrom'"
                                              [selectedDate]="createNgbDate(fromDate)">
          </valio-deliverynote-datepicker-form>
        </div>

        <div class="col-12 col-sm-6" [ngClass]="!isDayView ? 'col-lg-4 col-xl-3':''">
          <valio-deliverynote-datepicker-form (searchButtonEventEmitter)="searchDate($event,false)"
                                              (resetViewEventEmitter)="resetView()"
                                              [resetViewVisible]="!isDayView"
                                              [dateList]="fullOrderHistory"
                                              [labelPlaceholder]="'deliveryNotes.datePicker.placeHolderTo'"
                                              [dateId]="'dateTo'"
                                              [selectedDate]="createNgbDate(toDate)">
          </valio-deliverynote-datepicker-form>
        </div>
      </div>
    </div>

    <ng-container *ngIf="orderHistory$|async as orderHistory">
      <ng-container *ngIf="orderHistory.pastEntries.length==1 && orderHistory.pastEntries[0].totalItems == 0; else pastEntries">
        <div class="search-slot">
          <p>{{'orderhistory.emptyWeek'|cxTranslate}}</p>
        </div>
      </ng-container>

      <ng-template #pastEntries>
        <ng-container *ngFor="let dateEntry of orderHistory.pastEntries">
          <valio-cx-cart-details-title
            [dateEntry]="dateEntry"
            [cartLoaded]="false"
            [showNextButton]="false"
            [isReadOnly]="true"
            [showDateToggle]="true"
            [collapsed]="false"
            [historyView]="true"
            [allowDelete]="false"
          ></valio-cx-cart-details-title>
        </ng-container>
      </ng-template>
    </ng-container>

  </ng-container>
</div>

