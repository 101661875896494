import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductAdapter, ProductConnector} from "@spartacus/core";
import {ValioProduct} from "../../models";
import {ValioOccProductAdapter} from "./valio-occ-product.adapter";

@Injectable({
  providedIn: 'root',
})
export class ValioProductConnector extends ProductConnector {
  constructor(protected adapter: ProductAdapter) {
    super(adapter);
  }

  getManyProducts(productCode: string[]): Observable<ValioProduct[]> {
    return (this.adapter as ValioOccProductAdapter).getManyProducts(productCode);
  }
}
