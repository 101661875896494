import {Injectable} from "@angular/core";
import {UpdatePasswordComponentService} from "@spartacus/user/profile/components";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomFormValidators} from "@spartacus/storefront";
import {ValioCustomFormValidators} from "../../../../shared/validators/valio-custom-form-validators";

@Injectable({
  providedIn: 'root',
})
export class ValioUpdatePasswordComponentService extends UpdatePasswordComponentService {

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', [
        Validators.required,
        ValioCustomFormValidators.passwordValidator,
      ]),
      newPasswordConfirm: new UntypedFormControl('', Validators.required),
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'newPassword',
        'newPasswordConfirm'
      ),
    }
  );
}
