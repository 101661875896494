import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsVideoComponent} from '../../../../models';

@Component({
  selector: 'valio-video-component',
  templateUrl: './valio-video.component.html',
})
export class ValioVideoComponent {
  data$ = this.componentData.data$;

  constructor(
    protected componentData: CmsComponentData<ValioCmsVideoComponent>
  ) {
  }
}
