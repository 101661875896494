import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {getTopSellersContent, ValioLoadTopSellers, ValioStateWithTopSellers} from "./valio-top-sellers.action";
import {map, tap} from "rxjs/operators";
import {ProductSearchPage} from "@spartacus/core";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ValioTopSellersService {
  constructor(
    protected store: Store<ValioStateWithTopSellers>
  ) {
  }

  getTopSellers(): Observable<ProductSearchPage> {
    return this.store.pipe(
      select(getTopSellersContent),
      tap(details => {

        if (Object.keys(details).length == 1) {
          this.loadTopSellers();
        }
      })
    );
  }

  private loadTopSellers() {
    if (environment.marketingCloud.topSellers.enabled == true) {
      this.store.dispatch(
        new ValioLoadTopSellers()
      );
    }
  }

  isLoaded()
    :
    Observable<boolean> {
    return this.store.select(getTopSellersContent)
      .pipe(map(() => {
        return true;
      }));
  }
}
