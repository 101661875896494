import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ValioHamburgerMenuService} from './valio-hamburger-menu.service';
import {HamburgerMenuComponent} from '@spartacus/storefront';
import {RoutingService} from '@spartacus/core';

@Component({
  selector: 'valio-cx-hamburger-menu',
  templateUrl: './valio-hamburger-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioHamburgerMenuComponent extends HamburgerMenuComponent {
  constructor(protected valioHamburgerMenuService: ValioHamburgerMenuService, protected routerService: RoutingService) {
    super(valioHamburgerMenuService);
  }

  toggle(): void {
    this.valioHamburgerMenuService.toggle();

    if (this.valioHamburgerMenuService.lastSelectedCategory != null && this.valioHamburgerMenuService.isExpanded) {
      const id = this.valioHamburgerMenuService.lastSelectedCategory;
      this.valioHamburgerMenuService.lastSelectedCategory = null;
      this.routerService.go('/search', {queryParams: {'query': ':relevance:category:' + id}});
    }
  }
}
