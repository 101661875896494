<ng-container *ngIf="headerNavigation">
  <div
    *ngIf="flyout && node?.children.length > 1"
    class="back is-open"
    (click)="back()"
  >
    <h5>
      <span class="valio-icon icon-inline icon-chevron icon-chevron-left"></span>
      {{ 'common.return' | cxTranslate }}
    </h5>
  </div>
</ng-container>

<ng-container *ngFor="let child of node?.children">
  <ng-container *ngTemplateOutlet="nav; context: { node: child }">
  </ng-container>
</ng-container>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node">
  <nav tabindex="0"
    id="navigation_{{node.uid}}"
    (click)="isMobile && (node.children || node.children?.length > 0) ? toggleSubMenu($event) : mainNavigation ? toggleMainOpen($event) : toggleOpen($event)"
  >
    <ng-container *ngIf="headerNavigation">
      <ng-container *ngIf="node.uid !== 'PersonalDetailsNavNode'; else personalDetailsNode">
        <cx-generic-link
          *ngIf="node.url && (!node.children || node.children?.length === 0); else heading"
          [url]="(node.uid==='SignOutNavNode') ? '/logoutPreview' : node.url"
        >
          {{ node.title }}
          <div *ngIf="flyout && node.children?.length > 0"
            class="valio-icon icon-inline icon-chevron icon-chevron-down"></div>
        </cx-generic-link>
      </ng-container>
    </ng-container>

    <!-- @TODO Personal details sub menu -->
    <ng-template #personalDetailsNode>
      <cx-generic-link
        class="title-open"
        *ngIf="node.url"
        [url]="isMobile ? null : node.url"
      >
        <div class="icon-submenu">
          <div class="valio-icon icon-sm icon-inline icon-chevron icon-chevron-left"></div>
        </div>
        {{ node.title }}
        <div class="d-lg-none d-inline-block">
          <div *ngIf="flyout && node.children?.length > 0"
            class="valio-icon icon-inline icon-chevron icon-chevron-right"></div>
        </div>
      </cx-generic-link>
      <div
        class="d-none childs childs-submenu"
        [attr.depth]="getTotalDepth(node)"
        [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
      >
        <ng-container *ngFor="let child of node.children">
          <ng-container *ngTemplateOutlet="nav; context: { node: child }">
          </ng-container>
        </ng-container>
      </div>
    </ng-template>

    <ng-container *ngIf="mainNavigation">
      <h5 [attr.aria-label]="node.title">
        <span class="d-none d-md-block">
          <cx-generic-link
            *ngIf="node.url && (!node.children || node.children?.length === 0)"
            [url]="node.url"
          >
            {{ node.title }}
          </cx-generic-link>
          <span class="valio-icon icon-inline icon-chevron icon-chevron-down"></span>
        </span>
      </h5>
    </ng-container>

    <ng-container *ngIf="footerNavigation || mainNavigationLink">
      <cx-generic-link
        *ngIf="node.url && (!node.children || node.children?.length === 0)"
        [url]="node.url"
      >
        {{ node.title }}
      </cx-generic-link>
      <ng-container *ngFor="let child of node.children">
        <ng-container *ngTemplateOutlet="nav; context: { node: child }">
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #heading>
      <ng-container *ngIf="node.uid==='MyAccountNavNode'">
        <ng-container *ngIf="user$ | async as user">
          <h5 [attr.aria-label]="node.title">
            <span class="d-block d-md-none">
              <span class="valio-icon icon-inline icon-account"></span>
              <span id="mobileSelectorArrow" *ngIf="flyout && node.children?.length > 0"
                    class="valio-icon icon-inline icon-chevron icon-chevron-down"></span>
            </span>
            <span class="d-none d-md-block">
              <span class="my-account-name">
                <ng-container
                  *ngIf="user.activeB2bUnit; then user.activeB2bUnit?.nonContractUnit ? activeNonContractB2BUnitHeading : activeB2BUnitHeading; else noB2BUnitHeading"></ng-container>
              </span>
              <span id="unitSelectorArrow" *ngIf="flyout && node.children?.length > 0"
                    class="valio-icon icon-inline icon-chevron icon-chevron-down"></span>
            </span>
          </h5>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #activeNonContractB2BUnitHeading>
      <ng-container *ngIf="user$ | async as user">
        {{user.firstName}} {{user.lastName}}
      </ng-container>
    </ng-template>
    <ng-template #activeB2BUnitHeading>
      <ng-container *ngIf="user$ | async as user">
        <span class="code d-block">{{user.activeB2bUnit.uid}}</span>
        <span class="name d-block">{{user.activeB2bUnit.name}}</span>
      </ng-container>
    </ng-template>
    <ng-template #noB2BUnitHeading>
      {{'valioRegister.guest'|cxTranslate}}
    </ng-template>

    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <ng-container *ngIf="headerNavigation">
      <ng-container *ngIf="node.uid !== 'PersonalDetailsNavNode'">
        <ng-container *ngIf="user$ | async as user">
          <div class="wrapper" *ngIf="node.children?.length > 0">
            <div *ngIf="user.b2bUnits"
              class="childs valio-units"
              [hidden]="user.activeB2bUnit?.nonContractUnit"
              [attr.depth]="getTotalDepth(node)"
              [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
            >
              <div *ngFor="let b2bUnit of user.b2bUnits"
                class="valio-unit" (click)="selectUnit(b2bUnit)"
                [ngClass]="b2bUnit.uid === user.activeB2bUnit.uid ? 'selected' : ''"
              >
                <div class="valio-unit-name" id="valio-unit-name_{{b2bUnit.uid}}">
                  {{b2bUnit.uid}} - {{b2bUnit.name}}
                </div>
              </div>
            </div>
            <div
              class="childs"
              [attr.depth]="getTotalDepth(node)"
              [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
            >
              <ng-container *ngFor="let child of node.children">
                <ng-container *ngTemplateOutlet="nav; context: { node: child }">
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="mainNavigation">
      <div class="wrapper main-navigation-wrapper">
        <ng-container *ngIf="mainNavigationProducts">
          <div class="main-categories">
            <ul *ngIf="mainCategories$|async as mainCategories">
              <li [attr.data-categoryName]="'productList.fiProducts' | cxTranslate" [routerLink]="'/search'">{{'categories.showmore'|cxTranslate}}</li>
              <li [attr.data-categoryName]="mainCat.fiName"
                [ngClass]="{'active':lastSelectedCategory==mainCat.id}"
                *ngFor="let mainCat of mainCategories.subcategories"
                (click)="toggleChildCategory(mainCat.id, mainCat.name,mainCat.fiName, $event)"
              >
                <span [innerHTML]="mainCat.name"></span>
                <span class="valio-icon icon-inline icon-chevron icon-chevron-right"></span>
              </li>
            </ul>
          </div>
          <div class="child-categories" *ngIf="childCategories$">
            <ul>
              <ng-container *ngIf="childCategories$|async as childCategories">
                <li [attr.data-categoryName]="childCat.fiName" *ngFor="let childCat of childCategories.subcategories">
                  <a (click)="openCategory(childCat.id)" [innerHTML]="childCat.name"></a>
                </li>
              </ng-container>
              <li [attr.data-categoryName]="lastSelectedCategoryFiName">
                <a class="text-bold" (click)="openCategory(lastSelectedCategory)">
                  {{'categories.showmore'|cxTranslate}}
                </a>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="mainNavigationVendors">
          <div class="vendors main-categories">
            <ul *ngIf="vendors$ |async as vendors">
              <li *ngIf="isAnonymous$ | async" [routerLink]="{ cxRoute: 'partnersPage' } | cxUrl">
                {{'categories.showmore'|cxTranslate}}
              </li>
              <li *ngIf="isSignedIn$ | async" [routerLink]="'/'">
                {{'categories.showmore'|cxTranslate}}
              </li>
              <li *ngFor="let vendor of vendors" (click)="switchPartner(vendor)" [innerHTML]="vendor.name"></li>
            </ul>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </nav>
</ng-template>
