<cx-page-slot position="TopHeaderSlot"></cx-page-slot>
<header
  [ngClass]="{'is-anonymous' : isAnonymous$ | async,'is-signed-in' : isSignedIn$ | async}"
  [class.is-expanded]="isExpanded$ | async"
>
  <cx-page-layout section="header" *hideOnLocation="'/login'"></cx-page-layout>
  <cx-page-layout section="navigation" *hideOnLocation="'/login'"></cx-page-layout>
</header>
<cx-page-slot position="SimpleHeader"></cx-page-slot>

<cx-page-slot position="BottomHeaderSlot"></cx-page-slot>

<cx-global-message></cx-global-message>

<router-outlet></router-outlet>

<footer>
  <cx-page-layout section="footer" *hideOnLocation="'/login'"></cx-page-layout>
</footer>
