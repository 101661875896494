import {Injectable} from "@angular/core";
import {Vendor} from "../../models/misc.model";
import {Observable, of} from "rxjs";
import {switchMap} from "rxjs/operators";
import {ValioPartnerSiteService} from "../site/valio-partner-site.service";
import {ALL_PARTNERS} from "../site/valio-site.constants";
import {ValioVendorService} from "./valio-vendor.service";


@Injectable({
  providedIn: 'root',
})
export class ValioPartnerService {
  constructor(protected vendorService: ValioVendorService,
              protected partnerSiteService: ValioPartnerSiteService) {
  }

  public getSelectedVendor(): Observable<Vendor> {
    return this.partnerSiteService.getActive().pipe(
      switchMap(selected => {
        if (this.isPartnerSelected(selected)) {
          return this.vendorService.getVendor(selected);
        }
        return of({allPartners: true, orderingAllowed: true, activeContract: true, code: ALL_PARTNERS} as Vendor);
      })
    );
  }

  isPartnerSelected(selectedPartner: string) {
    return ALL_PARTNERS != selectedPartner;
  }
}
