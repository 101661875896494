import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {ValioFrontpageComponent} from "./valio-frontpage.component";
import {ValioProductListModule} from "../product/valio-product-list.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioFrontpageComponent: {
                    component: ValioFrontpageComponent,
                },
            },
        }),
        ValioProductListModule,
    ],
    declarations: [ValioFrontpageComponent],
    exports: [ValioFrontpageComponent]
})
export class ValioFrontpageModule {
}
