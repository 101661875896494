import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ValioCheckoutModule } from "../../valio-checkout.module";
import { ValioVoucherModule } from "../../voucher/valio-voucher.module";
import { ValioReviewSubmitModule } from "../valio-review-submit.module";
import { ValioCreditPageGuard } from "./valio-credit-page.guard";
import { ValioReviewSubmitCreditComponent } from "./valio-review-submit-credit.component";

@NgModule({
    imports: [
        CommonModule,
        I18nModule,
        UrlModule,
        RouterModule,
        FeaturesConfigModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                CheckoutReviewOrderCredit: {
                    component: ValioReviewSubmitCreditComponent,
                    guards: [
                        ValioCreditPageGuard
                    ],
                },
            },
        }),
        ValioCheckoutModule,
        ValioReviewSubmitModule,
        ValioVoucherModule
    ],
    declarations: [ValioReviewSubmitCreditComponent],
    exports: [ValioReviewSubmitCreditComponent]
})
export class ValioReviewSubmitCreditModule {
}
