import {StateUtils} from "@spartacus/core";
import {ValioBaseOrder, ValioCart} from "../cart/valio-cart.objects";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";

export const ORDER_TEMPLATE_FEATURE = 'OrderTemplate';
export const ORDER_TEMPLATE_DATA = '[OrderTemplate] Order template Data';

const getOrderTemplateListSelector = (state: OrderTemplateState) => {
  return state.loaded ? state.templates : null;
};

const getOrderTemplateActiveSelector = (state: OrderTemplateState) => {
  return state.loaded ? state.active : '';
};

const getBaseOrderSelector = (state: OrderTemplateState) => {
  return state.loaded ? state.baseOrder : null;
};

export const getOrderTemplatesState: MemoizedSelector<StateWithOrderTemplate, OrderTemplatesState> = createFeatureSelector<OrderTemplatesState>(ORDER_TEMPLATE_FEATURE);

export const getActiveOrderTemplateState: MemoizedSelector<StateWithOrderTemplate,
  StateUtils.LoaderState<OrderTemplateState>> = createSelector(
  getOrderTemplatesState,
  (templatesState: OrderTemplatesState) => templatesState.active
);

export const getOrderTemplateState: MemoizedSelector<StateWithOrderTemplate,
  OrderTemplateState> = createSelector(
  getActiveOrderTemplateState,
  state => StateUtils.loaderValueSelector(state)
);

export const getOrderTemplateListContent: MemoizedSelector<StateWithOrderTemplate,
  ValioCart[]> = createSelector(
  getOrderTemplateState,
  getOrderTemplateListSelector
);

export const getActiveOrderTemplateContent: MemoizedSelector<StateWithOrderTemplate,
  string> = createSelector(
  getOrderTemplateState,
  getOrderTemplateActiveSelector
);

export const getBaseOrderContent: MemoizedSelector<StateWithOrderTemplate,
  ValioBaseOrder> = createSelector(
  getOrderTemplateState,
  getBaseOrderSelector
);


export interface StateWithOrderTemplate {
  [ORDER_TEMPLATE_FEATURE]: OrderTemplatesState;
}

export interface OrderTemplatesState {
  active: StateUtils.LoaderState<OrderTemplateState>;
}

export interface OrderTemplateState {
  templates: ValioCart[];
  active: string;
  baseOrder: ValioBaseOrder;
  loaded: boolean;
}

export const ORDER_TEMPLATES_LIST = '[OrderTemplate] list';

export class ValioOrderTemplateList extends StateUtils.LoaderLoadAction {
  readonly type = ORDER_TEMPLATES_LIST;

  constructor(public userId: string) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const ORDER_TEMPLATES_CREATE = '[OrderTemplate] create';

export class ValioOrderTemplateCreate extends StateUtils.LoaderLoadAction {
  readonly type = ORDER_TEMPLATES_CREATE;

  constructor(public userId: string, public name: string, public copyId: string, public copyDate: string) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const ORDER_TEMPLATES_SAVE = '[OrderTemplate] save';

export class ValioOrderTemplateSave extends StateUtils.LoaderLoadAction {
  readonly type = ORDER_TEMPLATES_SAVE;

  constructor(public userId: string, public cartId: string, public orderTemplate: ValioCart, public updateCart?: boolean) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const ORDER_TEMPLATES_REMOVE = '[OrderTemplate] remove';

export class ValioOrderTemplateRemove extends StateUtils.LoaderLoadAction {
  readonly type = ORDER_TEMPLATES_REMOVE;

  constructor(public userId: string, public templateId: string) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const ORDER_TEMPLATES_SUCCESS = '[OrderTemplate] success';

export class ValioOrderTemplateSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ORDER_TEMPLATES_SUCCESS;

  constructor(public templates?: ValioCart[], public active?: string) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const ORDER_TEMPLATES_FAIL = '[OrderTemplate] failed';

export class ValioOrderTemplateFail extends StateUtils.LoaderFailAction {
  readonly type = ORDER_TEMPLATES_FAIL;

  constructor(public templates?: boolean, public active?: boolean) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const BASE_ORDER_LOAD = '[BaseOrder] Load';

export class ValioBaseOrderLoad extends StateUtils.LoaderLoadAction {
  readonly type = BASE_ORDER_LOAD;

  constructor(public userId: string) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const BASE_ORDER_LOAD_SUCCESS = '[BaseOrder] Loaded Successfully';

export class ValioBaseOrderLoadSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = BASE_ORDER_LOAD_SUCCESS;

  constructor(public baseOrder: ValioBaseOrder) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const BASE_ORDER_LOAD_FAIL = '[BaseOrder] failed';

export class ValioBaseOrderLoadFail extends StateUtils.LoaderFailAction {
  readonly type = BASE_ORDER_LOAD_FAIL;

  constructor() {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const BASE_ORDER_SIMULATE = '[BaseOrder] Simulate';

export class ValioBaseOrderSimulate extends StateUtils.LoaderLoadAction {
  readonly type = BASE_ORDER_SIMULATE;

  constructor(public userId: string, public baseOrder: ValioBaseOrder) {
    super(ORDER_TEMPLATE_DATA);
  }
}

export const BASE_ORDER_SAVE = '[BaseOrder] Save';

export class ValioBaseOrderSave extends StateUtils.LoaderLoadAction {
  readonly type = BASE_ORDER_SAVE;

  constructor(public userId: string, public baseOrder: ValioBaseOrder) {
    super(ORDER_TEMPLATE_DATA);
  }
}
