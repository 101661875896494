<cx-page-slot position="PartnerBannerProductSlot" [ngClass]="{'partner-frontpage' : isPartnerFrontpage$ | async}"></cx-page-slot>
<ng-container *ngIf="model$ | async as model">
  <ng-container *ngIf="viewMode$ | async as viewMode">
    <ng-container *ngIf="selectedPartner$|async as selectedPartner">
      <div class="cx-product-container no-carousel" *ngIf="selectedPartner.orderingAllowed" [ngClass]="{'full-width' : fullWidth$ | async,'with-calendar' : hasUICart$ | async,'d-none' : isPartnerFrontpage$ | async}">
        <!-- Product list when using pagination -->
        <ng-container *ngIf="!isInfiniteScroll; else infiniteScroll">
          <ng-container *ngIf="viewMode === ViewModes.Grid">
            <div class="row">
              <valio-cx-product-grid-item
                *ngFor="let product of model?.products"
                [product]="product"
                [listName]="'googleTagManager.productList.search.name' | cxTranslate"
                class="col-"
              ></valio-cx-product-grid-item>
            </div>
          </ng-container>
          <ng-container *ngIf="viewMode === ViewModes.List">
            <valio-cx-product-list-item
              *ngFor="let product of model?.products"
              [product]="product"
              [listName]="'googleTagManager.productList.search.name' | cxTranslate"
              class="cx-product-search-list"
            ></valio-cx-product-list-item>
          </ng-container>
        </ng-container>
        <!-- Product list when using infinite scroll -->
        <ng-template #infiniteScroll>
          <cx-page-slot position="NoResultsSlot" *ngIf="model.products.length < 1">
          </cx-page-slot>
          <valio-cx-product-scroll
            [scrollConfig]="scrollConfig"
            [model]="model"
            [inputViewMode]="viewMode"
          ></valio-cx-product-scroll>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
