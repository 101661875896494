import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckoutOrchestratorModule } from "@spartacus/checkout/base/components";
import {CheckoutConfig, CheckoutStepType, DeliveryModePreferences} from "@spartacus/checkout/base/root";
import { CmsConfig, Config, ConfigModule } from '@spartacus/core';
import { ValioCheckoutOrchestratorComponent } from "./valio-checkout-orchestrator.component";

const valioCheckoutConfig: CheckoutConfig = {
  checkout: {
    steps: [
      {
        id: 'shippingAddress',
        name: 'checkoutProgress.shippingAddress',
        routeName: 'checkoutShippingAddress',
        type: [CheckoutStepType.DELIVERY_ADDRESS],
      },
      {
        id: 'reviewOrderInvoice',
        name: 'checkoutProgress.reviewOrder.invoice',
        routeName: 'checkoutReviewOrderInvoice',
        type: [CheckoutStepType.PAYMENT_DETAILS],
      },
      {
        id: 'reviewOrderCredit',
        name: 'checkoutProgress.reviewOrder.credit',
        routeName: 'checkoutReviewOrderCredit',
        type: [CheckoutStepType.REVIEW_ORDER],
      },
    ],
    express: false,
    defaultDeliveryMode: [DeliveryModePreferences.FREE],
    guest: false,
  },
};

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig(valioCheckoutConfig),
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrchestrator: {
          component: ValioCheckoutOrchestratorComponent,
          guards: [],
        },
      },
    }),
  ],
  providers: [{ provide: CheckoutConfig, useExisting: Config }],
  declarations: [ValioCheckoutOrchestratorComponent],
  exports: [ValioCheckoutOrchestratorComponent]
})
export class ValioCheckoutOrchestratorModule extends CheckoutOrchestratorModule {
}

