import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ParagraphComponent} from './paragraph.component';
import {CmsConfig, provideDefaultConfig} from '@spartacus/core';
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";

@NgModule({
    imports: [CommonModule, ValioPipeModule],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CMSParagraphComponent: {
                    component: ParagraphComponent,
                },
                CMSTabParagraphComponent: {
                    component: ParagraphComponent,
                },
            },
        }),
    ],
    declarations: [ParagraphComponent],
    exports: [ParagraphComponent]
})
export class CmsParagraphModule {
}
