<ng-container>
  <ng-container *ngIf="signedIn$|async">
    <a href="#" [routerLink]="'/news'">{{'navigation.news.title'|cxTranslate}}</a>
    <div class="header-news-list">
      <div class="header-news-list-container">
        <cx-page-slot position="NewsSection"></cx-page-slot>
        <div class="header-news-read-more">
          <a href="#" [routerLink]="'/news'">{{'navigation.news.readMore'|cxTranslate}}</a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
