<div class="valio-modal modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header cx-dialog-header">
      <h3 class="modal-title">
        {{ 'myaccount.orderTemplate.removetitle' | cxTranslate }}
      </h3>
    </div>

    <p class="remove-entry-description">
      {{ 'myaccount.orderTemplate.removetitle2' | cxTranslate }}
    </p>

    <div *ngIf="orderTemplate$|async as orderTemplate" class="remove-entry-content">
      <div class="row">
        <div class="col-4">
          <div class="cx-image-container">
            <cx-media *ngIf="orderTemplate.entries.length > 0"
                      [container]="orderTemplate.entries[0].product.images?.PRIMARY"
                      format="cartIcon"></cx-media>
          </div>
        </div>
        <div class="col-8 text-left">
          <div class="bold margin-top-base margin-bottom-sm">{{orderTemplate.name}}</div>
          <div class="small-text">{{'cart.header.products'|cxTranslate:{products: orderTemplate.entries.length} }}</div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-lg btn-secondary margin-right-sm"
      (click)="dismissModal()"
    >
      {{ 'common.cancel' | cxTranslate }}
    </button>

    <button
      type="button"
      class="btn btn-lg btn-primary margin-left-sm"
      aria-label="remove"
      (click)="remove()"
    >
      {{ 'myaccount.orderTemplate.remove' | cxTranslate }}
    </button>
  </div>
</div>
