import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import {ValioCloseAccountModalComponent} from "./valio-close-account-modal.component";

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        CLOSE_ACCOUNT_MODAL = 'CLOSE_ACCOUNT_MODAL',
    }
}

export const valioCloseAccountModalLayoutConfig: LayoutConfig = {
    launch: {
        CLOSE_ACCOUNT_MODAL: {
            inlineRoot: true,
            component: ValioCloseAccountModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        }
    },
};
