import {AbstractControl, ValidationErrors} from "@angular/forms";

export default class VatIdValidator {
  private static VATID_PATTERN: string = "^\\d{7}-\\d$";

  static valid(control: AbstractControl): ValidationErrors | null {
    const vatId = control.value as string;

    return vatId != null && vatId.match(VatIdValidator.VATID_PATTERN) ? null : {InvalidVatId: true};
  }
}
