import { Injectable } from '@angular/core';
import { CartConnector } from "@spartacus/cart/base/core";
import { Observable } from "rxjs";
import { ValioUser } from "../../models/misc.model";
import { ValioCart } from "./valio-cart.objects";
import { ValioOccCartAdapter } from "./valio-occ-cart.adapter";

@Injectable({
  providedIn: 'root',
})
export class ValioCartConnector extends CartConnector {
  constructor(protected adapter: ValioOccCartAdapter) {
    super(adapter);
  }

  public removeCartDate(userId: string, cartId: string, date: string): Observable<ValioCart> {
    return this.adapter.removeCartDate(userId, cartId, new Date(date));
  }

  public changeCartHeader(userId: string, cartId: string, field: string, value: any, body?: any): Observable<ValioCart> {
    return this.adapter.changeCartHeader(userId, cartId, field, value, body);
  }

  public simulate(userId: string, cartId: string): Observable<ValioCart|  undefined> {
    return this.adapter.load(userId, cartId, true);
  }

  addEmail(userId: string, cartId: string, email: string): Observable<ValioUser> {
    return this.adapter.addEmail(userId, cartId, email);
  }
}
