<div class="product-facet-navigation"
     [ngClass]="{'full-width' : fullWidth$ | async,'with-calendar' : hasUICart$ | async}">
  <ng-container *ngIf="selectedPartner$|async as selectedPartner">
    <div class="search-header">
      <cx-page-slot position="PartnerBannerTopSlot"></cx-page-slot>
      <div class="row">
        <div class="d-block col-lg-4 col-xl-6">
          <nav class="facet-nav">
            <div class="category-bread-crumb" *ngFor="let crumb of breadCrumbs$ | async; index as idx">
              <ng-container *ngIf="idx == 0 && !selectedPartner.allPartners">
                <a [routerLink]="'/'">{{crumb.facetValueName}}</a>
                <span>&nbsp;>&nbsp;</span>
                <a [routerLink]="'/search'"
                >{{selectedPartner.name}}
                </a>
              </ng-container>
              <ng-container *ngIf="idx != 0 || selectedPartner.allPartners">
                <a [routerLink]="'/search'"
                   [queryParams]="{query:':'+sortCode+':category:'+ crumb.facetValueCode + addFacetQuery(crumb.facetValueCode)}">
                  {{crumb.facetValueName}}
                </a>
              </ng-container>
              <span>&nbsp;>&nbsp;</span>
            </div>
          </nav>
        </div>
        <div class="col-lg-8 col-xl-6">
          <valio-cx-product-partner
            [selectedPartner]="selectedPartner"
          ></valio-cx-product-partner>
        </div>
      </div>

      <ng-container *ngIf="breadCrumbs$ | async as crumbs">
        <ng-container *ngFor="let crumb of crumbs; last as isLast;index as idx">
          <h1 class="facet-header" *ngIf="isLast && lastCategoryCode != null">
            {{ crumb.facetValueName }}
          </h1>
          <ng-container *ngIf="showCategoryDescription">
            <div class="facet-description" *ngIf="crumb.facetDescription" [innerHTML]="crumb.facetDescription"></div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="lastCategoryCode == null">
          <h1 class="facet-frontpage-header">
            <span *ngIf="!isPartnerSelected(selectedPartner)">{{ 'productList.products' | cxTranslate }}</span>
            <span *ngIf="isPartnerSelected(selectedPartner)">{{ selectedPartner.name}}</span>
          </h1>
          <ng-container *ngIf="!selectedPartner.orderingAllowed && isSignedIn$ | async">
            <div class="row">
              <div class="col-lg-8 facet-frontpage-contract">
                {{'productList.partners.partnerNotAllowed'|cxTranslate}}
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="selectedPartner.orderingAllowed && !selectedPartner.activeContract && isContractCustomer()|async">
            <div class="row">
              <div class="col-lg-8 facet-frontpage-contract">
                {{'productList.partners.partnerIsAllowed'|cxTranslate}}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <div class="cx-search-facet" *ngIf="selectedPartner.orderingAllowed">
        <div class="cx-facet-group">
          <ul class="cx-facet-list categoryList toggleCategoryList"
              [ngClass]="{'show-toggle' : categories?.subcategories?.length>2 }" id="toggleCategoryList"
              *ngIf="categories$|async as categories">
            <ng-container *ngIf="fiCategories$|async as fiCategories">
              <ng-container *ngIf="categories?.subcategories">
                <ng-container *ngFor="let category of categories.subcategories; index as idx">
                  <li class="btn btn-tag limitedCategoriesSelector limitedCategories"
                      [ngClass]="category.promoCategory ? 'btn-icon-tag' : ''"
                      [routerLink]="'/search'"
                      [queryParams]="{query:':'+sortCode+':category:'+category.id + addFacetQuery(category.id)}"
                      [attr.data-categoryName]="getFICategoryName(category.id, fiCategories)"
                  >
                    <span *ngIf="category.promoCategory" class="valio-icon icon-gift"></span>
                    <span [innerHTML]="category.name"></span>
                  </li>
                </ng-container>
                <li class="btn btn-tag limitedCategoriesToggle toggleMoreCategories" id="toggleMoreCategories"
                    (click)="toggleCategories()" *ngIf="categories?.subcategories?.length>2">
                  <span class="showMore">{{'categories.showmore'|cxTranslate}}</span>
                  <span class="showLess">{{'categories.showless'|cxTranslate}}</span>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedPartner.orderingAllowed">
      <div class="search-box">
        <valio-cx-searchbox
          [frontPage]="true"
          [setSelectedPartner]="selectedPartner"
          [filterQuery]="filterQuery$|async"
        ></valio-cx-searchbox>
      </div>

      <div class="valio-facets">
        <valio-product-facet-list [selectedPartner]="selectedPartner"></valio-product-facet-list>
        <div class="float-right margin-bottom-md">
          <valio-cx-product-contractual-switch
            (onSelect)="resetCategories()"
          ></valio-cx-product-contractual-switch>
          <div class="valio-sorts margin-left-base">
            <ng-container *ngIf="searchResult$|async as searchResult">
              <ng-select
                class="sort"
                *ngIf="searchResult.pagination?.sort"
                [searchable]="false"
                [clearable]="false"
                (change)="sort(searchResult.pagination.sort)"
                [tabIndex]="0"
                [(ngModel)]="sortCode">
                <ng-option *ngFor="let sort of searchResult.sorts" [value]="sort.code">
                  {{sort.name}}
                </ng-option>
              </ng-select>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
