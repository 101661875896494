import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ValioOccEnvironmentAdapter} from "./valio-occ-environment.adapter";
import {Qualtrics} from "./valio-environment.objects";

@Injectable({
  providedIn: 'root',
})
export class ValioEnvironmentConnector  {

  constructor(protected adapter: ValioOccEnvironmentAdapter) {
  }

  getQualtricsId(): Observable<Qualtrics> {
    return (this.adapter as ValioOccEnvironmentAdapter).getQualtricsId();
  }
}
