import {NgModule} from "@angular/core";
import {ValioImportFromFileComponent} from "./valio-import-from-file.component";
import {I18nModule, provideDefaultConfig} from "@spartacus/core";
import {ValioImportFromFileSummaryComponent} from "./valio-import-from-file-summary.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {valioImportFromFileLayoutConfig} from "./valio-import-from-file-layout.config";
import {valioImportFromFileSummaryLayoutConfig} from "./valio-import-from-file-summary-layout.config";

@NgModule({
    imports: [
        I18nModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule
    ],
    providers: [
      provideDefaultConfig(valioImportFromFileLayoutConfig),
      provideDefaultConfig(valioImportFromFileSummaryLayoutConfig)
    ],
    declarations: [ValioImportFromFileComponent, ValioImportFromFileSummaryComponent],
    exports: [ValioImportFromFileComponent, ValioImportFromFileSummaryComponent]
})
export class ValioImportFromFileModule {
}
