import {NgModule} from '@angular/core';
import {Actions, EffectsModule} from '@ngrx/effects';
import {GlobalMessageService, UserAddressConnector, UserAddressService,} from "@spartacus/core";
import {StoreModule} from "@ngrx/store";
import {ValioRouteCalendarEffects} from "./valio-routecalendar.effect";
import {ROUTE_CALENDAR_FEATURE} from "./valio-routecalendar.action";
import {metaReducers, reducerToken, valioRouteCalendarReducerProvider} from "./valio-routecalendar.reducer";


@NgModule({
  imports: [
    StoreModule.forFeature(ROUTE_CALENDAR_FEATURE, reducerToken, {metaReducers}),
    EffectsModule.forFeature([ValioRouteCalendarEffects]),
  ],
  providers: [
    valioRouteCalendarReducerProvider
  ]
})
export class ValioRouteCalendarModule /*extends UserStoreModule*/ {
  // TODO Check UserStoreModule
  constructor(actions$: Actions, userAddressConnector: UserAddressConnector, userAddressService: UserAddressService, messageService: GlobalMessageService) {
    //super(actions$, userAddressConnector,userAddressService,messageService);
  }
}


