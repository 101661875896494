<div [formGroup]="form">
  <div class="cx-item-list-row" #partnerToggle [ngClass]="{'collapsed' : collapsed}">
    <ng-container *ngIf="!collapsed">
      <div class="cx-item-list-items" *ngFor="let item of items">
        <valio-cx-cart-item
          [allowDelete]="allowDelete"
          *ngIf="!minicartMode"
          [hidePrices]="hidePrices"
          [parent]="getParent(item)"
          [item]="item"
          [isReadOnly]="readOnly"
          [cartIsLoading]="cartIsLoading"
          (update)="updateEntryData($event)"
          [compact]="compact"
          [listName]="'googleTagManager.productList.cart.name' | cxTranslate"
        >
        </valio-cx-cart-item>

        <valio-cx-minicart-item
          *ngIf="minicartMode"
          [parent]="getParent(item)"
          [item]="item"
          [isReadOnly]="readOnly"
          [cartIsLoading]="cartIsLoading"
          (update)="updateEntryData($event)"
          [compact]="compact"
        >
        </valio-cx-minicart-item>
      </div>
    </ng-container>
  </div>
</div>
