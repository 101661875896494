import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ActiveCartService, CartAdapter, SelectiveCartService } from "@spartacus/cart/base/core";
import { CART_NORMALIZER } from "@spartacus/cart/base/root";
import { CxDatePipe } from "@spartacus/core";
import { ValioCartEntryEffects } from "./valio-cart-entry.effect";
import { ValioOccCartNormalizer } from "./valio-cart-normalizer";
import { ValioCartEffects } from "./valio-cart.effect";
import { ValioCartService } from "./valio-cart.service";
import { CSV_IMPORT_FEATURE } from "./valio-csv-import.action";
import { ValioCsvImportEffects } from "./valio-csv-import.effect";
import { metaReducers, reducerToken, valioCsvImportReducerProvider } from "./valio-csv-import.reducer";
import { ValioOccCartEntryAdapter } from "./valio-occ-cart-entry.adapter";
import { ValioOccCartAdapter } from "./valio-occ-cart.adapter";
import { ValioSelectiveCartService } from "./valio-selective-cart.service";
import {ValioMultiCartEffects} from "./valio-multi-cart.effect";
import {ValioProductSearchEffects} from "./valio-product-search.effects";

@NgModule({
  imports: [
    EffectsModule.forFeature([ValioCartEntryEffects]),
    EffectsModule.forFeature([ValioCartEffects]),
    EffectsModule.forFeature([ValioProductSearchEffects]),
    StoreModule.forFeature(CSV_IMPORT_FEATURE, reducerToken, { metaReducers }),
    EffectsModule.forFeature([ValioCsvImportEffects])
  ],
  providers: [
    ValioOccCartEntryAdapter,
    ValioOccCartAdapter,
    valioCsvImportReducerProvider,
    ValioMultiCartEffects,
    CxDatePipe,
    {
      provide: CART_NORMALIZER,
      useClass: ValioOccCartNormalizer,
      multi: true
    },
    {
      provide: SelectiveCartService,
      useClass: ValioSelectiveCartService
    },
    {
      provide: CartAdapter,
      useClass: ValioOccCartAdapter
    },
    {
      provide: ActiveCartService,
      useClass: ValioCartService
    }
  ]
})
export class ValioCartOccModule {
}
