<div class="submit-row">
  <div class="row align-items-center">
    <div class="col-6 col-md-9 col-lg-8">
      <div class="row">
        <div class="col-12 col-md-9 col-lg-7">
          <h3 class="submit-title">{{title|cxTranslate}}</h3>
        </div>
        <div class="col-12 col-md-3 col-lg-5 products">{{'checkout.header.products'|cxTranslate:{products: items} }}</div>
      </div>
    </div>
    <div class="col-6 col-md-3 col-lg-4 totals">
      <div class="row">
        <div class="col-12">
          <div class="cx-vat">{{'cart.header.alv.zero'|cxTranslate }}</div>
          <div class="cx-price">{{priceWithoutTax.formattedValue}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="cx-vat">{{'cart.header.alv.incl'|cxTranslate }}</div>
          <div class="cx-price">{{priceWithTax.formattedValue}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
