<ng-container *ngIf="(model$ | async) as model">
  <ng-container *ngIf="(viewMode$ | async) as viewMode">
    <div class="cx-product-container no-carousel">
      <ng-container *ngIf="viewMode === ViewModes.Grid">
        <div class="row">
          <valio-cx-product-grid-item
            *ngFor="let product of model?.products"
            [product]="product"
            class="col-"
          ></valio-cx-product-grid-item>
        </div>
      </ng-container>
      <ng-container *ngIf="viewMode === ViewModes.List">
        <valio-cx-product-list-item
          *ngFor="let product of model?.products"
          [product]="product"
          class="cx-product-search-list"
        ></valio-cx-product-list-item>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
