import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ValioUser} from "../../../../models/misc.model";
import {ValioRestrictedAssortmentService} from "../../../../services/site/valio-restricted-assortment.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {SearchCriteria} from "../container/valio-product-list-component.service";
import { map } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';


@Component({
  selector: 'valio-cx-product-contractual-switch',
  templateUrl: './valio-product-contractual-switch.component.html'

})
export class ValioProductContractualSwitchComponent implements OnInit, OnDestroy {
  private query: string;
  user$: Observable<ValioUser>;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  subscriptions: Subscription = new Subscription();


  constructor(protected userAccountFacade: UserAccountFacade,
              protected valioRestrictedAssortmentSiteService: ValioRestrictedAssortmentService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router) {
  }


  ngOnInit(): void {
    this.user$ = this.userAccountFacade.get().pipe(map(user => user as ValioUser))
    this.query = this.activatedRoute.snapshot.queryParams.query;
    this.subscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.query = this.activatedRoute.snapshot.queryParams.query;
        }
      })
    );
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCurrentValue(): Observable<boolean> {
    return this.valioRestrictedAssortmentSiteService.getActive();
  }

  onCheckedChange(value: boolean) {
    if (this.activatedRoute.snapshot.url.length == 0) {
      this.router.navigate(["/search"]);
    }
    this.valioRestrictedAssortmentSiteService.setActive(value);
    this.onSelect.emit();
  }

  private createSearchCriteria(): SearchCriteria {
    return {query: this.query, currentPage: undefined};
  }
}
