import {NgModule} from '@angular/core';
import {I18nModule,} from '@spartacus/core';
import {MediaModule} from "@spartacus/storefront";
import {ValioProductTooltipComponent} from "./valio-product-tooltip.component";
import {CommonModule} from "@angular/common";
import {ValioProductPriceModule} from "../product-price/valio-product-price.module";
import {ValioAddToCartModule} from "../../cart/add-to-cart/valio-add-to-cart.module";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    ValioProductPriceModule,
    ValioAddToCartModule
  ],
  declarations: [ValioProductTooltipComponent,],
  exports: [ValioProductTooltipComponent],
})
export class ValioProductTooltipModule {
}
