import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule} from '@spartacus/core';

import {RouterModule} from '@angular/router';
import {GenericLinkModule, PageSlotModule} from '@spartacus/storefront';
import {ValioNavigationModule} from '../valio-navigation.module';
import {ValioMainNavigationComponent} from './valio-main-navigation.component';
import {ValioCartHeaderModule} from '../../cart/cart-header/valio-cart-header.module';
import {ValioCalendarModule} from "../../../calendar/valio-calendar.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioMainNavigationComponent: {
                    component: ValioMainNavigationComponent,
                },
            },
        }),
        ValioNavigationModule,
        GenericLinkModule,
        PageSlotModule,
        ValioCartHeaderModule,
        ValioCalendarModule
    ],
    declarations: [ValioMainNavigationComponent],
    exports: [ValioMainNavigationComponent]
})
export class ValioMainNavigationModule {
}
