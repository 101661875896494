import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {ConfigModule, StateConfig, StateTransferType} from "@spartacus/core";
import {ValioUserDetailsEffects} from "./valio-user-details.effect";
import {ORDER_HISTORY_NORMALIZER, ValioOccOrderHistoryNormalizer} from "./valio-order-history-normalizer";
import {ValioUserConnector} from "./valio-user.connector";
import {UserAccountConnector} from "@spartacus/user/account/core";

export function userStoreConfigFactory(): StateConfig {
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: {
          user: StateTransferType.TRANSFER_STATE
        }
      }
    }
  }
  return config;
}

@NgModule({
  imports: [
    EffectsModule.forFeature([ValioUserDetailsEffects]),
// TODO:Spartacus - The 'withConfigFactory' method's signature changed to: 'withConfigFactory(  configFactory: ConfigFactory,  deps: any[]): ModuleWithProviders<ConfigModule>'
    ConfigModule.withConfigFactory(userStoreConfigFactory),
  ],
  providers: [
    {
      provide: ORDER_HISTORY_NORMALIZER,
      useClass: ValioOccOrderHistoryNormalizer,
      multi: true
    },
    {
      provide: UserAccountConnector,
      useClass: ValioUserConnector
    }
  ]
})
export class ValioUserStoreModule {
}
