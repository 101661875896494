<div class="cart-entry-totals">
  <div class="row align-items-center">
    <div class="d-none d-md-block" [ngClass]="totalItems ? 'col-md-3 col-lg-4': 'col-md-6'"></div>
    <div class="col-12" [ngClass]="totalItems ? 'col-md-6 col-lg-5': 'col-md-4'">
      <valio-price-summary-component
        [cart]="cart"
        [totalItems]="totalItems"
        [subtotal]="subtotal"
        [totalPriceWithoutTax]="totalPriceWithoutTax"
        [totalPriceWithTax]="totalPriceWithTax"
        [freightCharge]="freightCharge"
        [variableWeightPaymentCost]="variableWeightPaymentCost"
      ></valio-price-summary-component>
    </div>
    <div class="col-12 col-md-3">
      <button class="btn btn-lg btn-block btn-primary" type="submit" (click)="click()" [disabled]="!buttonEnabled">
        {{ buttonTextKey | cxTranslate }}
      </button>
    </div>
    <div class="col-12 text-right">
      <p *ngIf="loggedUser" class="cart-confirm-text">{{'checkout.header.confirmtext'|cxTranslate}}</p>
    </div>
  </div>
</div>
