<label class="searchbox" [class.dirty]="!!searchInput.value">
  <span
    aria-label="search"
    class="valio-icon icon-inline icon-search"
  ></span>
  <input
    [class.is-invalid]="isInvalid"
    autocomplete="off"
    #searchInput
    id="searchInputField"
    [placeholder]="'searchBox.placeholder' | cxTranslate"
    value="{{value}}"
    aria-label="search"
    (keydown)="searchEvent($event)"
    (keydown.escape)="clearSearch()"
    data-hj-whitelist
  />
  <ng-container *ngIf="frontPage">
    <span class="reset">
      <span
        aria-label="reset"
        (click)="clearSearch()"
        class="valio-icon icon-reset"
      ></span>
    </span>
    <span class="search">
      <span
        aria-label="search"
        class="valio-icon icon-search"
        (click)="avoidReopen($event)"
      ></span>
    </span>
    <span class="search-open">
      <span
        aria-label="search"
        class="valio-icon icon-search"
        (click)="launchSearchResult($event, searchInput.value)"
      ></span>
    </span>
  </ng-container>
</label>

<div id="result-wrapper" *ngIf="searchInput.value.length>0 && searchResults$ | async as result" class="results" (click)="close($event)">
  <div
    *ngIf="result.message"
    class="message INFO"
    [innerHTML]="result.message"
  ></div>

  <div class="suggestions" (mousedown)="disableClose()">
    <a
      *ngFor="let suggestion of result.suggestions"
      [routerLink]="null" ]="
        {
          cxRoute: 'search',
          params: { query: suggestion }
        } | cxUrl
      "
    >
    </a>
  </div>

  <div class="products" (mousedown)="disableClose()" *ngIf="result.products">
    <a *ngFor="let product of result.products; index as idx" (mouseover)="showTooltip(product, idx)" (mouseout)="hideTooltip()" class="{{getAnchorClassName()}}" [ngClass]="{'active':isActive(idx)}" [attr.data-index]="idx" [attr.data-product]="product|json">
      <span class="product" (click)="selectProduct(product)"><span class="code">{{ product.code|productCode }}</span><span class="name">{{ product.name }}</span></span>
    </a>
  </div>
</div>

<valio-product-cx-tooltip
  [showEvents]="tooltipSubject.asObservable()"
  [showPrice]="false"
>
</valio-product-cx-tooltip>
