import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CartActions} from "@spartacus/cart/base/core";
import {
  AuthActions,
  OAuthLibWrapperService,

  OCC_USER_ID_CURRENT,
  RoutingService,
  SiteContextActions,

  UserIdService,
  withdrawOn
} from "@spartacus/core";
import {from, Observable} from "rxjs";
import {catchError, concatMap, filter, map, mergeMap} from "rxjs/operators";
import {ValioLoadRouteCalendar} from "../routecalendar/valio-routecalendar.action";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {
  CART_CHANGE_HEADER,
  CART_CHANGE_HEADER_RELOAD,
  CART_CHECKOUT,
  CART_REMOVE_DATE,
  CART_SIMULATE,
  TO_CART_CHECKOUT,

  ValioAddEmailToCart,
  ValioCartChangeHeader,
  ValioCartChangeHeaderReload,
  ValioCartCheckout,
  ValioCartSimulate,
  ValioRemoveCartDate,
  ValioToCartCheckout, VALIO_ADD_EMAIL_TO_CART
} from "./valio-cart-entry.action";
import {ValioCartConnector} from "./valio-cart.connector";
import {ValioCartService} from "./valio-cart.service";

@Injectable()
export class ValioCartEffects {

  constructor(
    protected actions2$: Actions,
    protected cartConnector2: ValioCartConnector,
    protected cartService: ValioCartService,
    protected routingService: RoutingService,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
  ) {
  }

  private contextChange2$ = this.actions2$.pipe(
    ofType(
      SiteContextActions.CURRENCY_CHANGE,
      SiteContextActions.LANGUAGE_CHANGE
    )
  );


  updateCart$: Observable<ValioCartChangeHeaderReload | ValioLoadRouteCalendar | CartActions.LoadCartFail> = createEffect(() => this.actions2$.pipe(
      ofType(CART_CHANGE_HEADER),
      map((action: ValioCartChangeHeader) => action.payload),
            concatMap(payload =>
                this.cartConnector2
            .changeCartHeader(payload.userId, payload.cartId, payload.field, payload.value, payload.body)
            .pipe(
              concatMap(cart => [
                new ValioLoadRouteCalendar(payload.userId, payload.cartId),
                new ValioCartChangeHeaderReload(payload.field == 'partner', {
                  userId: payload.userId,
                  cartId: payload.cartId
                }, cart),
              ]),
              /*  catchError(error => {
                    if (payload.userId !== OCC_USER_ID_ANONYMOUS) {
                      return from([
                        new ValioLoadUserDetailsFail(makeErrorSerializable(error))
                      ])
                    }
                    return from([
                      new ValioLoadUserDetailsSuccess({})
                    ])
                  }
                )*/
            )
      ),
      catchError(error =>
        from([
          new CartActions.LoadCartFail(makeErrorSerializable(error))
        ])
      )
    )
  );

  reloadCart$: Observable<CartActions.LoadCartSuccess | CartActions.LoadCartFail> = createEffect(() => this.actions2$.pipe(
      ofType(CART_CHANGE_HEADER_RELOAD),
      map((action: ValioCartChangeHeaderReload) => action),
      concatMap(action => {
          if (action.reload) {
            if (action.cart) {
              this.routingService.go('/cart');
            } else {
              window.location.href = "/";
            }
          }
          return this.cartConnector2.load(action.payload.userId, action.payload.cartId).pipe(
              map(cart => {
                  return new CartActions.LoadCartSuccess(
                      {
                        userId: action.payload.userId,
                        cartId: action.payload.cartId,
                        cart: cart,
                        extraData: {active: true}
                      }
                  );
                })
          );
        }
      ),
      catchError(error =>
        from([
          new CartActions.LoadCartFail(makeErrorSerializable(error))
        ])
      )
    )
  );
  reloadOnVoucherChange$: Observable<CartActions.LoadCart> = createEffect(() => this.actions2$.pipe(
      ofType(CartActions.CART_ADD_VOUCHER_SUCCESS, CartActions.CART_REMOVE_VOUCHER_SUCCESS),
      map((action: any) => action),
      map(action =>
        new CartActions.LoadCart({
          userId: action.payload.userId,
          cartId: action.payload.cartId,
          extraData: {active: true}
        })
      )
    )
  );
  removeCartDate$: Observable<CartActions.LoadCartSuccess | CartActions.LoadCartFail> = createEffect(() => this.actions2$.pipe(
      ofType(CART_REMOVE_DATE),
      map((action: ValioRemoveCartDate) => action),
      concatMap(action => {
          openCloseSpinner(true);
          return this.cartConnector2.removeCartDate(action.userId, action.cartId, action.date)
            .pipe(map(cart => {
                openCloseSpinner(false);
                return new CartActions.LoadCartSuccess({
                  userId: action.userId,
                  cartId: action.cartId,
                  cart: cart,
                  extraData: {active: true}
                });
              }
            ))
        }
      ),
      catchError(error => {
          openCloseSpinner(false);
          return from([
            new CartActions.LoadCartFail(makeErrorSerializable(error))
          ]);
        }
      )
    )
  );

  checkout$: Observable<CartActions.LoadCartSuccess | ValioToCartCheckout | CartActions.LoadCartFail> = createEffect(() => this.actions2$.pipe(
      ofType(CART_CHECKOUT),
      map((action: ValioCartCheckout) => action),
      concatMap(action => {
          openCloseSpinner(true);
          return this.cartConnector2
            .changeCartHeader(action.userId, action.cartId, 'deliveryDate', action.date)
            .pipe(
              concatMap(cart => {
                return [
                  new CartActions.LoadCartSuccess({
                    userId: action.userId,
                    cartId: action.cartId,
                    cart: cart,
                    extraData: {active: true}
                  }),
                  new ValioToCartCheckout(cart, action.userId, action.cartId, action.toCreditPage)
                ];
              }),
              catchError(error =>
                from([
                  new CartActions.LoadCartFail(makeErrorSerializable(error))
                ])
              )
            );
        }
      )
    )
  );
  toCheckout$: Observable<CartActions.LoadCartSuccess | CartActions.LoadCartFail> = createEffect(() => this.actions2$.pipe(
      ofType(TO_CART_CHECKOUT),
      map((action: ValioToCartCheckout) => action),
      map(action => {
          if (action.toCreditPage) {
            this.routingService.go('/checkout/shipping-address');
          } else {
            this.routingService.go('/checkout/review-order-invoice');
          }
          return new CartActions.LoadCartSuccess({
            userId: action.userId,
            cartId: action.cartId,
            cart: action.cart,
            extraData: {active: true}
          });
        }
      ),
      catchError(error =>
        from([
          new CartActions.LoadCartFail(makeErrorSerializable(error))
        ])
      )
    )
  );

  cartSimulate: Observable<CartActions.LoadCartSuccess> = createEffect(() => this.actions2$.pipe(
      ofType(CART_SIMULATE),
      filter((action: ValioCartSimulate): boolean => !!(action.userId && action.cartId)),
      map((action: ValioCartSimulate) => action),
      concatMap(action => {
          return this.cartConnector2.simulate(action.userId, action.cartId).pipe(
            map(cart => new CartActions.LoadCartSuccess({
              userId: action.userId,
              cartId: action.cartId,
              cart: cart,
              extraData: {active: true}
            }))
          )
        }
      )
    )
  );

  addEmail2$: Observable<CartActions.AddEmailToCartSuccess | CartActions.LoadCart | CartActions.AddEmailToCartFail | AuthActions.Login> = createEffect(() => this.actions2$.pipe(
      ofType(VALIO_ADD_EMAIL_TO_CART),
      map((action: ValioAddEmailToCart) => action.payload),
      mergeMap((payload) =>
        this.cartConnector2
          .addEmail(payload.userId, payload.cartId, payload.email)
          .pipe(
            mergeMap(user =>
              from(this.oAuthLibWrapperService.authorizeWithPasswordFlow(
                user.uid,
                user.customerId
              )).pipe(
                mergeMap(
                  auth => {
                    this.userIdService.setUserId(user.uid);
                    return [
                      // new AuthActions.Login(),
                      new CartActions.AddEmailToCartSuccess({
                        ...payload,
                        userId: user.uid,
                      }),
                      new CartActions.LoadCart({
                        userId: user.uid,
                        cartId: payload.cartId,
                      })
                    ];
                  }
                )
              )
            ),
            catchError((error) =>
              from([
                new CartActions.AddEmailToCartFail({
                  ...payload,
                  error: makeErrorSerializable(error),
                })
              ])
            )
          )
      ),
      withdrawOn(this.contextChange2$
      )
    )
  );
}

