<form (submit)="loginUser()" [formGroup]="form" id="loginForm" name="loginForm">
  <div class="login-form">
    <div class="content-group">
      <h1>{{ 'loginForm.welcome.label' | cxTranslate }}</h1>
      <p>{{ 'loginForm.welcome.instructions' | cxTranslate }}</p>
    </div>
    <div class="content-group">
      <valio-cx-input [isNotValidFunction]="form.controls['userId'].value.length > 0 && form.controls['userId'].invalid && (form.controls['userId'].touched || form.controls['userId'].dirty)"
                      [labelPlaceholder]="'loginForm.inputs.userName'" [nameForm]="'userId'"
                      [parentForm]="form" class="valio-input"></valio-cx-input>

      <valio-cx-input [isNotValidFunction]="false" [idName]="'password'"
                      [labelPlaceholder]="'loginForm.inputs.password'" [typeInput]="'password'" [nameForm]="'password'"
                      [parentForm]="form" class="valio-input"></valio-cx-input>
    </div>
    <div class="content-group">
      <button
        type="submit" id="loginButton"
        class="btn btn-lg btn-primary"
      >{{ 'loginForm.inputs.signIn' | cxTranslate }}
      </button>
    </div>
    <div class="content-group">
      <a
        [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
        aria-controls="reset-password"
        class="btn btn-link"
      >{{ 'loginForm.forgottenPassword.link' | cxTranslate }}</a>
      <valio-cx-no-account></valio-cx-no-account>
    </div>
  </div>
</form>
