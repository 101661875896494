<div class="row"
     [attr.data-productid]="product.code"
     [attr.data-listName]="listName ? listName : null">
  <div class="col-12 col-md-4">
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-image-container"
    >
      <cx-media
        class="cx-product-image"
        [container]="product.images?.PRIMARY"
        format="thumbnail"
        [alt]="product.summary"
      ></cx-media>
    </a>
  </div>
  <div class="col-12 col-md-8">
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-name"
      [innerHtml]="product.nameHtml"
    ></a>
    <valio-cx-product-price [product]="product"></valio-cx-product-price>
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="cx-product-summary" [innerHtml]="product.summary">
          {{ product.summary }}
        </p>
      </div>
      <div class="col-12 col-md-4">
        <valio-cx-add-to-cart
          [setProduct]="product"
        ></valio-cx-add-to-cart>
      </div>
    </div>
  </div>
</div>
