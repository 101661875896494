import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';

import {StoreModule} from "@ngrx/store";
import {ValioBoughtTogetherEffects} from "./valio-bought-together.effect";
import {BOUGHT_TOGETHER_FEATURE} from "./valio-bought-together.action";
import {metaReducers, reducerToken, valioBoughtTogetherReducerProvider} from "./valio-bought-together.reducer";


@NgModule({
  imports: [
    StoreModule.forFeature(BOUGHT_TOGETHER_FEATURE, reducerToken, {metaReducers}),
    EffectsModule.forFeature([ValioBoughtTogetherEffects]),
  ],
  providers: [
    valioBoughtTogetherReducerProvider
  ]
})
export class ValioBoughtTogetherProductsModule {
}
