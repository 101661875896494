import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {CmsService} from '@spartacus/core';
import {ValioCmsFooterLogosComponent} from '../../../../models';
import {combineLatest, Observable} from "rxjs";
import {filter, map, switchMap} from "rxjs/operators";

@Component({
  selector: 'valio-footer-logos-component',
  templateUrl: './valio-footer-logos.component.html',
})
export class ValioFooterLogosComponent {

  data$ = this.componentData.data$;

  partnerLogos$: Observable<any[]> = this.componentData.data$.pipe(
    filter(data => data != null),
    switchMap(data =>
      combineLatest(
        data.partnerLogos.split(' ').map(component =>
          this.cmsService.getComponentData<any>(component).pipe(
            map(image => {
              if (!image.flexType) {
                image = {
                  ...image,
                  flexType: image.typeCode,
                  altText: image.media?.altText,
                };
              }
              return {
                ...image,
              };
            })
          )
        )
      )
    )
  );

  constructor(
    protected componentData: CmsComponentData<ValioCmsFooterLogosComponent>,
    protected cmsService: CmsService
  ) {
  }
}
