import {Injectable} from "@angular/core";
import {CurrencyService, LanguageService, ProductSearchService, RoutingService} from "@spartacus/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ValioProductListComponentService} from "./valio-product-list-component.service";
import {ValioPartnerSiteService} from "../../../../services/site/valio-partner-site.service";
import {ViewConfig} from "@spartacus/storefront";


@Injectable({providedIn: 'root'})
export class ValioProductGridComponentService extends ValioProductListComponentService {
  constructor(
    protected productSearchService: ProductSearchService,
    protected routing: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected valioPartnerSiteService: ValioPartnerSiteService
  ) {
    super(productSearchService, routing, activatedRoute, currencyService, languageService, router, valioPartnerSiteService, {
      view: {
        defaultPageSize: 20
      }
    } as ViewConfig);


  }
}
