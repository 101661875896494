import {SITE_CONTEXT_FEATURE, SiteContextState, StateWithSiteContext} from "@spartacus/core";

import {Partner} from "../cart/valio-cart.objects";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";

export const PARTNER_CONTEXT_ID = 'partner';
export const ALL_PARTNERS = 'all';
export const RESTRICTED_ASSORTMENT = 'restrictedAssortment';

export interface ValioSiteContextState extends SiteContextState {
  partner: PartnersState;
  restrictedAssortment: RestrictedAssortmentState;
}

export interface PartnerEntities {
  [code: string]: Partner;
}

export interface PartnersState {
  entities: PartnerEntities;
  activePartner: string;
}

export interface RestrictedAssortmentState {
  selected: boolean;
}

const activePartnerSelector = (state: PartnersState) => {
  return state.activePartner;
};

export const getSiteContextState: MemoizedSelector<StateWithSiteContext,
  SiteContextState> = createFeatureSelector<SiteContextState>(SITE_CONTEXT_FEATURE);

export const getPartnersState: MemoizedSelector<StateWithSiteContext,
  PartnersState> = createSelector(
  getSiteContextState,
  (state: ValioSiteContextState) => state.partner
);

export const getActivePartner: MemoizedSelector<StateWithSiteContext,
  string> = createSelector(
  getPartnersState,
  activePartnerSelector
);

const activeRestrictedAssortmentSelector = (state: RestrictedAssortmentState) => state.selected;

export const getRestrictedAssortmentState: MemoizedSelector<StateWithSiteContext,
  RestrictedAssortmentState> = createSelector(
  getSiteContextState,
  (state: ValioSiteContextState) => state.restrictedAssortment
);

export const getActiveRestrictedAssortment: MemoizedSelector<StateWithSiteContext, boolean> = createSelector(
  getRestrictedAssortmentState,
  activeRestrictedAssortmentSelector
);
