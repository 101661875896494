import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {Price} from "@spartacus/core";


@Component({
  selector: 'valio-totals-view',
  templateUrl: './valio-totals-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioTotalsViewComponent implements OnInit {
  @Input() total: Price;
  @Input() totalPriceInvoice: Price;
  @Input() totalPriceCredit: Price;
  @Input() totalItems: number;
  @Input() totalItemsInvoice: number;
  @Input() totalItemsCredit: number;
  @Input() totalItemsCreditUnPaid: number;
  @Input() totalWeightInKg: string;
  @Input() historyView: boolean = false;

  ngOnInit(): void {
  }


}
