import {ProductSearchPage, StateUtils} from "@spartacus/core";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";

export const BOUGHT_TOGETHER_FEATURE = 'boughtTogether';

const getBoughtTogetherContentSelector = (state: ValioBoughtTogetherState) => {
  return state.content;
};

export const getBoughtTogethersState: MemoizedSelector<ValioStateWithBoughtTogether,
  ValioBoughtTogethersState> = createFeatureSelector<ValioBoughtTogethersState>(BOUGHT_TOGETHER_FEATURE);

export const getActiveBoughtTogetherState: MemoizedSelector<ValioStateWithBoughtTogether,
  StateUtils.LoaderState<ValioBoughtTogetherState>> = createSelector(
  getBoughtTogethersState,
  (cartsState: ValioBoughtTogethersState) => cartsState.active
);

export const getBoughtTogetherState: MemoizedSelector<ValioStateWithBoughtTogether,
  ValioBoughtTogetherState> = createSelector(
  getActiveBoughtTogetherState,
  state => StateUtils.loaderValueSelector(state)
);

export const getBoughtTogetherContent: MemoizedSelector<ValioStateWithBoughtTogether,
  ProductSearchPage> = createSelector(
  getBoughtTogetherState,
  getBoughtTogetherContentSelector
);

export interface ValioStateWithBoughtTogether {
  [BOUGHT_TOGETHER_FEATURE]: ValioBoughtTogetherState;
}

export interface ValioBoughtTogethersState {
  active: StateUtils.LoaderState<ValioBoughtTogetherState>;
}

export interface ValioBoughtTogetherState {
  content: ProductSearchPage;
}

export const BOUGHT_TOGETHER_DATA = '[BoughtTogether] Data';
export const LOAD_BOUGHT_TOGETHER = '[BoughtTogether] Load bought together';

export class ValioLoadBoughtTogether extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_BOUGHT_TOGETHER;

  constructor(public payload: { productCode:string, cartId: string, entry: string }) {
    super(BOUGHT_TOGETHER_DATA);
  }

}

export const LOAD_BOUGHT_TOGETHER_SUCCESS = '[BoughtTogether] Load Success';

export class ValioLoadBoughtTogetherSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_BOUGHT_TOGETHER_SUCCESS;

  constructor(public products: ProductSearchPage) {
    super(BOUGHT_TOGETHER_DATA);
  }
}

export const LOAD_BOUGHT_TOGETHER_FAIL = '[BoughtTogether] Load bought together failed';

export class ValioLoadBoughtTogetherFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_BOUGHT_TOGETHER_FAIL;

  constructor() {
    super(BOUGHT_TOGETHER_DATA);
  }
}
