import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ValioCartEntry} from "../../../../../services/cart/valio-cart.objects";
import {Observable} from "rxjs";

@Component({
  selector: 'valio-cx-cart-item-list',
  templateUrl: './valio-cart-item-list.component.html',
})
export class ValioCartItemListComponent implements OnInit, OnDestroy {
  @ViewChild('partnerToggle', {static: false})
  partnerToggle: ElementRef;
  @Output() updateCartEmitter: EventEmitter<any> = new EventEmitter();
  @Input() compact = false;
  @Input() minicartMode: boolean = false;
  @Input() collapsed: boolean;
  @Input() hidePrices = false;
  @Input() allowDelete = true;
  @Input() readOnly: false;
  @Input() items: ValioCartEntry[];
  @Input() cartIsLoading = false;
  @Input() partnerClickedEvent: Observable<void>;

  form: UntypedFormGroup;
  eventsSubscription: any;

  constructor(protected cartService: ValioCartService, protected cdr: ChangeDetectorRef, protected fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.fb.group({})
    if (this.partnerClickedEvent) {
      this.eventsSubscription = this.partnerClickedEvent.subscribe(() => {
        this.partnerToggle.nativeElement.classList.toggle('collapsed');
        this.collapsed = !this.collapsed;
      });
    }
    if (this.readOnly && this.items.length > 20) {
      this.collapsed = true;
    }
    this.items.forEach(item => this.createEntry(item));
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }

  private createEntry(item: ValioCartEntry): void {
    const entryNumber = item.entryNumber + "";
    if (!this.form.controls[entryNumber]) {
      this.form.setControl(entryNumber, this.createEntryFormGroup2(item));
    } else {
      const entryForm = this.form.controls[entryNumber] as UntypedFormGroup;
      entryForm.controls.quantity.setValue(item.quantity);
    }
  }

  private createEntryFormGroup2(entry): UntypedFormGroup {
    return this.fb.group({
      entryNumber: entry.entryNumber,
      quantity: entry.quantity,
    });
  }

  updateEntryData(input: any): void {
    this.updateCartEmitter.emit(input);
  }

  getParent(item: ValioCartEntry): UntypedFormGroup {
    if (!this.form.controls[item.entryNumber + ""]) {
      this.createEntry(item);
    }
    return this.form.controls[item.entryNumber + ""] as UntypedFormGroup;
  }
}
