<div class="section-header">{{titleKey|cxTranslate}}</div>
<div class="row">
  <valio-cx-input
    *ngIf="soldTo"
    (afterChange)="onChange()"
    idName="vatId" [isNotValidFunction]="isInvalidStep('vatId')"
    [labelPlaceholder]="'valioRegister.vatId.placeholder'" [nameForm]="'vatId'"
    [parentForm]="addressForm" class="valio-input col-4"></valio-cx-input>
  <valio-cx-input
    (afterChange)="onChange()"
    [isNotValidFunction]="isInvalidStep('companyName')" idName="companyName"
    [labelPlaceholder]="'valioRegister.companyName.placeholder'" [nameForm]="'companyName'"
    [parentForm]="addressForm" class="valio-input" [ngClass]="soldTo?'col-8':'col-12'"></valio-cx-input>
  <valio-cx-input
    (afterChange)="onChange()"
    [isNotValidFunction]="isInvalidStep('firstName')" idName="firstName"
    [labelPlaceholder]="'updateProfileForm.firstName.label'" [nameForm]="'firstName'"
    [parentForm]="addressForm" class="valio-input col-6"></valio-cx-input>
  <valio-cx-input
    (afterChange)="onChange()"
    [isNotValidFunction]="isInvalidStep('lastName')" idName="lastName"
    [labelPlaceholder]="'updateProfileForm.lastName.label'" [nameForm]="'lastName'"
    [parentForm]="addressForm" class="valio-input col-6"></valio-cx-input>
  <valio-cx-input
    (afterChange)="onChange()"
    [errorMessage]="'updateProfileForm.phoneRequired'" idName="phone"
    [isNotValidFunction]="isInvalidStep('phone')"
    [labelPlaceholder]="'updateProfileForm.phone.label'" [nameForm]="'phone'" [parentForm]="addressForm"
    class="valio-input" [ngClass]="soldTo?'col-6':'col-12'"></valio-cx-input>
  <valio-cx-input
    *ngIf="soldTo"
    (afterChange)="onChange()"
    [errorMessage]="'updateProfileForm.emailRequired'" idName="email"
    [isNotValidFunction]="isInvalidStep('email')"
    [labelPlaceholder]="'updateProfileForm.email.label'" [nameForm]="'email'" [parentForm]="addressForm"
    class="valio-input col-6"></valio-cx-input>
  <valio-cx-input
    (afterChange)="onChange()"
    [isNotValidFunction]="isInvalidStep('line1')" idName="line1"
    [labelPlaceholder]="'partnerApply.billingStreet'" [nameForm]="'line1'"
    [parentForm]="addressForm" class="valio-input col-12"></valio-cx-input>
  <valio-cx-input
    (afterChange)="onChange()"
    [isNotValidFunction]="isInvalidStep('postalCode')" idName="postalCode"
    [labelPlaceholder]="'valioRegister.postalCode.placeholder'" [nameForm]="'postalCode'"
    [parentForm]="addressForm" class="valio-input col-4"></valio-cx-input>
  <valio-cx-input
    (afterChange)="onChange()"
    [isNotValidFunction]="isInvalidStep('town')" idName="town"
    [labelPlaceholder]="'valioRegister.city.placeholder'" [nameForm]="'town'"
    [parentForm]="addressForm" class="valio-input col-8"></valio-cx-input>
</div>

