import {ProductSearchPage, StateUtils} from "@spartacus/core";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";

export const TOP_SELLERS_FEATURE = 'topSellers';

const getTopSellersContentSelector = (state: ValioTopSellersState) => {
  return state.content;
};

export const getTopSellerssState: MemoizedSelector<ValioStateWithTopSellers,
  ValioTopSellerssState> = createFeatureSelector<ValioTopSellerssState>(TOP_SELLERS_FEATURE);

export const getActiveTopSellersState: MemoizedSelector<ValioStateWithTopSellers,
  StateUtils.LoaderState<ValioTopSellersState>> = createSelector(
  getTopSellerssState,
  (cartsState: ValioTopSellerssState) => cartsState.active
);

export const getTopSellersState: MemoizedSelector<ValioStateWithTopSellers,
  ValioTopSellersState> = createSelector(
  getActiveTopSellersState,
  state => StateUtils.loaderValueSelector(state)
);

export const getTopSellersContent: MemoizedSelector<ValioStateWithTopSellers,
  ProductSearchPage> = createSelector(
  getTopSellersState,
  getTopSellersContentSelector
);

export interface ValioStateWithTopSellers {
  [TOP_SELLERS_FEATURE]: ValioTopSellersState;
}

export interface ValioTopSellerssState {
  active: StateUtils.LoaderState<ValioTopSellersState>;
}

export interface ValioTopSellersState {
  content: ProductSearchPage;
}

export const TOP_SELLERS_DATA = '[TopSellers] Data';
export const LOAD_TOP_SELLERS = '[TopSellers] Load top sellers';

export class ValioLoadTopSellers extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_TOP_SELLERS;

  constructor() {
    super(TOP_SELLERS_DATA);
  }
}

export const LOAD_TOP_SELLERS_SUCCESS = '[TopSellers] Load Success';

export class ValioLoadTopSellersSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_TOP_SELLERS_SUCCESS;

  constructor(public products: ProductSearchPage) {
    super(TOP_SELLERS_DATA);
  }
}

export const LOAD_TOP_SELLERS_FAIL = '[TopSellers] Load top sellers failed';

export class ValioLoadTopSellersFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_TOP_SELLERS_FAIL;

  constructor() {
    super(TOP_SELLERS_DATA);
  }
}
