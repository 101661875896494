<div class="cart-header-wrapper">
  <div class="header-info-slot header-info-slot-cart col-12">
    <div class="row">
      <div class="header-block col-4 col-lg-2">
        <div class="date-toggle"
             *ngIf="showDateToggle"
             (click)="toggleDate(true)"
             [ngClass]="{'collapsed':collapsed}"
             #dateToggleButton>
          <div class="valio-icon icon-chevron icon-chevron-white icon-chevron-up"></div>
        </div>
        <valio-date-view
          [date]="date"
          [status]="status"
        ></valio-date-view>
      </div>
      <ng-container *ngIf="showOtherDateToggle">
        <div class="header-block col-12 col-sm-8 col-lg-10">
          <div class="row align-items-center h-100">
            <div class="col-8 col-sm-7 col-md-8 col-lg-9 col-xl-10 text-right show-open-rows">
              {{ 'order.header.openRows' | cxTranslate }}
            </div>
            <div class="col-4 col-sm-5 col-md-4 col-lg-3 col-xl-2 text-right">
              <a class="margin-right-md header-button show-date" (click)="toggleDate(false)">
                {{ 'cart.cartItems.show'|cxTranslate }}
              </a>
              <a class="margin-right-md header-button delete-date" (click)="deleteCartDate(dateEntry.deliveryDate)">
                {{ 'cart.cartItems.delete'|cxTranslate }}
              </a>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showOtherDateToggle">
        <div class="header-block col-4 col-md-3 col-lg-2" *ngIf="!showButton">
          <br/>
          <a class="print-button d-none d-lg-block d-print-none" (click)="print($event)">
            <span class="valio-icon icon-print icon-print-white"></span>
            {{ 'order.header.print' | cxTranslate }}
          </a>
        </div>
        <div class="header-block col-4 col-md-5 col-lg-2">
          <div class="total-weight d-none d-md-block">
            <br *ngIf="totalItemsInvoice && totalItemsCredit"/>
            {{'cart.header.totalWeightInKg'|cxTranslate: {weight: totalWeightInKg} }}
          </div>
        </div>
        <div class="header-block col-12 col-lg-6 d-none d-lg-block">
          <valio-totals-view
            [totalPriceInvoice]="dateEntry.totalPriceInvoice"
            [totalPriceCredit]="dateEntry.totalPriceCredit"
            [totalItemsCredit]="totalItemsCredit"
            [totalItemsCreditUnPaid]="totalItemsCreditUnPaid"
            [totalItemsInvoice]="totalItemsInvoice"
            [totalWeightInKg]="totalWeightInKg"
            [historyView]="historyView"
          ></valio-totals-view>
        </div>
        <div class="header-block col-4 col-md-3 col-lg-2" *ngIf="showButton">
          <button
            [disabled]="!checkoutEnabled"
            [ngClass]="checkoutEnabled ? 'btn-secondary-orange' : 'btn-secondary-inv'"
            (click)="toCheckout()"
            class="btn float-right"
            type="button">
            {{ 'navigation.placeOrder' | cxTranslate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="header-info-slot mobile-header col-12 d-lg-none">
    <div class="row">
      <div class="header-block col-12">
        <div
          class="total-weight d-md-none">{{'cart.header.totalWeightInKg'|cxTranslate: {weight: totalWeightInKg} }}</div>
        <valio-totals-view
          [totalPriceInvoice]="dateEntry.totalPriceInvoice"
          [totalPriceCredit]="dateEntry.totalPriceCredit"
          [totalItemsCredit]="totalItemsCredit"
          [totalItemsCreditUnPaid]="totalItemsCreditUnPaid"
          [totalItemsInvoice]="totalItemsInvoice"
          [totalWeightInKg]="totalWeightInKg"
          [historyView]="historyView"
        ></valio-totals-view>
      </div>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="remove-date row" [ngClass]="{'collapsed' : detailsCollapsed}"
       *ngIf="!isReadOnly && dateEntry.totalItems > 0 && (dateEntry.status === 'open'|| dateEntry.status === 'error')">
    <div class="col-12 col-md-8">
      <ng-container *ngIf="dateEntry.baseOrderDeliveryBlockOn">
        {{'cart.cartItems.baseOrderDeliveryBlockOn'|cxTranslate}}
      </ng-container>
    </div>
    <div class="text-right col-12 col-md-4">
      <a class="btn btn-link delete-date"
         (click)="deleteCartDate(dateEntry.deliveryDate)">{{'cart.cartItems.deleteDate'|cxTranslate}}</a>
    </div>
  </div>
</div>
<div class="header-edit-slot" [ngClass]="{'collapsed' : detailsCollapsed}">
  <ng-container *ngIf="false && hasSalesEndPassed()">
    <div class="message-wrapper">
      <div class="message WARNING">
        {{'cart.header.messages.salesEnded'|cxTranslate}}
        <button class="btn btn-link-inv"
                [routerLink]="'/my-account/deliverynotes'">{{'cart.header.messages.salesEndedSuffix'|cxTranslate}}</button>
      </div>
    </div>
  </ng-container>
</div>

