import {BASE_SITE_CONTEXT_ID, BaseSiteService, CURRENCY_CONTEXT_ID, CurrencyService, LANGUAGE_CONTEXT_ID, LanguageService} from "@spartacus/core";
import {ValioPartnerSiteService} from "./valio-partner-site.service";
import {PARTNER_CONTEXT_ID, RESTRICTED_ASSORTMENT} from "./valio-site.constants";
import {ValioRestrictedAssortmentService} from "./valio-restricted-assortment.service";

export function valioServiceMapFactory() {
  return {
    [LANGUAGE_CONTEXT_ID]: LanguageService,
    [CURRENCY_CONTEXT_ID]: CurrencyService,
    [BASE_SITE_CONTEXT_ID]: BaseSiteService,
    [PARTNER_CONTEXT_ID]: ValioPartnerSiteService,
    [RESTRICTED_ASSORTMENT]: ValioRestrictedAssortmentService
  };
}

