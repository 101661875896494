import {Injectable} from "@angular/core";
import {UserAccountConnector, UserAccountService} from "@spartacus/user/account/core";
import {QueryService, UserIdService} from "@spartacus/core";
import {Observable} from "rxjs";
import {ValioUser} from "../../models/misc.model";


@Injectable({
  providedIn: 'root',
})
export class ValioUserAccountFacade extends UserAccountService {
  constructor(userAccountConnector: UserAccountConnector, userIdService: UserIdService, query: QueryService) {
    super(userAccountConnector, userIdService, query);
  }

  get(): Observable<ValioUser | undefined> {
    return super.get();
  }
}
