import {DIALOG_TYPE, LayoutConfig} from '@spartacus/storefront';
import {ValioLogoutModalComponent} from "./valio-logout-modal.component";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    LOGOUT_MODAL = 'LOGOUT_MODAL',
  }
}

export const valioLogoutModalLayoutConfig: LayoutConfig = {
  launch: {
    LOGOUT_MODAL: {
      inlineRoot: true,
      component: ValioLogoutModalComponent,
      dialogType: DIALOG_TYPE.DIALOG
    }
  },
};

export interface ValioLogoutDialogData {
  logoutModal: boolean;
  unit: string;
  date: string;
}
