import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageLayoutService, ProductListComponent, ViewConfig, ViewModes} from '@spartacus/storefront';
import {map, switchMap, take} from 'rxjs/operators';
import {ValioProductListComponentService} from './valio-product-list-component.service';
import {Observable, Subscription} from 'rxjs';
import {ValioUserService} from '../../../../services/user/valio-user.service';
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {Vendor} from "../../../../models/misc.model";
import {ValioPartnerService} from "../../../../services/vendor/valio-partner.service";
import {ValioProductSearchPage} from "../../../../models";
import {ValioPartnerSiteService} from "../../../../services/site/valio-partner-site.service";
import {ActivatedRoute} from "@angular/router";
import {ValioGoogleAnalyticsService} from "../../../../services/analytics/valio-google-analytics.service";
import {
  ProductListComponentService
} from "@spartacus/storefront/cms-components/product/product-list/container/product-list-component.service";
import {GlobalMessageService} from "@spartacus/core";

@Component({
  selector: 'valio-cx-product-scroll-list',
  templateUrl: './valio-product-scroll-list.component.html',
})
export class ValioProductScrollListComponent extends ProductListComponent implements OnInit, OnDestroy {
  fullWidth$: Observable<boolean>;
  hasUICart$: Observable<boolean>;
  isPartnerFrontpage$: Observable<boolean>;
  selectedPartner$: Observable<Vendor>;
  ViewModes = ViewModes;
  model$: Observable<ValioProductSearchPage>;
  listName: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected lPageLayoutService: PageLayoutService,
    protected lProductListComponentService: ValioProductListComponentService,
    protected lScrollConfig: ViewConfig,
    protected userService: ValioUserService,
    protected cartService: ValioCartService,
    protected partnerService: ValioPartnerService,
    protected googleAnalyticsService: ValioGoogleAnalyticsService,
    protected partnerSiteService: ValioPartnerSiteService,
    protected activatedRoute: ActivatedRoute,
    globalMessageService: GlobalMessageService,

  ) {
    super(lPageLayoutService, lProductListComponentService, globalMessageService, lScrollConfig );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fullWidth$ = this.cartService.hasUiCart(true);
    this.hasUICart$ = this.cartService.hasUiCart();
    this.subscriptions.add(
      this.lPageLayoutService.templateName$.pipe(take(1)).subscribe(template => {
        this.setViewMode(
          (template === 'ProductGridPageTemplate' || template === 'SearchResultsGridPageTemplate' || template === 'CartPageTemplate') ? ViewModes.Grid : ViewModes.List
        );
      })
    );
    this.isPartnerFrontpage$ = this.partnerSiteService.isPartnerSelected().pipe(
      switchMap(b => this.activatedRoute.queryParams.pipe(
        map(q => b && q.query == null)
      ))
    );

    this.selectedPartner$ = this.partnerService.getSelectedVendor();
    this.model$ = this.lProductListComponentService.model$;
    this.subscriptions.add(
      this.model$.subscribe(searchPage => {
        this.googleAnalyticsService.createImpressionEventFromSearchPage(searchPage)
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }
}
