<ng-container *ngIf="cart$|async as cart">
  <ng-container *ngIf="isMiniCartActive(cart) || isCalendarActive()">
    <ng-container *ngIf="requestedDate$ | async as requestedDate">
      <div class="calendar-side-section">
        <div class="calendar-header">
          <ng-container *ngIf="isSignedIn">
            <div (click)="setCalendarActive(true)"
                 [ngClass]="{'active':isCalendarActive(),'inactive':!isCalendarActive()}"
                 class="calendar-header-tab col-6">
              <div class="valio-icon icon-inline icon-calendar margin-right-base"></div>
              <span>{{'calendar.chooseDelivery'| cxTranslate}}</span>
            </div>
          </ng-container>
          <div (click)="setCalendarActive(false)"
               [ngClass]="{'active':!isCalendarActive(),'inactive':isCalendarActive(),'align-left':!isSignedIn}"
               class="calendar-header-tab col-6">
            <div class="valio-icon icon-inline icon-cart margin-right-base"></div>
            <span
              *ngIf="requestedDate">{{'calendar.minicart.miniCart'| cxTranslate: {date: requestedDate|valioDate:'shortDate'} }}</span>
          </div>
        </div>

        <ng-container *ngIf="isCalendarActive() && routeCalendar$|async as routeCalendar">
          <ng-container *ngIf="routeCalendar && routeCalendar.routeDates?.length>0">
            <!--      this builds week rows-->
            <valio-calendar-date-component
              (calendarLoaded)="scrollActivityLog($event)"
              (update)="selectDate($event)"
              [cartLoaded$]="cartLoaded$"
              [routeCalendar]="routeCalendar"
              [selectedDate]="requestedDate"
            >
            </valio-calendar-date-component>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isMiniCartActive(cart)">
          <div class="minicart">
            <div class="minicart-wrapper">
              <div class="minicart-header row" *ngIf="isSignedIn">
                <div *ngIf="requestedDate" class="minicart-date col-8">
                  {{'calendar.minicart.miniCartItems'| cxTranslate}} {{requestedDate|valioDate:'shortDate'}}
                </div>
                <div class="minicart-count col-4">
                  {{'cart.header.products'| cxTranslate: {products: cart.totalItems} }}
                </div>
                <div *ngIf="cart.baseOrderDeliveryBlockOn" class="col-12">
                  {{'cart.cartItems.baseOrderDeliveryBlockOn'|cxTranslate}}
                </div>
              </div>
              <div class="minicart-header row" *ngIf="!isSignedIn">
                <div class="minicart-products col-12" *ngIf="entries$|async as entries">
                  {{'calendar.minicart.products'|cxTranslate: {products: entries.length} }}
                </div>
                <div class="minicart-delivery col-12">
                  <!-- @TODO Check correct dates -->
                  {{ 'calendar.minicart.deliveryDates' | cxTranslate: {
                    from: convertDateToUi( cart.giftShopPurchaseDateRange?.from),
                    to: convertDateToUi(cart.giftShopPurchaseDateRange?.to)
                } }}<br/>
                  {{ 'calendar.minicart.deliverySelect' | cxTranslate }}
                </div>
              </div>
              <div *ngIf="entries$|async as entries" class="entries">
                <valio-cx-cart-item-list
                  (updateCartEmitter)="updateCart($event)"
                  [cartIsLoading]="!(cartLoaded$ | async)"
                  [compact]="false"
                  [items]="entries"
                  [minicartMode]="true"
                  [readOnly]="false"
                ></valio-cx-cart-item-list>
              </div>
              <div class="minicart-shipping" *ngIf="!isSignedIn">
                <div class="row">
                  {{createShippingForm(cart)}}
                  <div
                    class="minicart-shipping-header col-12">{{'valioRegister.postalCode.anonymousAvailability'|cxTranslate}}</div>
                  <div class="col-12">
                    <valio-cx-input (afterBlur)="changePostalCode()"
                                    [errorMessage]="'valioRegister.postalCode.anonymousInvalid'"
                                    [isNotValidFunction]="isInvalidPostalCode()" idName="anonymousPostalCode"
                                    [labelPlaceholder]="'valioRegister.postalCode.placeholder'"
                                    [nameForm]="'anonymousPostalCode'"
                                    [parentForm]="form" class="valio-input d-inline-block"></valio-cx-input>
                  </div>
                </div>
              </div>
              <div *ngIf="cart.paidEntries && cart.paidEntries.length > 0" class="paid-products">
                <div class="title row">
                  <div class="col-12">
                    <div class="row no-gutters">
                      <span class="title-label col-6">{{'calendar.minicart.paidProducts'|cxTranslate}}</span>
                      <span
                        class="col-4">{{cart.paidAmount.formattedValue}} {{'calendar.minicart.paid'|cxTranslate}}</span>
                      <span class="cx-price col-2">{{cart.unPaidAmount.formattedValue}}</span>
                    </div>
                  </div>
                </div>
                <div class="info row no-gutters">
                  <span class="col-12">{{'calendar.minicart.paidProductsInfo'|cxTranslate}}</span>
                </div>
                <valio-cx-cart-item-list
                  (updateCartEmitter)="updateCart($event)"
                  [cartIsLoading]="!(cartLoaded$ | async)"
                  [compact]="false"
                  [items]="cart.paidEntries"
                  [minicartMode]="true"
                  [readOnly]="false"
                ></valio-cx-cart-item-list>
              </div>
              <valio-cart-item-message
                [item]="cart"
                [compact]="false"
              ></valio-cart-item-message>
              <valio-price-summary-component
                [cart]="cart"
                [freightCharge]="cart.freightCharge"
                [subtotal]="cart.totalPrice"
                [totalPriceWithTax]="cart.totalPriceWithTax"
                [totalPriceWithoutTax]="cart.totalPriceWithoutTax"
                [variableWeightPaymentCost]="cart.variableWeightPaymentCost"
              ></valio-price-summary-component>
              <div *ngIf="entries$|async as entries" class="minicart-to-cart row no-gutters">
                <button (click)="toCheckout(requestedDate,cart)" [disabled]="!isCheckoutActive(cart,entries)"
                        class="btn btn-lg btn-block btn-primary">
                  {{ 'navigation.placeOrder' | cxTranslate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
