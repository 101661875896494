<ng-container *ngIf="isLoggedIn$ | async as isLoggedIn">
  <div class="valio-modal modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div *ngIf="isLoading$ | async; else loaded">
        <div class="cx-spinner">
          <cx-spinner></cx-spinner>
        </div>
      </div>
      <ng-template #loaded>
        <h3 class="modal-header">{{ 'closeAccount.confirmAccountClosure' | cxTranslate }}</h3>
        <p class="text-bold">{{ 'closeAccount.confirmAccountClosureMessage' | cxTranslate }}</p>
        <p>{{ 'closeAccount.confirmAccountClosureSubmessage' | cxTranslate }}</p>
        <div class="row buttons">
          <div class="col-6 d-flex justify-content-center">
            <button (click)="dismissModal()" class="btn btn-block btn-primary">
              {{ 'common.cancel' | cxTranslate }}
            </button>
          </div>
          <div class="col-6 d-flex justify-content-center">
            <button class="btn btn-block btn-close" (click)="closeAccount()">
              {{ 'closeAccount.closeMyAccount' | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
