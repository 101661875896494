import {Injectable} from "@angular/core";
import {
  AuthConfigService,
  GlobalMessageService,
  RoutingService
} from "@spartacus/core";

import {UserPasswordFacade} from "@spartacus/user/profile/root";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InverseUserIdValidator} from "../../../../shared/utils/validators/InverseUserIdValidator";
import {BehaviorSubject} from "rxjs";
import {ForgotPasswordComponentService} from "@spartacus/user/profile/components";
import {CustomFormValidators} from "@spartacus/storefront";

@Injectable({providedIn: 'root'})
export class ValioForgotPasswordComponentService extends ForgotPasswordComponentService {
  private isSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isFail: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private error

  isSuccess$ = this.isSuccess.asObservable();
  isFail$ = this.isFail.asObservable();
  form: UntypedFormGroup = new UntypedFormGroup({
    userEmail: new UntypedFormControl('', {
      validators: Validators.required,
      asyncValidators: this.inverseUserIdValidator.validate.bind(this.inverseUserIdValidator)
    })
  });


  constructor(protected userPasswordService: UserPasswordFacade,
              protected routingService: RoutingService,
              protected authConfigService: AuthConfigService,
              protected globalMessage: GlobalMessageService,
              protected fb: UntypedFormBuilder,
              protected inverseUserIdValidator: InverseUserIdValidator,) {
    super(userPasswordService, routingService, authConfigService, globalMessage);
  }


  protected onError(_error: Error) {
    //super.onError(_error);
    this.busy$.next(false);
    this.isSuccess.next(false);
    this.isFail.next(true);

  }

  protected onSuccess() {
    super.onSuccess();
    this.isSuccess.next(true);
    this.isFail.next(false);
  }
}
