import {Injectable} from '@angular/core';
import {SiteContextParamsService} from "@spartacus/core";
import {ALL_PARTNERS, PARTNER_CONTEXT_ID} from "./valio-site.constants";

@Injectable()
export class ValioSiteContextParamsService extends SiteContextParamsService {

  getParamValues(param: string): string[] {
    if (param == PARTNER_CONTEXT_ID) {
      const url = window.location.href;
      return [url.split('/')[4], ALL_PARTNERS];// accept all
    }
    return super.getParamValues(param);
  }

// TODO:Spartacus - The 'getParamDefaultValue' method's signature changed to: 'getParamDefaultValue(  param: string): string | undefined'
  getParamDefaultValue(param: string): string {
    if (param == PARTNER_CONTEXT_ID) {
      return ALL_PARTNERS;
    }
// TODO:Spartacus - The 'getParamDefaultValue' method's signature changed to: 'getParamDefaultValue(  param: string): string | undefined'
    return super.getParamDefaultValue(param);
  }
}
