import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioBaseOrderBlock} from "../../../../services/cart/valio-cart.objects";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'valio-baseorder-block-modal',
    templateUrl: './valio-baseorder-block-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioBaseorderBlockModalComponent implements OnInit {
    form: UntypedFormGroup;

    @Output() callBack: EventEmitter<ValioBaseOrderBlock> = new EventEmitter();

    constructor(
        private fb: UntypedFormBuilder,
        protected launchDialogService: LaunchDialogService
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            from: [null, [Validators.required]],
            to: [null, [Validators.required]],
            message: [''],
            email: [false]
        });
    }

    cancel() {
        this.launchDialogService.closeDialog(null);
    }

    isValid(): boolean {
        return this.form.valid &&
            this.toDate(this.form.controls['to'].value).getTime() > this.toDate(this.form.controls['from'].value).getTime();
    }

    save(): void {
        if (this.form.valid) {
            const from = this.toDate(this.form.controls['from'].value);
            const to = this.toDate(this.form.controls['to'].value);


            this.launchDialogService.closeDialog({
                blockStart: from.toISOString(),
                blockEnd: to.toISOString(),
                comment: this.form.controls['message'].value,
                sendEmail: this.form.controls['email'].value
            } as ValioBaseOrderBlock);

        }
    }

    toDate(ngbDate: NgbDate): Date {
        return new Date(ngbDate.year + "-" + ngbDate.month + "-" + ngbDate.day);
    }
}
