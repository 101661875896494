<div class="close-account container-account">
  <div class="header text-center">
    <h3>{{'myaccount.closeAccount'|cxTranslate}}</h3>
    <p>{{'myaccount.closeAccountText1'|cxTranslate}}</p>
    <p>{{'myaccount.closeAccountText2'|cxTranslate}}</p>
  </div>
  <div class="btn-centered row">
    <button class="btn btn-lg btn-block-sm-down btn-close" type="submit" (click)="openConfirm()">
      {{ 'myaccount.closeAccountNow' | cxTranslate }}
    </button>
  </div>
</div>
