import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ValioCartConnector} from "./valio-cart.connector";
import {ValioCartService} from "./valio-cart.service";
import {OAuthLibWrapperService, ProductActions, RoutingService, UserIdService} from "@spartacus/core";
import {tap} from "rxjs/operators";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {any} from "codelyzer/util/function";



@Injectable()
export class ValioProductSearchEffects {
    constructor(
        protected actions$: Actions,
        protected cartConnector2: ValioCartConnector,
        //  protected store2: Store<StateWithMultiCart>,
        protected cartService: ValioCartService,
        protected routingService: RoutingService,
        protected userIdService: UserIdService,
        protected oAuthLibWrapperService: OAuthLibWrapperService,
        //  protected userProfileFacade: UserProfileFacade
    ) {
    }

    onProductSearchEnd = createEffect(
        () => this.actions$.pipe(
            ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS || ProductActions.SEARCH_PRODUCTS_FAIL),
            tap(next => {
                // Close spinner
               // openCloseSpinner(false);
            })
        ),
        { dispatch: false }
        // FeatureActions.actionOne is not dispatched
    );

}
