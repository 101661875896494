<ng-container *ngIf="(loggedIn$ | async); else login">
  <cx-page-slot position="HeaderLinks"></cx-page-slot>
</ng-container>
<ng-template #login>
  <a [routerLink]="{ cxRoute: 'register' } | cxUrl" class="btn btn-link btn-register d-none d-md-inline-block">
    {{ 'loginForm.noAccount.link' | cxTranslate }}
  </a>
  <a class="btn btn-primary btn-lg btn-square btn-icon d-inline-block d-lg-none" role="link"
     [routerLink]="{ cxRoute: 'login' } | cxUrl">
    <span class="valio-icon icon-login"></span>
  </a>
  <a class="btn btn-primary btn-lg d-none d-lg-inline-block" role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl">
    {{ 'miniLogin.signInRegister' | cxTranslate }}
  </a>

</ng-template>
