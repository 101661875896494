<ng-container *ngIf="editable;else uneditable">
  <div class="cart-wrapper shipping-address">
    <div class="row">
      <div class="col-12">
        <h3>{{'checkout.delivery.header'|cxTranslate}}</h3>
      </div>
      <ng-container *ngIf="!loggedUser">
        <div class="col-12">
          <div class="section-row">
            <div class="section-column">
              <div class="section">
                <div class="section-header">{{'checkout.delivery.selectMode'|cxTranslate}}</div>
                <div class="delivery-modes">
                  <div (click)="updateDeliveryMode(mode)"
                       *ngFor="let mode of cart.allowedDeliveryModes"
                       [ngClass]="{'selected':cart.deliveryMode?.code == mode?.code}"
                       class="delivery-mode"
                  >
                    {{mode.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-12">
        <div class="section-row d-flex">
          <div class="section-column order-lg-2">
            <div class="section">
              <ng-container *ngIf="!loggedUser">
                <valio-cx-generic-address-details
                  (afterChange)="updateAddresses()"
                  [addressForm]="soldToAddressForm"
                  [submitted]="submitted"
                  [soldTo]="true"
                  [titleKey]="'checkout.delivery.orderer'"
                ></valio-cx-generic-address-details>
              </ng-container>
              <ng-container *ngIf="loggedUser">
                <div class="address-customer">
                  <div class="address-label">{{'checkout.delivery.customer'|cxTranslate}}</div>
                  <div class="address-value">{{cart.deliveryAddress?.department}}</div>
                  <div class="address-value">{{cart.deliveryAddress?.companyName}}</div>
                </div>
                <div class="address-delivery">
                  <div class="address-label">{{'checkout.delivery.address'|cxTranslate}}</div>
                  <div class="address-value">{{cart.deliveryAddress?.formattedAddress}}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="!cart.allowedPickupPoints">
                <div class="form-check" *ngIf="!loggedUser">
                  <label>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [disabled]="false"
                      [checked]="useShipTo"
                      (change)="setUseShipTo($event.target.checked)"
                    />
                    <span class="checkmark"></span>
                    <span class="form-check-label">{{'checkout.delivery.useshipto'|cxTranslate}}</span>
                  </label>
                </div>
                <ng-container *ngIf="useShipTo && !loggedUser">
                  <valio-cx-generic-address-details
                    (afterChange)="updateAddresses()"
                    [addressForm]="shipToAddressForm"
                    [submitted]="submitted"
                    [soldTo]="false"
                    [titleKey]="'checkout.delivery.shipto'"
                  ></valio-cx-generic-address-details>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="section-column order-lg-1">
            <div class="section">
              <ng-container *ngIf="!loggedUser">
                <ng-container *ngIf="cart.allowedPickupPoints">
                  <div class="section-header">{{'checkout.delivery.location'|cxTranslate}}</div>
                  <ng-select
                    [searchable]="false"
                    [clearable]="true"
                    [closeOnSelect]="true"
                    placeholder="{{'checkout.delivery.selectPickUpLocation'|cxTranslate}}"
                    bindLabel="name"
                    bindValue="name"
                    [(ngModel)]="selectedPickupValue"
                    [items]="cart.allowedPickupPoints"
                    (change)="updatePickupLocation($event)"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{item.name}} ({{item.address?.line1}} {{item.address?.line2}}, {{item.address?.postalCode}} {{item.address?.town}})
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      {{item.name}} ({{item.address?.line1}} {{item.address?.line2}}, {{item.address?.postalCode}} {{item.address?.town}})
                    </ng-template>
                  </ng-select>
                  <div *ngIf="selectedPickupValue" class="margin-top-base">
                    <span class="pickup-dot"></span>
                    {{'checkout.delivery.pickUpTime'|cxTranslate}}
                  </div>
                  <div *ngIf="pickupPointNotSelected" class="message ERROR margin-top-base">
                    {{'checkout.delivery.pickupPointNotSelected' |cxTranslate}}
                  </div>
                </ng-container>
              </ng-container>

              <div class="section-header" [ngClass]="{'margin-top-lg':!loggedUser && cart.allowedPickupPoints}">
                <ng-container *ngIf="!loggedUser ; else loggedDeliveryDate">
                  <ng-container *ngIf="cart.allowedPickupPoints ; else anonymousDeliveryDate">
                    {{'checkout.delivery.selectPickUpDate'|cxTranslate}}
                  </ng-container>
                </ng-container>
                <ng-template #loggedDeliveryDate>
                  <ng-container *ngIf="cart.allowedPickupPoints">
                    {{'checkout.delivery.selectDate'|cxTranslate}}
                  </ng-container>
                </ng-template>
                <ng-template #anonymousDeliveryDate>
                  {{'checkout.delivery.selectDeliveryDate'|cxTranslate}}
                </ng-template>
              </div>
              <div class="bold" *ngIf="loggedUser">
                {{getDeliveryDay()|cxTranslate}} {{headerDate|valioDate:'shortDate'}}
              </div>
              <ng-container *ngIf="!loggedUser">
                <ng-container *ngIf="routeCalendar">
                  <valio-calendar-date-component
                    [routeCalendar]="routeCalendar"
                    [selectedDate]="requestedDate"
                    (update)="selectDate($event)"
                    [showActivities]="false"
                  >
                  </valio-calendar-date-component>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!cart.allowedPickupPoints">
                <div class="section-header margin-top-lg" *ngIf="!loggedUser">{{'checkout.delivery.time'|cxTranslate}}</div>
                <div class="delivery-slots">
                  <div (click)="updateDeliverySlot(deliverySlot)"
                       *ngFor="let deliverySlot of cart.availableDeliverySlots"
                       [ngClass]="{'selected':cart.deliverySlot == deliverySlot}"
                       class="delivery-slot"
                  >
                    {{'checkout.header.deliveryslot.' + deliverySlot|cxTranslate}}
                  </div>
                </div>
                <div *ngIf="deliveryslotNotSelected" class="message ERROR margin-top-base">
                  {{'checkout.header.deliveryslot.notSelected' |cxTranslate}}
                </div>
              </ng-container>

            </div>
            <div class="section" *ngIf="loggedUser">
              <div [formGroup]="contactForm">
                <div class="section-header">{{'checkout.delivery.deliver-to'|cxTranslate}}</div>
                <div class="form-group section">
                  <p class="margin-bottom-sm">{{'checkout.delivery.phone'|cxTranslate}}</p>
                  <valio-cx-input (change)="updatePhone()" idName="phone"
                                  [errorMessage]="'updateProfileForm.phoneRequired'"
                                  [isNotValidFunction]="contactForm.controls['phone'].invalid"
                                  [labelPlaceholder]="'updateProfileForm.phone.label'"
                                  [nameForm]="'phone'"
                                  [parentForm]="contactForm"
                                  class="valio-input"></valio-cx-input>
                </div>
                <div class="form-group section">
                  <p class="margin-bottom-sm">{{'checkout.delivery.email'|cxTranslate}}</p>
                  <valio-cx-input (change)="updateEmail()" idName="email"
                                  [errorMessage]="'updateProfileForm.emailRequired'"
                                  [isNotValidFunction]="contactForm.controls['email'].invalid"
                                  [labelPlaceholder]="'updateProfileForm.email.label'"
                                  [nameForm]="'email'"
                                  [parentForm]="contactForm"
                                  class="valio-input"></valio-cx-input>
                </div>
                <div class="form-group section">
                  <p class="margin-bottom-sm">{{'valioRegister.vatId.label'|cxTranslate}}</p>
                  <valio-cx-input
                    idName="vatId" (change)="updateVatId()"
                    [errorMessage]="'updateProfileForm.vatId.required'"
                    [isNotValidFunction]="contactForm.controls['vatId'].invalid"
                    [labelPlaceholder]="'valioRegister.vatId.placeholder'"
                    [nameForm]="'vatId'"
                    [parentForm]="contactForm"
                    class="valio-input"></valio-cx-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row gutter-24">
          <div class="col-12 col-md-3 col-lg-2">
            <button (click)="goNext(cart )" class="btn btn-lg btn-block btn-primary margin-bottom-md">
              {{'common.continue' | cxTranslate}}
            </button>
          </div>
          <div class="col-12 col-md-9 col-lg-10" *ngIf="!loggedUser">
            {{'checkout.delivery.payment'|cxTranslate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="cart.paymentModeGroupedEntries as groups">
    <div *ngIf="groups['INVOICE'] as group" class="cart-wrapper">
      <div class="row align-items-center">
        <div class="col-6 col-md-9 col-lg-8">
          <div class="row">
            <div class="col-12 col-md-9 col-lg-7">
              <h4>{{'cart.header.invoicetype'|cxTranslate}}</h4>
            </div>
            <div class="col-12 col-md-3 col-lg-5 products">
              {{'cart.header.products'|cxTranslate:{products: group.totalItems} }}
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3 col-lg-4 totals">
          <div class="cx-vat">{{'cart.header.alv.zero'|cxTranslate}}</div>
          <div class="cx-price">{{group.totalPrice.formattedValue}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="groups['CARD'] as group" class="cart-wrapper">
      <div class="row align-items-center">
        <div class="col-6 col-md-9 col-lg-8">
          <div class="row">
            <div class="col-12 col-md-9 col-lg-7">
              <h4>{{'cart.header.credittype'|cxTranslate}}</h4>
            </div>
            <div class="col-12 col-md-3 col-lg-5 products">
              {{'cart.header.products'|cxTranslate:{products: group.totalItems} }}
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3 col-lg-4 totals">
          <div class="cx-vat">{{'cart.header.alv.zero'|cxTranslate}}</div>
          <div class="cx-price">{{group.totalPrice.formattedValue}}</div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #uneditable>
  <div *ngIf="cart && cart.deliveryAddress" class="shipping-details-slot">
    <div class="row">
      <div class="col-12">
        <div class="shipping-details-header">{{'checkout.delivery.header'|cxTranslate}}</div>
      </div>
    </div>
    <div class="row shipping-details">
      <div class="col-12 col-lg-3 shipping-detail-item">
        <ng-container *ngIf="!loggedUser;else loggedDeliveryDate">
          <label>{{'checkout.delivery.mode'|cxTranslate}}</label>
          <div class="bold">
            <div class="bold">{{cart.deliveryMode?.name}}</div>
            <div class="bold">{{getDeliveryDay()|cxTranslate}} {{headerDate|valioDate:'shortDate'}}</div>
            <ng-container *ngIf="cart.deliveryMode?.code === 'pickup'">
              <div class="bold">{{'checkout.delivery.pickUpTimeShort'|cxTranslate}}</div>
            </ng-container>
            <ng-container *ngIf="cart.deliveryMode?.code === 'delivery'">
              <div class="bold">{{'checkout.header.deliveryslot.' + cart.deliverySlot|cxTranslate}}</div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #loggedDeliveryDate>
          <label>{{'checkout.delivery.date'|cxTranslate}}</label>
          <div class="bold">{{getDeliveryDay()|cxTranslate}} {{headerDate|valioDate:'shortDate'}}</div>
        </ng-template>
      </div>
      <div class="col-12 col-lg-3 shipping-detail-item">
        <ng-container *ngIf="!loggedUser && selectedPickupPoint;else useShipToCustomer">
          <label>{{'checkout.delivery.orderer'|cxTranslate}}</label>
          <div class="bold">{{cart.deliveryAddress.firstName}} {{cart.deliveryAddress.lastName}}</div>
          <div class="bold">{{cart.deliveryAddress.phone}}</div>
          <div class="bold">{{cart.deliveryAddress.email}}</div>
        </ng-container>
        <ng-template #useShipToCustomer>
          <ng-container *ngIf="!loggedUser && useShipTo;else loggedDeliverTo">
            <label>{{'checkout.delivery.orderer'|cxTranslate}}</label>
            <div class="bold">{{cart.paymentAddress.firstName}} {{cart.paymentAddress.lastName}}</div>
            <div class="bold">{{cart.paymentAddress.phone}}</div>
            <div class="bold">{{cart.paymentAddress.email}}</div>
          </ng-container>
        </ng-template>
        <ng-template #loggedDeliverTo>
          <label>{{'checkout.delivery.deliver-to'|cxTranslate}}</label>
          <div class="bold">{{cart.deliveryAddress.phone}}</div>
          <div class="bold">{{cart.deliveryAddress.email}}</div>
        </ng-template>
      </div>
      <div class="col-12 col-lg-3 shipping-detail-item">
        <ng-container *ngIf="!loggedUser && selectedPickupPoint;else shiptoCustomer">
          <label>{{'checkout.delivery.pickUpLocation'|cxTranslate}}</label>
          <div class="bold">{{selectedPickupPoint.name}}</div>
          <div class="bold">{{selectedPickupPoint.address?.line1}} {{selectedPickupPoint.address?.line2}}</div>
          <div class="bold">{{selectedPickupPoint.address?.postalCode}} {{selectedPickupPoint.address?.town}}</div>
        </ng-container>
        <ng-template #shiptoCustomer>
          <ng-container *ngIf="!loggedUser && useShipTo;else loggedCustomer">
            <label>{{'checkout.delivery.deliver-to'|cxTranslate}}</label>
            <div class="bold">{{cart.deliveryAddress?.companyName}}</div>
            <div class="bold">{{cart.deliveryAddress?.firstName}} {{cart.deliveryAddress?.lastName}}</div>
            <div class="bold">{{cart.deliveryAddress?.phone}}</div>
          </ng-container>
        </ng-template>
        <ng-template #loggedCustomer>
          <label>{{'checkout.delivery.customer'|cxTranslate}}</label>
          <ng-container *ngIf="user$ | async as user">
            <div *ngIf="!user.activeB2bUnit?.nonContractUnit" class="bold">{{cart.unit?.uid}}</div>
          </ng-container>
          <div class="bold">{{cart.deliveryAddress?.department}}</div>
          <div class="bold">{{cart.deliveryAddress?.companyName}}</div>
        </ng-template>
      </div>
      <div class="col-12 col-lg-3 shipping-detail-item">
        <ng-container *ngIf="!loggedUser && selectedPickupPoint;else loggedDeliveryAddress">
          <label>{{'checkout.delivery.billingAddress'|cxTranslate}}</label>
        </ng-container>
        <ng-template #loggedDeliveryAddress>
          <label>{{'checkout.delivery.address'|cxTranslate}}</label>
        </ng-template>
        <div class="bold">{{cart.deliveryAddress.line1}}</div>
        <div class="bold">{{cart.deliveryAddress.postalCode}} {{cart.deliveryAddress.town}}</div>
      </div>
    </div>
  </div>
</ng-template>
