import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {WindowRef} from "@spartacus/core";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";

@Component({
  selector: 'valio-cx-sidebar',
  templateUrl: './valio-sidebar.component.html',
})
export class ValioSideBarComponent implements OnInit {
  constructor(
    protected winRef: WindowRef,
    protected route: ActivatedRoute,
    protected launchDialogService: LaunchDialogService) {
  }

  ngOnInit(): void {
  }

  isActive(path: string): Observable<boolean> {
    return this.route.url.pipe(
      map(url => url.toString().includes(path))
    );
  }

  openNewContractPopup(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.NEW_CONTACT, null);
  }

  hideMenuOnMobile(): void {
    document.getElementById('my-account-menu').classList.remove('is-open');
    const storefront = this.winRef.document.getElementsByTagName('valio-cx-storefront')[0];
    storefront.classList.remove('is-expanded');
  }
}
