import {AfterViewInit, ChangeDetectorRef, Component, Input} from '@angular/core';
import {ProductScrollComponent} from '@spartacus/storefront';
import {ValioProductSearchPage} from "../../../../../models";
import {ValioProductListComponentService} from "../valio-product-list-component.service";
import {ValioGoogleAnalyticsService} from "../../../../../services/analytics/valio-google-analytics.service";


@Component({
  selector: 'valio-cx-product-scroll',
  templateUrl: './valio-product-scroll.component.html',
})
export class ValioProductScrollComponent extends ProductScrollComponent implements AfterViewInit {
  constructor(
    protected productListComponentService2: ValioProductListComponentService,
    protected googleAnalyticsService: ValioGoogleAnalyticsService,
    protected cdr: ChangeDetectorRef
  ) {
    super(productListComponentService2, cdr);
  }

  ngAfterViewInit(): void {
  }

  @Input('model')
  set setModel(inputModel: ValioProductSearchPage) {
    this.infiniteScrollOperations2(inputModel);
    this.addAnalyticsInformationToProducts(inputModel);
  }

  private addAnalyticsInformationToProducts(inputModel: ValioProductSearchPage) {
    if (this.model) {
      const initialIndex: number = this.model.pagination.currentPage * this.model.pagination.pageSize;
      const edited = this.model.products.map((product, index) => ({
        ...product, gaList: {
          position: index + initialIndex,
          listName: 'haku - ' /*+ this.model.freeTextSearch*/  // Search string not necessary
        }
      }));

      this.model = {
        ...inputModel,
        products: edited
      };
      // Push data to GA here. Elsewhere it will cause double push.
      this.googleAnalyticsService.createImpressionEventFromSearchPage(this.model as ValioProductSearchPage)
    }
  }

  private infiniteScrollOperations2(inputModel: ValioProductSearchPage): void {
    if (!this.isSamePage2(this.model as ValioProductSearchPage, inputModel)) {
      if (this.model && (this.model as ValioProductSearchPage).selectedDate != inputModel.selectedDate) {
        this.appendProducts = false; // date has changed, we need to reprice the search result
      }
      if (this.appendProducts) {
        this.model = {
          ...inputModel,
          products: inputModel.products//this.model.products.concat(inputModel.products),
        };
      } else {
        this.model = {
          ...inputModel,
          pagination: {
            ...inputModel.pagination
          }
        };
// TODO:Spartacus - The type of property 'maxProducts: number' changed to: 'maxProducts: number | undefined' 
// TODO:Spartacus - The type of property 'productLimit: number' changed to: 'productLimit: number | undefined' 
        this.maxProducts = this.productLimit;
      }
      this.setConditions2();
      this.cdr.markForCheck();
    }
  }

  private isSamePage2(oldModel: ValioProductSearchPage, inputModel: ValioProductSearchPage): boolean {
    if (
      !this.resetList &&
      this.model &&
      oldModel.partner == inputModel.partner &&
      oldModel.selectedDate &&
      oldModel.selectedDate == inputModel.selectedDate &&
      ((this.model.breadcrumbs &&
          inputModel.breadcrumbs &&
          this.model.breadcrumbs.length > 0 &&
          inputModel.breadcrumbs.length > 0)
        || inputModel.freeTextSearch
      )
    ) {
      if (inputModel.pagination.currentPage != 0 && this.model.pagination.currentPage == inputModel.pagination.currentPage) {
        if (this.model.freeTextSearch === inputModel.freeTextSearch) {
          if (this.model.breadcrumbs == null || this.model.breadcrumbs.length == 0) {
            return true;
          }
          if (this.model.breadcrumbs.length === inputModel.breadcrumbs.length) {
            for (let i = 0; i < this.model.breadcrumbs.length; i++) {
              if (
                this.model.breadcrumbs[i].facetCode === inputModel.breadcrumbs[i].facetCode &&
                this.model.breadcrumbs[i].facetValueCode === inputModel.breadcrumbs[i].facetValueCode &&
                (!this.model.breadcrumbs[i].removeQuery && !inputModel.breadcrumbs[i].removeQuery ||
                  this.model.breadcrumbs[i].removeQuery.query.value === inputModel.breadcrumbs[i].removeQuery.query.value) &&
                this.model.pagination.currentPage === inputModel.pagination.currentPage
              ) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  //Set booleans after model has been retrieved
  private setConditions2(): void {
    this.isEmpty = !this.model.products || this.model.products.length === 0;

    this.isLastPage =
      this.model.pagination.currentPage ===
      this.model.pagination.totalPages - 1;

    this.isMaxProducts =
// TODO:Spartacus - The type of property 'productLimit: number' changed to: 'productLimit: number | undefined' 
      this.productLimit &&
// TODO:Spartacus - The type of property 'productLimit: number' changed to: 'productLimit: number | undefined' 
      this.productLimit !== 0 &&
// TODO:Spartacus - The type of property 'maxProducts: number' changed to: 'maxProducts: number | undefined' 
      this.model.products.length >= this.maxProducts;

    //Add the productLimit to the current number of products to determine the next max number of products
    if (this.isMaxProducts) {
// TODO:Spartacus - The type of property 'maxProducts: number' changed to: 'maxProducts: number | undefined' 
// TODO:Spartacus - The type of property 'productLimit: number' changed to: 'productLimit: number | undefined' 
      this.maxProducts = this.model.products.length + this.productLimit;
    }

    //Only change viewMode once the new model is set
    //This prevents flickering issues
    if (this.viewMode !== this.inputViewMode) {
      this.viewMode = this.inputViewMode;
    }

    this.resetList = false;
    this.appendProducts = false;
  }

  loadNextPage(pageNumber: number): void {
    super.loadNextPage(pageNumber);
  }
}
