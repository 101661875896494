import {Observable} from "rxjs";
import {AbstractControl, AsyncValidator, ValidationErrors} from "@angular/forms";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {ValioOccUserAdapter} from "../../../services/user/valio-occ-user.adapter";

@Injectable({providedIn: 'root'})
export class UserIdValidator implements AsyncValidator {

  constructor(protected valioOccUserAdapter:ValioOccUserAdapter) {
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.valioOccUserAdapter.checkIfUserExists(control.value).pipe(
      map(exists => {
        return exists ? {userIdTaken: true} : null;
      })
    );
  }
}
