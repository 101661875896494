<ng-container *ngIf="data$ | async as data">
  <div class="banner-container">
    <cx-media [container]="data.media"></cx-media>
    <div [ngClass]="{
                   'text-dark' : data.textColor == 'DARK',
                   'text-white' : data.textColor == 'LIGHT'}" class="banner-content">
      <h2 class="h1" [innerHTML]="data.headText"></h2>
      <div class="row banner-boxes">
        <div class="col-md-4">
          <div class="banner-box h-100">
            <p [innerHTML]="data.textbox1"></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="banner-box h-100">
            <p [innerHTML]="data.textbox2"></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="banner-box h-100">
            <p [innerHTML]="data.textbox3"></p>
          </div>
        </div>
      </div>
      <a [innerHTML]="data.buttonName" [ngClass]="{
                   'btn-primary' : data.buttonColor == 'PRIMARY',
                   'btn-primary-inv' : data.buttonColor == 'SECONDARY',
                   'btn-link' : data.buttonColor == 'TEXT'}" class="btn btn-lg" href="{{ data.urlLink }}"></a>
    </div>
  </div>
</ng-container>
