import {Action} from "@ngrx/store";
import {Vendor} from "../../models/misc.model";

export const LOAD_VENDORS = '[User] Load Vendors';
export const LOAD_VENDORS_FAIL = '[User] Load Vendors Fail';
export const LOAD_VENDORS_SUCCESS = '[User] Load Vendors Success';

export class LoadVendors implements Action {
  readonly type = LOAD_VENDORS;
  constructor() {}
}

export class LoadVendorsFail implements Action {
  readonly type = LOAD_VENDORS_FAIL;
  constructor(public payload: any) {}
}

export class LoadVendorsSuccess implements Action {
  readonly type = LOAD_VENDORS_SUCCESS;
  constructor(public payload: Vendor[]) {}
}

export type VendorsAction =
  | LoadVendors
  | LoadVendorsFail
  | LoadVendorsSuccess;
