import {Injectable} from "@angular/core";
import {OccPostalCodeAdapter} from "./occPostalCode.adapter";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})

export class PostalCodeConnector {
  constructor(private adapter:OccPostalCodeAdapter){}

  validPostalCode(postalCode: string): Observable<boolean> {
    return this.adapter.validPostalCode(postalCode);
  }

  validAnonymousPostalCode(postalCode: string): Observable<boolean> {
    return this.adapter.validAnonymousPostalCode(postalCode);
  }
}
