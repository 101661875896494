<div
  class="{{styleClass}} btn-group"
  role="group"
  tabindex="0"
  aria-label="Add more items"
  [class.focused]="focus"
  (keydown)="onKeyDown($event)"
  (blur)="onBlur($event)"
  (focus)="onFocus($event)"
>
  <button
      #decrementBtn
      type="button"
      class="cx-counter-action btn-left"
      (click)="decrement()"
      [disabled]="cartIsLoading || value <= min"
      *ngIf="isValueChangeable && showButtons"
  >
    -
  </button>
  <button
      #incrementBtn
      type="button"
      class="cx-counter-action btn-right"
      (click)="increment()"
      [disabled]="cartIsLoading || value >= max"
      *ngIf="isValueChangeable && showButtons"
  >
    +
  </button>
  <div class="cx-counter-content">
    <input
      autocomplete="off"
      #itemCounterInput
      class="cx-counter-value"
      type="text"
      id="{{id}}"
      name="value"
      cxOnlyNumber
      (blur)="onInputBlur($event)"
      (focus)="onInputFocus($event)"
      [formControl]="inputValue"
      *ngIf="isValueChangeable"
    />
    <div class="cx-counter-unit">
      {{unit ? unit.name : ''}}
    </div>
    <div class="cx-counter-value" *ngIf="!isValueChangeable">
      {{ value }}
    </div>
  </div>
</div>
