import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {GenericLinkModule, MediaModule, OutletModule} from '@spartacus/storefront';

import {ValioCommonModule} from "../../../../shared/valio-common.module";
import {ValioBackgroundImageComponent} from "./valio-background-image.component";


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioBackgroundImageComponent: {
                    component: ValioBackgroundImageComponent,
                },
            },
        }),
        GenericLinkModule,
    ],
    declarations: [ValioBackgroundImageComponent],
    exports: [ValioBackgroundImageComponent]
})
export class ValioBackgroundImageModule  {
}
