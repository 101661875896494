import {Injectable} from "@angular/core";
import {AbstractControl, AsyncValidator, ValidationErrors} from "@angular/forms";
import {Observable} from "rxjs";
import {PostalCodeService} from "./postalCode.service";
import {catchError, map} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class AnonymousPostalCodeValidator implements AsyncValidator {
  constructor(protected postalCodeService: PostalCodeService) {

  }

  validate(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.postalCodeService.validAnonymousPostalCode(ctrl.value).pipe(
      map( valid => valid ? null : {NotValidPostalCode: true}),
      catchError(() => null)
    )
  }
}
