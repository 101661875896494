import {DIALOG_TYPE, LayoutConfig} from "@spartacus/storefront";
import {ValioRemoveOrderTemplateComponent} from "./valio-remove-order-template.component";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    REMOVE_ORDER_TEMPLATE = 'REMOVE_ORDER_TEMPLATE',
  }
}

export const valioRemoveOrderTemplateLayoutConfig: LayoutConfig = {
  launch: {
    REMOVE_ORDER_TEMPLATE: {
      inlineRoot: true,
      component: ValioRemoveOrderTemplateComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    }
  },
};
export interface ValioRemoveOrderTemplateDialogData {
  id: string;
}
