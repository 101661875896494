import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ProductScope, ProductLoadingService, ProductService, StateWithProduct} from "@spartacus/core";
import {ValioProduct} from "../../models";
import {ValioProductConnector} from "./valio-product.connector";


@Injectable()
export class ValioProductService extends ProductService {
    constructor(
        protected store: Store<StateWithProduct>,
        productLoading: ProductLoadingService,
        protected productConnector: ValioProductConnector
    ) {
        super(store, productLoading);
    }

    getTooltipProduct(productCode: string): Observable<ValioProduct> {
        return this.productConnector.get(productCode, 'toolTip');
    }

    getTooltipProductWithPrice(productCode: string): Observable<ValioProduct> {
        return this.productConnector.get(productCode);
    }

    getMany(productCodes: string[]): Observable<ValioProduct[]> {
        return this.productConnector.getManyProducts(productCodes);
    }

    get(productCode: string, scopes?: (ProductScope | string)[] | ProductScope | string): Observable<ValioProduct> {
        return super.get(productCode, scopes);//.pipe(takeUntil( new ReplaySubject(1)));
    }
}
