<ng-container *ngIf="allowedWeekDays">
  <div class="order-template-header">
    <h1>{{ 'myaccount.baseorder.title'|cxTranslate }}</h1>
    <p>{{ 'myaccount.baseorder.headertext'|cxTranslate }} <b>{{ 'myaccount.baseorder.headerbolded'|cxTranslate }}</b>
    </p>
  </div>
  <ng-container *ngIf="baseorder$|async as baseorder">
    <ng-container *ngIf="baseOrderBlocks$ |async as blocks">
      <div *ngIf="blocks && blocks.length>0" class="blocks">
        <div class="row">
          <div class="col-12">
            <div class="header">
              <div class="row">
                <div class="col-8 col-sm-10">
                  {{ 'myaccount.baseorder.block.active'|cxTranslate : {size: blocks.length} }}
                </div>
                <div class="col-4 col-sm-2 text-right">
                  <div class="link">
                    <span
                      (click)="toggleBlocks()"> {{ 'myaccount.baseorder.block.' + (showBlocks ? 'hide' : 'show')|cxTranslate }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showBlocks">
          <div class="blocks-content">
            <div class="row">
              <div class="col-6 col-sm-3">
                <div class="title">
                  {{ 'myaccount.baseorder.block.from'|cxTranslate }}
                </div>
              </div>
              <div class="col-6 col-sm-3 text-right-xs-down">
                <div class="title">
                  {{ 'myaccount.baseorder.block.to'|cxTranslate }}
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="title">
                  {{ 'myaccount.baseorder.block.message'|cxTranslate }}
                </div>
              </div>
            </div>
            <ng-container *ngFor="let block of blocks">
              <div class="row">
                <div class="col-12">
                  <div class="blocks-separator">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-3">
                  <div class="content">
                    {{ convertDate(block.blockStart)|valioDate:'shortDate' }}
                  </div>
                </div>
                <div class="col-6 col-sm-3 text-right-xs-down">
                  <div class="content">
                    {{ convertDate(block.blockEnd)|valioDate:'shortDate' }}
                  </div>
                </div>
                <div class="col-8 col-sm-4">
                  <div class="content">
                    {{ block.comment }}
                  </div>
                </div>
                <div class="col-4 col-sm-2 text-right">
                  <div class="content link">
                    <span
                      (click)="removeBlock(baseorder,block)"> {{ 'myaccount.baseorder.block.remove'|cxTranslate }} </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <div class="order-template-content cart-details-wrapper">
    <h2 class="subtitle">{{ 'myaccount.baseorder.subtitle'|cxTranslate }}</h2>

    <valio-baseorder-search
      (addToCartEmitter)="addToBaseOrder($event)"
      *ngIf="allowedWeekDays"
      [allowedWeekDays]="allowedWeekDays"
      [baseOrderMode]="true">
    </valio-baseorder-search>

    <ng-container *ngIf="baseorder$|async as baseorder">
      <valio-cart-item-message [compact]="false" [item]="baseorder">
      </valio-cart-item-message>

      <div *ngFor="let weekEntry of baseorder.entries" class="divider">
        <div class="cart-entry-block">
          <div class="date-header">
            <div class="row">
              <div class="col-8 text-bold">
                {{ 'cart.header.weekday.' + weekEntry.weekday|cxTranslate }}
              </div>
              <div class="col-2">
                {{ weekEntry.totalWeigth }} kg
              </div>
              <div class="col-2 text-bold">
                {{ 'checkout.header.products'|cxTranslate:{products: calculateItems(baseorder, weekEntry)} }}
              </div>
            </div>
          </div>

          <valio-cx-cart-item-list (updateCartEmitter)="updateBaseOrder(baseorder,$event)"
                                   [allowDelete]="true"
                                   [cartIsLoading]="false"
                                   [hidePrices]="true"
                                   [items]="getActiveItems(weekEntry)"
                                   [readOnly]="false"
          ></valio-cx-cart-item-list>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="baseorder$|async as baseorder">
      <div *ngIf="baseorder.entries.length != 0" class="valio-cart-sticky-total-bar-rows">
        <div class="row">
          <div class="col-12 col-md-5 col-lg-3 col-xl-5">
            <p><b>{{ 'myaccount.baseorder.stickyTitle' | cxTranslate }}</b></p>
          </div>
          <div class="col-12 col-md-7 col-lg-9 col-xl-7 action text-right">
            <button (click)="addBlock(baseorder)" class="btn btn-lg btn-secondary margin-bottom-sm" type="submit">
              {{ 'myaccount.baseorder.addblock' | cxTranslate }}
            </button>
            <button (click)="saveBaseOrder(baseorder)"
                    [disabled]="!enableSaveBtn"
                    [ngClass]="enableSaveBtn ? 'btn-secondary-orange' : 'btn-primary'"
                    class="btn btn-lg margin-bottom-sm margin-left-base" type="submit">
              {{ 'myaccount.baseorder.save' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
