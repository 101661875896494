import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsNewsItemComponent} from '../../../../models';
import {ValioUser, Vendor} from "../../../../models/misc.model";
import {ValioVendorService} from "../../../../services/vendor/valio-vendor.service";
import {Observable, of} from "rxjs";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {RoutingService} from "@spartacus/core";
import {DefaultUrlSerializer} from "@angular/router";
import {map} from "rxjs/operators";
import { UserAccountFacade } from '@spartacus/user/account/root';


@Component({
  selector: 'valio-cx-news-item',
  templateUrl: './valio-news-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioNewsItemComponent {

  data$: Observable<ValioCmsNewsItemComponent>;
  user$: Observable<ValioUser>;
  signedIn$: Observable<boolean>;

  constructor(
    protected componentData: CmsComponentData<ValioCmsNewsItemComponent>,
    protected vendorService: ValioVendorService,
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade,
    protected routingService: RoutingService,
  ) {
    this.data$ = this.componentData.data$;
    this.user$ = this.userAccountFacade.get().pipe(map(user => (user as ValioUser)));
    this.signedIn$ = this.userService.isSignedIn();
  }

  getPartner(partner: string): Observable<Vendor> {
    if (partner != null) {
      return this.vendorService.getVendor(partner);
    }
    return of(null);
  }

  isShown(user: ValioUser, data: ValioCmsNewsItemComponent): boolean {
    return data.partner == null || (user.activeB2bUnit && user.activeB2bUnit.partners
      .filter(partner => data.partner == partner.code)
      .filter(partner => partner.activeContract)
      .length > 0);
  }

  openLink(url: string) {
    const split = url.split('?');
    const urlParams = {};
    if (split.length == 2) {
      const params = new DefaultUrlSerializer().parse(url).queryParamMap;
      // iterate over all keys
      for (const key of params.keys) {
        if (params.getAll(key).length > 1) {
          urlParams[key] = params.getAll(key);
        } else {
          urlParams[key] = params.get(key);
        }
      }
    }
    this.routingService.go(split[0], urlParams);
  }

  convertDate(d: string) {
    return new Date(parseInt(d));
  }
}
