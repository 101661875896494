import {Component, ComponentRef, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ValioCart} from "../../../../services/cart/valio-cart.objects";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {ValioOrderTemplatesService} from "../../../../services/orderTemplates/valio-order-templates.service";
import {DateUtils} from "../../../misc/util/date-utils";
import {ValioRemoveOrderTemplateDialogData} from "../popup/valio-remove-order-template-layout.config";
import {ValioCreateOrderTemplateDialogData} from "../popup/valio-create-order-template-layout.config";

@Component({
  selector: 'valio-cx-order-template-grid',
  templateUrl: './valio-order-template-grid.component.html',
})
export class ValioOrderTemplateGridComponent implements OnInit, OnDestroy {
  orderTemplates$: Observable<ValioCart[]>;
  subscriptions: Subscription = new Subscription()

  constructor(
    protected orderTemplatesService: ValioOrderTemplatesService,
    protected launchDialogService: LaunchDialogService
  ) {
  }

  ngOnInit(): void {
    this.orderTemplates$ = this.orderTemplatesService.getOrderTemplates();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  copyTemplate(copyId, copyName) {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CREATE_ORDER_TEMPLATE, null, {
        titleKey: 'myaccount.orderTemplate.copy',
        buttonKey: 'myaccount.orderTemplate.copyButton',
        copyId: copyId,
        copyName: copyName + " (2)"
      } as ValioCreateOrderTemplateDialogData
    );

  }

  convertDate(date: string): Date {
    return DateUtils.convertDate(date);
  }

  removeTemplate(id: string) {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.REMOVE_ORDER_TEMPLATE, null, {id: id} as ValioRemoveOrderTemplateDialogData);
  }

  openCreateTemplate() {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CREATE_ORDER_TEMPLATE, null, {
      titleKey: 'myaccount.orderTemplate.new',
      buttonKey: 'myaccount.orderTemplate.newBtn'
    } as ValioCreateOrderTemplateDialogData);
  }
}
