import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';

import {StoreModule} from "@ngrx/store";
import {ValioTopSellersEffect} from "./valio-top-sellers.effect";
import {TOP_SELLERS_FEATURE} from "./valio-top-sellers.action";
import {metaReducers, reducerToken, valioTopSellersReducerProvider} from "./valio-top-sellers.reducer";


@NgModule({
  imports: [
    StoreModule.forFeature(TOP_SELLERS_FEATURE, reducerToken, {metaReducers}),
    EffectsModule.forFeature([ValioTopSellersEffect]),
  ],
  providers: [
    valioTopSellersReducerProvider
  ]
})
export class ValioTopSellerProductsModule {
}
