<div class="valio-modal modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div [formGroup]="form" class="row">
      <div class="col-12">
        <h3>{{'myaccount.baseorder.block.title'|cxTranslate}}</h3>
        <p>
          {{'myaccount.baseorder.block.text'|cxTranslate}}
        </p>
      </div>
      <div class="col-6 date-pickers">
        <valio-datepicker-input [labelPlaceholder]="'myaccount.baseorder.block.from'"
                                [nameForm]="'from'"
                                [parentForm]="form"
                                [idName]="'from'"
                                [typeInput]="'text'"
                                [customDayTemplate]="customDay"
                                class="valio-input"
        ></valio-datepicker-input>
      </div>
      <div class="col-6 date-pickers date-picker-right">
        <valio-datepicker-input [labelPlaceholder]="'myaccount.baseorder.block.to'"
                                [nameForm]="'to'"
                                [parentForm]="form"
                                [idName]="'to'"
                                [typeInput]="'text'"
                                [customDayTemplate]="customDay"
                                class="valio-input"
        >
        </valio-datepicker-input>
      </div>
      <div class="col-12">
    <textarea rows="4"
              placeholder="{{'myaccount.baseorder.block.message'|cxTranslate}}" formControlName="message"
              class="form-control"></textarea>
      </div>
      <div class="col-12">
        <div class="email-check">
          <label>
            <input type="checkbox" formControlName="email" id="emailCheck" class="form-check-input">
            <span>{{'myaccount.baseorder.block.email'|cxTranslate}}</span>
          </label>
        </div>
      </div>
      <div class="col-12">
        <div class="disclaimer">
          {{'myaccount.baseorder.block.disclaimer'|cxTranslate}}
        </div>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-secondary" type="submit" (click)="cancel()">
          {{ 'myaccount.baseorder.block.cancel' | cxTranslate }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-block btn-primary" type="submit" (click)="save()" [disabled]="!isValid()">
          {{ 'myaccount.baseorder.block.save' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Template for days -->
<ng-template #customDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled" let-selected="selected">
  <valio-datepicker-day
    [today]="today"
    [disabled]="false"
    [selected]="selected"
    [hidden]="date.month !== currentMonth"
    [hasContent]="true"
    [date]="date"
  >
  </valio-datepicker-day>
</ng-template>
