import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {LaunchDialogService} from "@spartacus/storefront";
import {openCloseSpinner} from "../../../../services/util/valio-modals-utils";

@Component({
  selector: 'valio-cx-new-contract',
  templateUrl: './valio-new-contract.component.html',
})
export class ValioNewContractComponent implements OnInit {

  submitted = false;
  emailError: boolean;
  emailSend: boolean;

  form: UntypedFormGroup = this.fb.group(
    {
      name: ['', Validators.required],
      customerNumbersGroup: this.fb.array([]),
      message: [''],
    },
    {}
  );

  constructor(protected fb: UntypedFormBuilder,
              protected userService: ValioUserService,
              protected launchDialogService: LaunchDialogService,
              protected cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  isInvalidStep2(fieldName: string): boolean {
    return this.submitted && this.form.get(fieldName).invalid;
  }

  submit(): void {
    openCloseSpinner(true);
    if (this.form.invalid) {
      this.submitted = true;
    } else {
      const sub = this.userService.sendNewContractRequest(this.form.getRawValue()).subscribe(
        ret => {
          openCloseSpinner(false);
          sub.unsubscribe();
          if (ret) {
            this.emailSend = true;
          } else {
            this.emailError = true;
          }
        }
      );
    }
  }

  cancel() {
    this.launchDialogService.closeDialog(null);
  }
}
