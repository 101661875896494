import {ChangeDetectionStrategy, Component, Input, OnInit,} from '@angular/core';
import {DateUtils} from "../../../../misc/util/date-utils";


@Component({
  selector: 'valio-date-view',
  templateUrl: './valio-date-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioDateViewComponent implements OnInit {
  @Input() date: Date;
  @Input() status: string;
  @Input() notShowDate: boolean;

  ngOnInit(): void {
  }

  getDayOfMonth(): number {
    return this.getDate().getDate();
  }

  getMonth(): number {
    return this.getDate().getMonth() + 1;
  }

  getWeekdayShort(): string {
    return 'cart.header.weekdayShort.' + (1+this.getDate().getDay());
  }

  private getDate(): Date {
    if (this.date == null || isNaN(this.date.getDay())) {
      this.date = DateUtils.getNow();
    }
    return this.date;
  }
}
