<div class="container" *ngIf="fullDeliveryNoteList$|async as fullDeliveryNoteList">
  <div class="page-header">
    <div class="page-header-title">
      <h1>{{'deliveryNotes.title'|cxTranslate}}</h1>
      <p>{{'deliveryNotes.description'|cxTranslate}}</p>
    </div>
  </div>
  <div class="delivery-note-list" *ngIf="deliveryNoteList|async as deliveryNoteList">
    <div class="date-picker-row">
      <div class="row align-items-center">
        <div class="col-12 col-sm-6" [ngClass]="!isDayView ? 'col-lg-4 col-xl-3':''">
          <valio-deliverynote-datepicker-form (searchButtonEventEmitter)="searchDate($event,true)"
                                              (resetViewEventEmitter)="resetView()"
                                              [resetViewVisible]="!isDayView"
                                              [dateList]="toDateArray(fullDeliveryNoteList)"
                                              [labelPlaceholder]="'deliveryNotes.datePicker.placeHolderFrom'"
                                              [dateId]="'dateFrom'"
                                              [selectedDate]="createNgbDate(fromDate)">
          </valio-deliverynote-datepicker-form>
        </div>

        <div class="col-12 col-sm-6" [ngClass]="!isDayView ? 'col-lg-4 col-xl-3':''">
          <valio-deliverynote-datepicker-form (searchButtonEventEmitter)="searchDate($event,false)"
                                              (resetViewEventEmitter)="resetView()"
                                              [resetViewVisible]="!isDayView"
                                              [dateList]="toDateArray(fullDeliveryNoteList)"
                                              [labelPlaceholder]="'deliveryNotes.datePicker.placeHolderTo'"
                                              [dateId]="'dateTo'"
                                              [selectedDate]="createNgbDate(toDate)">
          </valio-deliverynote-datepicker-form>
        </div>
      </div>
    </div>
    <div class="table-content">
      <ng-container *ngIf="deliveryNoteList.weekList.length != 0; else noDeliveryNotes">
        <ng-container *ngFor="let week of deliveryNoteList.weekList">
          <div class="table-header" [hidden]="isDayView">
            {{'deliveryNotes.weekRoller.week'|cxTranslate}} {{week.week}}
          </div>
          <ng-container *ngFor="let date of week.dateList">
            <ng-container *ngFor="let deliveryNote of date.deliveryNotes; last as isLast; first as isFirst">
              <valio-deliverynote-row [isLast]="isLast" [isFirst]="isFirst"
                                      [deliveryNote]="deliveryNote"
                                      [isDayView]="isDayView"
                                      (modalWindowOpenEvent)="openReclamation(deliveryNoteList,deliveryNote)">
              </valio-deliverynote-row>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #noDeliveryNotes>
        <div class="no-delivery-notes text-center">
          <ng-container *ngIf="isDayView; else noDeliveryNotesWeek">
            {{'deliveryNotes.noDeliveryNotesDay'|cxTranslate}}
          </ng-container>
        </div>
      </ng-template>
      <ng-template #noDeliveryNotesWeek>
        {{'deliveryNotes.noDeliveryNotesWeek'|cxTranslate}}
      </ng-template>
    </div>
    <div class="table-footer">
    </div>
  </div>
</div>
