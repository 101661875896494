import {NgModule} from '@angular/core';
import {MediaModule} from '@spartacus/storefront';
import {ValioProductDeliveryPeriod} from "./valio-product-delivery-period";
import {CommonModule} from "@angular/common";
import {I18nModule} from "@spartacus/core";
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";


@NgModule({
    imports: [
        MediaModule,
        CommonModule,
        I18nModule,
        ValioPipeModule
    ],
    declarations: [ValioProductDeliveryPeriod],
    exports: [ValioProductDeliveryPeriod]
})
export class ValioProductDeliveryPeriodModule {
}
