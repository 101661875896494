import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {OutletModule, MediaModule} from '@spartacus/storefront';
import {ValioCommonModule} from "../../../../shared/valio-common.module";
import {ValioProductNutritionComponent} from "./valio-product-nutrition.component";
import {ValioPipeModule} from '../../../../services/pipes/valio-pipe.module';


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        MediaModule,
        I18nModule,
        ValioCommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ProductNutritionComponent: {
                    component: ValioProductNutritionComponent,
                },
            },
        }),
        ValioPipeModule,
    ],
    declarations: [ValioProductNutritionComponent],
    exports: [ValioProductNutritionComponent]
})
export class ValioProductNutritionModule {
}
