<div class="cart-header-wrapper">
  <div class="header-info-slot col-12">
    <div class="row">
      <div class="header-block col-6 col-lg-3">
        <valio-date-view
          [date]="date"
          [status]="status"
        ></valio-date-view>
      </div>
      <div class="header-block edit-order col-6 col-lg-9 d-print-none" *ngIf="order$ | async as order">
        <a
          *ngIf="order.paymentModeGroupedEntries && getInvoiceEntry(order.paymentModeGroupedEntries)"
          [routerLink]="{ cxRoute: 'cart', params: date } | cxUrl">
          {{ 'order.header.editItems' | cxTranslate }}
        </a>
      </div>
    </div>
  </div>
</div>
