import {ChangeDetectionStrategy, Component,} from '@angular/core';
import {CloseAccountModalComponent} from "@spartacus/user/profile/components";

@Component({
  selector: 'valio-cx-close-account-modal',
  templateUrl: './valio-close-account-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioCloseAccountModalComponent extends CloseAccountModalComponent {
  dismissModal(): void {
    this.launchDialogService.closeDialog(null);
  }
}
