import {CarouselService} from '@spartacus/storefront';
import {WindowRef} from "@spartacus/core";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ValioCarouselService extends CarouselService {
  constructor(protected winRef2: WindowRef) {
    super(winRef2);
  }

}
