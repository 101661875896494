import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {VALIO_SET_SITE_PARTNER, ValioSetSitePartner, ValioSetSitePartnerSuccessful} from "./valio-partner-site.action";



@Injectable()
export class ValioPartnerSiteEffects {
    constructor(
        private actions$: Actions
    ) {
    }

    activatePartner$: Observable<ValioSetSitePartnerSuccessful> = createEffect(() => this.actions$.pipe(
            ofType(VALIO_SET_SITE_PARTNER),
            concatMap((action: ValioSetSitePartner) => [
                    new ValioSetSitePartnerSuccessful(action.code)
                ]
            )
        )
    );
}
