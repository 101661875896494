import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ValioCategoryDataService {
  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();

  switchCategory(code: string) {
    this.messageSource.next(code);
    this.messageSource.complete();
  }
}
