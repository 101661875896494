<div class="cx-date">
  <span class="day">{{getDayOfMonth()}}</span>
  <span class="supsub">
    <sub class="status" *ngIf="!status && !notShowDate">
      {{'cart.cartItems.status.deliveryDay'|cxTranslate}}
    </sub>
    <sub class="month">
      {{getWeekdayShort()|cxTranslate}} {{notShowDate ? '' : getDayOfMonth() + "." + getMonth() + "."}}
    </sub>
    <sub class="status" *ngIf="status">
      <ng-container *ngIf="status == 'open'">
        <div class="cart-item-status">
          <span class="dot open"></span>
          {{('cart.cartItems.status.waiting')|cxTranslate}}
        </div>
      </ng-container>
      <ng-container *ngIf="status != 'open'">
        {{('cart.cartItems.status.' + status)|cxTranslate}}
      </ng-container>
    </sub>
  </span>
</div>
