import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import {ValioBaseorderBlockModalComponent} from "./valio-baseorder-block-modal.component";
declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        BASE_ORDER_BLOCK_MODAL = 'BASE_ORDER_BLOCK_MODAL',
    }
}
export const valioBaseOrderBlockModalLayoutConfig: LayoutConfig = {
    launch: {
        BASE_ORDER_BLOCK_MODAL: {
            inlineRoot: true,
            component: ValioBaseorderBlockModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};
