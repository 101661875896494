<div class="main-navigation">
  <ng-container *ngIf="routeCalendar$|async as routeCalendar">
    <ng-container *ngIf="requestedDate$|async as requestedDate">
      <div id="toggleCalendar" class="header-info-slot" [ngClass]="{'collapsed':isCalendarShown}">
        <div class="row">
          <div class="header-block col-12" (click)="showHideCalendar(false)">
            <valio-date-view
              [date]="requestedDate">
            </valio-date-view>
            <div class="valio-icon icon-inline icon-chevron icon-chevron-white icon-chevron-down"></div>
          </div>
        </div>
        <div class="popupCalendar" *ngIf="isCalendarShown">
          <valio-calendar-date-component
            [routeCalendar]="routeCalendar"
            [selectedDate]="requestedDate"
            (update)="selectDate($event)"
            [showActivities]="false"
          >
          </valio-calendar-date-component>
        </div>
      </div>

    </ng-container>
  </ng-container>
  <div class="main-navigation-slot">
    <valio-cx-navigation-ui
      [node]="productsNavigationNode$ | async"
      [mainNavigation]="true"
      [mainNavigationProducts]="true"
    ></valio-cx-navigation-ui>
  </div>
  <div class="main-navigation-slot">
    <valio-cx-navigation-ui
      [node]="vendorsNavigationNode$ | async"
      [mainNavigation]="true"
      [mainNavigationVendors]="true"
    ></valio-cx-navigation-ui>
  </div>

  <div class="main-navigation-slot float-right" *ngIf="false">
    <!--valio-cx-global-searchbox
      [globalSearch]="true"
      [limit]="5"
    ></valio-cx-global-searchbox-->
  </div>
</div>
