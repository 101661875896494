import {ProductSearchPage, StateUtils} from "@spartacus/core";
import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";

export const LAST_PURCHASES_FEATURE = 'lastPurchases';

const getLastPurchasesContentSelector = (state: ValioLastPurchasesState) => {
  return state.content;
};

export const getLastPurchasessState: MemoizedSelector<ValioStateWithLastPurchases,
  ValioLastPurchasessState> = createFeatureSelector<ValioLastPurchasessState>(LAST_PURCHASES_FEATURE);

export const getActiveLastPurchasesState: MemoizedSelector<ValioStateWithLastPurchases,
  StateUtils.LoaderState<ValioLastPurchasesState>> = createSelector(
  getLastPurchasessState,
  (cartsState: ValioLastPurchasessState) => cartsState.active
);

export const getLastPurchasesState: MemoizedSelector<ValioStateWithLastPurchases,
  ValioLastPurchasesState> = createSelector(
  getActiveLastPurchasesState,
  state => StateUtils.loaderValueSelector(state)
);

export const getLastPurchasesContent: MemoizedSelector<ValioStateWithLastPurchases,
  ProductSearchPage> = createSelector(
  getLastPurchasesState,
  getLastPurchasesContentSelector
);

export interface ValioStateWithLastPurchases {
  [LAST_PURCHASES_FEATURE]: ValioLastPurchasesState;
}

export interface ValioLastPurchasessState {
  active: StateUtils.LoaderState<ValioLastPurchasesState>;
}

export interface ValioLastPurchasesState {
  content: ProductSearchPage;
}

export const LAST_PURCHASES_DATA = '[LastPurchases] Data';
export const LOAD_LAST_PURCHASES = '[LastPurchases] Load last purchases';

export class ValioLoadLastPurchases extends StateUtils.LoaderLoadAction {
  readonly type = LOAD_LAST_PURCHASES;

  constructor() {
    super(LAST_PURCHASES_DATA);
  }
}

export const LOAD_LAST_PURCHASES_SUCCESS = '[LastPurchases] Load Success';

export class ValioLoadLastPurchasesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = LOAD_LAST_PURCHASES_SUCCESS;

  constructor(public products: ProductSearchPage) {
    super(LAST_PURCHASES_DATA);
  }
}

export const LOAD_LAST_PURCHASES_FAIL = '[LastPurchases] Load last purchases failed';

export class ValioLoadLastPurchasesFail extends StateUtils.LoaderFailAction {
  readonly type = LOAD_LAST_PURCHASES_FAIL;

  constructor() {
    super(LAST_PURCHASES_DATA);
  }
}
