<div class="input-group">
  <div [formGroup]="parentForm" class="input-label"
       [class.is-invalid]="isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)"
       [ngClass]="hasValue() ? 'has-value' : ''">
    <label for="{{idName}}" class="text-left" [ngClass]="hasValue() ? 'has-value' : ''">
      <span class="label-content">{{labelPlaceholder | cxTranslate}}</span>
    </label>
    <input
      type="{{typeInput ? typeInput: 'text'}}"
      class="form-control"
      name="{{nameForm}}"
      placeholder="{{labelPlaceholder | cxTranslate}}"
      formControlName="{{nameForm}}"
      id="{{idName}}"
      (navigate)="navigate($event)"
      (ngModelChange)="onChange($event)"
      ngbDatepicker #d="ngbDatepicker"
      [dayTemplate]="customDayTemplate"
      value="{{getFormattedDate()}}"
    />
  </div>
  <div class="input-group-append">
    <button class="btn btn-lg btn-secondary btn-icon btn-calendar valio-icon icon-calendar" type="button"
            (click)="d.toggle();fixWeekHeader()">
    </button>
  </div>
</div>

<div class="invalid-feedback d-block text-right" *ngIf="(isNotValidFunction ? isNotValidFunction :isNotValid(nameForm)) && errorMessage">
  <span>{{ errorMessage| cxTranslate}}</span>
</div>
<div class="extra-info text-right" *ngIf="extraInfoBottom">
  {{extraInfoBottom| cxTranslate}}
</div>
