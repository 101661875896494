import {NgModule} from "@angular/core";
import {VENDOR_FEATURE} from "./valio-vendor-state";
import {metaReducers, reducer} from "./valio-vendor.reducer";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {VendorEffects} from "./valio-vendor.effect";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(VENDOR_FEATURE, reducer, {metaReducers}),
    EffectsModule.forFeature([VendorEffects]),
  ]
})
export class ValioVendorModule {
}
