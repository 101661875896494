<div class="valio-modal modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header cx-dialog-header">
      <h3 class="modal-title">
        {{ titleKey | cxTranslate }}
      </h3>
    </div>

    <div class="valio-input">
      <div class="input-label"
           [ngClass]="hasValue() ? 'has-value' : ''">
        <label for="name" class="text-left" [ngClass]="hasValue() ? 'has-value' : ''">
          <span class="label-content">{{'myaccount.orderTemplate.name' | cxTranslate}}</span>
        </label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="name"
          placeholder="{{ copyName }}"
          id="name"
        >
      </div>
    </div>

    <div class="margin-top-lg">
      <button
        type="button"
        class="btn btn-lg btn-secondary margin-right-sm"
        (click)="dismissModal()"
      >
        {{ 'common.cancel' | cxTranslate }}
      </button>

      <button
        type="button"
        class="btn btn-lg btn-primary margin-left-sm"
        aria-label="create"
        (click)="create()"
      >
        {{ buttonKey | cxTranslate }}
      </button>
    </div>
  </div>
</div>
