import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {Vendor} from "../../../../../models/misc.model";
import {ValioVendorService} from "../../../../../services/vendor/valio-vendor.service";


@Component({
  selector: 'valio-cx-customer-number',
  templateUrl: './valio-customer-number.component.html'
})
export class ValioCustomerNumberComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() submitted = false;

  vendors$: Observable<Vendor[]>;

  constructor(
    protected vendorService: ValioVendorService,
    protected fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    const cng = this.form.controls.customerNumbersGroup as UntypedFormArray;
    if (cng.length == 0) {
      this.addCustomerNumber();
    }
    this.vendors$ = this.vendorService.getActiveVendors();
  }

  getCustomerNumbersControl() {
    let customerNumberGroup = <UntypedFormArray>this.form.controls['customerNumbersGroup'];
    return customerNumberGroup.controls;
  }

  removeCustomerNumber(idx: number) {
    let cng = this.form.controls.customerNumbersGroup as UntypedFormArray;
    cng.removeAt(idx);
  }

  addCustomerNumber() {
    const cng = this.form.controls.customerNumbersGroup as UntypedFormArray;
    cng.push(this.createCustomersNumberGroup());
  }


  createCustomersNumberGroup() {
    return this.fb.group(
      {
        vendor: ['', Validators.required],
        customerNumber: ['', Validators.required]
      }
    )
  }

  isInvalidStep2FormArray(formArray, idx, fieldName): boolean {
    let formArrayControl = <UntypedFormArray>this.form.get(formArray);
    let formGroup = <UntypedFormGroup>formArrayControl.at(idx);
    return this.submitted && formGroup.controls[fieldName].invalid;
  }

  hasValue(formArray, idx, fieldName): boolean {
    let formArrayControl = <UntypedFormArray>this.form.get(formArray);
    let formGroup = <UntypedFormGroup>formArrayControl.at(idx);
    return formGroup.controls[fieldName].value !== '';
  }

  setFocusToParent($event: MouseEvent) {
    const parent = (<HTMLElement>$event.target).closest('.input-label');
    parent.classList.add('has-value');
  }

  removeFocusToParent($event: MouseEvent, formArray, idx, fieldName) {
    if (!this.hasValue(formArray, idx, fieldName)) {
      const parent = (<HTMLElement>$event.target).closest('.input-label');
      parent.classList.remove('has-value');
    }
  }
}
