<ng-container *ngIf="viewMode === ViewModes.Grid">
  <div
    infiniteScroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="150"
    [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty"
  >
    <div class="row">
      <valio-cx-product-grid-item
        *ngFor="let product of model?.products"
        [product]="product"
        [listName]="'googleTagManager.productList.search.name' | cxTranslate"
        class="col-"
      ></valio-cx-product-grid-item>
    </div>
    <div class="text-center pagination-pages"
         *ngIf="model?.pagination?.totalPages > 0">
      <a *ngIf="model?.pagination?.currentPage > 0"
         (click)="loadNextPage(model?.pagination?.currentPage - 1)"
         class="pagination-link">
        <span class="valio-icon icon-inline icon-chevron icon-chevron-left margin-right-sm"></span>
        {{ 'productList.backToTopBtn' | cxTranslate }}
      </a>
      <span>{{model?.pagination?.currentPage + 1}} / {{model?.pagination?.totalPages}}</span>
      <a *ngIf="!isLastPage"
         (click)="loadNextPage(model?.pagination?.currentPage + 1)"
         class="pagination-link">
        {{ 'productList.showMoreBtn' | cxTranslate }}
        <span class="valio-icon icon-inline icon-chevron icon-chevron-right margin-left-sm"></span>
      </a>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewMode === ViewModes.List">
  <div
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty"
  >
    <valio-cx-product-list-item
      *ngFor="let product of model?.products"
      [product]="product"
      class="cx-product-search-list"
    >
    </valio-cx-product-list-item>
    <div class="text-center pagination-pages">
      <button *ngIf="(isMaxProducts || isLastPage) && model?.pagination?.currentPage > 0"
              (click)="scrollToTop()"
              class="btn btn-primary">
        {{ 'productList.backToTopBtn' | cxTranslate }}
      </button>
      <button *ngIf="isMaxProducts && !isLastPage"
              (click)="loadNextPage(model?.pagination?.currentPage + 1)"
              class="btn btn-primary margin-bottom-xl">
        {{ 'productList.showMoreBtn' | cxTranslate }}
      </button>
    </div>
    <div *ngIf="appendProducts" class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </div>
</ng-container>
