import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard, CmsConfig, ConfigModule, I18nModule, UrlModule,} from '@spartacus/core';
import {CmsPageGuard, MediaModule, PageLayoutComponent, SpinnerModule} from "@spartacus/storefront";
import {RouterModule} from "@angular/router";
import {ValioOrderTemplateContentComponent} from "./valio-order-template-content.component";
import {ValioCartSharedModule} from "../../cart/cart-shared/valio-cart-shared.module";
import {ValioOrderTemplateListComponent} from "../sidebar/valio-order-template-list.component";
import {ValioOrderTemplateModule} from "../valio-order-template.module";
import {ValioCartDetailsModule} from "../../cart/cart-detail/valio-cart-details.module";
import {ValioCalendarModule} from "../../../calendar/valio-calendar.module";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'my-account/modify-order-template/:orderTemplateCode',
                canActivate: [CmsPageGuard],
                component: PageLayoutComponent,
                data: { pageLabel: 'modify-order-templates' },
            },
            {
                path: null,
               canActivate: [AuthGuard, CmsPageGuard],
                component: PageLayoutComponent,
                data: { cxRoute: 'modifyOrderTemplate' },
            },
        ]),
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ModifyOrderTemplateComponent: {
                    component: ValioOrderTemplateContentComponent,
                    guards: [AuthGuard]
                },
                OrderTemplateListComponent: {
                    component: ValioOrderTemplateListComponent,
                    guards: [AuthGuard],
                }
            },
        }),
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule,
        I18nModule,
        RouterModule,
        ValioCartSharedModule,
        MediaModule,
        UrlModule,
        ValioOrderTemplateModule,
        ValioCartDetailsModule,
        ValioCalendarModule,
    ],
    declarations: [ValioOrderTemplateContentComponent],
    exports: [ValioOrderTemplateContentComponent]
})
export class ValioOrderTemplateContentModule {

}
