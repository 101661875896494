import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { ValioHamburgerMenuComponent } from './valio-hamburger-menu.component';
@NgModule({
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                HamburgerMenuComponent: {
                    component: ValioHamburgerMenuComponent,
                },
            },
        }),
    ],
    declarations: [ValioHamburgerMenuComponent],
    exports: [ValioHamburgerMenuComponent]
})
export class ValioHamburgerMenuModule {}
