import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ValioCart, ValioCartEntry} from "../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../services/cart/valio-cart.service";

@Component({
  selector: 'valio-checkout-details',
  templateUrl: './valio-checkout-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioCheckoutDetailsComponent implements OnInit {
  cart$: Observable<ValioCart>;
  entries$: Observable<ValioCartEntry[]>;
  cartLoaded$: Observable<boolean>;

  constructor(protected cartService: ValioCartService) {
  }

  ngOnInit() {
    this.cart$ = this.cartService.getActive();
    this.cartLoaded$ = this.cartService.getLoaded();
  }

  updateCart(data: any): void {
    this.cartService.updateEntryData(data.item.entryNumber, data.data);
  }
}
