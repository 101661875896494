<div class="container">
  <div class="cx-order-items" *ngIf="order$ | async as order">
    <div class="order-summary-block col-12 d-print-none">
      <div class="row">
        <div class="price-summary col-12 col-lg-6" *ngIf="false"><!-- hidden for now -->
          <div class="price-summary-header">
            <h3>{{'order.summary.pricesHeader'|cxTranslate}}</h3>
          </div>
          <div *ngIf="order.paymentModeGroupedEntries && getInvoiceEntry(order.paymentModeGroupedEntries)"
               class="payment-info-section">
            <div class="payment-info-item">
              <span class="label">{{'cart.header.invoicetype'|cxTranslate}}</span>
              <span
                class="value cx-right">{{getInvoiceEntry(order.paymentModeGroupedEntries).totalItems}} {{'order.summary.products'|cxTranslate}}</span>
            </div>
            <div class="payment-info-item">
              <span class="label">{{'cart.header.alv.zero'|cxTranslate}}</span>
              <span
                class="value cx-right">{{getInvoiceEntry(order.paymentModeGroupedEntries).totalPrice.formattedValue}}</span>
            </div>
            <ng-container *ngFor="let tax of getInvoiceEntry(order.paymentModeGroupedEntries).taxes">
              <div class="payment-info-item">
                <span class="label">{{'cart.header.alv.vat'|cxTranslate}} {{tax.percentage}} %</span>
                <span class="value cx-right">{{tax.value.formattedValue}}</span></div>
            </ng-container>
            <div class="payment-info-item">
              <span class="label bold">{{'cart.header.alv.incl'|cxTranslate}}</span>
              <span
                class="value cx-right bold">{{getInvoiceEntry(order.paymentModeGroupedEntries).totalPriceWithTax.formattedValue}}</span>
            </div>
          </div>
          <div *ngIf="order.paymentModeGroupedEntries && getCardEntry(order.paymentModeGroupedEntries)"
               class="payment-info-section">
            <div class="payment-info-item">
              <span class="label">{{'cart.header.credittype'|cxTranslate}}</span>
              <span class="value cx-right">{{getCardEntry(order.paymentModeGroupedEntries).totalItems}}</span></div>
            <div class="payment-info-item">
              <span class="label">{{'cart.header.alv.zero'|cxTranslate}}</span>
              <span
                class="value cx-right">{{getCardEntry(order.paymentModeGroupedEntries).totalPrice.formattedValue}}</span>
            </div>
            <ng-container *ngFor="let tax of getCardEntry(order.paymentModeGroupedEntries).taxes">
              <div class="payment-info-item">
                <span class="label">{{'cart.header.alv.vat'|cxTranslate}} {{tax.percentage}} %</span>
                <span class="value cx-right">{{tax.value.formattedValue}}</span></div>
            </ng-container>
            <div class="payment-info-item">
              <span class="label bold">{{'cart.header.alv.incl'|cxTranslate}}</span>
              <span
                class="value cx-right bold">{{getCardEntry(order.paymentModeGroupedEntries).totalPriceWithTax.formattedValue}}</span>
            </div>
          </div>
          <div class="price-summary-total">
            <div class="row" *ngIf="order.totalPrice">
              <div class="col-6">
                <span class="bold">{{'order.summary.total'|cxTranslate}}</span>
              </div>
              <div class="col-6 cx-right">
                <div class="row">
                  <div class="col-6">{{'cart.header.alv.zero'|cxTranslate}}</div>
                  <div class="bold col-6">{{order.totalPrice.formattedValue}}</div>
                </div>
                <div class="row">
                  <div class="col-6">{{'cart.header.alv.incl'|cxTranslate}}</div>
                  <div class="bold col-6">{{order.totalPriceWithTax.formattedValue}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="loggedUser">

          <div class="open-orders-summary col-12" *ngIf="cart$|async as cart">
            <div class="row align-items-center">
              <div class="orders-info-section left d-none d-lg-block col-lg-6" *ngIf="isCartUnapproved(cart)">
                <h3>{{'order.summary.openOrdersHeader'|cxTranslate}}</h3>
                <p>{{'order.summary.openOrdersText'|cxTranslate}}</p>
                <button
                  (click)="goToCart(cart)"
                  class="btn btn-lg btn-primary"
                  type="button">
                  {{'order.summary.confirmBtn'|cxTranslate}}
                </button>
              </div>

              <div class="orders-info-section"
                   [ngClass]="{'right col-12 col-lg-6':isCartUnapproved(cart),'col-12':!isCartUnapproved(cart)}">
                <h3>{{'order.summary.newHeader'|cxTranslate}}</h3>
                <p>{{'order.summary.newText'|cxTranslate}}</p>
                <button
                  (click)="goToHomePage()"
                  class="btn btn-lg btn-primary"
                  type="button">
                  {{'order.summary.dateBtn'|cxTranslate}}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!loggedUser">
          <div class="open-orders-summary col-12">
            <div class="row align-items-center">
              <ng-container *ngIf="order.deliveryMode?.code === 'pickup'">
                <div class="orders-info-section col-12">
                  <h3>{{'checkout.delivery.pickUpLocation'|cxTranslate}}</h3>
                  <p>
                    {{order.selectedPickupPoint?.name}}<br/>
                    {{order.selectedPickupPoint?.address?.line1}} {{order.selectedPickupPoint?.address?.line2}}<br/>
                    {{order.selectedPickupPoint?.address?.postalCode}} {{order.selectedPickupPoint?.address?.town}}<br/><br/>
                    <span class="pickup-dot"></span> {{'checkout.delivery.pickUpTime'|cxTranslate}}
                  </p>
                  <h3>{{'checkout.delivery.pickUpDate'|cxTranslate}}</h3>
                  <p>{{getDeliveryDay(order.requestedDate)|cxTranslate}} {{order.requestedDate|valioDate:'d.M.'}}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col-12">
      <div *ngIf="user$ | async as user" class="print-customer-info d-none d-print-block">
        <ng-container
          *ngIf="user.activeB2bUnit; then user.activeB2bUnit?.nonContractUnit ? activeNonContractB2BUnitHeading : activeB2BUnitHeading; else noB2BUnitHeading"></ng-container>

        <ng-template #activeNonContractB2BUnitHeading>
          {{user.firstName}} {{user.lastName}}
        </ng-template>
        <ng-template #activeB2BUnitHeading>
          <div>{{'valioRegister.customerNumber.label' | cxTranslate}} {{user.activeB2bUnit.uid}}</div>
          <div>{{user.activeB2bUnit.name}}</div>
          <div>{{user.activeB2bUnit.shippingAddress.formattedAddress}}</div>
        </ng-template>
        <ng-template #noB2BUnitHeading>
          {{'valioRegister.guest'|cxTranslate}}
        </ng-template>
      </div>
      <div class="row">
        <h3 class="cx-order-items-header">
          {{'order.header.orderItems' | cxTranslate}}
        </h3>
      </div>
    </div>

    <valio-order-header
      [date]="convertDate(order.requestedDate)"
      [status]="order.status"
    ></valio-order-header>

    <div class="cart-details-wrapper" *ngIf="order.paymentModeGroupedEntries">
      <div class="cart-details-section" *ngIf="getInvoiceEntry(order.paymentModeGroupedEntries)">
        <valio-cx-review-submit-title
          [items]="getInvoiceEntry(order.paymentModeGroupedEntries).totalItems"
          [priceWithTax]="getInvoiceEntry(order.paymentModeGroupedEntries).totalPriceWithTax"
          [priceWithoutTax]="getInvoiceEntry(order.paymentModeGroupedEntries).totalPrice"
          [showButton]="false"
          [title]="'cart.header.paidInvoice'"
          [buttonEnabled]="false"
        ></valio-cx-review-submit-title>
        <div class="cart-entry-block col-12"
             *ngFor="let partnerEntry of getInvoiceEntry(order.paymentModeGroupedEntries).entries">
          <valio-cx-cart-details-rows
            [isReadOnly]="true"
            [allowDelete]="false"
            [partnerEntry]="partnerEntry"
            [cartLoaded]="true"
          ></valio-cx-cart-details-rows>
        </div>
      </div>

      <div class="cart-details-section" *ngIf="getCardEntry(order.paymentModeGroupedEntries)">
        <valio-cx-review-submit-title
          [items]="getCardEntry(order.paymentModeGroupedEntries).totalItems"
          [priceWithTax]="getCardEntry(order.paymentModeGroupedEntries).totalPriceWithTax"
          [priceWithoutTax]="getCardEntry(order.paymentModeGroupedEntries).totalPrice"
          [showButton]="false"
          [title]="'cart.header.paidCredit'"
          [buttonEnabled]="false"
        ></valio-cx-review-submit-title>
        <div class="cart-entry-block col-12"
             *ngFor="let partnerEntry of getCardEntry(order.paymentModeGroupedEntries).entries">
          <valio-cx-cart-details-rows
            [isReadOnly]="true"
            [allowDelete]="false"
            [partnerEntry]="partnerEntry"
            [cartLoaded]="true"
          ></valio-cx-cart-details-rows>
        </div>
      </div>

      <div class="cart-details-section">
        <div class="col-12 cart-entry-totals">
          <valio-price-summary-component
            [cart]="order"
            [totalPriceWithoutTax]="order.totalPriceWithoutTax"
            [subtotal]="order.subtotal"
            [freightCharge]="order.freightCharge"
            [variableWeightPaymentCost]="order.variableWeightPaymentCost"
          ></valio-price-summary-component>
        </div>
      </div>
    </div>
  </div>
</div>
