import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CartActions} from "@spartacus/cart/base/core";
import {from, Observable} from "rxjs";
import {catchError, concatMap, map} from "rxjs/operators";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {ValioCartEntryConnector} from "./valio-cart-entry.connector";
import {ValioCartModification} from "./valio-cart.objects";
import {CSV_IMPORT, CSV_IMPORT_SUCCESS, ValioCsvImport, ValioCsvImportSuccess} from "./valio-csv-import.action";

@Injectable()
export class ValioCsvImportEffects {

    constructor(
        protected actions2$: Actions,
        protected cartEntryConnector: ValioCartEntryConnector
    ) {
    }


    csvAddEntry$: Observable<ValioCsvImportSuccess | CartActions.CartAddEntryFail> = createEffect(() => this.actions2$.pipe(
            ofType(CSV_IMPORT),
            map((action: ValioCsvImport) => action),
            concatMap(action => {
                    return this.cartEntryConnector.csvAddEntries(
                        action.userId,
                        action.cartId,
                        action.payload
                    ).pipe(concatMap((cartModification: ValioCartModification[]) => {
                                return [new ValioCsvImportSuccess({
                                    userId: action.userId,
                                    cartId: action.cartId,
                                    cartModification: cartModification
                                })];
                            }
                        ),
                        catchError(error => from([new CartActions.CartAddEntryFail(makeErrorSerializable(error))])
                        )
                    )
                }
            )
        )
    );

    importSuccess$: Observable<CartActions.LoadCart> = createEffect(() => this.actions2$.pipe(
            ofType(CSV_IMPORT_SUCCESS),
            map((action: ValioCsvImportSuccess) => action.payload),
            map(payload =>
                new CartActions.LoadCart({userId: payload.userId, cartId: payload.cartId, extraData: {active: true}})
            )
        )
    );
}

