import {DIALOG_TYPE, LayoutConfig} from "@spartacus/storefront";
import {ValioCreateOrderTemplateComponent} from "./valio-create-order-template.component";

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        CREATE_ORDER_TEMPLATE = 'CREATE_ORDER_TEMPLATE',
    }
}


export const valioCreateOrderTemplateLayoutConfig: LayoutConfig = {
    launch: {
        CREATE_ORDER_TEMPLATE: {
            inlineRoot: true,
            component: ValioCreateOrderTemplateComponent,
            dialogType: DIALOG_TYPE.DIALOG,

        },
    }
};

export interface ValioCreateOrderTemplateDialogData {
    titleKey: string;
    buttonKey: string;
    copyId: string;
    copyName: string;
    copyDate: string;
}
