<div [formGroup]="form">
  <div class="form-group" formArrayName="customerNumbersGroup" *ngFor="let customerNumber of getCustomerNumbersControl(); let i=index">
    <div [formGroupName]="i" class="row">
      <div class="col-12 col-sm-6">
        <div class="valio-input">
          <div class="input-label"
               [class.is-invalid]="isInvalidStep2FormArray('customerNumbersGroup', i, 'customerNumber')"
               [ngClass]="hasValue('customerNumbersGroup', i, 'customerNumber') ? 'has-value' : ''">
            <label for="customerNumber" class="text-left" [ngClass]="hasValue('customerNumbersGroup', i, 'customerNumber') ? 'has-value' : ''">
              <span class="label-content">{{'valioRegister.customerNumber.placeholder' | cxTranslate}}</span>
            </label>
            <input
              class="form-control"
              type="text"
              name="customerNumber"
              placeholder="{{'valioRegister.customerNumber.placeholder' | cxTranslate}}"
              formControlName="customerNumber"
              id="customerNumber"
              (focus)="setFocusToParent($event)"
              (blur)="removeFocusToParent($event,'customerNumbersGroup', i, 'customerNumber')"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="vendor">
          <ng-select
            [class.is-invalid]="isInvalidStep2FormArray('customerNumbersGroup', i, 'vendor')"
            formControlName="vendor"
            [searchable]="true"
            [clearable]="false"
            [items]="vendors$ | async"
            bindLabel="name"
            bindValue="code"
            placeholder="{{ 'valioRegister.vendor.selectOne' | cxTranslate }}"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-12">
        <a class="remove margin-bottom-base" (click)="removeCustomerNumber(i)" *ngIf="i > 0">{{'valioRegister.customerNumber.remove' | cxTranslate}}</a>
      </div>
    </div>
  </div>
</div>

<div class="add-another">
  <a (click)="addCustomerNumber()">{{'valioRegister.header.addanother' | cxTranslate}}</a>
</div>
