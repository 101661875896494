import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {CurrentProductService, ProductDetailOutlets} from '@spartacus/storefront';
import {combineLatest, Observable, Subject} from "rxjs";
import {Breadcrumb, ValioProduct} from "../../../../models";
import {map, takeUntil} from "rxjs/operators";
import {TranslationService} from "@spartacus/core";
import {DateUtils} from "../../../misc/util/date-utils";

@Component({
  selector: 'valio-cx-product-summary',
  templateUrl: './valio-product-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioProductSummaryComponent implements OnDestroy {
  outlets = ProductDetailOutlets;
  product$: Observable<ValioProduct>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  breadCrumbs$: Observable<Breadcrumb[]>;
  currentDay: Date;

  constructor(protected translation: TranslationService, protected currentProductService: CurrentProductService) {
    this.product$ = this.currentProductService.getProduct().pipe(takeUntil(this.destroy$));
    this.breadCrumbs$ = this.resolveBreadcrumbs();
  }

  convertDate(date: string) {
    return DateUtils.convertDate(date);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  resolveBreadcrumbs(): Observable<Breadcrumb[]> {
    return combineLatest([
      this.product$.pipe(),
      this.translation.translate('common.home'),
    ]).pipe(
      map(([p, label]: [ValioProduct, string]) => {
        const breadcrumbs = [];
        breadcrumbs.push({facetValueName: p.name, facetValueCode: p.code});
        for (const {name, code} of p.categories || []) {
          breadcrumbs.push({
            facetValueName: name,
            facetValueCode: code,
          });
        }
        return breadcrumbs.reverse();
      })
    );
  }

  showDeliveryPeriod(product: ValioProduct): boolean {
    this.currentDay = DateUtils.getNow();
    this.currentDay.setHours(0, 0, 0, 0);
    if ((product.deliveryPeriodFrom && new Date(product.deliveryPeriodFrom) > this.currentDay) || (product.deliveryPeriodTo && new Date(product.deliveryPeriodTo) > this.currentDay)) {
      return true;
    }
  }
}
