import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule} from '@spartacus/core';
import {ValioFooterLogosComponent} from './valio-footer-logos.component';
import {RouterModule} from '@angular/router';
import {GenericLinkModule, MediaModule, PageComponentModule, PageSlotModule} from '@spartacus/storefront';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioFooterLogosComponent: {
                    component: ValioFooterLogosComponent,
                },
            },
        }),
        GenericLinkModule,
        PageSlotModule,
        PageComponentModule,
    ],
    declarations: [ValioFooterLogosComponent],
    exports: [ValioFooterLogosComponent]
})
export class ValioFooterLogosModule {
}
