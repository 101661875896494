import { Injectable } from '@angular/core';
import { CheckoutConnector } from "@spartacus/checkout/base/core";
import { Order } from "@spartacus/order/root";
import { Observable } from 'rxjs';
import { ValioOccCheckoutAdapter } from "./valio-occ-checkout.adapter";

@Injectable({
  providedIn: 'root',
})
export class ValioCheckoutConnector extends CheckoutConnector {
  constructor(protected adapter: ValioOccCheckoutAdapter) {
    super(adapter);
  }

  public placeValioOrder(userId: string, cartId: string, date: string): Observable<string> {
    return this.adapter.placeValioOrder(userId, cartId, new Date(date));
  }

  payment(userId: string, cartId: string, date: string): Observable<string> {
    return this.adapter.placeValioOrder(userId, cartId, new Date(date))
  }

  loadOrder(userId: string, date: string, orderCode?: string): Observable<Order> {
    return this.adapter.loadOrder(userId, new Date(date), orderCode)
  }
}
