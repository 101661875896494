import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveCartService } from "@spartacus/cart/base/core";


import { StateWithProcess, UserIdService } from "@spartacus/core";
import { Subscription } from "rxjs";
import { openCloseSpinner } from "../util/valio-modals-utils";
import { ValioLoadOrder, ValioStartInvoice, ValioStartPayment } from "./valio-checkout.action";
import {CheckoutService} from "../../features/checkout/core/facade";
import {StateWithCheckout} from "../../features/checkout/core";


@Injectable()
export class ValioCheckoutService extends CheckoutService implements OnDestroy {
  userId: string;
  activeCartId: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected checkoutStore: Store<StateWithCheckout>,
    protected processStateStore: Store<StateWithProcess<void>>,
    protected activeCartService: ActiveCartService,
    protected userIdService: UserIdService
  ) {
    super(checkoutStore, processStateStore, activeCartService, userIdService);
    this.subscriptions.add(
      this.userIdService.getUserId()
        .subscribe((userId) => this.userId = userId)
    );
    this.subscriptions.add(
      this.activeCartService.getActiveCartId().subscribe(cartId => this.activeCartId = cartId)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadOrder(date: string, orderCode: string) {
    this.checkoutStore.dispatch(
      new ValioLoadOrder(this.userId, this.activeCartId, date, orderCode)
    );
  }

  /**
   * Places an order
   */
  placeInvoiceOrderForDate(date: string): void {
    if (this.actionAllowed()) {
      this.checkoutStore.dispatch(
        new ValioStartInvoice({
          userId: this.userId,
          cartId: this.activeCartId,
          date: date
        })
      );
    } else {
      openCloseSpinner(false);
    }
  }

  placeCreditOrderForDate(date: string): void {
    this.checkoutStore.dispatch(
      new ValioStartPayment({
        userId: this.userId,
        cartId: this.activeCartId,
        date: date
      })
    );

  }

}
