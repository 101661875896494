import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, concatMap, map} from "rxjs/operators";
import {makeErrorSerializable} from "../util/valio-serialization-utils";
import {ValioEnvironmentConnector} from "./valio-environment.connector";
import {
    LOAD_QUALTRICS,
    LoadQualtricsFail,
    LoadQualtricsSuccess,
    ValioEnvironmentAction
} from "./valio-environment.action";

@Injectable()
export class ValioEnvironmentEffects {


    loadQualtrics$: Observable<ValioEnvironmentAction> = createEffect(() => this.actions$.pipe(
            ofType(LOAD_QUALTRICS),
            concatMap(() => {
                return this.environmentConnector.getQualtricsId().pipe(
                    map(qualtrics => {
                        return new LoadQualtricsSuccess(qualtrics);
                    }),
                    catchError(error =>
                        of(new LoadQualtricsFail(makeErrorSerializable(error)))
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private environmentConnector: ValioEnvironmentConnector
    ) {
    }
}
