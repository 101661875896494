import { Injectable } from '@angular/core';
import {CheckoutPaymentTypeAdapter, CheckoutPaymentTypeConnector} from "@spartacus/checkout/b2b/core";

@Injectable({
  providedIn: 'root',
})
export class ValioCheckoutPaymentTypeConnector extends CheckoutPaymentTypeConnector {


  constructor(adapter: CheckoutPaymentTypeAdapter) {
    super(adapter);
  }
}


