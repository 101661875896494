import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule, GlobalMessageService, I18nModule, NotAuthGuard, RoutingService, UrlModule,} from '@spartacus/core';
import {ValioResetPasswordComponent} from "./valio-reset-password.component";
import {ValioInputModule} from '../../../input/valio-input.module';
import {SpinnerModule} from "@spartacus/storefront";
import {ValioForgotPasswordModule} from "../forgot-password/valio-forgot-password.module";
import {ResetPasswordComponentService} from "@spartacus/user/profile/components";
import {ValioUpdatePasswordComponentService} from "./valio-reset-password-component.service";
import {UserPasswordFacade} from "@spartacus/user/profile/root";

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ResetPasswordComponent: {
          component: ValioResetPasswordComponent,
          guards: [NotAuthGuard],
        },
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    ValioInputModule,
    UrlModule,
    SpinnerModule,
    ValioForgotPasswordModule,
  ],
  declarations: [ValioResetPasswordComponent],
  exports: [ValioResetPasswordComponent],
  providers: [
    {
      provide: ResetPasswordComponentService,
      useClass: ValioUpdatePasswordComponentService,
      deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
    },
  ],
})
export class ValioResetPasswordModule {
}
