import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {MediaModule, OutletModule} from '@spartacus/storefront';

import {ValioCommonModule} from "../../../../shared/valio-common.module";
import {ValioProductInfoComponent} from "./valio-product-info.component";
import {ValioPipeModule} from '../../../../services/pipes/valio-pipe.module';
import {ValioProductIconsModule} from "../product-icons/valio-product-icons.module";
import {RouterModule} from "@angular/router";


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ProductInfoComponent: {
                    component: ValioProductInfoComponent,
                },
            },
        }),
        ValioPipeModule,
        ValioProductIconsModule,
        RouterModule,
    ],
    declarations: [ValioProductInfoComponent],
    exports: [ValioProductInfoComponent]
})
export class ValioProductInfoModule {
}
