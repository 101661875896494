import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {CartDetailsModule} from "@spartacus/cart/base/components";
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
// TODO:Spartacus - // TODO:Spartacus - Class CartComponentModule has been removed and is no longer part of the public API. The cart base feature is now extracted to a lazy loadable library @spartacus/cart/base.  See the release documentation for more information.
import {PromotionsModule} from "@spartacus/storefront";
import {ValioCarouselModule} from "../../../../shared/components/carousel/valio-carousel.module";
import {ValioProductListModule} from "../../valio-product-list.module";
import {ValioTopSellersComponent} from "./valio-top-sellers.component";


@NgModule({
    imports: [
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioTopSellersComponent: {
                    component: ValioTopSellersComponent,
                },
            },
        }),
        I18nModule,
        PromotionsModule,
        CommonModule,
        ValioProductListModule,
        ValioCarouselModule,
    ],
    declarations: [ValioTopSellersComponent],
    exports: [ValioTopSellersComponent]
})
export class ValioTopSellersModule extends CartDetailsModule {
}
