import {Injectable, InjectionToken} from '@angular/core';
import {Converter, ConverterService, PRODUCT_NORMALIZER} from "@spartacus/core";
import {ValioProduct} from "../../models";

export const PRODUCT_LIST_NORMALIZER = new InjectionToken<Converter<ValioProduct[], ValioProduct[]>>(
  'ProductListNormalizer'
);

@Injectable({providedIn: 'root'})
export class ValioProductListNormalizer implements Converter<ValioProduct[], ValioProduct[]> {
  constructor(private converterService: ConverterService) {
  }

  convert(source: ValioProduct[], target?: ValioProduct[]): ValioProduct[] {
    if (source) {
      target = source.map(product => {
          if (product.images) {
            [].forEach.call(product.images, image => {
              if (image.galleryIndex == null) {
                delete image.galleryIndex;
              }
            });
          }
          return this.converterService.convert(product, PRODUCT_NORMALIZER);
        }
      );
    } else {
      target = [];
    }
    return target;
  }

}
