<div class="row">
  <div class="col-12">
    <hr class="minicart-separator"/>
  </div>
</div>
<div class="summary row">
  <div class="col-12 col-sm-4 minicart-total-label" *ngIf="totalItems">
    <div class="row">
      <div class="col-7 col-sm-12 minicart-total-shipping">{{totalItems}} {{'order.summary.products'|cxTranslate }}</div>
    </div>
  </div>
  <div class="minicart-total-label margin-bottom-base col-12 col-sm-8" [ngClass]="totalItems ? 'col-12' : 'col-12 col-sm-12'">
    <div class="minicart-total-shipping row" *ngIf="totalPriceWithoutTax">
      <div class="total-label col-7">{{'checkout.header.totalPriceNoVat'|cxTranslate }}</div>
      <div class="sum col-5">{{totalPriceWithoutTax.formattedValue}}</div>
    </div>
    <div class="minicart-total-shipping row" *ngIf="freightCharge">
      <div class="total-label col-7 subtotal">{{'checkout.header.freightCharge'|cxTranslate }}</div>
      <div class="sum col-5">{{freightCharge.formattedValue}}</div>
    </div>
    <div class="minicart-total-shipping row" *ngIf="variableWeightPaymentCost">
      <div class="total-label col-7 subtotal">{{'checkout.header.variableWeightPaymentCost'|cxTranslate }}</div>
      <div class="sum col-5">{{variableWeightPaymentCost.formattedValue}}</div>
    </div>
    <div class="minicart-total-shipping row" *ngIf="subtotal && (freightCharge || variableWeightPaymentCost)">
      <div class="total-label col-7">{{'checkout.header.subtotal'|cxTranslate }}</div>
      <div class="sum col-5">{{subtotal.formattedValue}}</div>
    </div>
    <div class="minicart-total-shipping row" *ngIf="totalPriceWithTax">
      <div class="total-label col-7">{{'checkout.header.totalPrice'|cxTranslate }}</div>
      <div class="sum col-5">{{totalPriceWithTax.formattedValue}}</div>
    </div>
  </div>
</div>
