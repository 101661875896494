import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule} from '@spartacus/core';

import {RouterModule} from '@angular/router';
import {GenericLinkModule, PageSlotModule} from '@spartacus/storefront';
import {ValioNavigationModule} from '../valio-navigation.module';
import {ValioPublicVendorNavigationComponent} from './valio-public-vendor-navigation.component';
import {ValioCartHeaderModule} from '../../cart/cart-header/valio-cart-header.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioPublicVendorNavigationComponent: {
                    component: ValioPublicVendorNavigationComponent,
                },
            },
        }),
        ValioNavigationModule,
        GenericLinkModule,
        PageSlotModule,
        ValioCartHeaderModule,
    ],
    declarations: [ValioPublicVendorNavigationComponent],
    exports: [ValioPublicVendorNavigationComponent]
})
export class ValioPublicVendorNavigationModule {
}
