import { Injectable } from '@angular/core';
import { CartEntryConnector } from "@spartacus/cart/base/core";
import { Observable } from 'rxjs';
import { ValioCart, ValioCartEntryUpdateData, ValioCartModification } from "./valio-cart.objects";
import { ValioOccCartEntryAdapter } from "./valio-occ-cart-entry.adapter";

@Injectable({
  providedIn: 'root',
})
export class ValioCartEntryConnector extends CartEntryConnector {
  constructor(protected adapter: ValioOccCartEntryAdapter) {
    super(adapter);
  }

  public updateEntry(
    userId: string,
    cartId: string,
    entryNumber: string,
    data: ValioCartEntryUpdateData
  ): Observable<ValioCart> {
    return this.adapter.updateEntry(userId, cartId, entryNumber, data);
  }

  addEntry(userId: string,
    cartId: string,
    productCode: string,
    data: ValioCartEntryUpdateData): Observable<ValioCart> {
    return this.adapter.addEntry(userId, cartId, productCode, data);
  }

  csvAddEntry(userId: string,
    cartId: string,
    productCode: string,
    data: ValioCartEntryUpdateData): Observable<ValioCartModification> {
    return this.adapter.csvAddEntry(userId, cartId, productCode, data);
  }

  csvAddEntries(userId: string,
    cartId: string,
    data: ValioCartEntryUpdateData[]): Observable<ValioCartModification[]> {
    return this.adapter.addCsvEntries(userId, cartId, data);
  }
}
