import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AuthGuard, CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {RouterModule} from '@angular/router';
import {ValioHeaderNewsComponent} from './valio-header-news.component';
import {PageSlotModule} from '@spartacus/storefront';

@NgModule({
    imports: [
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioHeaderNewsComponent: {
                    component: ValioHeaderNewsComponent
                },
            },
        }),
        I18nModule,
        RouterModule,
        PageSlotModule,
        CommonModule
    ],
    declarations: [ValioHeaderNewsComponent],
    exports: [ValioHeaderNewsComponent]
})
export class ValioHeaderNewsModule {
}
