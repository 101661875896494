import {AbstractControl, ValidationErrors} from "@angular/forms";

export default class PostalCodeValidator {
  private static POSTAL_CODE_PATTERN: string = "^\\d{5}$";

  static valid(control: AbstractControl): ValidationErrors | null {
    const postalCode = control.value as string;

    return postalCode.match(PostalCodeValidator.POSTAL_CODE_PATTERN) ? null : { InvalidPostalCode: true };
  }
}
