import {NgModule} from '@angular/core';
import {PageMetaResolver} from '@spartacus/core';
import {facadeProviders} from './facade/facade-providers';
import {CheckoutStoreModule} from './store/checkout-store.module';
import {CheckoutEventModule} from "@spartacus/checkout/base/root";
import { CheckoutCostCenterConnector} from "@spartacus/checkout/b2b/core";
import {CheckoutPageMetaResolver, CheckoutPaymentConnector} from "@spartacus/checkout/base/core";
import {CheckoutEventBuilder} from "./events";


@NgModule({
    imports: [CheckoutStoreModule, CheckoutEventModule],
    providers: [
        ...facadeProviders,
        CheckoutCostCenterConnector,
        CheckoutPaymentConnector,
        CheckoutEventBuilder,
        CheckoutPageMetaResolver,
        {
            provide: PageMetaResolver,
            useExisting: CheckoutPageMetaResolver,
            multi: true,
        }
    ],
})
export class CheckoutCoreModule {
}
