import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ValioProductSearchConnector} from "../../product/valio-product-search.connector";
import {Observable, of} from "rxjs";
import {catchError, concatMap, map} from "rxjs/operators";
import {
    LOAD_TOP_SELLERS,
    ValioLoadTopSellers,
    ValioLoadTopSellersFail,
    ValioLoadTopSellersSuccess
} from "./valio-top-sellers.action";

@Injectable()
export class ValioTopSellersEffect {

    constructor(
        protected actions$: Actions,
        protected productSearchConnector: ValioProductSearchConnector,
    ) {
    }

    loadTopSellers$: Observable<ValioLoadTopSellersSuccess | ValioLoadTopSellersFail> = createEffect(() => this.actions$.pipe(
            ofType(LOAD_TOP_SELLERS),
            map((action: ValioLoadTopSellers) => action),
            concatMap(() => {
                    return this.productSearchConnector.loadTopSellingProducts()
                        .pipe(
                            map(products => new ValioLoadTopSellersSuccess(products))
                        );
                }
            ), catchError(error => {
                console.error("Error in loadTopSellers$: " + error);
                return of(new ValioLoadTopSellersFail());
            })
        )
    );

}


