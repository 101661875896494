<ng-container *ngIf="order$ | async as order">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="cx-order-confirmation-message">
          <h1>{{ 'order.header.thankYou' | cxTranslate }}</h1>
          <span>{{ 'order.header.placeholder1' | cxTranslate }}</span>
          <span class="d-none d-lg-inline-block">{{ 'order.header.placeholder2' | cxTranslate }}</span>
          <div class="cx-order-confirmation-actions">
            <button
              (click)="print()"
              class="d-none d-lg-inline-block btn btn-secondary">
              {{ 'order.header.print' | cxTranslate }}
            </button>
            <button *ngIf="!isGuestCustomer && isContractCustomer()|async"
                    (click)="createTemplate(order)"
                    class="d-none d-lg-inline-block btn btn-secondary margin-left-base">
              {{ 'order.header.copy' | cxTranslate }}
            </button>
            <button
              [routerLink]="'/logoutPreview'"
              class="d-none d-lg-inline-block btn btn-secondary margin-left-base">
              {{ 'order.header.logOut' | cxTranslate }}
            </button>
          </div>
          <div id="qualtricsFeedback"></div>
        </div>
      </div>
      <div class="d-none d-md-block col-md-4">
        <div class="cx-order-confirmation-image">
          <cx-page-slot position="ImageContent"></cx-page-slot>
        </div>
      </div>
    </div>
    <div *ngIf="isGuestCustomer && false">
      <cx-guest-register-form
        [guid]="orderGuid"
        [email]="order.paymentInfo.billingAddress.email"
      ></cx-guest-register-form>
    </div>
  </div>
  <cx-add-to-home-screen-banner></cx-add-to-home-screen-banner>
</ng-container>
