<div class="cart-header-wrapper">
  <div class="header-info-slot col-12">
    <div class="row">
      <div class="header-block col-12 col-md-6 col-lg-6">
        <valio-date-view
          [date]="date"
          [status]="status"
        ></valio-date-view>
      </div>
      <div class="header-block col-12 col-md-6 col-lg-6 d-none d-md-block">
        <valio-totals-view
          [totalPriceInvoice]="totalPrice"
          [totalPriceCredit]="totalPrice"
          [totalItems]="totalItems"
          [totalWeightInKg]="totalWeightInKg"
        ></valio-totals-view>
      </div>
    </div>
  </div>
  <div class="header-info-slot mobile-header col-12 d-md-none">
    <div class="row">
      <div class="header-block col-12">
        <valio-totals-view
          [totalPriceInvoice]="totalPrice"
          [totalPriceCredit]="totalPrice"
          [totalItems]="totalItems"
          [totalWeightInKg]="totalWeightInKg"
        ></valio-totals-view>
      </div>
    </div>
  </div>
</div>
