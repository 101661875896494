<div *ngIf="result" class="container-apply">
  <div class="page-header">
    <div class="page-header-title">
      <h1>{{'partnerApply.okTitle'|cxTranslate}}</h1>
      <p>{{'partnerApply.' + result + 'Subtitle'|cxTranslate}}</p>
      <button class="btn btn-primary" [routerLink]="'/'">
        {{ 'partnerApply.toHome' | cxTranslate }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="!result && partner$|async as partner">
  <div class="page-header">
    <div class="page-header-title">
      <div class="partner-icon" *ngIf=" partnerIcon$|async as partnerIcon">
        <cx-media [container]="partnerIcon.media" [alt]="partnerIcon.altText"></cx-media>
      </div>
      <h1>{{'partnerApply.title'|cxTranslate:{partner: partner.name} }}</h1>
      <p>{{'partnerApply.subtitle'|cxTranslate}}</p>
    </div>
  </div>

  <div class="partner-apply-content" [formGroup]="form">
    <div class="content-center">
      <div class="section" *ngIf="customerNumbers$|async as customerNumbers">
        <div class="label-content header">{{'valioRegister.customerNumber.placeholder'|cxTranslate}}</div>
        <ng-select
          [searchable]="false"
          [clearable]="true"
          [closeOnSelect]="true"
          [formControlName]="'customerNumber'"
          placeholder="{{ 'valioRegister.customerNumber.placeholder' | cxTranslate}}"
          bindLabel="name"
          bindValue="uid"
          [items]="customerNumbers"
        ></ng-select>
      </div>

      <div class="section">
        <div class="label-content header">{{'valioRegister.header.company' | cxTranslate}}</div>
        <valio-cx-input [isNotValidFunction]="isInvalidStep('companyName')" idName="companyName"
                        [labelPlaceholder]="'valioRegister.companyName.placeholder'" [nameForm]="'companyName'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input idName="vatId"
                        [isNotValidFunction]="isInvalidStep('vatId') || (form.get('vatId').errors && (form.get('vatId').errors['vatId'] || form.get('vatId').errors['InvalidVatId']) && form.get('vatId').dirty)"
                        [labelPlaceholder]="'valioRegister.vatId.placeholder'" [nameForm]="'vatId'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('contact')" idName="contact"
                        [labelPlaceholder]="'partnerApply.contact'" [nameForm]="'contact'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>
      </div>

      <div class="section">
        <div class="label-content header">{{'partnerApply.billingInfo' | cxTranslate}}</div>
        <valio-cx-input [isNotValidFunction]="isInvalidStep('billingName')" idName="billingName"
                        [labelPlaceholder]="'partnerApply.billingName'" [nameForm]="'billingName'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('billingStreet')" idName="billingStreet"
                        [labelPlaceholder]="'partnerApply.billingStreet'" [nameForm]="'billingStreet'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('billingPostalcode')" idName="billingPostalcode"
                        [labelPlaceholder]="'valioRegister.postalCode.placeholder'" [nameForm]="'billingPostalcode'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('billingCity')" idName="billingCity"
                        [labelPlaceholder]="'valioRegister.city.placeholder'" [nameForm]="'billingCity'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>
      </div>

      <div class="section">
        <div class="label-content header">{{'partnerApply.shippingTitle' | cxTranslate}}</div>
        <p class="subtitle">{{'partnerApply.shippingSubtitle' | cxTranslate}}</p>
        <valio-cx-input [isNotValidFunction]="isInvalidStep('shippingName')" idName="shippingName"
                        [labelPlaceholder]="'partnerApply.shippingName'" [nameForm]="'shippingName'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('shippingStreet')" idName="shippingStreet"
                        [labelPlaceholder]="'valioRegister.street.placeholder'" [nameForm]="'shippingStreet'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('shippingPostalcode')" idName="shippingPostalcode"
                        [labelPlaceholder]="'valioRegister.postalCode.placeholder'" [nameForm]="'shippingPostalcode'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('shippingCity')" idName="shippingCity"
                        [labelPlaceholder]="'valioRegister.city.placeholder'" [nameForm]="'shippingCity'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('shippingPhone')" idName="shippingPhone"
                        [labelPlaceholder]="'updateProfileForm.phone.label'" [nameForm]="'shippingPhone'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>

        <valio-cx-input [isNotValidFunction]="isInvalidStep('shippingEmail')" idName="shippingEmail"
                        [labelPlaceholder]="'updateProfileForm.email.placeholder'" [nameForm]="'shippingEmail'"
                        [parentForm]="form" class="valio-input"></valio-cx-input>
      </div>

      <div class="section">
        <div class="label-content header info">{{'partnerApply.infoTitle' | cxTranslate}}</div>
        <p class="subtitle">{{'partnerApply.infoSubtitle' | cxTranslate}}</p>
        <textarea [formControlName]="'info'" class="form-control"
                  placeholder="{{ 'partnerApply.infoPlaceHolder' | cxTranslate}}"></textarea>
      </div>

      <div class="section partner-apply-buttons justify-content-center text-center">
        <button (click)="cancel()" class="btn btn-lg btn-block-sm-down btn-secondary margin-right-base margin-bottom-base order-2">
          {{ 'common.cancel' | cxTranslate }}
        </button>
        <button class="btn btn-lg btn-block-sm-down btn-primary margin-bottom-base order-1" (click)="send(partner)">
          {{ 'partnerApply.send' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
