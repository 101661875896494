import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {AddToCartModule} from "@spartacus/cart/base/components/add-to-cart";
import {CmsConfig, ConfigModule, I18nModule, provideDefaultConfig, UrlModule} from "@spartacus/core";
import {BreadcrumbModule, ItemCounterModule, ListNavigationModule, MediaModule, OutletModule, PageSlotModule, ProductListComponentService, ProductListModule, SearchBoxModule, SpinnerModule, StarRatingModule} from "@spartacus/storefront";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {ValioPipeModule} from "../../../services/pipes/valio-pipe.module";
import {ValioAddToCartModule} from "../cart/add-to-cart/valio-add-to-cart.module";
import {ValioBackgroundImageModule} from "../content/background-image/valio-background-image.module";
import {ValioBannerModule} from '../content/banner/valio-banner.module';
import {ValioSearchBoxModule} from "../navigation/search-box/valio-search-box.module";
import {ValioSuggestiveSearchBoxModule} from "../navigation/suggestive-search-box/valio-suggestive-search-box.module";
import {ValioProductGridInnerComponent} from "./container/grid/valio-product-grid.inner.component";
import {ValioProductScrollComponent} from "./container/scroll/valio-product-scroll.component";
import {ValioProductGridComponentService} from "./container/valio-product-grid-component.service";
import {ValioProductGridComponent} from "./container/valio-product-grid.component";
import {ValioProductListComponent} from "./container/valio-product-list.component";
import {ValioProductScrollListComponent} from "./container/valio-product-scroll-list.component";
import {ValioProductPartnerComponent} from "./partner/valio-product-partner.component";
import {ValioProductContractualSwitchComponent} from './product-contractual-switch/valio-product-contractual-switch.component';
import {ValioProductDeliveryPeriodModule} from "./product-delivery-period/valio-product-delivery-period.module";
import {ValioProductFacetListComponent} from "./product-facet-navigation/facet-list/valio-product-facet-list.component";
import {ValioProductFacetNavigationComponent} from "./product-facet-navigation/valio-product-facet-navigation.component";
import {ValioProductGridItemComponent} from "./product-grid-item/valio-product-grid-item.component";
import {ValioProductIconsModule} from "./product-icons/valio-product-icons.module";
import {ValioProductInfoModule} from "./product-info/valio-product-info.module";
import {ValioProductListItemComponent} from "./product-list-item/valio-product-list-item.component";
import {ValioProductNutritionModule} from "./product-nutrition/valio-product-nutrition.module";
import {ValioProductPriceModule} from "./product-price/valio-product-price.module";
import {ValioProductSummaryModule} from "./product-summary/valio-product-summary.module";


@NgModule({
  providers: [
    {
      provide: ProductListComponentService,
      useClass: ValioProductGridComponentService,
    }
  ],
  declarations: [
    ValioProductListItemComponent,
    ValioProductGridItemComponent,
    ValioProductListComponent,
    ValioProductGridComponent,
    ValioProductFacetNavigationComponent,
    ValioProductFacetListComponent,
    ValioProductGridInnerComponent,
    ValioProductScrollComponent,
    ValioProductScrollListComponent,
    ValioProductContractualSwitchComponent,
    ValioProductPartnerComponent
  ],
  exports: [
    ValioProductListItemComponent,
    ValioProductGridItemComponent,
    ValioProductListComponent,
    ValioProductGridComponent,
    ValioProductFacetNavigationComponent,
    ValioProductFacetListComponent,
    ValioProductGridInnerComponent,
    ValioProductScrollComponent,
    ValioProductScrollListComponent,
    ValioProductContractualSwitchComponent,
    ValioProductPartnerComponent
  ],
  imports: [
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CMSProductListComponent: {
          component: ValioProductListComponent
        },
        ProductGridComponent: {
          component: ValioProductScrollListComponent,
        },
        SearchResultsListComponent: {
          component: ValioProductScrollListComponent
        },
        SearchResultsGridComponent: {
          component: ValioProductScrollListComponent,
        },
        ProductRefinementComponent: {
          component: ValioProductFacetNavigationComponent,
        },
      },
    }),
    RouterModule,
    MediaModule,
    AddToCartModule,
    ItemCounterModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    StarRatingModule,
    ProductListModule,
    CommonModule,
    ValioProductSummaryModule,
    ValioProductPriceModule,
    ValioProductInfoModule,
    OutletModule,
    ValioAddToCartModule,
    BreadcrumbModule,
    SearchBoxModule,
    InfiniteScrollModule,
    SpinnerModule,
    ValioProductNutritionModule,
    ValioBackgroundImageModule,
    ValioProductIconsModule,
    PageSlotModule,
    ValioSearchBoxModule,
    ValioSuggestiveSearchBoxModule,
    ValioPipeModule,
    ValioBannerModule,
    NgSelectModule,
    FormsModule,
    ValioProductDeliveryPeriodModule
  ]
})
export class ValioProductListModule {
}
