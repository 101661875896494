import {Injectable} from '@angular/core';

import {User} from "@spartacus/core";
import {ValioOccUserAdapter} from "./valio-occ-user.adapter";
import {Observable} from "rxjs";
import {ValioDeliveryNote, ValioDeliveryNoteList, ValioDeliveryNoteProductList, ValioOrderHistoryList, ValioPartnerApplication, ValioReclamationCreate, ValioUser} from "../../models/misc.model";
import {Partner} from "../cart/valio-cart.objects";
import {UserAccountConnector} from "@spartacus/user/account/core";

@Injectable({
  providedIn: 'root',
})
export class ValioUserConnector extends UserAccountConnector {
  constructor(protected userAdapter: ValioOccUserAdapter) {
    super(userAdapter);
  }

  selectUnit(userId: string, unit: string): Observable<User> {
    return this.userAdapter.selectUnit(userId, unit);
  }

  getHistory(userId: any, sort: any, from: Date, to?: Date, poNumber?: string, metaDataOnly?: boolean): Observable<ValioOrderHistoryList> {
    return this.userAdapter.getHistory(userId, sort, from, to, poNumber, metaDataOnly);
  }

  getDeliveryNoteList(userId: string, fromDate: Date, toDate: Date): Observable<ValioDeliveryNoteList> {
    return this.userAdapter.getDeliveryNoteList(userId, fromDate, toDate);
  }

  getDeliveryNoteProducts(userId: string, deliveryNote: ValioDeliveryNote): Observable<ValioDeliveryNoteProductList> {
    return this.userAdapter.getDeliveryNoteProducts(userId, new Date(deliveryNote.deliveryDate), deliveryNote.billingDocument, deliveryNote.partner.code);
  }

  setRestrictedAssortment(userId: string, value: boolean): Observable<boolean> {
    return this.userAdapter.setRestrictedAssortment(userId, value);
  }

  isRestrictedAssortment(userId: string): Observable<boolean> {
    return this.userAdapter.isRestrictedAssortment(userId);
  }

  sendReclamation(userId: string, reclamation: ValioReclamationCreate): Observable<string> {
    return this.userAdapter.sendReclamation(userId, reclamation);
  }

  sendNewContractRequest(userId: string, form: any): Observable<boolean> {
    return this.userAdapter.sendNewContractRequest(userId, form);
  }

  applyForPartner(userId: string, partner: Partner, data: ValioPartnerApplication): Observable<boolean> {
    return this.userAdapter.applyForPartner(userId, partner.code, data);
  }

  requestForgotPasswordEmail(userEmailAddress: string): Observable<ValioUser>{
    return this.userAdapter.requestForgotPasswordEmail(userEmailAddress);
  }


}
