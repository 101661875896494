<form  [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="login-form" *ngIf="!emailAddress">
    <div class="content-group">
      <h1>{{ 'forgottenPassword.uid.label' | cxTranslate }}</h1>
      <p>{{ 'forgottenPassword.uid.instructions' | cxTranslate }}</p>
    </div>
    <div class="content-group">
      <valio-cx-input [isNotValidFunction]="isUserIDValid()"
                      [labelPlaceholder]="'forgottenPassword.uid.placeholder'"
                      [errorMessage]="errorMessage"
                      [nameForm]="'userEmail'"
                      [parentForm]="form" class="valio-input" (afterChange)="validateForm()">

      </valio-cx-input>
    </div>
    <div class="content-group">
      <button class="btn btn-lg btn-primary" type="submit">
        {{ 'forgottenPassword.uid.sendButton' | cxTranslate }}
      </button>
    </div>
    <valio-cx-no-account></valio-cx-no-account>
  </div>
</form>


<div class="login-form" *ngIf="emailAddress">
  <div class="content-group">
    <h1>{{ 'forgottenPassword.uid.labelOk' | cxTranslate }}</h1>
    <p>{{ 'forgottenPassword.uid.textOk1' | cxTranslate : {email: emailAddress} }}</p>
    <p>{{ 'forgottenPassword.uid.textOk2' | cxTranslate }}</p>
  </div>
  <div class="content-group">
    <button (click)="toFrontPage()" class="btn btn-lg btn-primary">
      {{ 'valioRegister.toHome' | cxTranslate }}
    </button>
  </div>

</div>
