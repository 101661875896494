<div class="valio-modal modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="switchPayment">
      <h1 class="modal-title">{{'cart.header.switchPayment.title' + toCard|cxTranslate}}</h1>
      <p class="bold">{{'cart.header.switchPayment.summary' + toCard|cxTranslate}}</p>
      <p>{{'cart.header.switchPayment.products'|cxTranslate: {products: numberOfItems, partner: partnerEntry.partner.name} }}</p>
      <div class="row">
        <div class="col-6">
          <button
            type="button"
            class="btn btn-block btn-primary"
            aria-label="cancel"
            (click)="cancel()"
          >{{ 'cart.header.switchPayment.cancel' | cxTranslate }}
          </button>
        </div>
        <div class="col-6">
          <button
            type="button"
            class="btn btn-block btn-primary"
            aria-label="cancel"
            (click)="switch()"
          >{{ 'cart.header.switchPayment.title' + toCard | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
