<div class="header-product-type row align-items-center" *ngIf="totalItems">
  <div class="col-6 col-md-5 col-lg-7">
    {{'cart.header.products'|cxTranslate:{products: totalItems} }}
  </div>
  <div class="col-6 col-md-7 col-lg-5 totals">
    <div class="cx-vat">{{'cart.header.alv.zero'|cxTranslate }}</div>
    <div class="cx-price" *ngIf="total">{{total.formattedValue}}</div>
  </div>
</div>
<div class="header-product-type row align-items-center" *ngIf="totalItemsInvoice">
  <div class="col-6 col-md-9 col-lg-8">
    <div class="row">
      <div class="col-12 col-md-9 col-lg-7">
        <div class="payment-type">{{'cart.header.invoicetype'|cxTranslate}}</div>
      </div>
      <div class="col-12 col-md-3 col-lg-5 products">{{'cart.header.products'|cxTranslate:{products: totalItemsInvoice} }}</div>
    </div>
  </div>
  <div class="col-6 col-md-3 col-lg-4 totals">
    <div class="cx-vat">{{'cart.header.alv.zero'|cxTranslate }}</div>
    <div class="cx-price" *ngIf="totalPriceInvoice">{{totalPriceInvoice.formattedValue}}</div>
  </div>
</div>
<div class="header-product-type row align-items-center" *ngIf="totalItemsCredit">
  <div class="col-6 col-md-9 col-lg-8">
    <div class="row">
      <div class="col-12 col-md-9 col-lg-7">
        <div class="payment-type">{{'cart.header.credittype'|cxTranslate}}</div>
      </div>
      <div class="col-12 col-md-3 col-lg-5 products">{{'cart.header.products'|cxTranslate:{products: totalItemsCredit} }}</div>
    </div>
  </div>
  <div class="col-6 col-md-3 col-lg-4 totals">
    <div class="cx-vat">{{'cart.header.alv.zero'|cxTranslate }}</div>
    <div class="cx-price" *ngIf="totalPriceCredit">{{totalPriceCredit.formattedValue}}</div>
  </div>
</div>

