import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FeaturesConfigModule} from '@spartacus/core';

import {GlobalMessageComponentModule, PageLayoutModule, PageSlotModule} from "@spartacus/storefront";
import {ValioStorefrontComponent} from "./valio-storefront.component";
import {ValioHideOnLocationDirective} from "./valio-hide-on-location.directive";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    FeaturesConfigModule,
    PageLayoutModule,
    PageSlotModule,
    GlobalMessageComponentModule,
  ],
  declarations: [ValioStorefrontComponent, ValioHideOnLocationDirective],
  exports: [ValioStorefrontComponent],
})
export class ValioMainModule {

}
