import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {ValioVideoComponent} from './valio-video.component';
import {RouterModule} from '@angular/router';
import {GenericLinkModule, MediaModule, PageComponentModule, PageSlotModule} from '@spartacus/storefront';
import {YouTubePlayerModule} from "@angular/youtube-player";

@NgModule({
    imports: [
        YouTubePlayerModule,
        CommonModule,
        RouterModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioVideoComponent: {
                    component: ValioVideoComponent,
                },
            },
        }),
        GenericLinkModule,
        PageSlotModule,
        PageComponentModule,
        I18nModule
    ],
    declarations: [ValioVideoComponent],
    exports: [ValioVideoComponent]
})
export class ValioVideoModule {
}
