import {InjectionToken, Provider} from "@angular/core";
import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store";

import {SelectUnit, SELECT_UNIT} from "../../user/valio-user-actions";
import {
    LOAD_TOP_SELLERS_FAIL,
    LOAD_TOP_SELLERS_SUCCESS,
    ValioLoadTopSellersFail,
    ValioLoadTopSellersSuccess, ValioTopSellerssState,
    ValioTopSellersState
} from "./valio-top-sellers.action";
import {StateUtils} from "@spartacus/core";

export const initialState: ValioTopSellersState = {
    content: {products: []}
};

export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<ValioTopSellerssState>> = new InjectionToken<ActionReducerMap<ValioTopSellerssState>>('TopSellersReducers');

export const valioTopSellersReducerProvider: Provider = {
    provide: reducerToken,
    useFactory: getValioTopSellersReducers,
};

export function clearState(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return function (state, action) {
        return reducer(state, action);
    };
}

export function getValioTopSellersReducers(): ActionReducerMap<ValioTopSellerssState> {
    return {
        active: StateUtils.loaderReducer<ValioTopSellersState>(LOAD_TOP_SELLERS_SUCCESS, valioTopSellersReducer),
    };
}

export function valioTopSellersReducer(
    state = initialState,
    action: ValioLoadTopSellersSuccess | ValioLoadTopSellersFail | SelectUnit
): ValioTopSellersState {
    switch (action.type) {
        case LOAD_TOP_SELLERS_SUCCESS: {
            return {
                content: action.products
            };
        }
        case LOAD_TOP_SELLERS_FAIL:
        case SELECT_UNIT: {
            //case CheckoutActions.PLACE_ORDER_SUCCESS:
            return initialState;
        }
        default: {
            return state
        }
    }
}
