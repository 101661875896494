import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { OrderConfirmationGuard } from "@spartacus/order/components";
import { OrderFacade } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ValioOrderConfirmationGuard extends OrderConfirmationGuard {
  constructor(
    protected lrouter: Router,
    protected lsemanticPathService: SemanticPathService,
    orderFacade: OrderFacade
  ) {
    super(orderFacade, lrouter, lsemanticPathService);
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.orderFacade.getOrderDetails().pipe(
      map(() => {
        return true;
      })
    );
  }
}
