<div class="table-row">
    <div class="row align-items-center">
        <ng-container *ngIf="!isDayView">
            <div class="col-12 d-md-none table-row-border table-row-top-border" *ngIf="isFirst">
                <span class="margin-left-sm">{{convertDate()|valioDate:'d.M.'}} {{ getWeekdayName() |cxTranslate}}</span>
            </div>
            <div class="d-none d-md-block col-md-2 table-row-border" *ngIf="isLast">
                <span class="margin-left-sm">{{convertDate()|valioDate:'d.M.'}} {{ getWeekdayName() |cxTranslate}}</span>
            </div>
        </ng-container>
        <div class="col-5 col-md-3 table-row-border" [ngClass]="!isDayView && !isLast ? 'offset-md-2' :''">
            <a class="text-bold margin-left-sm" *ngIf="deliveryNote.pdfLink" href="{{deliveryNote.pdfLink}}"
               target="_blank">
                <span class="valio-icon icon-inline icon-pdf"></span>
                <span class="margin-left-sm">{{deliveryNote.billingDocument}}</span>
            </a>
        </div>
        <div class="table-row-border" [ngClass]="isDayView?'col-2 col-md-6':'col-2 col-md-4'">
            {{deliveryNote.partner.name}}
        </div>
        <div class="col-5 col-md-3 table-row-border text-right">
            <ng-container *ngIf="deliveryNote.allowReclamations">
                <button class="btn btn-secondary" (click)="openCreateReclamationWindow()"
                        *ngIf="!deliveryNote.reclamation">
                    {{'deliveryNotes.row.createReclamation'|cxTranslate}}
                </button>
                <span class="margin-right-sm" *ngIf="deliveryNote.reclamation">
                    {{'deliveryNotes.row.reclamationCreated'|cxTranslate}}
                </span>
            </ng-container>
        </div>
    </div>
</div>
