<div class="partner-row" (click)="togglePartner()">
  <div class="row align-items-center">
    <div class="col-8 col-md-7 col-lg-8">
      <div class="partner-toggle d-inline-block margin-left-md"
           [ngClass]="{'collapsed':collapsed}"
           #partnerToggleButton>
        <div class="valio-icon icon-inline icon-chevron icon-chevron-up"></div>
      </div>
      <div class="cx-name margin-left-base">
        {{partnerEntry.partner.name}} {{title}}
      </div>
      <div class="d-block d-md-none margin-left-base print-total-items">
        {{'checkout.header.products'|cxTranslate:{products: partnerEntry.totalItems} }}
      </div>
      <div class="d-none d-lg-block float-right margin-right-base" *ngIf="partnerEntry.canChangePaymentMode">
        <a class="payment-mode-change" (click)="openCardPaymentModal()" *ngIf="switchToCardPayment != null && false"> <!-- disabled for now-->
          {{'cart.header.switchPayment.' + switchToCardPayment |cxTranslate}}
        </a>
      </div>
    </div>
    <div class="col-4 col-md-5 col-lg-4 column-separator">
      <div class="row align-items-center h-100">
        <div class="d-none d-md-block col-md-4">
          <span class="margin-left-base">
            {{'checkout.header.products'|cxTranslate:{products: partnerEntry.totalItems} }}
          </span>
        </div>
        <div class="col-12 col-md-8 totals">
          <span class="cx-vat margin-right-base">{{'cart.header.alv.zero'|cxTranslate }}</span>
          <span class="cx-price margin-right-base">{{partnerEntry.totalPrice.formattedValue}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<valio-cx-cart-item-list
  [collapsed]="collapsed"
  [items]="partnerEntry.entries"
  [cartIsLoading]="!cartLoaded"
  [readOnly]="isReadOnly"
  [allowDelete]="allowDelete"
  [partnerClickedEvent]="partnerClickSubject.asObservable()"
  (updateCartEmitter)="updateCart($event)"
></valio-cx-cart-item-list>
