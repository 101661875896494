<valio-cx-order-template-header></valio-cx-order-template-header>

<div class="order-template-content cart-details-wrapper" *ngIf="orderTemplate">
  <h2 class="subtitle">{{orderTemplate.name}}</h2>
  <p class="order-template-products">{{'checkout.header.products'|cxTranslate:{products: orderTemplate.entries.length} }}</p>

  <valio-suggestive-search
    [templateMode]="true"
    (addToCartEmitter)="addToTemplate($event)">
  </valio-suggestive-search>

  <div class="cart-entry-block">
    <valio-cx-cart-item-list
      [hidePrices]="true"
      [readOnly]="false"
      [items]="filterEntries(orderTemplate.entries)"
      [cartIsLoading]="false"
      (updateCartEmitter)="updateTemplate($event)"
    ></valio-cx-cart-item-list>

    <p class="order-template-empty" *ngIf="orderTemplate.entries.length == 0">
      {{'myaccount.orderTemplate.empty' | cxTranslate}}
    </p>
  </div>

  <div *ngIf="orderTemplate.entries.length != 0" class="valio-cart-sticky-total-bar-rows">
    <div class="row">
      <div class="col-12 action text-right">
        <button
          [disabled]="!enableSaveBtn"
          [ngClass]="enableSaveBtn ? 'btn-secondary-orange' : 'btn-primary'"
          class="btn btn-lg btn-block-sm-down margin-bottom-base margin-right-base" type="submit" (click)="save()">
          {{ 'myaccount.baseorder.save' | cxTranslate }}
        </button>
        <ng-container *ngIf="routeCalendar$|async as routeCalendar">
          <ng-container *ngIf="requestedDate$|async as requestedDate">
            <div class="popupCalendar" *ngIf="isCalendarShown">
              <valio-calendar-date-component
                [routeCalendar]="routeCalendar"
                [selectedDate]="requestedDate"
                (update)="addToCart($event)"
                [showActivities]="false"
              >
              </valio-calendar-date-component>
            </div>
          </ng-container>
        </ng-container>
        <button class="btn btn-lg btn-block-sm-down btn-primary margin-bottom-base"
                type="submit"
                (click)="showHideCalendar()"
                [disabled]="enableSaveBtn">
          {{ 'myaccount.baseorder.addToCart' | cxTranslate }}
        </button>
        <div [hidden]="!enableSaveBtn">{{'myaccount.baseorder.saveBeforeAdd' | cxTranslate}}</div>
      </div>
    </div>
  </div>
</div>
