import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {AuthService, RoutingService} from '@spartacus/core';
import {Observable} from 'rxjs';
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class ValioInvoicePageGuard implements CanActivate {

  constructor(
    protected routingService: RoutingService,
    protected authService: AuthService,
    protected cartService: ValioCartService,
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.cartService.getActive().pipe(
      map(cart => {
        if (cart.paymentModeGroupedEntries) {
          if (cart.paymentModeGroupedEntries['INVOICE'] == null || !cart.paymentModeGroupedEntries['INVOICE'].hasUnapprovedLines) {
            this.routingService.go('checkout/review-order-credit');
            return false;
          }
          return true;
        }
        return false;
      })
    );
  }
}
