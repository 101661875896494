import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {CartDetailsModule} from "@spartacus/cart/base/components";
import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {PromotionsModule} from "@spartacus/storefront";
import {ValioCarouselModule} from "../../../../shared/components/carousel/valio-carousel.module";
import {ValioProductListModule} from "../../valio-product-list.module";
import {ValioLastPurchasesComponent} from "./valio-last-purchases.component";


@NgModule({
    imports: [
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioLastPurchasesComponent: {
                    component: ValioLastPurchasesComponent,
                },
            },
        }),
        I18nModule,
        PromotionsModule,
        CommonModule,
        ValioProductListModule,
        ValioCarouselModule,
    ],
    declarations: [ValioLastPurchasesComponent],
    exports: [ValioLastPurchasesComponent]
})
export class ValioLastPurchasesModule extends CartDetailsModule {
}
