import {Component} from '@angular/core';

@Component({
  selector: 'valio-cx-order-template-header',
  templateUrl: './valio-order-template-header.component.html',
})
export class ValioOrderTemplateHeaderComponent {
  constructor() {
  }
}
