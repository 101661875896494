import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Price} from "@spartacus/core";

@Component({
  selector: 'valio-cx-review-submit-title',
  templateUrl: './valio-review-submit-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioReviewSubmitTitleComponent {
  @Input() title: string;
  @Input() items: number;
  @Input() priceWithTax: Price;
  @Input() priceWithoutTax: Price;
  @Input() showButton: boolean;
  @Input() buttonEnabled: boolean;
  @Input() buttonTextKey: string;

  @Output()
  clickButton = new EventEmitter<any>();

  click() {
    this.clickButton.emit();
  }
}
