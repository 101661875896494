<ng-container *ngIf="!deliveryNoteCreated; else noteCreated" [formGroup]="form">
  <div class="valio-modal modal-dialog modal-dialog-centered">
    <div class="modal-content wide-modal">
      <h3 class="modal-header">{{ 'deliveryNotes.reclamation.title' | cxTranslate}}</h3>
      <section class="text-left delivery-info">
        <div class="row">
          <div class="col-12">
            <h4 class="margin-bottom-base">{{deliveryNote.partner.name}}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4">
            <p>
              {{'deliveryNotes.reclamation.note'|cxTranslate:{billingDocument: deliveryNote.billingDocument} }}<br/>
              {{'deliveryNotes.reclamation.date'|cxTranslate:{deliveryDate: convertDate(deliveryNote.deliveryDate)|valioDate} }}
            </p>
          </div>
          <div class="col-12 col-lg-4">
            <p>
              {{deliveryNoteList.unit.uid}}<br/>
              {{deliveryNoteList.unit.name}}<br/>
              {{deliveryNoteList.unit.shippingAddress.formattedAddress}}
            </p>
          </div>
          <div class="col-12 col-lg-4">
            <p>
              {{deliveryNoteList.email}}<br/>
              {{deliveryNoteList.phone}}
            </p>
          </div>
        </div>
      </section>

      <ng-container *ngFor="let item of getItemsControl(); let idx = index" formArrayName="items">
        <div class="reclamation-item" *ngIf="item && productList$|async as productList" [formGroupName]="idx">
          <div class="row" *ngIf="errorMessage">
            <div class="col-12 message ERROR">{{errorMessage}}</div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-sm-8 col-md-6 col-lg-7">
                  <div class="input-label margin-bottom-base" [class.is-invalid]="isInvalidItem(idx,'material')">
                    <ng-select
                      (change)="selectProduct(idx,$event)"
                      [clearable]="true"
                      [closeOnSelect]="true"
                      [formControlName]="'material'"
                      [items]="productList.products"
                      [searchable]="true"
                      bindLabel="product.name"
                      bindValue="product.code"
                      placeholder="{{ 'deliveryNotes.reclamation.selectProduct' | cxTranslate}}"
                    ></ng-select>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-6 col-lg-5">
                  <div class="valio-cx-item-counter margin-bottom-base">
                    <div
                      class="cx-counter cx-counter-lg small-text btn-group"
                      role="group"
                      tabindex="0"
                      aria-label="Add more items"
                    >
                      <button
                        type="button"
                        class="cx-counter-action btn-left"
                        (click)="decrement(idx)"
                      >
                        -
                      </button>
                      <button
                        type="button"
                        class="cx-counter-action btn-right"
                        (click)="increment(idx)"
                      >
                        +
                      </button>
                      <div class="cx-counter-content">
                        <input
                          class="cx-counter-value"
                          type="text"
                          name="value"
                          cxOnlyNumber
                          [formControlName]="'quantity'"
                        />
                        <div class="cx-counter-unit">
                          {{getItem(idx).controls['salesUnitName'].value}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-10 col-md-5">
              <div class="row">
                <div class="col-12 col-sm-5 col-md-4 col-lg-5">
                  <div class="valio-input dark-input small-text margin-bottom-base">
                    <div class="input-label" [ngClass]="hasValue(idx,'remark') ? 'has-value' : ''" [class.is-invalid]="isInvalidItem(idx,'remark')">
                      <label for="remark" class="text-left" [ngClass]="hasValue(idx,'remark') ? 'has-value' : ''">
                        <span class="label-content">{{'deliveryNotes.reclamation.stamp' | cxTranslate}}</span>
                      </label>
                      <input class="form-control" [formControlName]="'remark'" id="remark" name="remark" placeholder="{{'deliveryNotes.reclamation.stamp' | cxTranslate}}" type="text"/>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-7 col-md-8 col-lg-7">
                  <div class="input-label margin-bottom-base" [class.is-invalid]="isInvalidItem(idx,'reason')">
                    <ng-select
                      [clearable]="false"
                      [closeOnSelect]="true"
                      [formControlName]="'reason'"
                      [items]="productList.reasons"
                      [searchable]="false"
                      bindLabel="reasonText"
                      bindValue="reason"
                      placeholder="{{ 'deliveryNotes.reclamation.selectReason' | cxTranslate}}"
                    ></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-2 col-md-1 add-remove-rows">
              <div class="margin-bottom-base">
                <span class="btn btn-primary btn-block btn-lg btn-icon" (click)="addItem()" *ngIf="idx == 0">+</span>
                <span class="btn btn-primary btn-block btn-lg btn-icon" (click)="removeItem(idx)" *ngIf="idx != 0">-</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="reclamation-note margin-bottom-md" formGroupName="header">
        <div class="row">
          <div class="col-12 col-md-6">
            <textarea class="form-control" [formControlName]="'note'" placeholder="{{ 'deliveryNotes.reclamation.commentary' | cxTranslate}}"></textarea>
          </div>
          <div class="col-12 col-md-6 text-left margin-top-base">
            <label>
              <input class="form-control" [formControlName]="'contactType'" type="radio" value="0">
              <span class="pl-2">{{ 'deliveryNotes.reclamation.email' | cxTranslate}}</span>
            </label>
            <label>
              <input class="form-control" [formControlName]="'contactType'" type="radio" value="1">
              <span class="pl-2">{{ 'deliveryNotes.reclamation.phone' | cxTranslate}}</span>
            </label>
            <label>
              <input class="form-control" [formControlName]="'contactType'" type="radio" value="2">
              <span class="pl-2">{{ 'deliveryNotes.reclamation.none' | cxTranslate}}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="footer-panel pt-4 text-center">
        <button
          (click)="dismissModal('cancel')"
          class="btn btn-lg btn-secondary"
          type="button">
          {{'deliveryNotes.reclamation.cancelButton'|cxTranslate}}
        </button>
        <button
          (click)="createNote()"
          class="btn btn-lg btn-primary ml-3"
          type="button">
          {{'deliveryNotes.reclamation.sendButton'|cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noteCreated>
  <div class="valio-modal modal-dialog modal-dialog-centered">
    <div class="modal-content wide-modal">
      <div class="container-center">
        <h3 class="modal-header">{{ 'deliveryNotes.reclamation.thanks' | cxTranslate}}</h3>
        <p class="margin-bottom-base">{{ 'deliveryNotes.reclamation.thanksMessage' | cxTranslate}}</p>
        <button
          (click)="dismissModal('created')"
          class="btn btn-primary"
          type="button">
          {{'deliveryNotes.reclamation.thanksClose'|cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>
