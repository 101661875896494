import {Injectable} from "@angular/core";
import {Converter, ConverterService, PRODUCT_NORMALIZER} from "@spartacus/core";
import {ValioCart, ValioOccCart} from "./valio-cart.objects";
import {ValioOccOrderHistoryNormalizer} from "../user/valio-order-history-normalizer";

@Injectable()
export class ValioOccCartNormalizer implements Converter<ValioOccCart, ValioCart> {
  constructor(private converter: ConverterService) {
  }

  convert(source: ValioOccCart, target?: ValioCart): ValioCart {
    if (target === undefined) {
      target = {...(source as any)};
    }
    if (source && source.paidEntries) {
      target.paidEntries = source.paidEntries
        .map(e => (
          {
            ...e,
            paidEntries: this.converter.convert(e.product, PRODUCT_NORMALIZER)
          })
        );
    }

    if (source && source.dateGroupedEntries) {
      target.dateGroupedEntries = source.dateGroupedEntries.map(e => ValioOccOrderHistoryNormalizer.convertGroupedEntries(e, this.converter));
    }
    if (source && source.paymentModeGroupedEntries) {
      target.paymentModeGroupedEntries = source.paymentModeGroupedEntries.map(e => ValioOccOrderHistoryNormalizer.convertGroupedEntries(e, this.converter));
    }
    ValioOccOrderHistoryNormalizer.handlePaymentEntries(target);
    return target;
  }

}

