import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';

import {StoreModule} from "@ngrx/store";
import {ValioLastPurchasesEffects} from "./valio-last-purchases.effect";
import {LAST_PURCHASES_FEATURE} from "./valio-last-purchases.action";
import {metaReducers, reducerToken, valioLastPurchasesReducerProvider} from "./valio-last-purchases.reducer";


@NgModule({
  imports: [
    StoreModule.forFeature(LAST_PURCHASES_FEATURE, reducerToken, {metaReducers}),
    EffectsModule.forFeature([ValioLastPurchasesEffects]),
  ],
  providers: [
    valioLastPurchasesReducerProvider
  ]
})
export class ValioLastPurchaseProductsModule {
}
