import {Injectable, InjectionToken} from "@angular/core";
import {Converter, ConverterService, PRODUCT_NORMALIZER} from "@spartacus/core";
import {ValioBaseOrder, ValioBaseOrderEntry, ValioBaseWeekdayEntry} from "../cart/valio-cart.objects";


export const BASE_ORDER_NORMALIZER = new InjectionToken<Converter<ValioBaseOrder, ValioBaseOrder>>(
  'BaseOrderNormalizer'
);

@Injectable()
export class ValioBaseOrderNormalizer implements Converter<ValioBaseOrder, ValioBaseOrder> {
  constructor(private converter: ConverterService) {
  }

  convert(source: ValioBaseOrder, target?: ValioBaseOrder): ValioBaseOrder {
    if (target === undefined) {
      target = {...(source as any)};
    }
    if (source && source.entries) {
      target.entries = source.entries
        .map(e => (
            {
              ...e,
              entries: e.entries.map(
                entry => (
                  {
                    ...entry,
                    product: this.converter.convert(entry.product, PRODUCT_NORMALIZER)
                  }
                ) as ValioBaseOrderEntry
              )
            }
          ) as ValioBaseWeekdayEntry
        );
    }

    return target;
  }

}

