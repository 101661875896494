<div class="product-delivery-period">
  <ng-container *ngIf="product.deliveryPeriodFrom && !product.deliveryPeriodTo">
    <ng-container *ngIf="showLongText; else shortFrom">
      {{'valioProduct.deliveryPeriodFromLong'|cxTranslate: {dateFrom: product.deliveryPeriodFrom|valioDate:'shortDate'} }}
    </ng-container>
    <ng-template #shortFrom>
      {{'valioProduct.deliveryPeriodFrom'|cxTranslate: {dateFrom: product.deliveryPeriodFrom|valioDate:'d.M.'} }}
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!product.deliveryPeriodFrom && product.deliveryPeriodTo">
    <ng-container *ngIf="showLongText; else shortTo">
      {{'valioProduct.deliveryPeriodToLong'|cxTranslate: {dateTo: product.deliveryPeriodTo|valioDate:'shortDate'} }}
    </ng-container>
    <ng-template #shortTo>
      {{'valioProduct.deliveryPeriodTo'|cxTranslate: {dateTo: product.deliveryPeriodTo|valioDate:'d.M.'} }}
    </ng-template>
  </ng-container>
  <ng-container *ngIf="product.deliveryPeriodFrom && product.deliveryPeriodTo">
    <ng-container *ngIf="showLongText; else shortFromTo">
      {{'valioProduct.deliveryPeriodFromTo'|cxTranslate: {dateFrom: product.deliveryPeriodFrom|valioDate:'shortDate', dateTo: product.deliveryPeriodTo|valioDate:'shortDate'} }}
    </ng-container>
    <ng-template #shortFromTo>
      {{'valioProduct.deliveryPeriodFromTo'|cxTranslate: {dateFrom: product.deliveryPeriodFrom|valioDate:'d.M.', dateTo: product.deliveryPeriodTo|valioDate:'d.M.'} }}
    </ng-template>
  </ng-container>
</div>
