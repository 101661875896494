<valio-cx-order-template-header></valio-cx-order-template-header>
<div *ngIf="orderTemplates$|async as orderTemplates" class="order-template-grid">
  <div class="row">
    <div *ngIf="orderTemplates.length == 0" class="col-12 text-center">
      <button class="btn btn-primary" type="submit" (click)="openCreateTemplate()">
        {{ 'myaccount.orderTemplate.new' | cxTranslate }}
      </button>
    </div>
    <div *ngFor="let template of orderTemplates" class="col-12 col-md-6 col-lg-4 grid-item">
      <div class="grid-item-inner">
        <a class="grid-item-link" [routerLink]="'/my-account/modify-order-template/' + template.code">
          <div class="row">
            <ng-container *ngFor="let entry of template.entries; index as idx">
              <div *ngIf="idx < 6" class="col-4">
                <div class="cx-image-container">
                  <cx-media
                    [container]="entry.product.images?.PRIMARY"
                    format="cartIcon"></cx-media>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="template.entries.length < 6">
              <ng-container *ngFor="let item of [].constructor(6 - template.entries.length); index as idx">
                <div *ngIf="idx < 6" class="col-4">
                  <div class="cx-image-container"></div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </a>
        <div class="grid-footer">
          <div class="row">
            <div class="col-12">
              <span class="grid-footer-date">{{convertDate(template.requestedDate) | valioDate :'mediumDate'}}</span>
              <span class="grid-footer-products">{{'checkout.header.products'|cxTranslate:{products: template.entries.length} }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <a [routerLink]="['/my-account/modify-order-template',template.code]" class="grid-footer-name bold" href="#">{{template.name}}</a>
            </div>
            <div class="col-4 text-right">
              <a (click)="copyTemplate(template.code, template.name)" class="grid-footer-copy bold">{{ 'myaccount.orderTemplate.copyBtn' | cxTranslate }}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex">
                <a [routerLink]="['/my-account/modify-order-template',template.code]" class="btn btn-block btn-primary flex-fill margin-right-base" href="#">
                  {{ 'myaccount.orderTemplate.open' | cxTranslate }}
                </a>
                <button
                  (click)="removeTemplate(template.code)"
                  class="btn btn-icon delete-icon valio-icon icon-remove">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
