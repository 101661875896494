import { NgModule } from '@angular/core';
import {CheckoutCoreModule} from "./core";
import {CheckoutOccModule} from "@spartacus/checkout/base/occ";
import {CheckoutComponentsModule} from "@spartacus/checkout/base/components";


@NgModule({
  imports: [CheckoutComponentsModule, CheckoutCoreModule, CheckoutOccModule],
})
export class CheckoutModule {}
