import {ChangeDetectionStrategy, Component, ComponentRef, OnDestroy} from '@angular/core';
import {GlobalMessageService, RoutingService, TranslationService} from "@spartacus/core";
import {Order, OrderFacade} from '@spartacus/order/root';
import {Observable, Subscription} from "rxjs";
import {ValioOrder} from "../../../../../services/checkout/valio-checkout.module";
import {ValioUserService} from "../../../../../services/user/valio-user.service";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {OrderConfirmationThankYouMessageComponent} from "@spartacus/order/components";
import {
  ValioCreateOrderTemplateDialogData
} from "../../../order-template/popup/valio-create-order-template-layout.config";
import {ValioCheckoutService} from "../../../../../services/checkout/valio-checkout.service";
import {ActivatedRoute} from "@angular/router";
import {map, mergeMap} from "rxjs/operators";
import {Actions, ofType} from "@ngrx/effects";
import {PLACE_ORDER_SUCCESS} from "../../../../checkout/core/store/actions/checkout.action";
import {PlaceOrderSuccess} from "../../../../../services/cart/valio-cart-entry.action";


@Component({
  selector: 'valio-cx-order-confirmation-thank-you-message',
  templateUrl: './valio-order-confirmation-thank-you-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioOrderConfirmationThankYouMessageComponent extends OrderConfirmationThankYouMessageComponent {
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected routingService: RoutingService,
    protected userService: ValioUserService,
    protected launchDialogService: LaunchDialogService,
    protected checkoutService: ValioCheckoutService,
    protected route: ActivatedRoute,
    protected actions$: Actions,
    orderFacade: OrderFacade,
    globalMessageService: GlobalMessageService,
    translationService: TranslationService
    ) {
    super(orderFacade, globalMessageService, translationService);
  }

  ngOnInit() {
    // Override default ngOnInit as orders are loaded differently by standard SAP.
    // Will cause that this component won't be visible as order is null.
    //super.ngOnInit();

    // PLACE_ORDER_SUCCESS is dispatched by ValioOrderConfirmationItemsComponent's
    // this.checkoutService.loadOrder(date, orderCode);
    this.order$ = this.actions$.pipe(
      ofType(PLACE_ORDER_SUCCESS),
      map((action: PlaceOrderSuccess) => action),
      mergeMap((payload) => {
          return (this.checkoutService.getOrderDetails() as Observable<ValioOrder>)
        }
      ));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }

  print() {
    window.print();
  }

  createTemplate(order: Order) {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CREATE_ORDER_TEMPLATE, undefined, {
        copyDate: (order as ValioOrder).requestedDate,
        titleKey: 'myaccount.orderTemplate.new',
        buttonKey: 'myaccount.orderTemplate.newBtn'
      } as ValioCreateOrderTemplateDialogData
    );


  }

  logout() {
  }

  isContractCustomer(): Observable<boolean> {
    return this.userService.isContractCustomer();
  }
}
