<div class="cx-item row no-gutters" [ngClass]="{'deleted': item.deleted }">
  <div class="col-12" *ngIf="item.erpMessages">
    <!-- error messages -->
    <valio-cart-item-message
      [item]="item"
      [compact]="compact"
      (remove)="removeItem($event)"
      (update)="updateEntryData($event)"
    ></valio-cart-item-message>
  </div>
  <!-- Item Image -->
  <div class="col-2 no-gutters">
    <!-- Item Image -->
    <a [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
       class="cx-product-image-container"
       (click)="viewItem()">
      <cx-media
        class="cx-product-image"
        [container]="item.product.images?.PRIMARY"
        format="cartIcon"></cx-media>
    </a>
  </div>

  <!-- Item Description -->
  <div class="col-md-5 padding-top-sm">
    <div *ngIf="item.product.name" class="cx-name margin-right-base">
      <a class="cx-link"
         [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
         (click)="viewItem()"
      >{{ item.product.name }}</a>
    </div>
  </div>

  <!-- Item Quantity -->
  <div class="cx-quantity col-md-4">
    <div class="cx-label d-block d-lg-none d-xl-none" title="{{ 'cartItems.quantityTitle' | cxTranslate }}">
      {{ 'cartItems.quantity' | cxTranslate }}
    </div>
    <div *ngIf="getIsReadOnly()" class="cx-value padding-top-sm">{{ item.quantity }}
      <span class="unit-name">{{item.unit.name}}</span>
    </div>
    <div *ngIf="!getIsReadOnly() && parent" class="cx-value" [formGroup]="parent">
      <valio-cx-item-counter
        [isValueChangeable]="item.updateable"
        [step]="1"
        [min]="0"
        [value]="item.quantity"
        [max]="item.product.stock?.stockLevel || 1000"
        (update)="updateEntryData($event)"
        [cartIsLoading]="cartIsLoading"
        [showButtons]="true"
        [unit]="item.unit"
        [id]="'itemCounterInput_'+item.entryNumber"
        [styleClass]="'cx-counter'"
      >
      </valio-cx-item-counter>
    </div>
  </div>


  <div *ngIf="item.deletable && allowDelete" class="col-md-1">
    <button
      class="btn"
      [class.disabled]="cartIsLoading"
      [disabled]="cartIsLoading"
      [ngClass]="{'delete-icon btn-icon':!item.deleted,'recover-icon btn-link':item.deleted}"
      (click)="removeItem($event)">

      <ng-container *ngIf="item.deleted; else icon">
        {{('cart.cartItems.recover') | cxTranslate }}
      </ng-container>

      <ng-template #icon>
        <span class="valio-icon icon-remove"></span>
      </ng-template>
    </button>
  </div>

  <div class="cart-item-status col-md-2">
    <span class="dot {{item.statusDisplay}}"></span>
    {{'cart.cartItems.statusshort.' + item.statusDisplay|cxTranslate}}
  </div>

  <div class="col-md-5">
    <div *ngIf="item.product.code" class="cx-code">
      {{ 'cartItems.id' | cxTranslate }} {{ item.product.code | productCode }}
      | {{item.unit.conversionToInvoiceUnit}}
    </div>
  </div>

  <!-- Total -->
  <div *ngIf="item.totalPrice" class="cx-total col-md-5">
    <valio-product-icons
      [entry]="item"
    ></valio-product-icons>
    <div class="cx-value text-bold d-inline-block float-right">{{ item.totalPrice?.formattedValue }}</div>
  </div>
</div>
