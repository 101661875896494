import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import {CmsSearchBoxComponent, RoutingService, WindowRef} from '@spartacus/core';
import {CmsComponentData} from "@spartacus/storefront";
import {ValioPartnerSiteService} from "../../../../services/site/valio-partner-site.service";
import {ValioSuggestiveSearchBoxComponent} from "../suggestive-search-box/valio-suggestive-search-box.component";
import {
  ValioSearchBoxComponentService
} from "../../cart/cart-detail/suggestive-search/valio-search-box-component.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'valio-cx-searchbox',
  templateUrl: './valio-search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioSearchBoxComponent extends ValioSuggestiveSearchBoxComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('searchInput', {static: false})
  searchInputField: ElementRef;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    protected routing: RoutingService,
    protected searchBoxComponentService: ValioSearchBoxComponentService,
    protected partnerSiteService: ValioPartnerSiteService,
    winRef: WindowRef,
    @Optional()
    protected componentData: CmsComponentData<CmsSearchBoxComponent>
  ) {
    super(cdr, routing, searchBoxComponentService, winRef, componentData);
    this.subscriptions.add(
      partnerSiteService.getActive().subscribe(
        () => {
          if (this.searchInputField) {
            this.searchInputField.nativeElement.value = '';
          }
        }
      )
    );
  }

  ngAfterViewChecked(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  launchSearchResult(event: UIEvent, query: string): void {
    if (!query || query.trim().length === 0) {
      query = '*';
    }
    this.routingService.go('/search', {queryParams: {'query': query + this.filterQuery}});
    this.clearSearch();
  }
}
