<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div class="cart-details-wrapper">
      <div class="cx-total">
        {{ 'cartDetails.cartName' | cxTranslate: {code: cart.code} }}
      </div>
      <valio-cx-cart-item-list
        [items]="entries"
        [readOnly]="true"
        [cartIsLoading]="!(cartLoaded$ | async)"
        (updateCartEmitter)="updateCart($event)"
      ></valio-cx-cart-item-list>
      <!-- NOT FOR MVP  <cx-cart-coupon></cx-cart-coupon> -->
    </div>
  </ng-container>
</ng-container>
