import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsThreeBoxBannerComponent} from '../../../../models';


@Component({
  selector: 'valio-cx-three-box-banner',
  templateUrl: './valio-three-box-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioThreeBoxBannerComponent {

  data$ = this.componentData.data$;

  constructor(
    protected componentData: CmsComponentData<ValioCmsThreeBoxBannerComponent>,
  ) {
  }
}
