<div class="calendar">
  <div class="calendar-wrapper" id="calendar-wrapper">
    <div *ngIf="calendarMonths" class="calendar-table">
      <div #body id="calendar-body">
        <div class="calendar-head-row">
          <div>{{'calendar.week' | cxTranslate}}</div>
          <div>{{'calendar.monday' | cxTranslate}}</div>
          <div>{{'calendar.tuesday' | cxTranslate}}</div>
          <div>{{'calendar.wednesday' | cxTranslate}}</div>
          <div>{{'calendar.thursday' | cxTranslate}}</div>
          <div>{{'calendar.friday' | cxTranslate}}</div>
          <div>{{'calendar.saturday' | cxTranslate}}</div>
          <div>{{'calendar.sunday' | cxTranslate}}</div>
        </div>
        <ng-container *ngFor="let month of calendarMonths;">
          <div class="calendar-month-row">
            <div class="week"></div>
            <div class="month">
              <span>{{'cart.header.month.' + (month.month + 1) | cxTranslate}}</span>
            </div>
          </div>
          <ng-container *ngFor="let week of month.weeks; let i = index">
            <div [ngClass]="isLastWeekOfMonth(week, month) ? 'last' : ''" class="calendar-week-row">
              <div class="week">{{week.weekNumber}}</div>
              <ng-container *ngFor="let days of week.days; let y = index">
                <ng-container>
                  <div (click)="selectDay($event)"
                       [attr.data-day]="days.date"
                       [ngClass]="{
                       'has-items' : getOrderedCartItems(days) > 0||days.unapprovedLines > 0,
                       'has-closed' : days.status === 'closed',
                       'has-open' : days.status === 'open',
                       'has-accepted' :  days.status === 'approved',
                       'today' : days.today,
                       'holiday-date' : days.holiday,
                       'active' : isDateSelected(days),
                       'selectable' : days.deliveryDate,
                       'non-selectable' : !days.deliveryDate,
                       'weekend' : days.dayDate.getDay() == 6 ||  days.dayDate.getDay() == 0,
                       'exceptionDate' : days.exceptionDate
                       }" class="{{days.status}} day js-calendarDay" id="day_{{days.date}}">
                    <span *ngIf="isSameMonth(month, days.dayDate)" title="{{days.dayDate|valioDate}}">{{days.dayDate.getDate()}}</span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="showActivities" (click)="selectDay($event)" [attr.data-day]="firstAvailableDay" class="calendar-back-today">{{'calendar.today' | cxTranslate}}</div>
</div>

<h1 class="activity-log-header d-lg-none">{{'orderhistory.future'|cxTranslate}}</h1>

<div *ngIf="showActivities" class="activity-log col-12">
  <div *ngIf="routeCalendar as activityCalendar" class="activity-entry-wrapper mobile-view" id="activity-entry-wrapper">
    <ng-container *ngFor="let activity of activityCalendar.activities">
      <div [ngClass]="{
      'active' : isActivitySelected(activity),
      'today' : this.getRouteDate(activity.date) == null ? false : this.getRouteDate(activity.date).today
      }" class="activity-entry gutter-24" id="activity_{{activity.date}}">
        <div class="col-2">
          <valio-date-view
            [date]="convertDate(activity.date)"
            [notShowDate]="true"
          ></valio-date-view>
        </div>
        <div class="col-10">
          <div (click)="selectDay($event)" *ngIf="activity.order" [attr.data-day]="activity.date" [ngClass]="{
          'active' :        isActivitySelected(activity),
          'selectable' :    true,
          'has-open' :      activity.order.status==='open',
          'has-error' :     activity.order.status==='error',
          'has-closed' :    activity.order.status==='closed',
          'has-accepted' :  activity.order.status==='approved',
          'exceptionDate' : getRouteDate(activity.date.toString()) !=null &&
                            getRouteDate(activity.date.toString()).exceptionDate

          }" class="order-history">
            <div class="row">
              <div class="col-7 title-row selected-date">
                <span
                  *ngIf="isActivitySelected(activity)">
                {{'calendar.isActivitySelected(activity)'|cxTranslate}}&nbsp;
                </span>
                {{convertDate(activity.date)|valioDate:'shortDate'}}</div>
              <ng-container *ngIf="activity.order.status!='empty' || (activity.order.status=='empty' && isActivitySelected(activity)); else emptyCard">
                <div class="col-5 title-row text-right">{{activity.order.totalPrice.formattedValue}}</div>
                <div class="col-7 sub-title-row">
                  <ng-container *ngIf="activity.order.totalItems != 0">
                    {{('cart.cartItems.status.' + activity.order.status)|cxTranslate}}
                  </ng-container>
                  <ng-container *ngIf="activity.order.status=='empty'">
                    <span class="add-products">{{'cart.cartItems.status.emptyTextAddProducts'|cxTranslate}}</span>
                  </ng-container>
                </div>
                <div class="col-5 sub-title-row text-right">{{'cart.header.products'|cxTranslate:{products: activity.order.totalItems} }}</div>
                <div class="col-12 horizontal-row">
                  <hr/>
                </div>
                <div class="col-12 text-right">
                  <button
                    (click)="selectDay($event)"
                    [attr.data-day]="activity.date"
                    [ngClass]="{
                      'btn-link-inv' : isActivitySelected(activity),
                      'btn-link' : !isActivitySelected(activity)
                      }"
                    [routerLink]="'/cart'"
                    class="btn btn-icon-link"
                  >{{'calendar.activity.show'|cxTranslate}}
                    <span class="valio-icon icon-chevron icon-chevron-right" [ngClass]="{'icon-chevron-white' : isActivitySelected(activity)}"></span>
                  </button>
                </div>

              </ng-container>
              <ng-template #emptyCard>
                <div class="col-12 title-row">{{('cart.cartItems.status.' + activity.order.status)|cxTranslate}}</div>
                <div class="col-12 sub-title-row">{{'cart.cartItems.status.emptytext'|cxTranslate}} <span
                  class="select-product-text">{{'cart.cartItems.status.emptytextSelectProduct'|cxTranslate}}</span> <span
                  class="select-date-text">{{'cart.cartItems.status.emptytextSelect'|cxTranslate}}</span></div>
              </ng-template>
            </div>
          </div>
          <div *ngIf="activity.holiday" class="holiday">
            <div class="row">
              <div class="col-12 title-row">{{activity.holiday}}</div>
              <div class="col-12 sub-title-row"></div>
            </div>
          </div>
          <div class="order-block" *ngIf="activity.baseOrderDeliveryBlockOn">
            {{'cart.cartItems.baseOrderDeliveryBlockOn'|cxTranslate}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div id="activity-log-button" class="activity-log-button col-12 d-lg-none text-center">
    <button class="btn btn-primary" (click)="showAllActivityEntries()">{{'categories.showmore'|cxTranslate}}</button>
  </div>
</div>
