import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    AuthActions,
    GlobalMessageActions,
    normalizeHttpError,
    OCC_USER_ID_ANONYMOUS,
    SiteContextActions,
    UserActions,
    withdrawOn,
} from '@spartacus/core';
import {from, Observable, of} from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

import {CheckoutActions} from '../actions';
import {
    CheckoutDeliveryAddressConnector, CheckoutDeliveryModesConnector,
    CheckoutPaymentConnector
} from "@spartacus/checkout/base/core";
import {CheckoutCostCenterConnector} from "@spartacus/checkout/b2b/core";
import {CartActions} from "@spartacus/cart/base/core";
import {CheckoutState} from "@spartacus/checkout/base/root";
import {OrderConnector} from "@spartacus/order/core";
import {ValioOccCheckoutAdapter} from "../../../../../services/checkout/valio-occ-checkout.adapter";

@Injectable()
export class CheckoutEffects {
    private contextChange$ = this.actions$.pipe(
        ofType(
            SiteContextActions.CURRENCY_CHANGE,
            SiteContextActions.LANGUAGE_CHANGE
        )
    );

    addDeliveryAddress$: Observable<| UserActions.LoadUserAddresses | CheckoutActions.SetDeliveryAddress | CheckoutActions.AddDeliveryAddressFail> = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.ADD_DELIVERY_ADDRESS),
            map((action: CheckoutActions.AddDeliveryAddress) => action.payload),
            mergeMap((payload) =>
                this.checkoutDeliveryAddressConnector
                    .createAddress(payload.userId, payload.cartId, payload.address)
                    .pipe(
                        mergeMap((address) => {
                            address['titleCode'] = payload.address.titleCode;
                            if (payload.address.region && payload.address.region.isocodeShort) {
                                Object.assign(address.region, {
                                    isocodeShort: payload.address.region.isocodeShort,
                                });
                            }
                            if (payload.userId === OCC_USER_ID_ANONYMOUS) {
                                return [
                                    new CheckoutActions.SetDeliveryAddress({
                                        userId: payload.userId,
                                        cartId: payload.cartId,
                                        address: address,
                                    }),
                                ];
                            } else {
                                return [
                                    new UserActions.LoadUserAddresses(payload.userId),
                                    new CheckoutActions.SetDeliveryAddress({
                                        userId: payload.userId,
                                        cartId: payload.cartId,
                                        address: address,
                                    }),
                                ];
                            }
                        }),
                        catchError((error) =>
                            of(
                                new CheckoutActions.AddDeliveryAddressFail(
                                    normalizeHttpError(error)
                                )
                            )
                        )
                    )
            ),
            withdrawOn(this.contextChange$)
        )
    );
    setDeliveryAddress$: Observable<
        | CheckoutActions.SetDeliveryAddressSuccess
        | CheckoutActions.ClearSupportedDeliveryModes
        | CheckoutActions.ClearCheckoutDeliveryMode
        | CheckoutActions.ResetLoadSupportedDeliveryModesProcess
        | CheckoutActions.LoadSupportedDeliveryModes
        | CheckoutActions.SetDeliveryAddressFail
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.SET_DELIVERY_ADDRESS),
            map((action: any) => action.payload),
            mergeMap((payload) => {
                return this.checkoutDeliveryAddressConnector
                    .setAddress(payload.userId, payload.cartId, payload.address.id)
                    .pipe(
                        mergeMap(() => [
                            new CheckoutActions.SetDeliveryAddressSuccess(payload.address),
                            new CheckoutActions.ClearCheckoutDeliveryMode({
                                userId: payload.userId,
                                cartId: payload.cartId,
                            }),
                            new CheckoutActions.ClearSupportedDeliveryModes(),
                            new CheckoutActions.ResetLoadSupportedDeliveryModesProcess(),
                            new CheckoutActions.LoadSupportedDeliveryModes({
                                userId: payload.userId,
                                cartId: payload.cartId,
                            }),
                        ]),
                        catchError((error) =>
                            of(
                                new CheckoutActions.SetDeliveryAddressFail(
                                    normalizeHttpError(error)
                                )
                            )
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );
    loadSupportedDeliveryModes$: Observable<
        | CheckoutActions.LoadSupportedDeliveryModesSuccess
        | CheckoutActions.LoadSupportedDeliveryModesFail> = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.LOAD_SUPPORTED_DELIVERY_MODES),
            map((action: any) => action.payload),
            mergeMap((payload) => {
                return this.checkoutDeliveryModesConnector
                    .getSupportedModes(payload.userId, payload.cartId)
                    .pipe(
                        map((data) => {
                            return new CheckoutActions.LoadSupportedDeliveryModesSuccess(data);
                        }),
                        catchError((error) =>
                            of(
                                new CheckoutActions.LoadSupportedDeliveryModesFail(
                                    normalizeHttpError(error)
                                )
                            )
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );

    clearCheckoutMiscsDataOnLanguageChange$: Observable<
        | CheckoutActions.CheckoutClearMiscsData
        | CheckoutActions.ResetLoadSupportedDeliveryModesProcess
        | CheckoutActions.ResetLoadPaymentTypesProcess
    > = createEffect(() => this.actions$.pipe(
            ofType(SiteContextActions.LANGUAGE_CHANGE),
            mergeMap(() => [
                new CheckoutActions.ResetLoadSupportedDeliveryModesProcess(),
                new CheckoutActions.ResetLoadPaymentTypesProcess(),
                new CheckoutActions.CheckoutClearMiscsData(),
            ])
        )
    );
    clearDeliveryModesOnCurrencyChange$: Observable<CheckoutActions.ClearSupportedDeliveryModes> = createEffect(() => this.actions$.pipe(
            ofType(SiteContextActions.CURRENCY_CHANGE),
            map(() => new CheckoutActions.ClearSupportedDeliveryModes())
        )
    );


    clearCheckoutDataOnLogout$: Observable<
        | CheckoutActions.ClearCheckoutData
        | CheckoutActions.ResetLoadSupportedDeliveryModesProcess
        | CheckoutActions.ResetLoadPaymentTypesProcess
    > = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.LOGOUT),
            mergeMap(() => [
                new CheckoutActions.ClearCheckoutData(),
                new CheckoutActions.ResetLoadSupportedDeliveryModesProcess(),
                new CheckoutActions.ResetLoadPaymentTypesProcess(),
            ])
        )
    );

    clearCheckoutDataOnLogin$: Observable<CheckoutActions.ClearCheckoutData> = createEffect(() => this.actions$.pipe(
            ofType(AuthActions.LOGIN),
            map(() => new CheckoutActions.ClearCheckoutData())
        )
    );


    setDeliveryMode$: Observable<
        | CheckoutActions.SetDeliveryModeSuccess
        | CheckoutActions.SetDeliveryModeFail
        | CartActions.LoadCart
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.SET_DELIVERY_MODE),
            map((action: any) => action.payload),
            mergeMap((payload) => {
                return this.checkoutDeliveryModesConnector
                    .setMode(payload.userId, payload.cartId, payload.selectedModeId)
                    .pipe(
                        mergeMap(() => {
                            return [
                                new CheckoutActions.SetDeliveryModeSuccess(
                                    payload.selectedModeId
                                ),
                                new CartActions.LoadCart({
                                    userId: payload.userId,
                                    cartId: payload.cartId,
                                }),
                            ];
                        }),
                        catchError((error) =>
                            of(
                                new CheckoutActions.SetDeliveryModeFail(normalizeHttpError(error))
                            )
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );


    createPaymentDetails$: Observable<
        | UserActions.LoadUserPaymentMethods
        | CheckoutActions.CreatePaymentDetailsSuccess
        | CheckoutActions.CreatePaymentDetailsFail
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.CREATE_PAYMENT_DETAILS),
            map((action: any) => action.payload),
            mergeMap((payload) => {
                // get information for creating a subscription directly with payment provider
                return this.checkoutPaymentConnector
                    .createPaymentDetails(payload.userId, payload.cartId, payload.paymentDetails)
                    .pipe(
                        mergeMap((details) => {
                            if (payload.userId === OCC_USER_ID_ANONYMOUS) {
                                return [new CheckoutActions.CreatePaymentDetailsSuccess(details)];
                            } else {
                                return [
                                    new UserActions.LoadUserPaymentMethods(payload.userId),
                                    new CheckoutActions.CreatePaymentDetailsSuccess(details),
                                ];
                            }
                        }),
                        catchError((error) =>
                            of(
                                new CheckoutActions.CreatePaymentDetailsFail(
                                    normalizeHttpError(error)
                                )
                            )
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );

    setPaymentDetails$: Observable<
        | CheckoutActions.SetPaymentDetailsSuccess
        | CheckoutActions.SetPaymentDetailsFail
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.SET_PAYMENT_DETAILS),
            map((action: any) => action.payload),
            mergeMap((payload) => {
                return this.checkoutPaymentConnector
                    .setPaymentDetails(payload.userId, payload.cartId, payload.paymentDetails.id)
                    .pipe(
                        map(
                            () =>
                                new CheckoutActions.SetPaymentDetailsSuccess(
                                    payload.paymentDetails
                                )
                        ),
                        catchError((error) =>
                            of(
                                new CheckoutActions.SetPaymentDetailsFail(
                                    normalizeHttpError(error)
                                )
                            )
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );

    placeOrder$: Observable<
        | CheckoutActions.PlaceOrderSuccess
        | GlobalMessageActions.AddMessage
        | CheckoutActions.PlaceOrderFail
        | CartActions.RemoveCart
    > = createEffect(() =>
        this.actions$.pipe(
            ofType(CheckoutActions.PLACE_ORDER),
            map((action: any) => action.payload),
            mergeMap((payload) => {
                return this.orderConnector
                    .placeOrder(payload.userId, payload.cartId, payload.termsChecked)
                    .pipe(
                        switchMap((data) => [
                            new CartActions.RemoveCart({cartId: payload.cartId}),
                            new CheckoutActions.PlaceOrderSuccess(data),
                        ]),
                        catchError((error) =>
                            of(new CheckoutActions.PlaceOrderFail(normalizeHttpError(error)))
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );


    loadCheckoutDetails$: Observable<
        | CheckoutActions.LoadCheckoutDetailsSuccess
        | CheckoutActions.LoadCheckoutDetailsFail
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.LOAD_CHECKOUT_DETAILS),
            map((action: CheckoutActions.LoadCheckoutDetails) => action.payload),
            mergeMap((payload) => {
                return this.occCheckoutAdapter
                    .loadCheckoutDetails(payload.userId, payload.cartId)
                    .pipe(
                        map(
                            (data: CheckoutState) =>
                                new CheckoutActions.LoadCheckoutDetailsSuccess(data)
                        ),
                        catchError((error) =>
                            of(
                                new CheckoutActions.LoadCheckoutDetailsFail(
                                    normalizeHttpError(error)
                                )
                            )
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );


    reloadDetailsOnMergeCart$: Observable<CheckoutActions.LoadCheckoutDetails> = createEffect(() => this.actions$.pipe(
            ofType(CartActions.MERGE_CART_SUCCESS),
            map((action: CartActions.MergeCartSuccess) => action.payload),
            map((payload) => {
                return new CheckoutActions.LoadCheckoutDetails({
                    userId: payload.userId,
                    cartId: payload.cartId,
                });
            })
        )
    );

    clearCheckoutDeliveryAddress$: Observable<
        | CheckoutActions.ClearCheckoutDeliveryAddressFail
        | CheckoutActions.ClearCheckoutDeliveryAddressSuccess
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.CLEAR_CHECKOUT_DELIVERY_ADDRESS),
            map(
                (action: CheckoutActions.ClearCheckoutDeliveryAddress) => action.payload
            ),
            filter((payload) => Boolean(payload.cartId)),
            switchMap((payload) => {
                return this.checkoutDeliveryAddressConnector.clearCheckoutDeliveryAddress(payload.userId, payload.cartId)
                    .pipe(
                        map(() => new CheckoutActions.ClearCheckoutDeliveryAddressSuccess()),
                        catchError((error) =>
                            of(
                                new CheckoutActions.ClearCheckoutDeliveryAddressFail(
                                    normalizeHttpError(error)
                                )
                            )
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );


    clearCheckoutDeliveryMode$: Observable<
        | CheckoutActions.ClearCheckoutDeliveryModeFail
        | CheckoutActions.ClearCheckoutDeliveryModeSuccess
        | CartActions.LoadCart
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.CLEAR_CHECKOUT_DELIVERY_MODE),
            map((action: CheckoutActions.ClearCheckoutDeliveryMode) => action.payload),
            filter((payload) => Boolean(payload.cartId)),
            concatMap((payload) => {
                return this.checkoutDeliveryModesConnector.clearCheckoutDeliveryMode(payload.userId, payload.cartId)
                    .pipe(
                        mergeMap(() => [
                            new CheckoutActions.ClearCheckoutDeliveryModeSuccess({
                                ...payload,
                            }),
                            new CartActions.LoadCart({
                                cartId: payload.cartId,
                                userId: payload.userId,
                            }),
                        ]),
                        catchError((error) =>
                            from([
                                new CheckoutActions.ClearCheckoutDeliveryModeFail({
                                    ...payload,
                                    error: normalizeHttpError(error),
                                }),
                                new CartActions.LoadCart({
                                    cartId: payload.cartId,
                                    userId: payload.userId,
                                }),
                            ])
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );

    setCostCenter$: Observable<
        | CheckoutActions.SetCostCenterSuccess
        | CheckoutActions.SetCostCenterFail
        | CheckoutActions.ClearCheckoutDeliveryAddress
        | CartActions.LoadCart
    > = createEffect(() => this.actions$.pipe(
            ofType(CheckoutActions.SET_COST_CENTER),
            map((action: CheckoutActions.SetCostCenter) => action.payload),
            switchMap((payload) => {
                return this.checkoutCostCenterConnector
                    .setCostCenter(payload.userId, payload.cartId, payload.costCenterId)
                    .pipe(
                        mergeMap((_data) => [
                            new CartActions.LoadCart({
                                cartId: payload.cartId,
                                userId: payload.userId,
                            }),
                            new CheckoutActions.SetCostCenterSuccess(payload.costCenterId),
                            new CheckoutActions.ClearCheckoutDeliveryAddress({
                                userId: payload.userId,
                                cartId: payload.cartId,
                            }),
                        ]),
                        catchError((error) =>
                            of(new CheckoutActions.SetCostCenterFail(normalizeHttpError(error)))
                        )
                    );
            }),
            withdrawOn(this.contextChange$)
        )
    );

    constructor(
        private actions$: Actions,
        private checkoutPaymentConnector: CheckoutPaymentConnector,
        private checkoutCostCenterConnector: CheckoutCostCenterConnector,
        private orderConnector: OrderConnector,
        private checkoutDeliveryAddressConnector: CheckoutDeliveryAddressConnector,
        private checkoutDeliveryModesConnector: CheckoutDeliveryModesConnector,
        private occCheckoutAdapter: ValioOccCheckoutAdapter
    ) {
    }
}
