import {GlobalMessageConfig, GlobalMessageType} from "@spartacus/core";

export function valioGlobalMessageConfigFactory(): GlobalMessageConfig {
  return {
    globalMessages: {
      [GlobalMessageType.MSG_TYPE_CONFIRMATION]: {
        timeout: 10000,
      },
      [GlobalMessageType.MSG_TYPE_INFO]: {
        timeout: 10000,
      },
      [GlobalMessageType.MSG_TYPE_ERROR]: {
        timeout: 10000,
      },
    },
  };
}
