<div class="show-password text-right" *ngIf="typeInput && typeInput == 'password'">
  <a (click)="toggleVisibility(idName)">{{ 'updatePasswordForm.showPassword' | cxTranslate}}</a>
</div>

<ng-container *ngIf="parentForm; else formlessInput">
  <div [formGroup]="parentForm" class="input-label"
       [class.is-invalid]="isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)"
       [ngClass]="hasValue() ? 'has-value' : ''">
    <label for="{{idName}}" class="text-left" [ngClass]="hasValue() ? 'has-value' : ''">
      <span class="label-content">{{labelPlaceholder | cxTranslate}}</span>
    </label>
    <input
      type="{{typeInput ? typeInput: 'text'}}"
      class="form-control"
      name="{{nameForm}}"
      placeholder="{{labelPlaceholder | cxTranslate}}"
      formControlName="{{nameForm}}"
      id="{{idName}}"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (keypress)="onKeypress($event)"
    />
  </div>
</ng-container>

<ng-template #formlessInput>
  <div class="input-label" [ngClass]="hasValue() ? 'has-value' : ''">
    <label for="{{idName}}" class="text-left" [ngClass]="hasValue() ? 'has-value' : ''">
      <span class="label-content">{{labelPlaceholder | cxTranslate}}</span>
    </label>
    <input
      type="{{typeInput ? typeInput: 'text'}}"
      class="form-control"
      name="{{nameForm}}"
      placeholder="{{labelPlaceholder | cxTranslate}}"
      value="{{inputValue}}"
      id="{{idName}}"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (keypress)="onKeypress($event)"
    />
  </div>
</ng-template>

<div class="invalid-feedback d-block text-right" *ngIf="(isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)) && errorMessage">
  <span>{{ errorMessage| cxTranslate}}</span>
</div>
<div class="extra-info text-right" *ngIf="extraInfoBottom">
  {{extraInfoBottom| cxTranslate}}
</div>
