import {NgModule} from '@angular/core';
import {MediaModule} from '@spartacus/storefront';
import {ValioProductIcons} from "./valio-product-icons";
import {CommonModule} from "@angular/common";
import {I18nModule} from "@spartacus/core";


@NgModule({
    imports: [
        MediaModule,
        CommonModule,
        I18nModule
    ],
    declarations: [ValioProductIcons],
    exports: [ValioProductIcons]
})
export class ValioProductIconsModule {
}
