import {GenericLinkModule, LayoutModule} from "@spartacus/storefront";
import {NgModule} from "@angular/core";
import {CmsConfig, ConfigModule, I18nModule, UrlModule} from "@spartacus/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from '@angular/router';
import {ValioNavigationComponent} from './valio-navigation.component';
import {ValioNavigationUIComponent} from './valio-navigation-ui.component';
import {ValioNavigationGuard} from "./valio-navigation.guard";
import {ValioCategoryDataService} from "../product/product-facet-navigation/valio-category-data.service";
import {ValioGlobalSearchBoxModule} from "./global-search-box/valio-global-search-box.module";

@NgModule({
  providers: [
    {
      provide: ValioCategoryDataService,
      useClass: ValioCategoryDataService,
    }
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    GenericLinkModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        NavigationComponent: {
          component: ValioNavigationComponent,
          guards: [ValioNavigationGuard]
        },
      },
    }),
    I18nModule,
    UrlModule,
    ValioGlobalSearchBoxModule
  ],
  declarations: [ValioNavigationComponent, ValioNavigationUIComponent],
  exports: [ValioNavigationComponent, ValioNavigationUIComponent]
})
export class ValioNavigationModule {
}
