import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HamburgerMenuService} from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})
export class ValioHamburgerMenuService extends HamburgerMenuService {
  lastSelectedCategory: string;

  constructor(router: Router) {
    super(router);
  }
}
