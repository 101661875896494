<ng-container *ngIf="data$ | async as data">
  <cx-media [container]="data.media"></cx-media>
  <div class="footer-top">
    <div class="row">
      <div class="col-xs-12 col-md-6 footer-value-promise" [innerHTML]="data.valuePromise"></div>
      <div class="col-xs-12 col-md-6 footer-newsletter"></div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4 footer-functionality">
        <h5 [innerHTML]="data.functionalityColumnHead"></h5>
        <p [innerHTML]="data.functionalityColumn"></p>
        <valio-cx-navigation-ui
          [node]="functionalityNavigationNode$ | async"
          [footerNavigation]="true"
        ></valio-cx-navigation-ui>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4 footer-products">
        <h5 [innerHTML]="data.productsColumnHead"></h5>
        <p [innerHTML]="data.productsColumn"></p>
        <valio-cx-navigation-ui
          [node]="contractsNavigationNode$ | async"
          [footerNavigation]="true"
        ></valio-cx-navigation-ui>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4 footer-instructions">
        <h5 [innerHTML]="data.instructionsColumnHead"></h5>
        <valio-cx-navigation-ui
          [node]="instructionsNavigationNode$ | async"
          [footerNavigation]="true"
        ></valio-cx-navigation-ui>
        <p class="footer-instructions" [ngClass]="{'is-anonymous' : isAnonymous$ | async,'is-signed-in' : isSignedIn$ | async}" [innerHTML]="data.instructionsColumn"></p>
        <valio-cx-site-context-selector context="language" *ngIf=" languageComponent$|async"></valio-cx-site-context-selector>
      </div>
    </div>
  </div>
</ng-container>
