import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmptyToStringPipe} from './utils/empty-to-string.pipe';
import {ConfigModule, I18nModule} from '@spartacus/core';
import { translationChunksConfig } from '@spartacus/assets';

@NgModule({
  declarations: [
    EmptyToStringPipe
  ],
  imports: [CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/custom.json',
        },
        chunks: {
          orders: ['orders', 'order-statuses', 'order-types'],
          shared: ['shared'],
          common: ['cart', 'checkout', 'order'],
          custom: ['valioProduct', 'valioRegister'],
          cart: ['header','cartDetails', 'cartItems'],
          userAccount: ['userAccount'],
          userProfile: ['userProfile']
        },
      },
    }),
  ],
  exports: [EmptyToStringPipe, I18nModule],
  providers: []
})
export class ValioCommonModule {
}
