<ng-container *ngIf="!newVisible && !existingVisible">
  <div class="cx-register row">
    <div class="register-intro col-xs-12 col-sm-6 col-lg-5">
      <h1 class="register-intro-label">{{'valioRegister.header.register' | cxTranslate}}</h1>
      <p class="register-intro-text">
        {{'valioRegister.header.intro' | cxTranslate}}
        <a role="link"
           [routerLink]="{ cxRoute: 'serviceInformation' } | cxUrl"
        ><b>{{'valioRegister.header.introLink' | cxTranslate}}</b></a>
      </p>
    </div>
    <div class="register-select col-xs-12 col-sm-6 col-lg-7">
      <div class="register-header">{{'valioRegister.header.head' | cxTranslate}}</div>
      <div class="register-select-label">
        <p>{{'valioRegister.header.existing' | cxTranslate}}</p>
        <p>{{'valioRegister.header.new' | cxTranslate}}</p>
      </div>
      <div class="register-buttons">
        <button class="btn btn-primary btn-lg btn-block-md-down margin-right-base" (click)="showExisting()">{{'valioRegister.header.showExisting' | cxTranslate}}</button>
        <button class="btn btn-primary btn-lg btn-block-md-down" (click)="showNew()">{{'valioRegister.header.showNew' | cxTranslate}}</button>
      </div>
    </div>
  </div>
</ng-container>
<valio-cx-register-existing *ngIf="existingVisible"></valio-cx-register-existing>
<valio-cx-register-new *ngIf="newVisible"></valio-cx-register-new>
