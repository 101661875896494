export class DateUtils {
  static stdTimezoneOffset(d: Date): number {
    const jan = new Date(d.getFullYear(), 0, 1);
    const jul = new Date(d.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  static isDstObserved(d: Date): boolean {
    return d.getTimezoneOffset() < DateUtils.stdTimezoneOffset(d);
  }

  static getNow(): Date {
    const d = new Date();
    // this is only a hack to get the timezones working for browserstack tests.
    if (Intl.DateTimeFormat().resolvedOptions().timeZone != "Europe/Helsinki") {// do not change anything FI timezones
      const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
      return new Date(utc + (3600 * 1000 * (DateUtils.isDstObserved(d) ? 3 : 2)));
    }
    return d;
  }

  static convertDate(date: string): Date {
    return new Date(date);
  }

  /**
   *  Returns an array of dates between the two dates
   */
  static getDates(startDate: Date, endDate: Date): Date[] {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate < endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  /**
   * Get the first day of month and returns the date of previous Monday or given date if that day is Monday
   * @param date
   */
  static getFirstMondayDate(date: Date) {
    const firstDayOfMonth: Date = this.getFirstDayOfMonth(date);
    return new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), firstDayOfMonth.getDate() - DateUtils.diffToSunday(firstDayOfMonth));
  }

  /**
   * Get the last day of month and returns the date of next Sunday or given date if that is Sunday
   * @param date
   */
  static getLastSundayDate(date: Date) {
    const lastDayOfMonth: Date = this.getLastDayOfMonth(date);
    return new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() + DateUtils.diffToSunday(lastDayOfMonth));
  }

  /**
   * Gets the first day of month
   * @param date date
   */
  static getFirstDayOfMonth(date: Date) {

    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  /**
   * Gets the last day of month
   * @param date date
   */
  static getLastDayOfMonth(date: Date) {
    // Get first day of next month and roll back one day
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  /**
   * Amount of days to same weeks Monday
   * @param date Date
   */
  static diffToMonday(date: Date) {
    const dayOfWeek: number = date.getDay();
    return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  }

  /**
   * Amount of days to same weeks Sunday
   * @param date Date
   */
  static diffToSunday(date: Date) {
    const dayOfWeek: number = date.getDay();
    return dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  }

  static getISO8601WeekNo(dt: Date) {
    const tdt: Date = new Date(dt.valueOf());
    const dayn: number = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday: number = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

  /**
   * Checks if given date is between or equal to two other dates
   * @param date date to check
   * @param start Range start, inclusive
   * @param end Range end, inclusive
   */
  static isBetween(date: Date, start:Date, end:Date) {
      return (start <= date && end>=date);
  }
}
