<div class="custom-day"
     [class.focused]="focused"
     [class.selected]="selected"
     [class.has-content]="hasContent"
     [class.disabled]="!(hasContent || selected)"
     [class.hidden]="hidden"
     [class.text-muted]="disabled"
     [class.today]="today"
>
  <span class="align-middle">{{ date.day }}</span>
</div>
