import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {ValioFaqComponent} from './valio-faq.component';
import {RouterModule} from '@angular/router';
import {GenericLinkModule, MediaModule, PageComponentModule, PageSlotModule} from '@spartacus/storefront';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioFaqComponent: {
                    component: ValioFaqComponent,
                },
            },
        }),
        GenericLinkModule,
        PageSlotModule,
        PageComponentModule,
        I18nModule
    ],
    declarations: [ValioFaqComponent],
    exports: [ValioFaqComponent]
})
export class ValioFaqModule {
}
