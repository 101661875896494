<ng-container *ngIf="!emailSend; else emailSendTemplate">
  <div class="valio-modal modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div [formGroup]="form">
        <div class="header">
          <h2 class="modal-header">{{'myaccount.newContract.title'|cxTranslate}}</h2>
          <p>{{'myaccount.newContract.text'|cxTranslate}}</p>
        </div>

        <div class="content">
          <valio-cx-input [isNotValidFunction]="isInvalidStep2('name')"
                          [labelPlaceholder]="'myaccount.newContract.name'" [nameForm]="'name'"
                          [parentForm]="form" class="valio-input"></valio-cx-input>

          <valio-cx-customer-number [form]="form" [submitted]="submitted"></valio-cx-customer-number>

          <textarea
            name="message"
            placeholder="{{'myaccount.newContract.message' | cxTranslate}}"
            formControlName="message"
            class="form-control margin-bottom-md"></textarea>

          <div class="row col-12">
            <button
              type="button"
              (click)="submit()"
              class="btn btn-block col-6 offset-3 btn-primary margin-bottom-md"
            >
              {{ 'myaccount.newContract.send' | cxTranslate }}
            </button>
            <button
              type="cancel"
              (click)="cancel()"
              class="btn btn-block col-6 offset-3 btn-secondary"
            >
              {{ 'common.cancel' | cxTranslate }}
            </button>
          </div>

          <div class="message ERROR" *ngIf="emailError">{{'myaccount.newContract.sendFailed'|cxTranslate}}</div>

        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #emailSendTemplate>
  <div class="valio-modal modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="header">
        <h2 class="modal-header">{{'myaccount.newContract.emailSendTitle'|cxTranslate}}</h2>
        <p>{{'myaccount.newContract.emailSendMessage'|cxTranslate}}</p>
      </div>
      <div class="row col-12">
        <button
          type="cancel"
          (click)="cancel()"
          [routerLink]="'/'"
          class="btn btn-block col-6 offset-3 btn-primary"
        >
          {{ 'myaccount.newContract.emailSendClose' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
