import {Component, Input} from '@angular/core';
import {ValioCart} from "../../../../../services/cart/valio-cart.objects";
import {Price} from "@spartacus/core";


@Component({
  selector: 'valio-price-summary-component',
  templateUrl: './valio-price-summary.component.html',
})

export class ValioPriceSummaryComponent {
  @Input() cart: ValioCart;
  @Input() totalItems: number;
  @Input() subtotal: Price;
  @Input() totalPriceWithTax: Price;
  @Input() totalPriceWithoutTax: Price;
  @Input() freightCharge: Price;
  @Input() variableWeightPaymentCost: Price;


  isCreditPayment() {
    return this.cart.paymentModeGroupedEntries && this.cart.paymentModeGroupedEntries['CARD'] != null;
  }
}
