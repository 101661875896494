import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ValioEuroPipe} from "./valio-euros.pipe";
import {ValioCentPipe} from "./valio-cent.pipe";
import {ValioCentSummaryPipe} from "./valio-cent-summary.pipe";
import {ValioProductCodePipe} from "./valio-product-code.pipe";
import {ValioDatePipe} from "./valio-date.pipe";
import {ValioSafeHtmlPipe} from "./valio-safe-html";

@NgModule({
  imports: [CommonModule],
  declarations: [ValioEuroPipe, ValioCentPipe, ValioCentSummaryPipe, ValioProductCodePipe, ValioDatePipe, ValioSafeHtmlPipe],
  exports: [ValioEuroPipe, ValioCentPipe, ValioCentSummaryPipe, ValioProductCodePipe, ValioDatePipe, ValioSafeHtmlPipe],
})
export class ValioPipeModule {
}
