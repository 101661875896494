<ng-container *ngIf="isSignedIn$|async">
  <ng-container *ngIf="size$|async as size">
    <ng-container *ngIf="size>0">
      <ng-container *ngIf="(model$ | async) as model">
        <valio-cx-carousel
          [products]="model.products"
          [size]="size"
          [title]="title"
          [showScroll]="showScroll"
        >
        </valio-cx-carousel>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
