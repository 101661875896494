import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CartSharedModule } from "@spartacus/cart/base/components";
import { CheckoutAuthGuard } from "@spartacus/checkout/base/components";
import {CmsConfig, ConfigModule, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import { ValioPipeModule } from "../../../services/pipes/valio-pipe.module";
import { ValioInputModule } from "../../input/valio-input.module";
import { ValioItemCounterModule } from "../cart/cart-shared/cart-item/item-counter/valio-item-counter.module";
import { ValioSuggestiveSearchBoxModule } from "../navigation/suggestive-search-box/valio-suggestive-search-box.module";
import { ValioDatepickerModule } from "./datepicker/valio-datepicker.module";
import { ValioDeliveryNoteReclamationComponent } from "./reclamation/valio-deliverynote-reclamation.component";
import { ValioDateRangeSearchComponent } from "./valio-date-range-search.component";
import { ValioDeliveryNoteDatepickerForm } from "./valio-deliverynote-datepicker-form";
import { ValioDeliveryNoteRow } from "./valio-deliverynote-row";
import { ValioDeliveryNoteWeekScrollerComponent } from "./valio-deliverynote-week-scroller.component";
import { ValioDeliveryNoteComponent } from './valio-deliverynote.component';
import {valioDeliveryNoteReclamationComponent} from "./reclamation/valio-deliverynote-reclamation-layout.config";

@NgModule({
  imports: [
    CartSharedModule,
    CommonModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        DeliveryNotesComponent: {
          component: ValioDeliveryNoteComponent,
          guards: [CheckoutAuthGuard]
        },
      },
    }),
    I18nModule,
    NgbDatepickerModule,
    FormsModule,
    ValioSuggestiveSearchBoxModule,
    ValioItemCounterModule,
    ValioInputModule,
    NgSelectModule,
    ReactiveFormsModule,
    ValioDatepickerModule,
    NgbModule,
    ValioPipeModule
  ],
  providers: [
    provideDefaultConfig(valioDeliveryNoteReclamationComponent)
  ],
  declarations: [ValioDeliveryNoteComponent, ValioDeliveryNoteRow, ValioDeliveryNoteWeekScrollerComponent, ValioDeliveryNoteReclamationComponent, ValioDeliveryNoteDatepickerForm, ValioDateRangeSearchComponent],
  exports: [ValioDeliveryNoteComponent, ValioDeliveryNoteReclamationComponent, ValioDeliveryNoteDatepickerForm, ValioDateRangeSearchComponent]
})
export class ValioDeliveryNoteModule {
  model;

}
