import {NgModule} from "@angular/core";
import {EffectsModule} from "@ngrx/effects";

import {StoreModule} from "@ngrx/store";
import {BASE_ORDER_NORMALIZER, ValioBaseOrderNormalizer} from "./valio-baseorder-normalizer";
import {ValioOrderTemplateEffects} from "./valio-order-template.effect";
import {ORDER_TEMPLATE_FEATURE} from "./valio-order-template.action";
import {metaReducers, reducerToken, valioOrderTemplateReducerProvider} from "./valio-order-template.reducer";


@NgModule({
  imports: [
    EffectsModule.forFeature([ValioOrderTemplateEffects]),
    StoreModule.forFeature(ORDER_TEMPLATE_FEATURE, reducerToken, {metaReducers}),
  ], providers: [
    valioOrderTemplateReducerProvider,
    {
      provide: BASE_ORDER_NORMALIZER,
      useClass: ValioBaseOrderNormalizer,
      multi: true
    }
  ]

})
export class ValioOrderTemplatesModule {
}
