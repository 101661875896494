import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component,} from '@angular/core';
import {ValioSuggestiveSearchBoxComponent} from "../suggestive-search-box/valio-suggestive-search-box.component";
import {CmsSearchBoxComponent, RoutingService, WindowRef} from "@spartacus/core";
import {ValioSearchBoxComponentService} from "../../cart/cart-detail/suggestive-search/valio-search-box-component.service";
import {CmsComponentData} from "@spartacus/storefront";
import {ValioLastPurchasesService} from "../../../../services/lastPurchases/valio-last-purchases.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {ValioProduct} from "../../../../models";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {ValioPartnerSiteService} from "../../../../services/site/valio-partner-site.service";

const SESSION_KEY_LAST_SEARCHES = 'valo_last_searches';
const QUERY_QUEUE_SIZE = 3;
const QUERY_QUEUE_SEPARATOR = '|';

const LAST_PURCHASES_SIZE = 3;

const DEFAULT_ADD_TO_CART_QTY = 1;

@Component({
  selector: 'valio-cx-global-searchbox',
  templateUrl: './valio-global-search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioGlobalSearchBoxComponent extends ValioSuggestiveSearchBoxComponent implements AfterViewChecked {
  private sessionStorage: Storage;
  latestOrders$: Observable<ValioProduct[]>;

  constructor(
    protected cartService: ValioCartService,
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    protected searchBoxComponentService: ValioSearchBoxComponentService,
    protected winRef: WindowRef,
    protected lastPurchasesService: ValioLastPurchasesService,
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    private partnerSiteService: ValioPartnerSiteService
  ) {
    super(cdr, routingService, searchBoxComponentService, winRef, componentData);
    this.sessionStorage = winRef.sessionStorage;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.latestOrders$ = this.lastPurchasesService.getLastPurchases().pipe(
      map(purchases => {
        if (purchases.products) {
          const ret = [...purchases.products];
          ret.splice(LAST_PURCHASES_SIZE);
          return ret;
        }
        return [];
      })
    );
  }

  ngAfterViewChecked(): void {
    this.searchInput.nativeElement.addEventListener('focus', () => this.toggleSuggestions(true));
    this.searchInput.nativeElement.addEventListener('blur', () => setTimeout(() => this.toggleSuggestions(false), 200));
    // document.getElementById('suggestions').addEventListener('blur', () => this.toggleSuggestions(false));
  }

  addProductLooper($event: KeyboardEvent, query: string) {
    this.launchSearchResult(null, query);
  }

  launchSearchResult(event: UIEvent, query: string): void {
    this.partnerSiteService.reset();
    this.value = query;
    this.searchInput.nativeElement.value = query;
    this.queueQuery(query);
    super.launchSearchResult(event, query);
  }

  queueQuery(query: string): void {
    query = query.replace(QUERY_QUEUE_SEPARATOR, '');
    let sessionValue = this.sessionStorage.getItem(SESSION_KEY_LAST_SEARCHES);
    if (sessionValue) {
      const queue = sessionValue.split(QUERY_QUEUE_SEPARATOR);
      if (!queue.find(el => el == query)) {//prevent duplicates
        if (query.length >= QUERY_QUEUE_SIZE) {// keep queue size
          queue.splice(QUERY_QUEUE_SIZE - 1);
        }
        queue.splice(0, 0, query);//add as first element
      }
      sessionValue = queue.join(QUERY_QUEUE_SEPARATOR);// separate the queryterms with separator
    } else {
      sessionValue = query;
    }
    this.sessionStorage.setItem(SESSION_KEY_LAST_SEARCHES, sessionValue);
  }

  getOldQueries(): string[] {
    let sessionValue = this.sessionStorage.getItem(SESSION_KEY_LAST_SEARCHES);
    if (sessionValue) {
      return sessionValue.split(QUERY_QUEUE_SEPARATOR);
    }
    return [];
  }


  private toggleSuggestions(show: boolean) {
    const parent = (<HTMLElement>this.searchInput.nativeElement).closest('.searchbox-wrapper');
    if (show) {
      parent.querySelector('.suggestions').classList.remove('d-none');
      parent.querySelector('.searchbox').classList.add('open');
    } else if (document.activeElement != document.getElementById('suggestions')) {
      parent.querySelector('.suggestions').classList.add('d-none');
      parent.querySelector('.searchbox').classList.remove('open');
    }
  }

  addToCart(product: ValioProduct) {
    this.cartService.addEntryToCart(product.code, {qty: DEFAULT_ADD_TO_CART_QTY});
  }

  clearSearchBox() {
    this.searchInput.nativeElement.value = '';
    this.value = '';
    this.clearSearch();
  }

  clearSearch() {
    this.searchBoxComponentService.clearResults();
    this.close(null);
    this.cdr.detectChanges();
  }

  getAnchorClassName(): string {
    return 'global-search-result-anchor';
  }
}
