import {
  LOAD_BOUGHT_TOGETHER_FAIL,
  LOAD_BOUGHT_TOGETHER_SUCCESS,
  ValioBoughtTogethersState,
  ValioBoughtTogetherState,
  ValioLoadBoughtTogetherFail,
  ValioLoadBoughtTogetherSuccess
} from "./valio-bought-together.action";
import {InjectionToken, Provider} from "@angular/core";
import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store";
import {SELECT_UNIT, SelectUnit} from "../../user/valio-user-actions";
import {StateUtils} from "@spartacus/core";


export const initialState: ValioBoughtTogetherState = {
  content: {products: []}
};

export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<ValioBoughtTogethersState>> = new InjectionToken<ActionReducerMap<ValioBoughtTogethersState>>('BoughtTogetherReducers');

export const valioBoughtTogetherReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getValioBoughtTogetherReducers,
};

export function clearState(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export function getValioBoughtTogetherReducers(): ActionReducerMap<ValioBoughtTogethersState> {
  return {
    active: StateUtils.loaderReducer<ValioBoughtTogetherState>(LOAD_BOUGHT_TOGETHER_SUCCESS, valioBoughtTogetherReducer),
  };
}

export function valioBoughtTogetherReducer(
  state = initialState,
  action: ValioLoadBoughtTogetherSuccess | ValioLoadBoughtTogetherFail | SelectUnit
): ValioBoughtTogetherState {
  switch (action.type) {
    case LOAD_BOUGHT_TOGETHER_SUCCESS: {
      return {
        content: action.products
      };
    }
    case LOAD_BOUGHT_TOGETHER_FAIL:
    case SELECT_UNIT: {
      return initialState;
    }
  }
  return state;
}
