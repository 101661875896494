import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {ValioOrderTemplatesService} from "../../../../services/orderTemplates/valio-order-templates.service";
import {Observable, Subscription} from "rxjs";
import {ValioCart} from "../../../../services/cart/valio-cart.objects";
import {LaunchDialogService} from "@spartacus/storefront";
import {ValioRemoveOrderTemplateDialogData} from "./valio-remove-order-template-layout.config";


@Component({
    selector: 'valio-cx-remove-order-template',
    templateUrl: './valio-remove-order-template.component.html',
})
export class ValioRemoveOrderTemplateComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Output() afterClose = new EventEmitter();

    orderTemplate$: Observable<ValioCart>;
    private subscriptions: Subscription = new Subscription();

    constructor(
        protected orderTemplatesService: ValioOrderTemplatesService,
        protected launchDialogService: LaunchDialogService,
        protected cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.launchDialogService.data$.subscribe((data: ValioRemoveOrderTemplateDialogData) => {
            this.id = data.id;
            this.orderTemplate$ = this.orderTemplatesService.getOrderTemplate(this.id)
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    dismissModal(): void {
        this.launchDialogService.closeDialog(null);
    }

    remove() {
        this.orderTemplatesService.removeOrderTemplate(this.id);
        this.dismissModal();
        this.afterClose.emit();
    }
}
