import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { CART_NORMALIZER } from "@spartacus/cart/base/root";
import { ConverterService, Occ, OccConfig, OccEndpointsService } from "@spartacus/core";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { ValioOccEndpoints } from "../../valio-occ-endpoints";
import { ValioBaseOrder, ValioCart } from "../cart/valio-cart.objects";
import { ValioDatePipe } from "../pipes/valio-date.pipe";
import { BASE_ORDER_NORMALIZER } from "./valio-baseorder-normalizer";



@Injectable({
  providedIn: 'root',
})
export class ValioOccValioOrderTemplatesAdapter {
  endpoints: ValioOccEndpoints;

  constructor(protected cxDate: ValioDatePipe,
    protected config: OccConfig,
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService) {

    this.endpoints = this.config.backend.occ.endpoints;
  }

  getOrderTemplates(userId: string): Observable<ValioCart[]> {
    return this.http
      .get<Occ.CartList>(this.occEndpointsService.buildUrl('orderTemplates', { urlParams: { userId } }))
      .pipe(
        pluck('carts'),
        this.converterService.pipeableMany(CART_NORMALIZER)
      ) as Observable<ValioCart[]>;
  }

  getOrderTemplate(userId: string, cartId: string): Observable<ValioCart> {
    const url = this.occEndpointsService.buildUrl('cart', {
      urlParams: { userId, cartId },
      queryParams: { orderTemplate: true }
    });
    return this.http.get<Occ.Cart>(url).pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<ValioCart>;
  }

  createOrderTemplate(userId: string, name: string, copyId?: string, copyDate?: string): Observable<ValioCart[]> {
    const url = this.occEndpointsService.buildUrl('createOrderTemplate', {
      urlParams: { userId },
      queryParams: {
        name: name,
        copyId: copyId,
        copyDate: copyDate ? this.cxDate.transform(copyDate, this.endpoints.dateFormat) : null
      }
    });
    return this.http.post<Occ.CartList>(url, null)
      .pipe(
        pluck('carts'),
        this.converterService.pipeableMany(CART_NORMALIZER)
      ) as Observable<ValioCart[]>;
  }

  saveOrderTemplate(userId: string, cartId: string, orderTemplate: ValioCart, updateCart?: boolean): Observable<ValioCart[]> {
    const url = this.occEndpointsService.buildUrl(updateCart ? 'orderTemplateToCart' : 'saveOrderTemplate', {
      urlParams: {
        userId,
        cartId
      }
    });
    return this.http.put<Occ.CartList>(url, orderTemplate)
      .pipe(
        pluck('carts'),
        this.converterService.pipeableMany(CART_NORMALIZER)
      ) as Observable<ValioCart[]>;
  }

  removeOrderTemplate(userId: string, templateId: string): Observable<ValioCart[]> {
    const url = this.occEndpointsService.buildUrl('removeOrderTemplate', {
      urlParams: { userId, templateId },
      queryParams: {}
    });
    return this.http.delete<Occ.CartList>(url)
      .pipe(
        pluck('carts'),
        this.converterService.pipeableMany(CART_NORMALIZER)
      ) as Observable<ValioCart[]>;
  }

  getBaseOrder(userId: string): Observable<ValioBaseOrder> {
    const url = this.occEndpointsService.buildUrl('baseOrder', { urlParams: { userId } });
    return this.http.get<ValioBaseOrder>(url).pipe(this.converterService.pipeable(BASE_ORDER_NORMALIZER));
  }

  updateBaseOrder(userId: string, baseOrder: ValioBaseOrder): Observable<ValioBaseOrder> {
    const url = this.occEndpointsService.buildUrl('baseOrder', { urlParams: { userId } });
    return this.http.put<ValioBaseOrder>(url, baseOrder).pipe(this.converterService.pipeable(BASE_ORDER_NORMALIZER));
  }

  checkBaseOrder(userId: string, baseOrder: ValioBaseOrder): Observable<ValioBaseOrder> {
    const url = this.occEndpointsService.buildUrl('baseOrder', { urlParams: { userId } });
    return this.http.post<ValioBaseOrder>(url, baseOrder).pipe(this.converterService.pipeable(BASE_ORDER_NORMALIZER));
  }
}
