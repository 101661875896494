import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {Observable} from 'rxjs';
import {ValioUserService} from '../../../../../services/user/valio-user.service';
import {map} from 'rxjs/operators';
import {ValioProduct} from '../../../../../models';
import {ValioTopSellersService} from "../../../../../services/recommendations/top-sellers/valio-top-sellers.service";
import {ProductSearchPage} from "@spartacus/core";

@Component({
  selector: 'valio-top-sellers',
  templateUrl: 'valio-top-sellers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioTopSellersComponent implements OnInit {
  isAnonymous$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;
  model$: Observable<ProductSearchPage>;
  size$: Observable<number>;

  @Input() title: string;
  @Input() showScroll: string;

  constructor(protected recommendationsService: ValioTopSellersService,
              protected userService: ValioUserService) {
  }

  ngOnInit() {
    this.model$ = this.recommendationsService.getTopSellers();
    this.isAnonymous$ = this.userService.isAnonymous();
    this.isSignedIn$ = this.userService.isSignedIn();

    this.size$ = this.model$.pipe(
      map(m => m.products ? m.products.length : 0)
    );
  }
}
