import {Injectable} from '@angular/core';
import {ProductActions, SearchboxService, SearchConfig} from "@spartacus/core";
import {SearchResults} from "@spartacus/storefront";
import {PaginationModel} from "@spartacus/core/src/model/misc.model";

@Injectable({
  providedIn: 'root',
})
export class ValioSearchboxService extends SearchboxService {
  /**
   * dispatch the search for the search box
   */
  search(query: string, searchConfig?: ValioSearchConfig): void {
    this.store.dispatch(
      new ProductActions.SearchProducts(
        {
          queryText: query,
          searchConfig: searchConfig,
        },
        true
      )
    );
  }

}

export interface ValioSearchConfig extends SearchConfig {
  showPrices?: boolean;
  partner?: string
  baseOrderMode?: boolean;
  searchCategories?: boolean;
  globalSearch?: boolean;
}

export interface ValioSearchResults extends SearchResults{
  pages?: PaginationModel
}
