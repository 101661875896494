import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule, I18nModule, NotAuthGuard,} from '@spartacus/core';
import {ValioInputComponent} from "./valio-input.component";

@NgModule({
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioInputComponent: {
                    component: ValioInputComponent,
                    guards: [NotAuthGuard],
                },
            },
        }),
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        I18nModule,
    ],
    declarations: [ValioInputComponent],
    exports: [ValioInputComponent]
})
export class ValioInputModule {
}
