import {Injectable, OnDestroy} from "@angular/core";
import {NgbDate, NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {LanguageService, OccConfig, TranslationService} from "@spartacus/core";
import * as moment from "moment";
import {Subscription} from "rxjs";
import {ValioDatePipe} from "../../../../services/pipes/valio-date.pipe";
import {DateUtils} from "../../../misc/util/date-utils";

@Injectable()
export class ValioNgbDateParserFormatter extends NgbDateParserFormatter implements OnDestroy {
  language: string;
  dateFormat: string;
  private subscriptions: Subscription[] = [];

  constructor(protected languageService: LanguageService,
              protected cxDate: ValioDatePipe,
              protected config: OccConfig,
              protected translation: TranslationService) {
    super();
    this.subscriptions.push(languageService.getActive().subscribe(lang => this.language = lang, err => console.error(err)));
    this.subscriptions.push(this.translation.translate('deliveryNotes.datePicker.dateFormat').subscribe(format => this.dateFormat = format, err => console.error(err)));
  }

  ngOnDestroy(): void {
    /* This method has to be called manually at component level on destroy method. ngOnDestroy is not automatically called on injectables */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  parse(value: string): NgbDateStruct {
    if (value !== null && value !== undefined) {

      const date: Date = moment(value, this.dateFormat, this.language).toDate();
      return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }
    return null;
  }

  convertDate(date: string): Date {
    return DateUtils.convertDate(date);
  }

  format(date: NgbDateStruct): string {
    if (date !== undefined && date !== null) {
      const date1: Date = new Date(date.year, date.month - 1, date.day);
      return moment(date1).format(this.dateFormat);
    }
    return null;
  }


}
