import {Injectable} from "@angular/core";
import {
  GlobalMessageService
} from "@spartacus/core";

import {UserProfileFacade} from "@spartacus/user/profile/root";
import {Observable} from "rxjs";
import {UpdateProfileComponentService} from "@spartacus/user/profile/components";
import {User} from "@spartacus/user/account/root";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ValioRegisterExistingComponent} from "../../user/register/valio-register-existing.component";
import {ValioUser} from "../../../../models/misc.model";

@Injectable({providedIn: 'root'})
export class ValioUpdateProfileComponentService extends UpdateProfileComponentService {
  user$: Observable<User>
  form: UntypedFormGroup

  constructor(protected userProfile: UserProfileFacade,
              protected globalMessageService: GlobalMessageService,
              private fb: UntypedFormBuilder) {
    super(userProfile, globalMessageService);
    this.user$ = this.userProfile.get()
  }

  initForm(user: ValioUser): UntypedFormGroup {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.email],
      phone: ['', ValioRegisterExistingComponent.phoneNumberValidator],
      enableOrderConfirmationEmails: []
    });
    this.form.patchValue(user);
    return this.form;
  }
}
