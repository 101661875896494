import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {RouteCalendar} from "../../models/valio-calendar.model";
import {
    getRouteCalendarContent,
    getRouteCalendarState,
    ValioLoadRouteCalendar,
    ValioRouteCalendarState,
    ValioStateWithRouteCalendar
} from "./valio-routecalendar.action";
import {map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {ValioUserService} from "../user/valio-user.service";
import {ValioCartService} from "../cart/valio-cart.service";
import {UserIdService} from "@spartacus/core";

@Injectable({
    providedIn: 'root',
})
export class ValioRouteCalendarService implements OnDestroy {
    subscriptions: Subscription = new Subscription();

    constructor(
        protected store: Store<ValioStateWithRouteCalendar>,
        protected userService: ValioUserService,
        protected userIdService: UserIdService,
        protected cartService: ValioCartService
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getRouteCalendar(): Observable<RouteCalendar> {

        return this.cartService.hasUiCart().pipe(
            switchMap(hasCart => this.store.pipe(
                select(getRouteCalendarState),
                tap((details: ValioRouteCalendarState) => {
                        if (hasCart && !details.loading) {
                            this.subscriptions.add(this.cartService.getActiveCartId()
                                .pipe(withLatestFrom(this.userIdService.takeUserId()))
                                .subscribe(([cartId, userId]) => {
                                    this.loadRouteCalendar(userId, cartId);
                                }));
                        }
                    }
                ))
            ), map((details: ValioRouteCalendarState) => details.content));
    }

    loadRouteCalendar(userId: string, cartId: string) {
        this.store.dispatch(
            new ValioLoadRouteCalendar(
                userId, cartId
            )
        );
    }

    isLoaded(): Observable<boolean> {
        return this.store.select(getRouteCalendarContent)
            .pipe(map((state) => {
                return true;// Object.keys(details).length > 1;
            }));
    }
}
