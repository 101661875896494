import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule, I18nModule, UrlModule,} from '@spartacus/core';
import {ValioMiniCartComponent} from './valio-mini-cart.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                MiniCartComponent: {
                    component: ValioMiniCartComponent
                },
            },
        }),
        UrlModule,
        I18nModule,
    ],
    declarations: [ValioMiniCartComponent],
    exports: [ValioMiniCartComponent]
})
export class ValioMiniCartModule {
}
