import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule, I18nModule} from '@spartacus/core';
import {GenericLinkModule, MediaModule, OutletModule, PageSlotModule} from '@spartacus/storefront';

import {ValioCommonModule} from '../../../../shared/valio-common.module';
import {ValioPartnerApplyComponent} from './valio-partner-apply.component';
import {RouterModule} from '@angular/router';
import {ValioInputModule} from "../../../input/valio-input.module";
import {ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";


@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        ValioCommonModule,
        MediaModule,
        GenericLinkModule,
        PageSlotModule,
        RouterModule,
        ValioInputModule,
        ReactiveFormsModule,
        NgSelectModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ApplyForPartnerComponent: {
                    component: ValioPartnerApplyComponent
                },
            },
        }),
    ],
    declarations: [ValioPartnerApplyComponent],
    exports: [ValioPartnerApplyComponent]
})
export class ValioPartnerApplyModule {
}
