import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {LanguageService, ProductSearchPage, RoutingService} from '@spartacus/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {flatMap, map} from 'rxjs/operators';
import {Breadcrumb, CategoryHierarchy} from '../../../../models';
import {ValioProductListComponentService} from '../container/valio-product-list-component.service';
import {ValioCategoriesService} from "../../../../services/categories/valio-categories.service";
import {ValioCategoryDataService} from "./valio-category-data.service";
import {Vendor} from '../../../../models/misc.model';
import {ValioUserService} from '../../../../services/user/valio-user.service';
import {ValioPartnerService} from "../../../../services/vendor/valio-partner.service";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";

const categoryRoot = '0';
const defaultSort = 'relevance';

@Component({
  selector: 'valio-cx-product-facet-navigation',
  templateUrl: './valio-product-facet-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioProductFacetNavigationComponent implements OnInit, OnDestroy {
  fullWidth$: Observable<boolean>;
  isSignedIn$: Observable<boolean>;
  hasUICart$: Observable<boolean>;
  searchResult$: Observable<ProductSearchPage>;
  categories$: Observable<CategoryHierarchy>;
  fiCategories$: Observable<CategoryHierarchy>;
  breadCrumbs$: Observable<Breadcrumb[]>;
  categorySubscription: Subscription;

  selectedPartner$: Observable<Vendor>;
  filterQuery$: Observable<string>;
  lastCategoryCode: string;
  showCategoryDescription: boolean;
  sortCode = defaultSort;
  subscriptions: Subscription = new Subscription();


  constructor(
    protected categoriesService: ValioCategoriesService,
    protected productListComponentService: ValioProductListComponentService,
    protected router: Router,
    protected routingService: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected valioCategoryDataService: ValioCategoryDataService,
    protected partnerService: ValioPartnerService,
    protected cdr: ChangeDetectorRef,
    protected userService: ValioUserService,
    protected languageService: LanguageService,
    protected cartService: ValioCartService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.userService.isSignedIn().subscribe(signed => this.showCategoryDescription = !signed)
    );
    this.fullWidth$ = this.cartService.hasUiCart(true);
    this.isSignedIn$ = this.userService.isSignedIn();
    this.hasUICart$ = this.cartService.hasUiCart();
    this.filterQuery$ = new Observable(obs => obs.next(':' + defaultSort + ':'));
    this.selectedPartner$ = this.partnerService.getSelectedVendor();
    this.searchResult$ = this.productListComponentService.model$;

    this.breadCrumbs$ = this.searchResult$.pipe(
      map(searchResult => {
        return searchResult.breadcrumbs;
      }));
    this.subscriptions.add(
      this.searchResult$.subscribe(
        searchResult => {
          this.sortCode = searchResult.sorts.filter(s => s.selected)[0]?.code;
          this.setCategories(this.lastCategoryCode);
        }
      )
    );

    this.setCategories(this.getSuperCategoryCode(this.activatedRoute.snapshot.url.join('/') + '?' + this.activatedRoute.snapshot.queryParams.query));//default category
    this.subscriptions.add(
      this.valioCategoryDataService.currentMessage.subscribe(code => {
        if (code) {
          this.setCategories(this.getSuperCategoryCode(code));
        }
      })
    ); // switching 1:st level category
    this.subscriptions.add(
      this.categorySubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.setCategories(this.getSuperCategoryCode(event.url));
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * VALCOM-1772: Work-a-round for forcing Finnish category names to data-attributes
   */
  getFICategoryName(id: string, categoryHierarchy: CategoryHierarchy): string {
    if (id === categoryHierarchy.id) {
      return categoryHierarchy.name;
    }
    const result: string[] = categoryHierarchy.subcategories.filter(subCat => id === subCat.id).map(subcat => subcat.name);
    if (result && result.length > 0) {
      return result[0];
    }
    return undefined;
  }

  resetCategories() {
    this.setCategories(this.lastCategoryCode);
  }

  setCategories(code: string): void {
    if (code == '' || code == categoryRoot) {
      code = null;
    }
    this.lastCategoryCode = code;
    let fq = ':' + this.sortCode + ':';
    if (code != null) {
      fq += 'category:' + code;
    }
    this.filterQuery$ = new Observable(obs => obs.next(fq));
    this.categories$ = this.languageService.getActive()
      .pipe(flatMap(lang => {
        return this.categoriesService.getCategories(code, true);
      }));//switching category from url

    // VALCOM-1772 Get/Force Finnish labels for category attribute,
    this.fiCategories$ = this.categoriesService.getCategories(code, true, 'fi');
  }

  getSuperCategoryCode(url: string): string {
    const selectedCategoryStartIdx = url.indexOf('category/');
    const selectedCategoryEndIdx = url.indexOf('?');
    return selectedCategoryStartIdx >= 0 ? url.substring(selectedCategoryStartIdx + 'category/'.length,
      selectedCategoryEndIdx > 0 ? selectedCategoryEndIdx : url.length) : this.getSuperCategoryCodeFromParams(url);
  }

  getSuperCategoryCodeFromParams(url: string): string {
    const selectedCategoryStartIdx = url.indexOf('category:');
    const length = selectedCategoryStartIdx + 'category:'.length;
    let selectedCategoryEndIdx = url.indexOf(':', length) < 0 ? url.indexOf('&', length) : url.indexOf(':', length);
    return selectedCategoryStartIdx >= 0 ? url.substring(length, selectedCategoryEndIdx > 0 ? selectedCategoryEndIdx : url.length) : '';
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  toggleCategories() {
    [].forEach.call(document.getElementsByClassName('limitedCategoriesSelector'), (element) => {
      element.classList.toggle('limitedCategories');
    });

    document.getElementById('toggleMoreCategories').classList.toggle('toggleMoreCategories');
    document.getElementById('toggleCategoryList').classList.toggle('toggleCategoryList');
  }

  isPartnerSelected(partner: Vendor) {
    return partner && this.partnerService.isPartnerSelected(partner.code);
  }

  isContractCustomer(): Observable<boolean> {
    return this.userService.isContractCustomer()
  }

  addFacetQuery(categoryId: string) {
    if (categoryRoot != categoryId) {
      const regex = new RegExp('.*:' + this.sortCode, "g")
      return this.activatedRoute.snapshot.queryParams.query ? this.activatedRoute.snapshot.queryParams.query
        .replace(/\:category\:[^:]*/g, '')
        .replace(regex, '') : '';
    }
    return '';
  }

  sort(oldSort: string): void {
    const regex = new RegExp('\:' + oldSort, "g")

    this.routingService.go('/search', {
      queryParams: {
        query: this.activatedRoute.snapshot.queryParams.query ? this.activatedRoute.snapshot.queryParams.query
          .replace(regex, ':' + this.sortCode) : ':' + this.sortCode
      }
    });
  }
}
