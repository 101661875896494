import {Action} from "@ngrx/store";

export const VALIO_SET_SITE_PARTNER = '[PARTNER] Set Site Partner';

export class ValioSetSitePartner implements Action {
  readonly type = VALIO_SET_SITE_PARTNER;

  constructor(public code: string) {
  }
}

export const VALIO_SET_SITE_PARTNER_SUCCESSFUL = '[PARTNER] Successfully Set Site Partner';

export class ValioSetSitePartnerSuccessful implements Action {
  readonly type = VALIO_SET_SITE_PARTNER_SUCCESSFUL;

  constructor(public code: string) {
  }
}
