import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { CartActions, CartVoucherService } from "@spartacus/cart/base/core";
import { RoutingService } from "@spartacus/core";
import { Subscription } from "rxjs";
import { ValioCart } from "../../../../services/cart/valio-cart.objects";
import { ValioCartService } from "../../../../services/cart/valio-cart.service";

@Component({
  selector: 'valio-voucher',
  templateUrl: './valio-voucher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioVoucherComponent implements OnInit, OnDestroy {

  @Input() cart: ValioCart;
  form: UntypedFormGroup;
  private error: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    protected routingService: RoutingService,
    protected actions$: Actions,
    protected cartVoucherService: CartVoucherService,
    protected cartService: ValioCartService,
    protected fb: UntypedFormBuilder,
    protected cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group(
      {
        applyVoucher: ['']
      });
    this.subscription.add(
      this.actions$.pipe(
        ofType(CartActions.CART_ADD_VOUCHER_FAIL)
      ).subscribe(event => {
        this.error = true;
        this.cdr.detectChanges();
      })
    );
    this.subscription.add(
      this.actions$.pipe(
        ofType(CartActions.CART_ADD_VOUCHER_SUCCESS)
      ).subscribe(event => {
        this.error = false;
        this.form.controls['applyVoucher'].setValue('');

        this.cdr.detectChanges();
        const innerSub = this.actions$.pipe(
          ofType(CartActions.LOAD_CART_SUCCESS)
        ).subscribe(() => {
          this.routingService.go('/checkout/review-order-invoice'); // redirect back to invoice page, if the added product is invoice-type
          innerSub.unsubscribe();
        }
        );
      })
    );
  }

  ngOnInit(): void {
    this.error = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  applyVoucher($event) {
    this.error = false;
    this.cartVoucherService.addVoucher(this.form.get('applyVoucher').value, this.cart.code);
  }

  removeVoucher(code: string) {
    this.cartVoucherService.removeVoucher(code, this.cart.code);
  }

  isInvalidVoucher(): boolean {
    return this.error;
  }
}
