import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CmsConfig, ConfigModule, I18nModule, NotAuthGuard, provideConfig, UrlModule,} from '@spartacus/core';
import {ValioForgotPasswordComponent} from "./valio-forgot-password.component";
import {ValioInputModule} from '../../../input/valio-input.module';
import {ValioLoginModule} from "../../user/login/valio-login.module";
import {ValioLoginFormModule} from "../../user/login-form/valio-login-form.module";
import {FormErrorsModule} from "@spartacus/storefront";
import {ValioForgotPasswordComponentService} from "./valio-forgot-password.component.service";
import {ForgotPasswordComponentService} from "@spartacus/user/profile/components";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        I18nModule,
        ValioInputModule,
        ValioLoginModule,
        ValioLoginFormModule,
        FormErrorsModule,
    ],
    declarations: [ValioForgotPasswordComponent],
    exports: [ValioForgotPasswordComponent],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                ForgotPasswordComponent: {
                    component: ValioForgotPasswordComponent,
                    providers: [
                        {
                            provide: ForgotPasswordComponentService,
                            useClass: ValioForgotPasswordComponentService,
                        },
                    ],
                    guards: [NotAuthGuard]
                }
            },
        }),
    ]
})
export class ValioForgotPasswordModule {}
