import {Component, Input} from "@angular/core";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'valio-datepicker-day',
  templateUrl: './valio-datepicker-day.component.html',
})
export class ValioDatepickerDayComponent  {
  @Input()
  focused: boolean;
  @Input()
  selected: boolean;
  @Input()
  disabled: boolean;
  @Input()
  today: boolean;
  @Input()
  hidden: boolean;
  @Input()
  hasContent: boolean;
  @Input()
  date: NgbDate;




}
