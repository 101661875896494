import {Injectable} from '@angular/core';
import {CmsNavigationComponent, CmsService, SemanticPathService} from '@spartacus/core';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {ValioNavigationNode} from './valio-navigation-node.model';

@Injectable({
  providedIn: 'root',
})
export class ValioNavigationService {
  constructor(
    protected cmsService: CmsService,
    protected semanticPathService: SemanticPathService
  ) {
  }

  public createNavigation(
    data$: Observable<CmsNavigationComponent>
  ): Observable<ValioNavigationNode> {
    return combineLatest([data$, this.getValioNavigationNode(data$)]).pipe(
      filter(data => data != null),
      map(([data, nav]) => {
        return {
          title: data?.name,
          uid: data?.uid,
          children: [nav],
        };
      })
    );
  }

  public getValioNavigationNode(
    data$: Observable<CmsNavigationComponent>
  ): Observable<ValioNavigationNode> {
    if (!data$) {
      return of();
    }
    return data$.pipe(
      filter(data => !!data),
      switchMap(data => {
        const navigation = data.navigationNode ? data.navigationNode : data;
        return this.cmsService.getNavigationEntryItems(navigation.uid).pipe(
          tap(items => {
            if (items === undefined) {
              this.getNavigationEntryItems(navigation, true);
            }
          }),
          filter(Boolean),
          map(items => this.createNode(navigation, items))
        );
      })
    );
  }

  private getNavigationEntryItems(
    nodeData: any,
    root: boolean,
    itemsList = []
  ) {
    if (nodeData.entries && nodeData.entries.length > 0) {
      nodeData.entries.forEach(entry => {
        itemsList.push({
          superType: entry.itemSuperType,
          id: entry.itemId,
        });
      });
    }

    if (nodeData.children && nodeData.children.length > 0) {
      this.processChildren(nodeData, itemsList);
    }

    if (root) {
      const rootUid = nodeData.uid;
      this.cmsService.loadNavigationItems(rootUid, itemsList);
    }
  }

  private processChildren(node, itemsList): void {
    for (const child of node.children) {
      this.getNavigationEntryItems(child, false, itemsList);
    }
  }

  private createNode(nodeData: any, items: any): ValioNavigationNode {
    const node: ValioNavigationNode = {};

    node.title = nodeData.title;
    node.uid = nodeData.uid;

    if (nodeData.entries && nodeData.entries.length > 0) {
      this.addLinkToNode(node, nodeData.entries[0], items);
    }

    if (nodeData.children && nodeData.children.length > 0) {
      node.children = this.createChildren(nodeData, items);
    }

    return node;
  }

  private addLinkToNode(node: ValioNavigationNode, entry, items) {
    const item = items[`${entry.itemId}_${entry.itemSuperType}`];

    // now we only consider CMSLinkComponent
    if (entry.itemType === 'CMSLinkComponent' && item !== undefined) {
      if (!node.title) {
        node.title = item.linkName;
      }

      node.url = this.getLink(item);

      // if "NEWWINDOW", target is true
      node.target = item.target;
    }
  }

  private getLink(item): string | string[] {
    if (item.url) {
      return item.url;
    } else if (item.categoryCode) {
      return this.semanticPathService.transform({
        cxRoute: 'category',
        params: {
          code: item.categoryCode,
          name: item.name,
        },
      });
    }
  }

  private createChildren(node, items) {
    const children = [];

    for (const child of node.children) {
      const childNode = this.createNode(child, items);
      children.push(childNode);
    }

    return children;
  }
}
