<ng-container *ngIf="searchResult$ | async as searchResult">
  <div class="cx-search-facet">
    <ng-container *ngIf="false">
      <h4 class="cx-facet-filter-header">
        {{ 'productList.filterBy.label' | cxTranslate }}
      </h4>
      <div class="cx-facet-filter-container">
        <div
          *ngFor="let breadcrumb of searchResult.breadcrumbs"
          [hidden]="breadcrumb.facetValueCode === activeFacetValueCode"
          class="cx-facet-filter-pill"
          role="filter"
        >
              <span class="cx-facet-pill-value">
                {{ breadcrumb.facetValueName }}
              </span>
          <button
            (click)="toggleValue(breadcrumb.removeQuery.query.value)"
            aria-label="Close"
            class="close"
            type="button"
          >
            <span aria-hidden="true" class="valio-icon icon-reset icon-reset-black"></span>
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="facets$ | async as visibleFacets">
      <ng-container *ngFor="let facet of visibleFacets; let facetId = index">
        <div *ngIf="facet" class="cx-facet-group">
          <ng-container *ngIf="!isFacetCollapsed(facet.name)">
            <div class="facet-name"
                 id="facetName_{{facet.code}}"
                 [ngClass]="{'has-active-facet':isFacetActive(facet.code),
                     'facet-open':isPreOpenFacet(facet.code)}"
                 (click)="toggleFacetList(facet.code)"
            >
              {{facet.name}} {{getSelectedOptions(facet.code) > 0 ? '(' + getSelectedOptions(facet.code) + ')' : ''}}
              <div class="valio-icon icon-inline icon-chevron icon-chevron-down"></div>
            </div>
            <ul class="cx-facet-list" id="facetList_{{facet.code}}"
                [ngClass]="{'active-facet':isPreOpenFacet(facet.code)}"
            >
              <li
                *ngFor="let value of getVisibleFacetValues(facet); index as facetValueId"
                [ngClass]="value.selected?'selected':'not-selected'"
              >
                <div (click)="toggleValueForFacet(value.query.query.value,facet.code)" class="cx-facet-text">
                  {{ value.name }} ({{ value.count }})
                  <span class="valio-icon icon-sm icon-inline icon-check margin-left-base"></span>
                </div>
              </li>
              <li
                (click)="showLess(facet.name)"
                *ngIf="showAllPerFacetMap.get(facet.name)"
                class="cx-facet-toggle-btn"
              >
                {{ 'productList.showLess' | cxTranslate }}
              </li>
              <li
                (click)="showMore(facet.name)"
                *ngIf="
                          !showAllPerFacetMap.get(facet.name) &&
                          facet.values.length > minPerFacet
                        "
                class="cx-facet-toggle-btn"
              >
                {{ 'productList.showMore' | cxTranslate }}
              </li>
            </ul>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="cx-facet-mobile">
    <button
      #trigger
      (click)="launch()"
      class="btn cx-facet-mobile-btn"
    >
      {{ 'productList.filterBy.label' | cxTranslate }}
      <span class="valio-icon icon-inline icon-chevron icon-chevron-down margin-left-base"></span>
    </button>

    <ng-container
      *ngIf="isOpen$ | async"
      (closeList)="close()"
      [class.active]="isActive$ | async"
      [class.dialog]="hasTrigger">
      <div class="d-block fade modal show">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="cx-facet-modal-title" id="modal-title">
                {{ 'productList.filterBy.label' | cxTranslate }}
              </h4>
              <button
                (click)="close()"
                data-dismiss="modal"
                aria-label="Close"
                class="close"
                type="button"
              >
                <span aria-hidden="true" class="valio-icon icon-reset icon-reset-black"></span>
              </button>
            </div>
            <div class="modal-body cx-facet-modal-body">
              <form>
                <div
                  *ngFor="let facet of searchResult.facets; index as facetId"
                  class="form-group"
                >
                  <h4 class="cx-facet-modal-label" for="megapixels">
                    {{ facet.name }}
                  </h4>
                  <div class="input-group">
                    <ul class="cx-facet-list">
                      <li *ngFor="let value of facet.values; index as facetValueId"
                          [ngClass]="value.selected?'selected':'not-selected'"
                      >
                        <div (click)="toggleValueForFacet(value.query.query.value,facet.code)" class="cx-facet-text">
                          {{ value.name }} ({{ value.count }})
                          <span class="valio-icon icon-sm icon-inline icon-check margin-left-base"></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- START ONLY SHOW FILTER SECTION IN MOBILE WHEN THEY ARE SELECTED -->
  <div class="container">
    <div *ngIf="false" class="cx-facet-mobile">
      <div class="cx-facet-filter-container">
        <h4 class="cx-facet-filter-header">
          {{ 'productList.appliedFilter' | cxTranslate }}
        </h4>
        <div
          *ngFor="let breadcrumb of searchResult.breadcrumbs"
          class="cx-facet-filter-pill"
          role="filter"
        >
              <span class="cx-facet-pill-value">
                {{ breadcrumb.facetValueName }}
              </span>
          <button
            (click)="toggleValueForFacet(breadcrumb.removeQuery.query.value, '')"
            aria-label="Close"
            class="close"
            type="button"
          >
            <span aria-hidden="true" class="valio-icon icon-reset icon-reset-black"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END ONLY SHOW FILTER SECTION IN MOBILE WHEN THEY ARE SELECTED -->
</ng-container>
