import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SiteContextComponentService, SiteContextSelectorComponent} from '@spartacus/storefront';

@Component({
  selector: 'valio-cx-site-context-selector',
  templateUrl: './valio-site-context-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioSiteContextSelectorComponent extends SiteContextSelectorComponent {

  constructor(
    componentService: SiteContextComponentService
  ) {
    super(componentService);
  }

  reloadPage(): void {
    window.location.reload();
  }
}
