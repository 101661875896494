import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ItemCounterModule} from "@spartacus/storefront";
import {ValioItemCounterComponent} from "./valio-item-counter.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [ValioItemCounterComponent],
  exports: [ValioItemCounterComponent],
})
export class ValioItemCounterModule extends ItemCounterModule {
}
