import {Action} from "@ngrx/store";

export const SELECT_UNIT = '[User] Select Unit';

export class SelectUnit implements Action {
  readonly type = SELECT_UNIT;

  constructor(public userId: string, public unit: string) {
  }
}

export const VALIO_FORGOT_PASSWORD_EMAIL_REQUEST_SUCCESS =
  '[Valio User] Forgot Password Email Request Success';

export class ForgotPasswordEmailRequestSuccess implements Action {
  readonly type = VALIO_FORGOT_PASSWORD_EMAIL_REQUEST_SUCCESS;

  constructor(public email: string) {
  }
}
