import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CmsConfig, ConfigModule} from '@spartacus/core';
import {ValioFooterComponent} from './valio-footer.component';
import {RouterModule} from '@angular/router';
import {GenericLinkModule, MediaModule, PageSlotModule} from '@spartacus/storefront';
import {ValioNavigationModule} from '../navigation/valio-navigation.module';
import {ValioSiteContextSelectorModule} from "../site-context-selector/valio-site-context-selector.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ValioFooterComponent: {
                    component: ValioFooterComponent,
                },
            },
        }),
        ValioNavigationModule,
        GenericLinkModule,
        PageSlotModule,
        MediaModule,
        ValioSiteContextSelectorModule,
    ],
    declarations: [ValioFooterComponent],
    exports: [ValioFooterComponent]
})
export class ValioFooterModule {
}
