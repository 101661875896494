<ng-container *ngIf="data$ | async as valioFaq">
    <div class="faq" id="faq-{{valioFaq.uid}}">
      <section class="question-title" (click)="showHideAnswer( 'faq-'+ valioFaq.uid)" >
        <h4>
          {{valioFaq.question}}
          <span class="valio-icon icon-chevron icon-chevron-right-down"></span>
        </h4>
      </section>
      <section class="answer-section d-none">
        <p class="text-bold">{{'faqs.answer'|cxTranslate}} </p>
        <p [innerHTML]="valioFaq.answer"></p>
      </section>
    </div>
</ng-container>


