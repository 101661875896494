import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CmsConfig, PageMetaModule, ConfigModule, I18nModule} from '@spartacus/core';
import {BreadcrumbModule} from "@spartacus/storefront";
import {ValioBreadcrumbComponent} from "./valio-breadcrumb.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                BreadcrumbComponent: {
                    component: ValioBreadcrumbComponent,
                },
            },
        }),
        PageMetaModule,
        I18nModule,
    ],
    declarations: [ValioBreadcrumbComponent],
    exports: [ValioBreadcrumbComponent]
})
export class ValioBreadcrumbModule extends BreadcrumbModule {
}
