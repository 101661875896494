import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';

import {ValioStateWithCategories} from "./valio-categories.action";
import {CategoryHierarchy} from "../../models";
import {ValioProductSearchConnector} from "../product/valio-product-search.connector";
import {ValioPartnerSiteService} from "../site/valio-partner-site.service";
import {filter, map, switchMap} from "rxjs/operators";
import {ValioVendorService} from "../vendor/valio-vendor.service";
import {ValioRestrictedAssortmentService} from "../site/valio-restricted-assortment.service";
import {ValioUser} from "../../models/misc.model";
import {UserAccountFacade} from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class ValioCategoriesService {
  constructor(
    protected store: Store<ValioStateWithCategories>,
    protected productSearchConnector: ValioProductSearchConnector,
    protected partnerSiteService: ValioPartnerSiteService,
    protected vendorService: ValioVendorService,
    protected userAccountFacade: UserAccountFacade,
    private restrictedAssortmentSiteService: ValioRestrictedAssortmentService,
  ) {
  }

// do some category caching to reduce ajax calls. This could be moved to store at some point
  private categoryMap = new Map();

  getCategories(superCategory: string, filterPartners: boolean, lang?: string): Observable<CategoryHierarchy> {// store disabled, because the load did not work twice in a row
    return this.partnerSiteService.getActive().pipe(
      switchMap(partner => this.vendorService.getVendor(partner).pipe(
        switchMap(vendor => this.restrictedAssortmentSiteService.getActiveAsUrlParameterValue().pipe(
          switchMap(assortment => this.userAccountFacade.get()
            .pipe(map(user => user as ValioUser))
            .pipe(switchMap(user => {
                if (!vendor || vendor.orderingAllowed) {
                  const vendorCode = filterPartners && vendor ? vendor.code : null;
                  const b2bUnit = user?.activeB2bUnit ? user.activeB2bUnit.uid : '';
                  const key = superCategory + "_" + vendorCode + '_' + assortment + "_" + b2bUnit + "_" + lang;

                  if (!this.categoryMap.get(key)) {
                    this.categoryMap.set(key, this.productSearchConnector.getCategories(superCategory, vendorCode, assortment, b2bUnit, lang));
                  }
                  return this.categoryMap.get(key);
                }
                return of({subcategories: []} as CategoryHierarchy);
              }
            ))
          ))
        ))
      ));
  }

}
