import {Injectable} from '@angular/core';
import {ValioOccRoutecalendarAdapter} from "./valio-occ-routecalendar.adapter";
import {Observable} from "rxjs";
import {RouteCalendar} from "../../models/valio-calendar.model";

@Injectable({
  providedIn: 'root',
})
export class ValioRoutecalendarConnector {
  constructor(protected adapter: ValioOccRoutecalendarAdapter) {
  }

  getRouteCalendar(userId: string, cartId: string): Observable<RouteCalendar> {
    return this.adapter.getRouteCalendar(userId, cartId);
  }
}
