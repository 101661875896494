import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccCartEntryAdapter } from "@spartacus/cart/base/occ";
import { Cart, CART_NORMALIZER } from "@spartacus/cart/base/root";
import {
  ConverterService,
  OccConfig,
  OccEndpointsService
} from "@spartacus/core";
import { Observable } from 'rxjs';
import { ValioOccEndpoints } from "../../valio-occ-endpoints";
import { ValioDatePipe } from "../pipes/valio-date.pipe";
import { ValioCart, ValioCartEntryUpdateData, ValioCartModification } from "./valio-cart.objects";

@Injectable()
export class ValioOccCartEntryAdapter extends OccCartEntryAdapter {
  endpoints: ValioOccEndpoints;

  constructor(
    protected cxDate: ValioDatePipe,
    protected config: OccConfig,
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService
  ) {
    super(http, occEndpointsService, converterService);
    this.endpoints = this.config.backend.occ.endpoints;
  }

  public updateEntry(
    userId: string,
    cartId: string,
    entryNumber: string,
    data: ValioCartEntryUpdateData
  ): Observable<ValioCart> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(
      'updateEntries',
      {
        urlParams: { userId, cartId, entryNumber },
        queryParams: this.getQueryParams(data)
      }
    );

    return this.http
      .patch<Cart>(url, {}, { headers })
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<ValioCart>;
  }


  public addEntry(
    userId: string,
    cartId: string,
    productCode: string,
    data: ValioCartEntryUpdateData
  ): Observable<ValioCart> {
    const toAdd = JSON.stringify({});

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(
      'addEntries',
      {
        urlParams: {
          userId,
          cartId,
        },
        queryParams: { ...this.getQueryParams(data), code: productCode }
      }
    );

    return this.http
      .post<Cart>(url, toAdd, { headers })
      .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<ValioCart>;
  }


  public addCsvEntries(
    userId: string,
    cartId: string,
    data: ValioCartEntryUpdateData[]
  ): Observable<ValioCartModification[]> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = this.occEndpointsService.buildUrl(
      'addCsvEntries',
      {
        urlParams: {
          userId,
          cartId,
        },
        queryParams: {}
      }
    );

    return this.http.post<ValioCartModification[]>(url, data, { headers });
  }

  public csvAddEntry(
    userId: string,
    cartId: string,
    productCode: string,
    data: ValioCartEntryUpdateData
  ): Observable<ValioCartModification> {
    const toAdd = JSON.stringify({});

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(
      'csvAddEntries',
      {
        urlParams: {
          userId,
          cartId,
        },
        queryParams: { ...this.getQueryParams(data), code: productCode }
      }
    );

    return this.http.post<ValioCartModification>(url, toAdd, { headers });
  }

  private getQueryParams(data: ValioCartEntryUpdateData) {
    return {
      qty: data.qty,
      poNumber: data.poNumber,
      allowMerge: data.allowMerge,
      schoolMilkSupport: data.schoolMilkSupport,
      date: data.date ? this.cxDate.transform(data.date, this.endpoints.dateFormat) : null,
      externalOrderNumber: data.externalOrderNumber,
      externalItemNumber: data.externalItemNumber,
      confirm: data.confirm,
      quickAdd: data.quickAdd
    };
  }
}
