import {NgModule} from "@angular/core";
import {ValioOccProductAdapter} from "./valio-occ-product.adapter";
import {
  PRODUCT_NORMALIZER,
  ProductAdapter,
  ProductReferencesAdapter,
  ProductSearchAdapter,
  ProductSearchService,
  ProductService,
  SearchboxService
} from "@spartacus/core";
import {ValioOccProductSearchAdapter} from "./valio-occ-product-search.adapter";
import {ValioOccProductReferencesAdapter} from "./valio-occ-product-references.adapter";
import {ValioProductService} from "./valio-product.service";
import {ValioSearchboxService} from "./valio-searchbox.service";
import {PRODUCT_LIST_NORMALIZER, ValioProductListNormalizer} from "./valio-product-list-normalizer";
import {ValioProductUrlNormalizer} from "./valio-product-url-normalizer";

@NgModule({

  providers: [
    {
      provide: ProductService,
      useClass: ValioProductService,
    },
    {
      provide: ProductAdapter,
      useClass: ValioOccProductAdapter,
    },
    {
      provide: ProductSearchAdapter,
      useClass: ValioOccProductSearchAdapter,
    },
    {
      provide: ProductReferencesAdapter,
      useClass: ValioOccProductReferencesAdapter,
    },
    {
      provide: ProductSearchService,
      useClass: ValioSearchboxService
    },
    {
      provide: SearchboxService,
      useClass: ValioSearchboxService
    },
    {
      provide: PRODUCT_LIST_NORMALIZER,
      useClass: ValioProductListNormalizer,
      multi: true
    },
    {
      provide: PRODUCT_NORMALIZER,
      useClass: ValioProductUrlNormalizer,
      multi: true
    }

  ]
})

export class ValioProductModule {
}
