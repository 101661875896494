import {Component} from '@angular/core';
import {UpdateProfileComponent} from '@spartacus/user/profile/components';
import {Observable} from "rxjs";
import {User} from "@spartacus/user/account/root";
import {ValioUpdateProfileComponentService} from "./valio-update-profile.component.service";

@Component({
  selector: 'valio-cx-update-profile',
  templateUrl: './valio-update-profile.component.html',
})
export class ValioUpdateProfileComponent extends UpdateProfileComponent {

  user$: Observable<User> = this.service.user$;
  constructor(
    protected service: ValioUpdateProfileComponentService
  ) {
    super(service);
  }
}
