import {Injectable} from "@angular/core";
import {PostalCodeConnector} from "./postalCode.connector";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class PostalCodeService {

  constructor(private connector: PostalCodeConnector){}

  validPostalCode(postCode: string): Observable<boolean> {
    return this.connector.validPostalCode(postCode);
  }

  validAnonymousPostalCode(postCode: string): Observable<boolean> {
    return this.connector.validAnonymousPostalCode(postCode);
  }
}
