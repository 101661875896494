/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {Language, LanguagesState, SiteContextActions} from "@spartacus/core";

export const initialState: LanguagesState = {
  entities: null,
  activeLanguage: null,
};

export function languageReducer(
  state = initialState,
  action: SiteContextActions.LanguagesAction
): LanguagesState {
  switch (action.type) {
    case SiteContextActions.LOAD_LANGUAGES_SUCCESS: {
      const languages: Language[] = action.payload;
      const entities = languages.reduce(
        (langEntities: { [isocode: string]: Language }, language: Language) => {
          return {
            ...langEntities,
            [language.isocode ?? '']: language,
          };
        },
        {
          ...state.entities,
        }
      );

      return {
        ...state,
        entities,
      };
    }

    case SiteContextActions.SET_ACTIVE_LANGUAGE: {
      const isocode = action.payload;

      return {
        ...state,
        activeLanguage: isocode,
      };
    }
  }
  return state;
}
