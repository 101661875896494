<div class="image-center product-cart-image" *ngIf="product.images; else productNoImages">
  <cx-media class="cx-product-image" [container]="product.images.PRIMARY" format="product"
            [alt]="product?.summary"></cx-media>
</div>
<ng-template #productNoImages>
  <div class="image-center">
    <cx-media class="cx-product-image" [container]="null" format="product" [alt]="product?.summary"></cx-media>
  </div>
</ng-template>
<div class="product-top d-print-none">
  <div class="valio-product-bread-crumb">
    <div class="category-bread-crumb" *ngFor="let crumb of breadCrumbs$ | async; index as idx">
      <a [routerLink]="'/search'"
         [queryParams]="{query:':relevance:category:'+ crumb.facetValueCode}">
        {{crumb.facetValueName}}
      </a>
      <span>&nbsp;>&nbsp;</span>
    </div>
  </div>
  <button
    (click)="print()"
    class="btn btn-icon btn-print valio-icon icon-print d-none d-lg-block">
  </button>
</div>
<div class="product-info">
  <span>{{ product.partnerSupplier.name }}</span>
  <h1>{{ product.name}}</h1>
  <valio-product-icons
    [product]="product"
  ></valio-product-icons>
  <section class="product-info-section">
    <span *ngIf="product.ean">{{ 'valioProduct.productDetails.ean' | cxTranslate }} {{ product.ean }}</span>
    <span>{{ 'cartItems.id' | cxTranslate }} {{ product.code|productCode }}</span>
    <span *ngIf="product.unit?.conversionToInvoiceUnit">{{product.unit?.conversionToInvoiceUnit}}</span>
  </section>
  <section class="d-none d-md-block product-price-section">
    <span
      *ngIf="product.salesUnitPrice"><strong>{{product.salesUnitPrice?.formattedValue}}</strong>/{{product.unit?.name}}</span>
    <span
      *ngIf="product.comparisonPrice"><strong>{{product.comparisonPrice?.formattedValue}}</strong>/{{product.baseUnit?.name}}</span>
  </section>
</div>
