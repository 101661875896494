import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {
  getBoughtTogetherContent,
  ValioLoadBoughtTogether,
  ValioStateWithBoughtTogether
} from "./valio-bought-together.action";
import {map, tap} from "rxjs/operators";
import {ProductSearchPage} from "@spartacus/core";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ValioBoughtTogetherService {
  constructor(
    protected store: Store<ValioStateWithBoughtTogether>
  ) {
  }

  getBoughtTogether(): Observable<ProductSearchPage> {
    return this.store.pipe(
      select(getBoughtTogetherContent),
      tap(details => {
        if (Object.keys(details).length == 1) {
          // Initial data load
          this.loadBoughtTogether(null, null, null);
        }
      })
    );
  }

  public loadBoughtTogether(productCode: string, cartId: string, entry: string) {
    if (environment.marketingCloud.boughtTogether.enabled == true) {
      this.store.dispatch(new ValioLoadBoughtTogether({productCode: productCode, cartId: cartId, entry: entry}));
    }
  }

  isLoaded(): Observable<boolean> {
    return this.store.select(getBoughtTogetherContent)
      .pipe(map(() => {
        return true;
      }));
  }
}
