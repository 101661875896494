<ng-container *ngIf="banner; else bannerComponent">
  <ng-container *ngTemplateOutlet="bannerContent; context: { banner: banner, isCarousel: true }">
  </ng-container>
</ng-container>

<ng-template #bannerComponent>
  <ng-container *ngIf="data$ | async as banner">
    <ng-container *ngTemplateOutlet="bannerContent; context: { banner: banner, isCarousel: false }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #bannerContent let-banner="banner" let-isCarousel="isCarousel">
  <ng-container *ngIf="selectedPartner$|async as selectedPartner">
    <div class="banner-paddings" *ngIf="showBanner(selectedPartner, banner.partner)" [ngClass]="{'is-anonymous' : isAnonymous$ | async}">
      <div class="banner-container">
        <cx-media [container]="banner.media" [ngClass]="{'dark-layer' : banner.hideDarkLayer == 'false'}"></cx-media>
        <div [ngClass]="{
                    'text-dark' : banner.textColor == 'DARK',
                    'text-white' : banner.textColor == 'LIGHT',
                    'text-background-box' : banner.textBox == 'true',
                    'text-shadow' : banner.textShadow == 'true'}" class="banner-content">
          <h2 class="h1" *ngIf="banner.headText?.length > 0" [innerHTML]="banner.headText"></h2>
          <p *ngIf="banner.headText?.length > 0" [innerHTML]="banner.text"></p>

          <a *ngIf="banner.buttonName?.length > 0 && banner.queryParams?.length > 0" [innerHTML]="banner.buttonName"
             [routerLink]="'/search'" [queryParams]="{query: banner.queryParams}"
             [ngClass]="{
                    'btn-lg' : !isCarousel,
                    'btn-sm' : isCarousel,
                    'btn-primary' : banner.buttonColor == 'PRIMARY',
                    'btn-primary-inv' : banner.buttonColor == 'SECONDARY',
                    'btn-link' : banner.buttonColor == 'TEXT'}" class="btn margin-bottom-base"></a>

          <a *ngIf="banner.buttonName?.length > 0 && banner.urlLink?.length > 0" [innerHTML]="banner.buttonName"
             [routerLink]="banner.urlLink"
             [ngClass]="{
                    'btn-lg' : !isCarousel,
                    'btn-sm' : isCarousel,
                    'btn-primary' : banner.buttonColor == 'PRIMARY',
                    'btn-primary-inv' : banner.buttonColor == 'SECONDARY',
                    'btn-link' : banner.buttonColor == 'TEXT'}" class="btn margin-bottom-base"></a>

          <a *ngIf="banner.buttonName?.length > 0 && banner.switchPartner?.length > 0" [innerHTML]="banner.buttonName"
             (click)="switchPartner(banner.switchPartner)"
             [routerLink]="'/search'"
             [ngClass]="{
                    'btn-lg' : !isCarousel,
                    'btn-sm' : isCarousel,
                    'btn-primary' : banner.buttonColor == 'PRIMARY',
                    'btn-primary-inv' : banner.buttonColor == 'SECONDARY',
                    'btn-link' : banner.buttonColor == 'TEXT'}" class="btn margin-bottom-base"></a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
