import {Injectable} from "@angular/core";
import {Vendor} from "../../models/misc.model";
import {Observable} from "rxjs";
import {ValioVendorAdapter} from "./valio-vendor.adapter";


@Injectable({
  providedIn: 'root',
})
export class ValioVendorConnector {
  constructor(protected adapter: ValioVendorAdapter) {
  }

  getVendors(userId: string): Observable<Vendor[]> {
    return this.adapter.get(userId);
  }
}
