import {InjectionToken, Provider} from "@angular/core";
import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store";
import {
  ValioCsvImportsState,
  ValioCsvImportState,
  ValioCsvImportFail,
  ValioCsvImportSuccess,
  CSV_IMPORT_SUCCESS,
  CSV_IMPORT_FAIL, ValioCsvImportReset, CSV_IMPORT_RESET
} from "./valio-csv-import.action";
import {StateUtils} from "@spartacus/core";

export const initialState: ValioCsvImportState = {
  content: []
};

export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<ValioCsvImportsState>> = new InjectionToken<ActionReducerMap<ValioCsvImportsState>>('CsvImportReducers');

export const valioCsvImportReducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getValioCsvImportReducers,
};

export function clearState(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export function getValioCsvImportReducers(): ActionReducerMap<ValioCsvImportsState> {
  return {
    active: StateUtils.loaderReducer<ValioCsvImportState>(CSV_IMPORT_SUCCESS, valioCsvImportReducer)
  };
}

export function valioCsvImportReducer(state = initialState, action: ValioCsvImportSuccess | ValioCsvImportFail | ValioCsvImportReset): ValioCsvImportState {
  switch (action.type) {
    case CSV_IMPORT_SUCCESS: {
      return {content: [...state.content, ...action.payload.cartModification]};
    }
    case CSV_IMPORT_FAIL:
    case CSV_IMPORT_RESET: {
      return initialState;
    }
  }
  return state;
}
