import {NgModule} from '@angular/core';
import {ValioInputModule} from "../../../input/valio-input.module";
import {ReactiveFormsModule} from "@angular/forms";
import {I18nModule} from "@spartacus/core";
import {ValioDatepickerInputComponent} from "./valio-datepicker-input.component";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {ValioDatepickerDayComponent} from "./valio-datepicker-day.component";


@NgModule({
    exports: [
        ValioDatepickerInputComponent,
        ValioDatepickerDayComponent,
    ],
    imports: [
        ValioInputModule,
        ReactiveFormsModule,
        I18nModule,
        NgbDatepickerModule,
        CommonModule,
    ],
    declarations: [ValioDatepickerInputComponent, ValioDatepickerDayComponent]
})
export class ValioDatepickerModule {
}
