<div class="suggestive-row">
  <div class="row">
    <div class="col-12 col-md-6 col-xl-2" *ngIf="partners$|async as partners">
      <ng-select
        [searchable]="false"
        [clearable]="true"
        placeholder="{{ 'valioRegister.vendor.selectOne'|cxTranslate}}"
        bindLabel="name"
        bindValue="code"
        [items]="partners"
        (change)="selectPartner($event)"
      ></ng-select>
    </div>
    <div class="col-12 col-md-6 col-xl-4">
      <valio-suggestive-cx-searchbox
        [baseOrderMode]="true"
        [setSelectedPartner]="selectedPartner"
        [value]="(selectedProduct.code ? ((selectedProduct.code|productCode)  +', ') : '') + selectedProduct.name"
        (onSelect)="selectProduct($event)"
        (addToCartEmitter)="addToCart()"
      ></valio-suggestive-cx-searchbox>
    </div>
    <div class="col-12 col-md-6 col-xl-4 text-nowrap">
      <valio-cx-item-counter
        [isValueChangeable]="true"
        [step]="1"
        [min]="1"
        [value]="selectedQty"
        [max]="1000"
        (update)="updateQuantity($event)"
        [showButtons]="true"
        [stepperTimeout]="0"
        [unit]="selectedUnit"
        [valueSetter$]="valueSetter.asObservable()"
        [styleClass]="'cx-counter cx-counter-lg cx-counter-transparent cx-counter-suggestive margin-bottom-base'"
      >
      </valio-cx-item-counter>
      <div class="d-inline-block margin-left-base padding-top-sm" *ngIf="selectedUnit?.conversionToWeightUnit">
        {{'cart.cartItems.unit'|cxTranslate}}
        <div class="bold">{{selectedUnit?.conversionToInvoiceUnit}}</div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-2">
      <valio-cx-input [labelPlaceholder]="'cart.cartItems.poNumber'"
                      [nameForm]="'poNumber'" class="valio-input dark-input small-text"
                      [inputValue]="selectedPoNumber ? selectedPoNumber : ''"
                      (change)="selectPoNumber($event)"></valio-cx-input>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-xl-2">
      <ng-select
        [searchable]="false"
        [clearable]="false"
        bindLabel="name"
        bindValue="code"
        [ngModel]="evenOdd"
        (change)="selectEvenOdd($event)"
      >
        <ng-option [value]="'EVERY_WEEK'">{{'myaccount.baseorder.evenOdd.every'|cxTranslate}}</ng-option>
        <ng-option [value]="'EVEN_WEEK'">{{'myaccount.baseorder.evenOdd.even'|cxTranslate}}</ng-option>
        <ng-option [value]="'ODD_WEEK'">{{'myaccount.baseorder.evenOdd.odd'|cxTranslate}}</ng-option>
      </ng-select>
    </div>
    <div class="col-12 col-xl-8">
      <div class="row">
        <ng-container *ngFor="let weekDay of weekDays">
          <div class="col-4 week-day-column" *ngIf="isWeekdayAllowed(weekDay)">
            <div class="week-day-selector form-check"
                 [attr.data-day]="weekDay"
                 id="weekDay_{{weekDay}}">
              <label>
                <input type="checkbox" name="checkDay_{{weekDay}}" (change)="toggleSelector( weekDay )"/>
                <span class="checkmark"></span>
                <span class="form-check-label">{{'cart.header.weekdayShort.' + weekDay |cxTranslate}}</span>
              </label>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-12 col-xl-2">
      <button
        (click)="addToCart()"
        [class.disabled]="weekDayError||codeError"
        [disabled]="weekDayError||codeError"
        type="submit"
        class="btn btn-lg btn-block btn-primary">
        {{'cart.header.addToCart'|cxTranslate}}
      </button>
    </div>
  </div>
</div>
