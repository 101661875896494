import {DIALOG_TYPE, LayoutConfig} from "@spartacus/storefront";
import {ValioDeliveryNoteReclamationComponent} from "./valio-deliverynote-reclamation.component";
import {ValioDeliveryNote, ValioDeliveryNoteList} from "../../../../models/misc.model";

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    DELIVERY_NOTE_RECLAMATION = 'DELIVERY_NOTE_RECLAMATION',
  }
}

export const valioDeliveryNoteReclamationComponent: LayoutConfig = {
  launch: {
    DELIVERY_NOTE_RECLAMATION: {
      inlineRoot: true,
      component: ValioDeliveryNoteReclamationComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    }
  },
};
export interface ValioDeliveryNoteReclamationDialogData {
  deliveryNoteList: ValioDeliveryNoteList,
  deliveryNote: ValioDeliveryNote
}
