import {ChangeDetectionStrategy, Component, OnDestroy,} from '@angular/core';
import {ValioCheckoutService} from "../../../../../services/checkout/valio-checkout.service";
import {Observable, Subscription} from "rxjs";
import {ValioOrder} from "../../../../../services/checkout/valio-checkout.module";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {ActivatedRoute} from "@angular/router";
import {ValioCart, ValioCartPaymentModeGroupedEntryWrapper} from "../../../../../services/cart/valio-cart.objects";
import {AuthService, RoutingService} from "@spartacus/core";
import {ValioUserService} from "../../../../../services/user/valio-user.service";
import {ValioUser} from "../../../../../models/misc.model";
import {DateUtils} from "../../../../misc/util/date-utils";
import {UserAccountFacade} from '@spartacus/user/account/root';
import {map, mergeMap} from "rxjs/operators";
import {Actions, ofType} from "@ngrx/effects";
import {PLACE_ORDER_SUCCESS} from "../../../../checkout/core/store/actions/checkout.action";
import {PlaceOrderSuccess} from "../../../../../services/cart/valio-cart-entry.action";
import {ValioDatePipe} from "../../../../../services/pipes/valio-date.pipe";

@Component({
  selector: 'valio-cx-order-confirmation-items',
  templateUrl: './valio-order-confirmation-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioOrderConfirmationItemsComponent implements OnDestroy {
  order$: Observable<ValioOrder>;
  cart$: Observable<ValioCart>;
  loggedUser: boolean;
  user$: Observable<ValioUser>;
  subscriptions: Subscription = new Subscription();
  isContractCustomer: boolean;

  constructor(
    protected checkoutService: ValioCheckoutService,
    protected cartService: ValioCartService,
    protected route: ActivatedRoute,
    protected userService: ValioUserService,
    protected userAccountFacade: UserAccountFacade,
    protected actions$: Actions,
    protected cxDate: ValioDatePipe,
    protected authService?: AuthService,
    protected router?: RoutingService
  ) {
  }


  ngOnInit() {
    this.subscriptions.add(
      this.userService.isSignedIn().subscribe(signed => this.loggedUser = signed)
    );
    const date: string = this.route.snapshot.queryParamMap.get('date');
    const orderCode: string = this.route.snapshot.queryParamMap.get('thankyou');
    if (date != null) {
      this.checkoutService.loadOrder(date, orderCode);
    }
    // Wait for load order to complete and then get order details
    this.order$ = this.actions$.pipe(
      ofType(PLACE_ORDER_SUCCESS),
      map((action: PlaceOrderSuccess) => action),
      mergeMap((payload) => {
          return (this.checkoutService.getOrderDetails() as Observable<ValioOrder>)
        }
      ));

    this.cart$ = this.cartService.getActive();
    this.subscriptions.add(this.userService.isContractCustomer().subscribe(val => {
      this.isContractCustomer = val;
    }))
  }

  ngOnDestroy() {
    this.checkoutService.clearCheckoutData();
    this.subscriptions.unsubscribe();
  }

  convertDate(date: string): Date {
    return DateUtils.convertDate(date);
  }

  getDeliveryDay(date: string): string {
    return 'cart.header.weekday.' + (1 + this.convertDate(date).getDay());
  }

  getInvoiceEntry(paymentModeGroupedEntries: { [p: string]: ValioCartPaymentModeGroupedEntryWrapper }) {
    if (paymentModeGroupedEntries["INVOICE"]) {
      return paymentModeGroupedEntries["INVOICE"];
    }
    return null;
  }

  getCardEntry(paymentModeGroupedEntries: { [p: string]: ValioCartPaymentModeGroupedEntryWrapper }) {
    if (paymentModeGroupedEntries["CARD"]) {
      return paymentModeGroupedEntries["CARD"];
    }
    return null;
  }

  goToCart(cart: ValioCart) {
    const deliveryDate: string = this.findIncompleteOrderDate(cart)
    // Change date to first incomplete order date.
    if (deliveryDate !== null) {
      this.cartService.changeDate(deliveryDate);
    }
    this.router.go('/cart');
  }

  private findIncompleteOrderDate(cart: ValioCart): string {
    if (cart != null) {
      if (cart.dateGroupedEntries) {
        const currentDay: Date = DateUtils.getNow();
        const date = this.cxDate.transform(currentDay, 'yyyy-MM-dd');
        for (const entry of cart.dateGroupedEntries) {
          if (entry.deliveryDate >= date && entry.validForOrdering) {
            return entry.deliveryDate; // Stop and return the first matching deliveryDate.
          }
        }
      }
    }
    return null
  }

  async goToHomePage() {
    if (this.isContractCustomer) {
      this.router.go('/');
    } else {
      await this.authService.coreLogout()
      await this.router.go('/logoutPreview')
    }
  }

  isCartUnapproved(cart: ValioCart) {
    return cart.dateGroupedEntries && cart.dateGroupedEntries.filter(g => g.status == 'open').length > 0;
  }

  logOrder(order: ValioOrder) {
    console.log(order);
  }
}
