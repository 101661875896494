<div class="vouchers">
  <div class="row">
    <div class="col-12">
      <div class="vouchers-header">{{ 'voucher.header' | cxTranslate }}</div>
    </div>
  </div>
  <div class="row voucher-details">
    <div class="col-12 col-lg-6">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-7 col-xl-8">
          <valio-cx-input
            idName="applyVoucher"
            [isNotValidFunction]="isInvalidVoucher()"
            [nameForm]="'applyVoucher'"
            [parentForm]="form"
            [labelPlaceholder]="'voucher.placeholder'"
            [errorMessage]="'voucher.inValid'"
            class="valio-input"></valio-cx-input>
        </div>
        <div class="col-12 col-sm-6 col-lg-5 col-xl-4">
          <button class="btn btn-lg btn-block btn-primary margin-bottom-base" type="submit" (click)="applyVoucher($event)">
            {{ 'voucher.apply' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="applied-vouchers" *ngIf="cart.appliedVouchers?.length > 0">
        <div class="row" *ngFor="let voucher of cart.appliedVouchers">
          <div class="col-12 col-sm-6">
            {{'voucher.vouchersApplied'|cxTranslate: {voucherCode: voucher.voucherCode} }} <a (click)="removeVoucher(voucher.voucherCode)">{{'voucher.remove' | cxTranslate}}</a>
          </div>
          <div class="col-12 col-sm-6">
            {{voucher.description}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
