import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {NgModule, Provider} from '@angular/core';

import {HttpErrorHandler, I18nModule, provideConfigFromMetaTags} from '@spartacus/core';
import {OutletRefModule, SpinnerModule, defaultCmsContentProviders} from '@spartacus/storefront';

import {AppComponent} from './app.component';
import {FeaturesModule} from "./features/features.module";
import {environment} from '../environments/environment';
import {extModules} from '../environments/modules';
// locales
import {registerLocaleData} from "@angular/common";

import localeEn from '@angular/common/locales/en';
import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv';

import {ValioBrowserCheckModule} from "./features/cms-components/navigation/browserCheck/valio-browser-check.module";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {ValioUnauthorizedErrorHandler} from "./shared/utils/handlers/valio-unauthorized.handler";
import {EffectsFeatureModule, EffectsModule} from "@ngrx/effects";
import {UserAccountFacade} from '@spartacus/user/account/root';
import {ValioUserAccountFacade} from "./services/user/valio-user-account.facade";
import {ROUTER_CONFIGURATION} from "@angular/router";
import {SpartacusModule} from "./spartacus/spartacus.module";
import {AppRoutingModule} from "./app-routing.module";
import {StoreModule} from "@ngrx/store";
import {HttpClientModule} from "@angular/common/http";
import {CheckoutCostCenterAdapter} from "@spartacus/checkout/b2b/core";
import {OccCheckoutCostCenterAdapter} from "@spartacus/checkout/b2b/occ";
import {OccCheckoutAdapter} from "@spartacus/checkout/base/occ";
import {CheckoutPaymentConnector} from "@spartacus/checkout/base/core";

registerLocaleData(localeFi, 'fi');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeSv, 'sv');

export const facadeProviders: Provider[] = [

    ValioUserAccountFacade,
    {
        provide: UserAccountFacade,
        useExisting: ValioUserAccountFacade,
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        SpartacusModule,
        HttpClientModule,

        BrowserModule.withServerTransition({appId: 'spartacus-app'}),
        OutletRefModule,
        BrowserTransferStateModule,
        I18nModule,
        FeaturesModule,
        extModules,
        SpinnerModule,
        ValioBrowserCheckModule,
        EffectsModule,
        EffectsFeatureModule,
        GoogleTagManagerModule.forRoot({
            id: environment.googleManager.id,
        })
        , SpartacusModule
    ],


    providers: [
        defaultCmsContentProviders,
        facadeProviders,
        provideConfigFromMetaTags(),
        {
            provide: HttpErrorHandler,
            useExisting: ValioUnauthorizedErrorHandler,
            multi: true
        },
        {
            provide: ROUTER_CONFIGURATION,
            useValue: {
                scrollPositionRestoration: 'enabled'
            }
        },
        CheckoutPaymentConnector,
        OccCheckoutAdapter,
        OccCheckoutCostCenterAdapter,
        {
            provide: CheckoutCostCenterAdapter,
            useClass: OccCheckoutCostCenterAdapter,
        },
    ],

    bootstrap: [AppComponent]
})


export class AppModule {
}

