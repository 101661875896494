import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsBannerComponent} from '../../../../models';
import {Observable} from 'rxjs';
import {ValioPartnerSiteService} from "../../../../services/site/valio-partner-site.service";
import {ALL_PARTNERS} from "../../../../services/site/valio-site.constants";
import {ValioUserService} from "../../../../services/user/valio-user.service";
import {IGNORE_SEARCH_URL_PARAM} from "../../product/container/valio-product-list-component.service";
import {Router} from "@angular/router";
import {RoutingService} from "@spartacus/core";


@Component({
  selector: 'valio-cx-banner',
  templateUrl: './valio-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioBannerComponent implements OnInit {

  @Input() banner: ValioCmsBannerComponent;

  data$ = this.componentData.data$;
  selectedPartner$: Observable<string>;
  isAnonymous$: Observable<boolean>;

  constructor(
    protected componentData: CmsComponentData<ValioCmsBannerComponent>,
    protected partnerSiteService: ValioPartnerSiteService,
    protected userService: ValioUserService,
    protected router2: Router,
    protected valioPartnerSiteService: ValioPartnerSiteService,
    protected routerService: RoutingService
  ) {
  }

  ngOnInit(): void {
    this.selectedPartner$ = this.partnerSiteService.getActive();
    this.isAnonymous$ = this.userService.isAnonymous();
  }

  showBanner(selectedPartner: string, partner: string) {
    return (selectedPartner == ALL_PARTNERS && (partner == null || partner.length == undefined)) || partner == selectedPartner;
  }

  switchPartner(vendorCode) {
    // force page reload
    this.router2.navigateByUrl('/?' + IGNORE_SEARCH_URL_PARAM, {skipLocationChange: true}).then(() => {
      this.valioPartnerSiteService.setActive(vendorCode);
      this.routerService.go('/search');
    });
  }
}
