import {Component} from '@angular/core';
import {CmsComponentData} from '@spartacus/storefront';
import {ValioCmsFaqComponent} from '../../../../models';

@Component({
  selector: 'valio-faq-component',
  templateUrl: './valio-faq.component.html',
})
export class ValioFaqComponent {
  data$ = this.componentData.data$;

  constructor(
    protected componentData: CmsComponentData<ValioCmsFaqComponent>
  ) {
  }

  showHideAnswer(sectionId: string) {
    const parent = document.getElementById(sectionId);
    parent.querySelector('.question-title').classList.toggle('collapsed');
    parent.querySelector('.answer-section').classList.toggle('d-none');
  }
}
