import {Action} from "@ngrx/store";
import {Qualtrics} from "./valio-environment.objects";

export const LOAD_QUALTRICS = '[Environment] Load Qualtrics';
export const LOAD_QUALTRICS_FAIL = '[Environment] Load Qualtrics Fail';
export const LOAD_QUALTRICS_SUCCESS = '[Environment] Load Qualtrics Success';

export class LoadQualtrics implements Action {
  readonly type = LOAD_QUALTRICS;

  constructor() {
  }
}

export class LoadQualtricsFail implements Action {
  readonly type = LOAD_QUALTRICS_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadQualtricsSuccess implements Action {
  readonly type = LOAD_QUALTRICS_SUCCESS;

  constructor(public payload: Qualtrics) {

  }
}

export type ValioEnvironmentAction =
  | LoadQualtrics
  | LoadQualtricsFail
  | LoadQualtricsSuccess;
