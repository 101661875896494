import {NgModule} from '@angular/core';
import {valioCategoriesReducerProvider} from "./valio-categories.reducer";


@NgModule({
  imports: [
    
    //   StoreModule.forFeature(CATEGORIES_FEATURE, reducerToken, {metaReducers}),
    //   EffectsModule.forFeature([ValioCategoriesEffects]),
  ],

  providers: [
    valioCategoriesReducerProvider
  ]
})
export class ValioCategoriesModule {

}


