import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {I18nModule, UrlModule,} from '@spartacus/core';
import {MediaModule, SearchBoxModule} from "@spartacus/storefront";
import {ValioSearchBoxComponent} from "./valio-search-box.component";
import {ValioHighlightPipe} from "./valio-highlight.pipe";
import {ValioPipeModule} from "../../../../services/pipes/valio-pipe.module";
import {ValioProductTooltipModule} from "../../product/product-tooltip/valio-product-tooltip.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        UrlModule,
        I18nModule,
        SearchBoxModule,
        ValioPipeModule,
        ValioProductTooltipModule
    ],
    declarations: [ValioSearchBoxComponent, ValioHighlightPipe],
    exports: [ValioSearchBoxComponent, ValioHighlightPipe]
})
export class ValioSearchBoxModule {
}
