<ng-container *ngIf="signedIn$ | async">
  <ng-container *ngIf="user$ | async as user">
    <ng-container *ngIf="data$ | async as data">
      <div *ngIf="isShown(user, data)" class="news-item">
        <span class="news-date">{{convertDate(data.date)|valioDate:'shortDate'}}</span>
        <span class="d-none d-sm-inline-block news-partner">
        <ng-container *ngIf="getPartner(data.partner)|async as partner">{{partner != null ? partner.name : ''}}</ng-container>
        <ng-container *ngIf="data.partner==null">VALO</ng-container>
      </span>
        <span [innerHTML]="data.title" class="news-title"></span>
        <span class="news-popup-title">
        <a *ngIf="data.externalLink=='true'" [innerHTML]="data.title" href="{{data.attachmentName}}" target="_blank"></a>
        <a *ngIf="data.externalLink=='false'" [innerHTML]="data.title" [routerLink]="data.url"></a>
      </span>
        <span class="news-url">
        <a *ngIf="data.externalLink=='true'" href="{{data.url}}" target="_blank">{{'navigation.news.open'|cxTranslate}}</a>
        <a *ngIf="data.externalLink=='false'" (click)="openLink(data.url)">{{'navigation.news.open'|cxTranslate}}</a>
      </span>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
