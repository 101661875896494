import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProductListItemComponent} from "@spartacus/storefront";
import {ValioProduct} from "../../../../models";

@Component({
  selector: 'valio-cx-product-list-item',
  templateUrl: './valio-product-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioProductListItemComponent extends ProductListItemComponent{
  @Input() product: ValioProduct;
  @Input() listName: string;

}
