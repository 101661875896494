import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CartDetailsComponent} from "@spartacus/cart/base/components";
import {CartConfigService} from "@spartacus/cart/base/core";
import {AuthService, RoutingService} from '@spartacus/core';
import {Observable} from "rxjs";
import {ValioCart, ValioCartEntry, ValioCartEntryUpdateData} from "../../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {DateUtils} from "../../../misc/util/date-utils";
import {SelectiveCartFacade} from "@spartacus/cart/base/root";


@Component({
    selector: 'valio-cx-cart-details',
    templateUrl: './valio-cart-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioCartDetailsComponent extends CartDetailsComponent {
    valioCart$: Observable<ValioCart>;
    @Input() isReadOnly = false;
    addMessage: ValioCartEntry;

    constructor(
        protected cartService: ValioCartService,
        protected selectiveCartService: SelectiveCartFacade,
        protected authService2: AuthService,
        protected routingService2: RoutingService,
        protected cartConfig: CartConfigService
    ) {
        super(cartService, selectiveCartService, authService2, routingService2, cartConfig);
    }

    ngOnInit() {
        this.cartLoaded$ = this.cartService.isStable();
        this.valioCart$ = this.cartService.getActive();
    }

    convertDate(date: string): Date {
        return DateUtils.convertDate(date);
    }

    addToCart(data: { code: string, data: ValioCartEntryUpdateData }): void {
        this.addMessage = null;
        if (data.data.qty >= 100 && data.data.confirm == null) {
            this.addMessage = {
                erpMessages: [{
                    message: 'cart.cartItems.messages.maxQuantity',
                    confirm: true,
                    severity: 'INFO'
                }], product: {code: data.code}, quantity: data.data.qty
            };
        } else if (data.data.confirm != false) {
            this.cartService.addEntryToCart(data.code, data.data);
        }
    }

    updateCart(data: any): void {
        this.cartService.updateEntryData(data.item.entryNumber, data.data);
    }
}
