import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AddToCartModule } from "@spartacus/cart/base/components/add-to-cart";
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from "@spartacus/core";
import { ItemCounterModule } from "@spartacus/storefront";
import { ValioPipeModule } from "../../../../services/pipes/valio-pipe.module";
import { ValioItemCounterModule } from '../cart-shared/cart-item/item-counter/valio-item-counter.module';
import { ValioAddToCartComponent } from "./valio-add-to-cart.component";

@NgModule({
  imports: [
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ProductAddToCartComponent: {
          component: ValioAddToCartComponent,
        },
      },
    }),
    ItemCounterModule,
    I18nModule,
    CommonModule,
    ValioItemCounterModule,
    RouterModule,
    UrlModule,
    ValioPipeModule
  ],
  declarations: [ValioAddToCartComponent],
  exports: [ValioAddToCartComponent]
})
export class ValioAddToCartModule extends AddToCartModule {
}
