import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'valio-cx-mini-cart',
  templateUrl: './valio-mini-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioMiniCartComponent implements OnInit {
  quantity$: Observable<number>;

  constructor(protected cartService: ValioCartService) {

  }

  ngOnInit(): void {
    this.quantity$ = this.cartService.getActive().pipe(
      map(cart => cart.deliveryItemsQuantity || 0)
    );
  }
}
